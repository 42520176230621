@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Protest+Revolution&display=swap');

.Contenthub_inicio_destacat {
	width: 90vw;
	margin: 8px auto;
	padding: 10px;
}

.Contenthub_inicio_destacat .container {
	background-color: #fff;
	position: relative;
	overflow: hidden;
	width: 100%;
	min-height: 500px;
	padding: 0;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
}

.overlay-container {
	width: 50%;
	background: linear-gradient(-45deg, #ee7752, #f56da1, #8cf2ff, #ff5fb7);
	background-size: 400% 400%;
	animation: gradient 12s ease infinite;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}

.overlay-panel {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}


@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}

	50% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0% 50%;
	}
}


/**/

.overlay-panel h2 {
	font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
	font-size: 20px;
	margin-bottom: 20px;
	color: #5c5c5c;
}

.overlay-panel h1 {
	display: flex;
	align-items: center;
	font-family: "Protest Revolution", sans-serif;
	font-size: 42px;
	color: #414141;
	margin: 8px auto;
	text-shadow: 1px 4px 3px rgba(0, 0, 0, 0.6);
}

.overlay-panel p {
	font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
	font-size: 10px;
	color: #666666;
}

.overlay-panel .heart {
	font-size: 50px;
	margin-right: 15px;
	color: rgb(255, 80, 80);
	animation: beat2 .4s infinite alternate;
	transform-origin: center;
}

.overlay-panel .heart.left {
	margin-left: 15px;
}

/* Heart beat animation */
@keyframes beat2 {
	to {
		transform: scale(1.2);
	}
}

/*/*/

/* From Uiverse.io by Wendell47 */
.button_registerhub {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 15px 30px;
	border: 0;
	position: relative;
	overflow: hidden;
	border-radius: 10rem;
	transition: all 0.02s;
	font-weight: bold;
	cursor: pointer;
	color: rgb(37, 37, 37);
	z-index: 0;
	box-shadow: 0 0px 7px -5px rgba(0, 0, 0, 0.5);
}

.button_registerhub:hover {
	background: rgb(193, 228, 248);
	color: rgb(33, 0, 85);
}

.button_registerhub:active {
	transform: scale(0.97);
}

.hoverEffect {
	position: absolute;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
}

.hoverEffect div {
	background: rgb(222, 0, 75);
	background: linear-gradient(90deg,
			rgba(222, 0, 75, 1) 0%,
			rgba(191, 70, 255, 1) 49%,
			rgba(0, 212, 255, 1) 100%);
	border-radius: 40rem;
	width: 10rem;
	height: 10rem;
	transition: 0.4s;
	filter: blur(20px);
	animation: effect infinite 3s linear;
	opacity: 0.5;
}

.button_registerhub:hover .hoverEffect div {
	width: 8rem;
	height: 8rem;
}

@keyframes effect {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}



/***/

.form-container {
	width: 50%;
}


.Contenthub_inicio_destacat .auth_page-login {
	width: 100%;
	height: 100%;
	border: 0;
	margin: 0;
}


.Contenthub_inicio_destacat h1 {
	font-weight: bold;
	margin: 0;
}

.Contenthub_inicio_destacat h2 {
	text-align: center;
}

.Contenthub_inicio_destacat p {
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 5px 0;
}

.Contenthub_inicio_destacat span {
	font-size: 12px;
}

.Contenthub_inicio_destacat a {
	color: #333;
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
}

/*
.Contenthub_inicio_destacat button {
	border-radius: 20px;
	border: 1px solid #FF4B2B;
	background-color: #FF4B2B;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
}*/

.Contenthub_inicio_destacat button:active {
	transform: scale(0.95);
}

.Contenthub_inicio_destacat button:focus {
	outline: none;
}

.Contenthub_inicio_destacat button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}

.Contenthub_inicio_destacat form {
	display: flex;
	flex-direction: column;
	height: 100%;
	text-align: center;
}

.Contenthub_inicio_destacat input {
	background-color: #eee;
	border: none;
	padding: 12px 15px;
	margin: 10px 0;
	width: 100%;
}

/****/
.auth_page-login form .social-container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 10px auto;
}

.auth_page-login form .social-container a {
	border: 1px solid #DDDDDD;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 40px;
	text-decoration: none;
	text-emphasis: none;
}

#google-signin-btn {
	border: solid rgba(255, 120, 79, 0.527) 2px;
	margin: 15px auto;
	width: 100%;
}

/** tarjed content **/
.cart_contethub_public {
    width: 84%;
    background-color: #ffffff; /* Fondo blanco para la tarjeta */
    border-radius: 12px; /* Bordes redondeados */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Sombra suave */
    overflow: hidden; /* Para que los bordes redondeados funcionen bien */
    margin: 20px auto; /* Espaciado entre tarjetas */
height: auto;
}

.cart_contethub_public .header_cart_contenthub {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #ddd; /* Líneas sutiles para separar secciones */
}

.cart_contethub_public .header_cart_contenthub img {
    width: 40px;
    height: 40px;
    border-radius: 50%; /* Imagen redondeada */
    object-fit: cover; /* Asegura que la imagen se recorte correctamente */
    margin-right: 20px;
}

.cart_contethub_public .header_cart_contenthub h1 {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    color: #333;
}

.cart_contethub_public .header_cart_contenthub small {
    font-size: 12px;
    color: #888;
}

.cart_contethub_public .content_cart_description {
    padding: 8px 20px;
    font-size: 14px;
    color: #555;
    line-height: 1.5;
    height: auto; /* Permite que el contenido se ajuste */
    overflow: hidden;
    text-overflow: ellipsis; /* Agrega puntos suspensivos si el texto es largo */
}

.content_img_contenhub {
    width: 100%; /* Se adapta al ancho disponible */
    overflow: hidden; /* Evita que contenido adicional sobresalga */
}
.content_img_contenhub video {
    width: 100%; /* Ajusta el video al contenedor */
    height: auto; /* Mantiene la proporción */
    object-fit: contain;
}

.cart_contethub_public:hover {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); /* Sombra más fuerte al pasar el ratón */
}

.cart_contethub_public .header_cart_contenthub:hover {
    background-color: #f0f0f0; /* Color de fondo más oscuro al pasar el ratón */
}

.h2_containerhub {
	width: 80%;
	margin: 60px auto;
	padding: 8px;
	font-size: 24px;
	font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
	color: #5c5c5c;
}




@media (max-width: 768px) {
    .cart_contethub_public {
		width: 100%;
        margin: 10px auto; /* Menos margen en pantallas pequeñas */
    }

    .cart_contethub_public .header_cart_contenthub h1 {
        font-size: 14px;
    }

    .cart_contethub_public .content_cart_description {
        font-size: 12px;
    }
}


@media (max-width: 750px) {
	.Contenthub_inicio_destacat {
		width: 100%;
		margin: 0;
	}

	.Contenthub_inicio_destacat .container {
		flex-direction: column;
	}

	.overlay-container {
		width: 100%;
	}

	.form-container {
		width: 100%;
	}

	.overlay-panel {
		padding: 5px;
	}

	.overlay-panel h2 {
		font-size: 12px;
		margin: 14px;
	}

	.overlay-panel h1 {
		font-size: 36px;
		margin: 14px;
	}

	.overlay-panel .heart {
		font-size: 32px;
	}

	.Contenthub_inicio_destacat p {
		margin: 8px auto;
		font-size: 10.5px;
		width: 80%;
		text-align: center;
	}

	.content_cart_description p {
		margin: 2px auto;
		font-size: 10.5px;
		width: 100%;
		text-align: justify;
	}
}