.terminos_condiciones {
    max-width: 1025px;
    width: 100%;
    margin: 10px auto;
    background: white;
    border-radius: 5px;
    padding: 15px;
}

.terminos_condiciones h2 {
    color: rgb(170, 41, 2);
    font-size: 22px;
    margin: 6px 8px;
}

.terminos_condiciones p {
    margin: 5px auto;
    max-width: 80%;
    text-align: justify;
    font-size: 18px;
    color: #424949;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.terminos_condiciones ol {
    max-width: 90%;
    text-align: justify;
    padding: 10px;
    margin: 5px auto;
    font-size: 18px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.terminos_condiciones ol li {
    margin: 10px 0;
    color: #283747;
    font-family: Arial, Helvetica, sans-serif;
}

@media (max-width:725px) {

    .terminos_condiciones h2 {
        color: rgb(170, 41, 2);
        font-size: 16px;
        margin: 6px 8px;
    }

    .terminos_condiciones p {
        max-width: 90%;
        font-size: 14px;
    }

    .terminos_condiciones ol {
        font-size: 14px;
    }

    .terminos_condiciones ol li {
        margin: 10px 0;
        font-size: 13px;
    }
}