.fullscreen-video {
    max-width: 1025px;
    margin: 2px auto;
    display: flex;
}

.app_home .Menu_header {
    display: none;
}

/********/

.fullscreen-video .app_home {
    position: relative;
    margin: 0px auto;
    height: 90vh;
    width: 50%;
    display: grid;
    place-items: center;
}

.app__videos_inicio {
    position: absolute;
    top: 5px;
    display: flex;
    justify-content: center;
    margin: 20px auto;
    z-index: 4000;
}

.app__videos_inicio h3 {
    font-size: 20px;
    margin: 0px 10px;
    cursor: pointer;
    color: aliceblue;
    font-weight: 500;
    text-shadow: rgb(59, 59, 59) 1px 0 10px;
}

.app__videos {
    position: relative;
    height: 96%;
    margin: 5px;
    border-radius: 20px;
    overflow: scroll;
    width: 80%;
    max-width: 500px;
    scroll-snap-type: y mandatory;
}

.app__videos::-webkit-scrollbar {
    display: none;
}

.app__videos .video {
    position: relative;
    background-color: white;
    width: 100%;
    height: 100%;
    margin: 5px 0px;
    scroll-snap-align: start;
}

.video__player {
    object-fit: fill;
    height: 100%;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.videoFooter {
    position: absolute;
    padding: 8px;
    bottom: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.videoFooter img {
    width: 35px;
    height: 35px;
    animation: rotate 4s infinite linear;
}

.videoSidebar__button a {
    color: aliceblue;
}

#text-no-video {
    position: absolute;
    font-size: 24px;
    top: 20%;
    width: 100%;
}

/*******page short hot*********/

#app_home_shorts {
    width: 72%;
    margin: 10px auto;
}


@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.videoFooter__text h3 {
    font-size: 18px;
    margin: 0;
}

.videoFooter__inicio {
    display: flex;
}

.videoFooter__inicio h2 {
    font-size: 18px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin-left: 5px;
    color: rgba(221, 221, 221, 0.925);
    text-shadow: rgb(32, 32, 32) 1px 0 10px;
}

#activebuttotik {
    font-weight: 600;
    font-size: 20px;
    color: aliceblue;
    text-decoration: underline;
}

.videoFooter__inicio img {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin: 0px 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/*------cambio-------*/
.videoFooter__inicio small {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #a3a3a3;
    text-shadow: rgb(32, 32, 32) 1px 0 10px;
}

/*-------------------*/

/*-----------modficacion--------*/
.videoFooter__ticker {
    margin: 10px;
    padding: 5px;
    max-width: 85%;
}

.videoFooter__ticker button {
    background: none;
    border: none;
    font-size: 13px;
    margin-left: 5px;
    color: #4b1500;
}

/*-------------------*/

.videoFooter__ticker span {
    font-size: 16.5px;
    color: rgb(219, 219, 219);
    margin-left: 10px;
    text-shadow: 1px 1px 2px rgba(54, 54, 54, 0.795);
}

.videoSidebar {
    position: absolute;
    top: 40%;
    left: 86%;
}

.videoSidebar .videoSidebar__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
    color: rgb(236, 236, 236);
}

.videoSidebar .videoSidebar__button i {
    font-size: 30px;
    text-shadow: rgb(114, 114, 114) 1px 0 10px;
    cursor: pointer;
}

.videoSidebar .videoSidebar__button small {
    font-size: 15px;
    text-shadow: rgb(59, 59, 59) 1px 0 10px;
}

.modal_compartir {
    background: aliceblue;
    position: absolute;
    top: 50%;
    width: 100%;
    padding: 10px 5px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.product_img .contenedor_rells {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 35px;
    border: solid 4px #f0b17aea;
    color: #BA4A00;
    border-radius: 5px;
    background: #f6ddcc65;
    cursor: pointer;
}

.product_img a:link,
.product_img a:visited,
.product_img a:hover,
.product_img a:active {
    color: #000000;
    text-decoration: none;
}

.product_img .contenedor_rells span {
    font-size: 14.5px;
    color: #A04000;
}

.product_img video {
    width: 96%;
    height: 96%;
    object-fit: cover;
    /* Ajusta el valor según tus necesidades */
    border-radius: 5px;
    position: absolute;
}

.title-avisos-home a:link,
.title-avisos-home a:visited,
.title-avisos-home a:hover,
.title-avisos-home a:active {
    color: #666666;
    text-decoration: none;
}

.carousel_contactos_inte .title-avisos-home {
    font-size: 21px;
}

.product_img .footer_short_videos {
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: 25px;
    display: flex;
    justify-content: center;
}

.product_img .footer_short_videos span {
    font-size: 19px;
    font-weight: 500;
    color: #5f5f5f;
    text-shadow: rgb(41, 41, 41) 1px 0 10px;
}

#slider_video {
    height: 500px;
}

@media (max-width:750px) {
    #sugerperfil_ {
        display: none;
    }

    .fullscreen-video .app_home {
        width: 100%;
        height: 100%;
        padding: 0;
    }

    .app__videos_inicio {
        margin: 10px auto;
    }

    .app__videos_inicio h3 {
        font-size: 18px;
        margin: 0px 10px;
    }


    .fullscreen-video {
        background: rgb(235, 146, 146);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 2000000000;
    }

    .app_home .Menu_header {
        display: block;
        background: rgba(0, 0, 0, 0.9);
        width: 100%;
        height: 60px;
        position: fixed;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
    }

    .app_home .Menu_header li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        color: rgb(224, 224, 224);
        cursor: pointer;
    }

    .app_home .Menu_header li a {
        color: rgb(224, 224, 224);
    }

    .app_home .Menu_header li i:hover {
        color: red;
    }

    .app_home .Menu_header li small {
        font-size: 12px;
    }

    .videoFooter {
        bottom: 60px;
    }

    .app__videos {
        height: 100%;
        width: 100%;
        margin: 5px;
        border-radius: 0px;
    }

    /*--------probar btn cargar más------*/
    .btn_videos_load {
        display: none;
    }

    .product_img .contenedor_rells {
        height: 80%;
    }

    .product_img .contenedor_rells span {
        font-size: 9px;
    }

    .carousel_contactos_inte .title-avisos-home {
        font-size: 18px;
    }

    .product_img video {
        height: 96%;
        width: 96%;
    }

    .product_img .footer_short_videos {
        bottom: 15px;
        width: 90%;
    }

    .videoFooter__ticker span {
        font-size: 13.5px;
    }

    .videoFooter__ticker button {
        font-size: 11.5px;
    } 

    .videoFooter__ticker {
        margin: 5px;
        padding: 3px;
    }

    #slider_video {
        height: 420px;
    }
}


/*.fullscreen-video {
    background: rgb(235, 146, 146);
    position: absolute;
    top: 0;
    left: 0;
    height: 92%;
    width: 100%;
    z-index: 2000000000;
}*/