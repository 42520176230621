/* Estilos personalizados para el acordeón 
.accordion {}

.accordion-item {
    
}

.accordion-header {}
 Estilos para cada elemento del acordeón */

.accordion-button {
    color: rgb(78, 78, 78) !important;
    font-weight: 500;
    height: 50px;
    background-color: solid rgb(214, 214, 214) 1px !important;
    text-shadow: 0.2px 0.25px 0.25px rgba(68, 68, 68, 0.555);
}

.accordion-button i {
    margin-right: 10px;
    color: rgba(146, 146, 146, 0.459);
    font-weight: 600;
}

.accordion-button:focus {
    box-shadow: 0 0 0 0.25rem rgba(253, 223, 185, 0.25);
    border-color: rgba(255, 81, 0, 0.692);
}

.accordion-button:not(.collapsed) {
    margin-bottom: 5px;
    background-color: rgba(248, 248, 248, 0.637);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.Btn_enlace_forum {
    outline: 0;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background: #ff4949;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
    box-sizing: border-box;
    padding: 20px 24px;
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    overflow: hidden;
    cursor: pointer;
    margin: 15px auto;
    width: 60%;
    text-shadow: 1px 1px 2px black;
    position: relative;
    left: 50%;
    transform: translate(-50%);
}

.Btn_enlace_forum:hover {
    opacity: .95;
}

.Btn_enlace_forum .animation {
    border-radius: 100%;
    animation: ripple 0.6s linear infinite;
}

@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1);
    }

    100% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1), 0 0 0 80px rgba(255, 255, 255, 0);
    }
}

/*
.accordion-collapse {}

 Estilos específicos para cuando el contenido del acordeón está mostrándose 
.accordion-collapse.show {
     Estilos para el contenido del acordeón cuando está expandido 
}*/

/*********/
.row-forum-item {
    display: flex;
    width: 100%;
}

.ibox-content {
    clear: both;
}

.ibox-content {
    background-color: #ffffff;
    color: inherit;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 1px 0;
}

.forum-container,
.forum-post-container {
    padding: 0 15px !important;
}

.forum-title {
    margin: 10px 0 10px 0;
}

.forum-item {
    margin: 10px 0;
    padding: 8px 0;
    border-bottom: 1px solid #f1f1f1;
    font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.forum-icon {
    float: left;
    width: 20px;
    margin-right: 10px;
    text-align: center;
}

.forum-item.active a.forum-item-title {
    color: #3a322e;
    transition: all 0.6s ease;
}

a.forum-item-title {
    color: inherit;
    display: block;
    font-size: 16.5px;
    font-weight: 600;
}

a.forum-item-title:hover {
    text-decoration: none;
}

.forum-item .forum-sub-title {
    color: #999;
    margin-left: 50px;
}


.views-number {
    font-size: 12px;
    font-weight: 600;
}

.ibox-content h1,
.ibox-content h2,
.ibox-content h3,
.ibox-content h4,
.ibox-content h5,
.ibox-title h1,
.ibox-title h2,
.ibox-title h3,
.ibox-title h4,
.ibox-title h5 {
    margin-top: 5px;
}

.row-forum-item h3,
.row-forum-item h4,
.row-forum-item h5 {
    margin-top: 5px;
    font-weight: 600;
}

.row-forum-item h3 {
    font-size: 16px;
}

.forum-item.active .fa {
    color: #f9ba8e;
}

.forum-icon .fa {
    font-size: 20px;
    margin-top: 4px;
    color: #9b9b9b;
}

.forum-desc {
    color: #999;
}

.forum-item small {
    color: #999;
    font-size: 85%;
}

.forum-item.active a.forum-item-title:hover {
    color: rgb(136, 43, 0);
}

.col-forum-item {
    width: 70%;
}

.col-md-forum-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-left: solid 1px rgba(124, 124, 124, 0.671);
}

.col-md-forum-info .forum_icons_ img {
    width: 26px;
    height: 26px;
    border-radius: 50%;
}

.col-md-forum-info small {
    font-size: 10.5px;
}

.col-forum-info {
    width: 50%;
    border-right: solid 1px rgba(124, 124, 124, 0.671);
    border-left: solid 1px rgba(124, 124, 124, 0.671);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#title_forum_a {
    height: 30px;
    width: 90%;
    /* Ancho fijo para el div */
    overflow: hidden;
    /* Oculta el texto que se desborda del div */
    text-overflow: ellipsis;
    /* Agrega puntos suspensivos (...) al final del texto que se corta */
    display: inline-block;
    /* Para que el div solo tenga el ancho del contenido */
}

.content_footer_body_forum {
    display: flex;
    width: 30%;
}

.forum-item .forum-sub-title a {
    color: #ac0b00;
    font-weight: 600;
}

.card_header.forum {
    margin: 5px auto;
    padding: 4px 6px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
}

.material-icons.forum {
    font-size: 28px;
    display: block;
}

.nav-item.dropdown.forum {
    display: block;
    cursor: pointer;
}

.info_list_detail {
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px 6px;
    border-radius: 4px;
    background-color: white;
}

.footer_forum_cards {
    margin: 8px auto;
}

.contet_card_forum_ {
    padding: 6px;
    margin: 8px auto;
}

.contet_card_forum_ h2 {
    color: #cb2702;
    font-size: 18px;
    margin: 10px 0;
}

.contet_card_forum_ h2:hover {
    color: #f37703;
}

.footer_forum_cards .footer_icons_ {
    display: flex;
    justify-content: end;
    padding: 5px 8px;
    align-items: center;
    font-size: 22px;
}

.footer_icons_ i,
.footer_icons_ a {
    margin: 0 15px;
}

.footer_icons_ i:hover {
    color: #ff5601;
    cursor: pointer;
}

.postBtn.forum {
    padding: 3px 8px;
    border-radius: 4px;
    background-color: #ff520e;
    color: white;
    transition: all 1s ease;
    position: relative;
    margin: 6px;
    width: 80px;
}

.postBtn.forum:hover {
    background-color: #ffe5d7e3;
    color: rgb(61, 61, 61);
    box-shadow: rgba(255, 133, 85, 0.35) 0px 5px 15px;
}


.cardfooter.comment_input {
    margin: 6px auto;
}

.comment_input textarea {
    background: #f7f7f7;
    border: none;
    outline: none;
    flex: 1;
    overflow: auto;
    min-height: 100px;
    border-radius: 2px;
}

.content_footer_replay {
    width: 86%;
    margin: 4px;
    position: relative;
}

.content_btn_form_ {
    padding: 4px;
    margin: 0px auto 8px;
}

.content_btn_form_ button {
    border: none;
    height: 25px;
    width: auto;
    margin: 0 6px;
    border-radius: 4px;
    background-color: rgb(255, 107, 107);
    padding: 1px 5px;
    font-size: 14px;
    color: rgb(247, 247, 247);
    transition: all 0.8s ease;
}

.content_btn_form_ button:Hover {
    background-color: whitesmoke;
    color: rgb(255, 107, 107);
}



.comment_content.forums {
    background-color: rgba(243, 243, 243, 0.945);
}

.content_commet_replay {
    padding: 10px 0;
}


/********************/

.title_MODEL_body input {
    margin: 5px auto 10px;
    width: 100%;
}

#status_form_MODAL textarea {
    margin: 8px auto;
}

/*******************/

@import url(https://fonts.googleapis.com/css?family=Open+Sans);

.accordion-body.forum {
    background-color: #ffffff;
}

.d-flex.forumsta {
    background-color: #dfdfdfbd;
    border: none;
}

.d-flex.forumsta button {
    background-color: #ff9f9f;
    font-weight: 600;
}

.activity-feed {
    padding: 0px;
    list-style: none;
    display: flex;
    justify-content: space-between;
}

.activity-feed .feed-item {
    position: relative;
    padding-bottom: 8px;
    padding-left: 20px;
    border-left: 2px solid #e4e8eb;
}

.activity-feed .feed-item:last-child {
    border-color: transparent;
}

.activity-feed .feed-item::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -6px;
    width: 10px;
    height: 10px;
    border-radius: 6px;
    background: #fff;
    border: 1px solid #f37167;
}

.title_forums_ {
    margin: 20px 0 14px;
    font-size: 22px;
    padding: 8px 4px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
    color: #383838;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.activity-feed .feed-item .date {
    display: block;
    position: relative;
    top: -5px;
    color: #8c96a3;
    text-transform: uppercase;
    font-size: 13px;
}

.activity-feed .feed-item .text {
    position: relative;
    top: -3px;
    font-size: 17.5px;
}

.activity-feed .feed-item .text a {
    text-decoration: none;
    color: #a83102;
    font-weight: 500;
    transition: all 1 ease;
}

.activity-feed .feed-item .text a:hover {
    color: rgb(255, 192, 55);
}

.form_category_formulary {
    background-color: #29292991;
    position: fixed;
    top: 80px;
    width: 100%;
    height: 100vh;
    left: 0;
    z-index: 2000000000000000;
}

#modal_form_category {
    width: 50%;
    margin: 10px auto;
    background-color: rgb(255, 255, 255);
    position: relative;
    border-radius: 10px;
    height: 80%;
    padding: 6px;
    overflow: scroll;
}

.outline-danger_form {
    position: absolute;
    top: 0;
    margin: 10px 20px;
    right: 0;
    border: none;
    background-color: crimson;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: whitesmoke;
}

.row_category_ {
    border: solid rgba(134, 134, 134, 0.555) 0.5px;
    display: flex;
    justify-content: space-between;
    padding: 2px 4px;
    margin: 0px 8px;
}

.row_category_ p {
    font-size: 16px;
}

.row_category_ button {
    border: none;
    margin: 0 5px;
    width: 50px;
    border-radius: 4px;
    color: rgb(51, 51, 51);
}

.form_category_inicio {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    width: 90%;
    margin: 8px auto;
    justify-content: center;
    align-items: center;
}

.categori_input_n {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.categori_input_n input {
    margin: 2px 4px;
}

.form_category_inicio button {
    margin: auto 6px;
    border: none;
    border-radius: 4px;
    background-color: #8c96a3;
    color: white;
    width: 60px;
}

.btn_form_create_cat {
    background-color: #ff4c06;
    border: none;
    margin: 6px;
    padding: 2px 6px;
    border-radius: 4px;
    color: white;
}

.activity-feed .feed-item i {
    margin: 2px 4px;
    color: #837f7a;
}

.activity-feed .feed-item small {
    color: rgb(54, 54, 54);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#status_form_MODAL {
    display: flex;
    flex-direction: column;
}

.title_MODEL_body {
    display: flex;
    flex-direction: column;
}

@media (max-width:750px) {

    .Btn_enlace_forum {
        width: 90%;
        font-size: 13px;
        padding: 5px 8px;
    }
    .row-forum-item {
        flex-direction: column;
    }

    .col-forum-item {
        width: 100%;
    }

    .content_footer_body_forum {
        width: 100%;
    }

    .col-md-forum-info,
    .col-forum-info {
        flex-direction: row;
        justify-content: space-around;
    }

    .forum-item .forum-sub-title {
        font-size: 13.5px;
        margin-bottom: 3px;
    }

    a.forum-item-title {
        font-size: 15.5px;
    }

    .forum-icon .fa {
        font-size: 15px;
    }

    .accordion-button {
        font-size: 13.5px;
        height: 42px;
    }

    .activity-feed .feed-item .text {
        font-size: 14.5px;
        top: -8px;
    }

    .activity-feed .feed-item .date {
        font-size: 12px;
    }

    #modal_form_category {
        width: 98%;
    }

    .row_category_ p {
        font-size: 12.5px;
    }

    .row_category_ button {
        width: 40px;
        font-size: 11px;
    }

    .title_forums_ {
        font-size: 15.5px;
        letter-spacing: 0;
    }

    .d-flex.forumsta button {
        font-size: 13px;
        font-weight: 700;
    }

    .cardfooter.comment_input {
        display: flex;
        flex-direction: column;
    }

    .postBtn.forum {
        width: 90%;
    }

}