.filter_catalogo {
    margin: 5px auto;
    padding: 15px;
    background: #E5E7E9;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    max-width: 1025px;
}

.filter_catalogo select {
    margin: 0 auto;
    background: #E91E63;
    color: #FBFCFC;
    cursor: pointer;
    border-radius: 2px;
}

.filter_catalogo select option {
    background-color: #eeffff;
    color: #424242;
    outline: none;
    cursor: pointer;
    opacity: 0.5;
}

.filter_catalogo .filter_shorp,
.filter_catalogo .filter_temp {
    width: 20%;
}

.filter_catalogo .filter_shorp span,
.filter_catalogo .filter_temp span {
    font-size: 10px;
    background: #E0F7FA;
    border-radius: 5px;
    position: relative;
    padding: 4px;
    color: rgb(124, 2, 2);
    left: 4px;
    top: 5%;
    float: left;
}

.filter_catalogo .filter_shorp select,
.filter_catalogo .filter_temp select {
    height: 100%;
    width: 9rem;
    float: right;
    border: none;
    text-align: center;
}

.filter_catalogo .filter_input {
    width: 60%;
    position: relative;

}

.filter_catalogo .filter_input input:hover {
    border: 1px solid #F8BBD0;
    background-color: #FFEBEE;
    box-shadow: 0 0 20px #FCE4EC;
}

.filter_catalogo .filter_input input {
    width: 100%;
    background: #F5F5F5;
    border-radius: 5px;
}

@media only screen and (max-width: 966px) and (min-width: 760px) {
    .filter_catalogo{
        margin: 6px;
    }
    .filter_catalogo .filter_shorp span,
    .filter_catalogo .filter_temp span {
        display: none;
    }
}

@media (max-width: 760px) {
    .filter_catalogo {
        padding: 10px 10px;
        border-radius: 8px;
        display: block;
        overflow: hidden;
        margin: 6px;
    }

    .filter_catalogo .filter_shorp select {
        position: absolute;
        font-size: 13px;
        left: 5%;
        margin: 2px auto;

    }

    .filter_catalogo .filter_temp select {
        position: absolute;
        font-size: 13px;
        left: 16%;
    }

    .filter_catalogo .filter_shorp span,
    .filter_catalogo .filter_temp span {
        font-size: 9px;
        background: #E0F7FA;
        border-radius: 5px;
        z-index: 100;
        position: relative;
        padding: 0px;
        color: rgb(124, 2, 2);
        left: 0%;
        top: -44%;
        background: none;
    }

    .filter_catalogo .filter_shorp {
        width: 20px;
        height: 10px;
        position: relative;
        float: right;
        width: 45%;
        height: 30px;
        display: flex;
    }

    .filter_catalogo .filter_temp {
        width: 20px;
        height: 10px;
        position: relative;
        float: left;
        height: 30px;
        width: 45%;
        display: flex;
    }

    .filter_catalogo .filter_input {
        width: 100%;
        position: relative;
        margin: 0px 0px 20px 0px;
    }

    .filter_catalogo .filter_input input {
        width: 100%;
    }

}