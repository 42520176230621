.avatar-suger-carousel {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .cards-carousel {
    position: relative;
    width: 80px;
    height: 80px;
    border: 4px solid #fdfdfd;
    border-radius: 50%;
    margin: 15px auto;
  }

  .cards-carousel::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 15px);
    height: calc(100% + 15px);
    background-image:
    linear-gradient(45deg,
    #f09433, #e6683c 25%, #dc2743 50%, #6902be 75%, #bc1888 100%);
    border-radius: inherit;
    z-index: -1;
}

.carrousel_usuarios_avisos {
  width: 94%;
  margin: 0 auto;
}

.carousel_contactos_inte {
  width: 94%;
  margin: 0 auto;
}