/*
========================
========================
          MENU
========================
========================
*/

.styles_contactanos {
    max-width: 1025px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    margin: 50px auto;
}

.styles_contactanos .container {
    padding: 8px auto;
}

.styles_contactanos .sideMenu {
    /*big menu*/
    position: fixed;
    z-index: 1;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: #272125;
    width: 200px;
    height: 70%;
    top: 90px;
    left: 5px;
    box-shadow: 1px 5px 10px 5px #888888;
    transition: 4s;
}

.styles_contactanos .sideMenuMinimized {
    /*small menu*/
    position: fixed;
    background-color: #272125;
    width: 90px;
    height: 100%;
    top: 0;
    left: 0;
    box-shadow: 1px 5px 10px 5px #888888;
    transition: 4s;
}

.styles_contactanos .iconsTopMenu {
    /*button resize menu*/
    color: white;
    cursor: pointer;
    font-size: 30px;
    padding-right: 7px;
    margin-right: -10px;
}

.containerContent .info_contactos_p span {
    font-size: 15px;
    color: red;
    font-weight: 500;
    letter-spacing: 2px;
}

.styles_contactanos .animatedbounce {
    font-size: 23px;
    margin-bottom: 12px;
}

.styles_contactanos .imgMenu {
    /*perfil image*/
    margin-top: 5px;
    border-radius: 50%;
    animation-name: swing;
}

.styles_contactanos .imgMenu:hover {
    /*perfil image on mouse over*/
    animation-name: jello;
}

.styles_contactanos #imgPerfil {
    /*best position top image*/
    padding-top: 30px;
}

.styles_contactanos .myNameMenu {
    /*Eduardo Mondragon*/
    padding-top: 5px;
    text-align: center;
    color: white;
}

.styles_contactanos .especified {
    /*web developer*/
    padding-top: 5px;
    color: #bdc3c7;
}

.styles_contactanos .hrMenu {
    /*divisor between web developer and menu controls*/
    margin-top: 10px;
    background-color: #95a5a6;
    width: 70%;
}

.styles_contactanos .menuModules {
    /*=BIG MENU=About Me - My Works - My Tools - Contact Me*/
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #424949;
    line-height: 45px;
    font-size: 35px;
    font-weight: 600;
}

.styles_contactanos .menuModules i {
    color: #bbbbbb;
    cursor: pointer;
}

.styles_contactanos .menuModulesMinimized {
    /*=SMALL MENU=About Me - My Works - My Tools - Contact Me*/
    display: block;
    line-height: 65px;
    font-size: 30px;
}

.styles_contactanos ul li a {
    /*=TEXT=About Me - My Works - My Tools - Contact Me*/
    color: #c0392b;
    text-align: center;
    display: block;
}

.styles_contactanos ul li a:hover {
    /*=TEXT ON MOUSE OVER=About Me - My Works - My Tools - Contact Me*/
    text-decoration: none;
    color: #bdc3c7;
}

.styles_contactanos ul li a:focus {
    /*=TEXT ON CLICK= About Me - My Works - My Tools - Contact Me*/
    text-decoration: none;
    color: #bdc3c7;
}

.styles_contactanos li {
    /*BOTH SIDES TRANSPARENTS LINES = About Me - My Works - My Tools - Contact Me*/
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    margin-left: -40px;
}

.styles_contactanos li:hover {
    /*BOTH SIDES RED LINES ON MOUSE OVER = About Me - My Works - My Tools - Contact Me*/
    border-left: 3px solid #c0392b;
    border-right: 3px solid #c0392b;
}

.containerContent .row {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*
  ========================
  ========================
          GENERAL
  ========================
  ========================
  */

.styles_contactanos .transition {
    transition: 0.2s;
}

.styles_contactanos .hide {
    visibility: hidden;
}

.styles_contactanos .visible {
    visibility: visible;
}

.styles_contactanos .containerContent {
    margin: 15px auto;
    display: flex;
}

.styles_contactanos .smallMenuC {
    max-width: 1033px;
    margin-left: -70px;
}

.styles_contactanos .bigMenuC {
    max-width: 1095px;
    margin-left: -80px;
}

.styles_contactanos .myWorks_divs {
    display: flex;
    justify-content: space-between;
    margin: 12px auto;
    padding: 5px;
}

.styles_contactanos .myWorks_divs .myWorks_divs_info {
    display: flex;
    align-items: center;
    margin: 0 3px;
}

.myWorks_divs .myWorks_divs_info h3 {
    font-size: 17px;
    color: #424949;
    margin-right: 5px;
}

.myWorks_divs .myWorks_divs_info span {
    color: #641E16;
}

#contactMe{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 5px;
}

#contactMe .pull-right {
    color: #424949;
    font-size: 27px;
}

#contactMe label {
    color: #943126;
}

#contactMe .followSpaceTop {
    display: flex;
    justify-content: center;
    margin: 25px auto;
}

.followSpaceTop .followSpaceTop_div {
    width: 50px;
    height: 50px;
    margin: 5px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    cursor: pointer;
    color: #909497;
}

.followSpaceTop .followSpaceTop_div a {
    color: #909497;
}

/*
  ========================
  ========================
           BODY
  ========================
  ========================
  */

.styles_contactanos .myContainerOpen {
    margin-left: 300px;
    margin-top: 30px;
    transition: 0.2s;
}

.styles_contactanos .myContainerClose {
    margin-left: 200px;
    margin-top: 30px;
    transition: 0.2s;
}

.styles_contactanos .moduleTopSpace {
    margin-top: 100px;
}


/*
  ========================
  ========================
          ABOUT ME
  ========================
  ========================
  */

.styles_contactanos .aboutTitle {
    opacity: 0.9;
    border-left: 4px solid #c0392b;
    padding-left: 15px;
}

.styles_contactanos .someText {
    text-align: justify;
    padding-top: 20px;
    font-size: 22px;
    opacity: 0.9;
}

.styles_contactanos .aboutImg {
    top: 70px;
    width: 70%;
    left: 31%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.aboutRedDiv .title_fnas {
    width: 50%;
    margin: 40px auto;
}
.aboutRedDiv .title_fnas h3 {
    display: inline-block;
    color: #000;
    background: #fff;
    mix-blend-mode: multiply;
    position: relative;
    font-weight: 700;
    font-family: cursive;
    font-size: 45px;
    text-shadow: 1px 1px 2px rgb(112 112 112);
}

.aboutRedDiv .title_fnas h3::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgb(255, 51, 0), rgb(248, 182, 38), rgb(255, 136, 0));
    mix-blend-mode: screen;
    position: absolute;
    top: 0;
    left: 0;
}

.styles_contactanos .redButton {
    margin-left: -15px !important;
    border: 1px solid #c0392b;
    background-color: #c0392b;
    border-radius: 0px;
    width: 113.5%;
    cursor: pointer;
}

.styles_contactanos .redButton:hover {
    background-color: #c0392b;
    border: 1px solid #c0392b;
}

.styles_contactanos .redButton:focus {
    outLine: 0;
    box-shadow: none;
}

.styles_contactanos .greenButton {
    margin-left: -15px !important;
    border: 1px solid #272125;
    background-color: #272125;
    border-radius: 0px;
    width: 113.5%;
    cursor: pointer;
}

.styles_contactanos .greenButton:hover {
    border: 1px solid #272125;
    background-color: #272125;
}

.styles_contactanos .greenButton:focus {
    outLine: 0;
    box-shadow: none;
}


/*
  ========================
  ========================
          MY WORKS
  ========================
  ========================
  */

.styles_contactanos .firstImgMyWorks {
    width: 100%;
    border: 4px solid white;
    box-shadow: 0 7px 65px 0 rgba(0, 0, 0, 0.12);
    margin-bottom: 15px;
}

.styles_contactanos .myWorksAnimation:hover {
    animation-name: swing;
}

/*
  ========================
  ========================
          MY TOOLS
  ========================
  ========================
  */

.styles_contactanos .img-circle {
    border-radius: 50%;
    border: 6px solid #272125;
}

.styles_contactanos .imgAnimated:visited {
    transition: 5s;
    animation-name: flipInX;
}

@media (max-width:750px) {
    .styles_contactanos .sideMenu {
        display: none;
    }
    .styles_contactanos .container {
        display: flex;
        flex-direction: column;
    }

    #cold-md-ou {
        display: none;
    }

    .styles_contactanos .myWorks_divs {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        margin: 5px auto;
    }

    .styles_contactanos .myWorks_divs h3 {
        font-size: 13.5px;
    }

    .styles_contactanos .animatedbounce {
        font-size: 16px;
    }

    .info_contactos_p {
        font-size: 14px;
    }

    #contactMe .pull-right {
        font-size: 15px;
    }
}