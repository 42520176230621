.header {
    width: 100%;
    min-height: 70px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10000;
    box-shadow: 0 0 1px rgba(255, 208, 177, 0.384);
    background-color: hsl(0, 0%, 100%);
}

.header .header_encabezado {
    width: 100%;
    height: 5px;
    background: rgb(251, 104, 8);
    background: linear-gradient(306deg, rgba(251, 104, 8, 1) 14%, rgba(255, 201, 162, 1) 29%, rgba(251, 148, 70, 1) 45%, rgba(255, 165, 84, 1) 60%, rgba(255, 173, 87, 1) 77%, rgba(249, 116, 0, 1) 91%);
}

.menus-header {
    display: flex;
    justify-content: space-between;
}

/******btn anuncios******/
.btn-p {
    flex: 1 1 auto;
    margin: 2px auto;
    padding: 8px 15px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px rgba(126, 126, 126, 0.493);
    border-radius: 10px;
    font-weight: 600;
    text-decoration: none;
}

/* Demo Stuff End -> */

/* <- Magic Stuff Start */

.btn-p:hover {
    background-position: right center;
    color: #7c7b7b;
}

.btn-1p {
    background-image: linear-gradient(to right, #ffd23c 0%, #f57a07 50%, #ff8e32 100%);
}

@media (max-width: 766px) {
    .btn-p {
        padding: 5px 12px;
        font-size: 12px;
        border-radius: 4px;
        margin: 2px auto;
    }
}

/*********logo********/
#logo_css {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: 700;
    font-size: 1.5em;
    text-align: center;
    cursor: pointer;
    text-shadow: 0.1em 0.1em 0.2em rgba(253, 176, 132, 0.37)
}

.gradient-text {
    background-color: #ca4246;
    background-image: linear-gradient(45deg,
            #ca4246 16.666%,
            #e16541 16.666%,
            #e16541 33.333%,
            #f18f43 33.333%,
            #f18f43 60%,
            #8b9862 20%,
            #8b9862 66.666%,
            #476098 66.666%,
            #476098 83.333%,
            #a7489b 83.333%);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: rainbow-text-simple-animation-rev 0.75s ease forwards;
}

.gradient-text:hover {
    animation: rainbow-text-simple-animation 0.5s ease-in forwards;
}

@keyframes rainbow-text-simple-animation-rev {
    0% {
        background-size: 650%;
    }

    40% {
        background-size: 650%;
    }

    100% {
        background-size: 100%;
    }
}

@keyframes rainbow-text-simple-animation {
    0% {
        background-size: 100%;
    }

    80% {
        background-size: 650%;
    }

    100% {
        background-size: 650%;
    }
}


/*--switch---*/

.toggle {
    position: relative;
    background-color: white;
    border-radius: 50%;
    display: flex;
    cursor: pointer;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    color: #a09e9e;
    transition: all 1s ease;
    margin-left: 20px;
    box-shadow: rgba(224, 224, 224, 0.425) 0px 30px 60px -12px inset, rgba(206, 206, 206, 0.836) 0px 18px 36px -18px inset;
}

.toggle:hover {
    background-color: #ff8667;
    color: white;
    box-shadow: 0 20px 30px -6px rgba(253, 143, 137, 0.5);
}

/***********************/

.header .navbar {
    width: 100%;
    height: 100%;
    padding-bottom: 7px;
    display: flex;
    justify-content: space-around;
}

.header .navbar .Menutres {
    display: flex;
    justify-content: center;
}

.header .navbar .Menutres span {
    color: #7c7b7b;
    font-size: 35px;
}

.header .navbar .Menutres span:hover {
    color: aquamarine;
}

.header .navbar .Menutres div {
    margin: 10px;
}

.header .logo h1 {
    font-size: 1.8rem;
}


/*--header Menu---*/

.header .menu li {
    opacity: 0.5;
}

.header .menu li.active {
    opacity: 1;
}

.header .menu .material-icons {
    font-size: 30px;
}

.header .menu .dropdown-menu {
    position: absolute;
    left: inherit;
    right: 0;
}

.header .menu label {
    cursor: pointer;
}

#notify_length {
    position: absolute;
    background: red;
    width: 18.5px;
    height: 18.5px;
    text-align: center;
    color: #ECEFF1;
    font-weight: 700;
    top: 24%;
    left: 35%;
    transform: translate(-50%, -50%);
    color: rgb(253, 253, 253);
    font-size: 14px;
    border-radius: 50%;
    padding-bottom: 8px;
}

/*--switch---*/

#theme[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
}

#toggle_inicio {
    cursor: pointer;
    text-indent: -9999px;
    width: 60px;
    height: 34px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
}

#toggle_inicio:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 26px;
    height: 26px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

#theme:checked+#toggle_inicio {
    background: #f70a0a;
}

#theme:checked+#toggle_inicio:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}

#toggle_inicio:active:after {
    width: 52px;
}

/*----------*/

.logo-app-web {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-app-web #font_logo {
    font-size: 24px;
    color: #f74e00;
    font-family: cursive;
    margin-left: 5px;
}

.logo-app-web img {
    width: 45px;
}

.navbar .logo {
    text-decoration: none;
}

/*********anuncios*********/

.header_btn_anuncios {
    transition: all 0.3s ease-in-out;
    font-family: "Dosis", sans-serif;
    width: 100px;
    height: 40px;
    border-radius: 40px;
    background-image: linear-gradient(135deg, #feb692 0%, #ea5455 100%);
    box-shadow: 0 20px 30px -6px rgba(253, 143, 137, 0.5);
    outline: none;
    cursor: pointer;
    border: none;
    font-size: 19px;
    color: white;
}

.header_btn_anuncios:hover {
    transform: translateY(3px);
    box-shadow: none;
}

.header_btn_anuncios:active {
    opacity: 0.5;
}

/*--responsive---*/

@media (max-width: 766px) {
    .header .navbar {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 2px 8px;
    }

    .header .header_encabezado {
        height: 4px;
    }

    .header .navbar a {
        grid-column: 1/3;
    }

    #logo_css {
        font-size: 2em;
        margin-bottom: 2px;
    }

    .menus-header {
        display: flex;
        justify-content: end;

    }

    .header_btn_anuncios {
        width: 65px;
        height: 25px;
        font-size: 12px;
    }

    .toggle {
        margin: 0;
        margin-left: 8px;
        width: 29px;
        height: 29px;
        font-size: 20px;
    }

    .logo-app-web {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .header .menuDos {
        width: 100%;
        justify-content: start;
    }

    .header .menuDos .listaDos .material-icons {
        font-size: 25px;
    }

    .logo-app-web #font_logo {
        font-size: 22px;
        margin: auto 4px;
    }

    .logo-app-web img {
        width: 26px;
    }

    .header .navbar .drackcolor-boton {
        display: flex;
        justify-content: end;
    }

    .header .navbar .Menutres {
        display: flex;
        justify-content: space-between;
        padding: 2px;
    }

    .header .navbar .Menutres div {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        padding: 6px;
        margin: 0px 8px 0 8px;
        justify-content: center;
        align-items: center;
    }

    .header .navbar .Menutres span {
        font-size: 1.6rem;
        color: #7c7b7b;
        border-radius: 50%;
        padding: 4px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    .header .menu {
        position: absolute;
        top: calc(100vh - 60px);
        left: 0;
        width: 100%;
        height: 60px;
        box-shadow: 0 0 10px #ddd;
        z-index: 2;
        background: rgba(255, 255, 255, 0.8);
        border: 1px solid rgb(0, 0, 0, .125);
    }

    .header .menu .dropdown-menu {
        bottom: 100%;
        top: auto;
    }

    .conteinerBus .search_form {
        position: relative;
        width: 98%;
        margin-bottom: 10px;
    }

    .conteinerBus .search_form #search {
        background: #ECEFF1;
        width: 80%;
        left: 37px;
        position: relative;
    }

    .conteinerBus .search_form .users {
        top: 2.5rem;
        right: 1.2rem;
        z-index: 40;
    }


    .conteinerBus .search_form .search_icon {
        top: 50%;
        left: 40%;
    }

    .conteinerBus .search_form .close_search {
        top: -28px;
        left: 70%;
    }

    .conteinerBus .search_form .loading {
        top: 14px;
        left: 70%;
    }

    .header .logo {
        margin: auto;
    }

    .header .logo h1 {
        font-size: 1.5rem;
    }

    /*--switch---*/

    #toggle_inicio {
        width: 42px;
        height: 22px;
    }

    #toggle_inicio:after {
        width: 15px;
        height: 15px;
    }

    #toggle_inicio:active:after {
        width: 38px;
    }

    #notify_length {
        top: 25%;
        left: 15%;
        text-align: center;
    }

    /*----------*/
}


/*-----btn de servicios-----*/
/*
:root{
    --gradient: linear-gradient(to right, #e2336b, #fcac46);
}

 .btn2{
    display: flex;
    align-items: center;
    padding: 7px;
    border-radius: 8px;
    background: var(--gradient);
    margin-right: 0.5rem;
}
 .btn2 i{
    color: hsla(0, 25%, 98%, 0.6);
    margin-right: 10px;
}
*/

/*---suggestesPrueba---*/

.suggestesPrueba {
    position: absolute;
    background: white;
}