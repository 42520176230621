.home_card_tinder {
	display: flex;
	justify-content: center;
	width: 100%;
}

.home_card_tinder .card_user_interes {
	width: 18%;
}

/*-------filter datos---------*/


.home_card_tinder .card_tinder_filter {
	width: 15%;
	min-width: 200px;
	border: 2.5px solid #CACFD2;
	height: 90%;
	position: sticky;
	top: 78px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.home_card_tinder .card_tinder_filter h1 {
	margin: 20px 0;
	font-size: 28px;
	font-family: fantasy;
	color: #858585;
	letter-spacing: 0.25em;
	word-spacing: 0.25em;
	text-shadow: 2px 4px 4px rgb(228, 195, 195);
	cursor: pointer;
}

.home_card_tinder .card_tinder_filter span {
	background: rgb(255, 81, 0);
	padding: 5px 30px;
	color: #f8f6f6;
	border: 2px solid rgb(253, 96, 5);
	border-radius: 8px;
	font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
	cursor: pointer;
	transition: 0.5s;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.home_card_tinder .card_tinder_filter span:hover {
	background: rgb(255, 155, 97);
	color: rgb(133, 32, 1);
}

.home_card_tinder .card_tinder_filter a {
	text-decoration: none;
	margin: 25px 0;
}

.home_card_tinder .card_tinder_filter ul {
	display: flex;
	color: #858585;
	flex-direction: column;
	gap: 15px;
	width: 100%;
	position: relative;
}

.home_card_tinder .card_tinder_filter ul li {
	list-style: none;
	position: relative;
	width: 100%;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-size: 20px;
	font-family: cursive;
	position: relative;
	left: -5px;
}

.home_card_tinder .card_tinder_filter ul li:hover {
	color: rgb(255, 95, 2);
}

.home_card_tinder .card_tinder_filter ul li i {
	margin-right: 8px;
}

/****card****/

.home_card_tinder .card_tinder {
	width: 50%;
	height: 3500px;
	/*border: blue solid 1px;*/
}

.home_card_tinder .card_tinder .Card_perfil {
	width: 60%;
	height: 100%;
	background: #f4f6f6;
	margin: 0 auto;
	position: relative;
	display: flex;
	justify-content: center;
}

.app-card_up {
	position: absolute;
	background: #f4f6f6;
	border-radius: 50px;
	width: 90%;
	height: 100%;
	margin-bottom: 15px;
}

/*------tarjeta------*/

.tarjeta .tinder_card_new .img_principalCard {
	width: 80%;
	height: 68vh;
	overflow: hidden;
	margin: 4px auto;
	position: relative;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
	border-radius: 25px;
	margin-bottom: 40px;
}

.tarjeta .tinder_card_new .img_principalCard span {
	position: absolute;
	background: rgb(161, 161, 161);
	border-radius: 8px;
	padding: 2px 10px;
	font-size: 18px;
	margin: 5%;
	color: #fdfdfd;
	font-weight: 700;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.tinder_card_new .img_principalCard img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 25px;
}

.tinder_card_new {
	position: relative;
}

.tinder_card_new .icon_profile_ {
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: center;
	position: absolute;
	top: 62vh;
}

.tinder_card_new .icon_profile_ a {
	text-decoration: none;
}

.tinder_card_new .icon_profile_ .icon_ {
	width: 75px;
	height: 75px;
	margin: 10px;
	background: rgb(223, 223, 223);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 28px;
	/*
	box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
	*/
	transition: 0.5s;
	cursor: pointer;
	border: none;
	color: #505050;
}

.icon_profile_ .icon_:hover {
	transform: scale(1.15);
	background: rgb(255, 81, 0);
	color: whitesmoke;
}

.tinder_card_new .boxtitle {
	/*background: red;*/
	padding: 2px;
}

.boxtitle .boxtitle_inicio {
	display: flex;
	justify-content: space-between;
	padding: 5px;
	margin: 5px 0px;
	/*background: blueviolet;*/
	width: 100%;
	border-radius: 4px;
}

.boxtitle .Sinteres {
	font-size: 19px;
	font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
	color: #7c7c7c;
	margin: 5px 0;
}

.boxtitle .boxtitle_inicio .boxtitle_inicio_title {
	display: flex;
	width: 52%;
	justify-content: start;
	color: #4b4b4b;
	align-items: center;
}

.boxtitle .boxtitle_inicio .boxtitle_inicio_title .span_title {
	font-size: 24px;
	margin-left: 5px;
	font-family: fantasy;
	letter-spacing: 1px;
	text-shadow: 5px 5px 5px rgba(189, 187, 187, 0.836);
}

.boxtitle .boxtitle_inicio .boxtitle_inicio_title .span_edad {
	font-size: 18px;
	font-family: cursive;
}

.boxtitle_inicio .boxtitle_inicio_precio {
	display: flex;
	justify-content: center;
	align-items: center;
}

.boxtitle_inicio .boxtitle_inicio_precio span {
	font-size: 20px;
	color: crimson;
	font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

/*---botones interes---*/

.tarjeta .interes {
	display: flex;
	align-items: center;
	position: relative;
	justify-content: space-between;
	width: 90%;
	margin: 5px auto;
	background: #e9e9e9;
	border-radius: 10px;
	box-shadow: 0px 0px 8px 8px rgba(206, 204, 204, 0.308)
}

.interes .no i {
	color: #f13800;
	font-size: 24px;
}

.interes .star {
	background-color: rgb(14, 240, 44);
	width: 50px;
	height: 50px;
	box-shadow: 0 1px 20px 0 rgba(0, 20, 20, 0.2);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50px;
	margin: 4px 10% 4px 5px;
	transition: 0.2s transform;
	font-size: 30px;
	color: white;
}

.interes .star a {
	color: #f4f6f8;
	font-size: 35px;
}

.interes .heart i {
	color: red;
	font-size: 16px;
}

.interes .heart {
	background-color: rgb(247, 243, 243);
	width: 50px;
	height: 50px;
	box-shadow: 0 1px 20px 0 rgba(0, 20, 20, 0.2);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50px;
	margin: 4px 5px 4px 10%;
	transition: 0.2s transform;
}

.interes .no {
	background-color: #fff;
	width: 50px;
	height: 50px;
	box-shadow: 0 1px 20px 0 rgba(0, 20, 20, 0.2);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50px;
	margin: 4px;
	transition: 0.2s transform;
}

.interes .no:hover,
.interes .star:hover,
.interes .heart:hover {
	transform: scale(1.1);
}

/*------*/

/*--efecto tarBos---*/

.tarjeta .tarBox {
	position: relative;
	width: 98%;
	background: rgb(202, 207, 210);
	font-size: 17px;
	border-radius: 10px;
	padding: 5px;
	margin: 5px auto;
	overflow-y: scroll;
	box-shadow: 0 50px 50px 0 rgb(10, 17, 17);
}

.tarjeta .tarBox::-webkit-scrollbar {
	width: var(--scroll-size, 10px);
	height: var(--scroll-size, 10px);
}

.tarjeta .tarBox p {
	font-family: 'Bradley Hand', cursive;
	color: rgb(112, 123, 124);
}

.tarjeta .tarBox {
	--scrollbar-width: 0.5px;
	--mask-height: 10px;
	--mask-image-content: linear-gradient(to bottom,
			transparent,
			rgb(252, 19, 19) var(--mask-height),
			rgb(250, 11, 11) calc(100% - var(--mask-height)),
			transparent);
	--mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
	--mask-image-scrollbar: linear-gradient(rgb(175, 172, 172), rgb(241, 178, 178));
	--mask-size-scrollbar: var(--scrollbar-width) 100%;
	mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
	mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
	mask-repeat: no-repeat, no-repeat;
}

/*-----------------*/

.boxtitle .boxtitle_content {
	background: #f4f6f6;
	display: flex;
	flex-direction: column;
	padding: 5px;
	margin: 5px auto;
}

.boxtitle .boxtitle_content i {
	font-size: 18px;
	color: #858585;
	margin-right: 5px;
}

.boxtitle .boxtitle_content span {
	font-size: 17px;
	font-family: cursive;
	color: #5a5959;
}

.tinder_card_new .img_secunds {
	width: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.tinder_card_new .img_secunds img {
	width: 98%;
	height: 96%;
	/*object-fit: contain;*/
	margin: 2px auto;
}

/*-----------*/
#alert_card_tinder {
	position: fixed;
	top: 110px;
	z-index: 500;
}

@media (max-width:725px) {
	.home_card_tinder .card_tinder {
		width: 100%;
		margin-bottom: 10px;
		height: 2100px;
	}

	.home_card_tinder .card_user_interes {
		display: none;
	}

	.tarjeta .tinder_card_new .img_principalCard {
		width: 75%;
		height: 60vh;
		margin-bottom: 30px;
	}

	.boxtitle .boxtitle_content span {
		font-size: 15px;
	}

	.tarjeta .tarBox {
		font-size: 15px;
	}

	.boxtitle .Sinteres {
		font-size: 16px;
	}

	.tinder_card_new .icon_profile_ .icon_ {
		width: 56px;
		height: 56px;
		margin: 6px;
	}

	.tinder_card_new .icon_profile_ {
		top: 54vh;
	}

	.boxtitle .boxtitle_inicio .boxtitle_inicio_title {
		width: 55%;
	}

	.boxtitle .boxtitle_inicio .boxtitle_inicio_title .span_title {
		font-size: 20px;
	}

	.boxtitle .boxtitle_inicio .boxtitle_inicio_title .span_edad {
		font-size: 16px;
	}

	.boxtitle_inicio .boxtitle_inicio_precio span {
		font-size: 16px;
	}

	.home_card_tinder .card_tinder_filter {
		display: none;
	}

	.home_card_tinder .card_tinder .Card_perfil {
		width: 100%;
	}

	.app-card_up {
		width: 96%;
		padding: 0px 6px;
	}

	.tinder_card_new .img_secunds {
		width: 100%;
		height: 850px;
		overflow: hidden;
	}

	.tinder_card_new .img_secunds img {
		max-width: 80%;
		max-height: 310px;
		margin: 2px auto;
	}
}