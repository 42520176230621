@import url('https://fonts.googleapis.com/css2?family=Caprasimo&family=Indie+Flower&display=swap');

.Perfil_COMER {
  max-width: 1025px;
  margin: 5px auto;
  height: 110vh;
  overflow: hidden;
  position: relative;
}

@import url('https://fonts.googleapis.com/css2?family=Fuggles&display=swap');

.Perfil_COMER_user {
  max-width: 1025px;
  margin: 5px auto;
  height: 110vh;
  overflow: hidden;
  position: relative;
}

.btn_id_perfil_comer {
  position: absolute;
  z-index: 20;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 10px;
}

.btn_id_perfil_comer span {
  font-size: 50px;
  margin-left: -45px;
  position: absolute;
  top: -28px;
  font-family: 'Fuggles', cursive;
  color: #d4ad00;
  text-shadow: rgba(250, 0, 0, 0.685) 1px 0 10px;
}

.btn_id_perfil_comer .heart_comerk {
  fill: rgba(255, 51, 0, 0.836);
  position: relative;
  width: 30px;
  cursor: pointer;
  animation: pulse 1s ease infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}


.Perfil_comercio_img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: inline-block;
}

.Perfil_comercio_img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(7, 7, 7, 0.3);
  /* Ajusta el color y la opacidad del oscurecimiento */
  pointer-events: none;
  /* Permite que los elementos debajo del ::before sean clicables */
}

.Perfil_comercio_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.perfil_comercio_content {
  position: relative;
  z-index: 2;
  width: 50%;
  overflow: hidden;
  margin: 5% auto;
}

.perfil_comercio_content .perfil_img_avatar {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  overflow: hidden;
  margin: 10px auto;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.perfil_comercio_content .perfil_img_avatar img {
  width: 100%;
  height: 100%;
}

.perfil_present_content {
  width: 98%;
  margin: 4px auto;
  padding: 6px;
  text-align: justify;
}

.perfil_present_content p {
  font-size: 16px;
  color: white;
  text-shadow: 1px 1px 2px rgb(73, 73, 73);
}

.Perfil_comercio_button {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

/***** btn ******/

.button_btn_comerc {
  display: flex;
  color: #ffffff;
  padding: 0.5rem 1.4rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  border-radius: 0.5rem;
  gap: 0.75rem;
  border: none;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  cursor: pointer;
  transition: .6s ease;
  height: 45px;
  margin: 8px 4px;
  animation: welcome 0.5s ease 0s 1 normal forwards;
}

.button_btn_comerc.instagram {
  background-color: #17e204;
  background-image: linear-gradient(to top right, #fd502a, #fc9c2f, #ffb380);
}

.button_btn_comerc.telegram {
  background-color: #4ab1e4;
  background-image: linear-gradient(to top right, #146ee4, #18ACFE);
}

.button_btn_comerc.tiktok {
  background-color: #353535;
  background-image: linear-gradient(to top right, #494949, #808080);
}

.button_btn_comerc.only {
  background-color: #4ab1e4;
  background-image: linear-gradient(to top right, #68bee9, #95ceeb);
}

.button_btn_comerc.link {
  background-color: #c062ff;
  background-image: linear-gradient(to top right, #cd82ff, #d8a0fd);
}

.button_btn_comerc .svg_logo {
  height: 30px;
  width: 30px;
  padding: 2px;
  font-size: 25px;
  text-align: center;
}

.svg_logo img {
  width: 30px;
  height: 30px;
}

.button_btn_comerc:hover {
  box-shadow: none;
}

.menu_comers_logo {
  position: absolute;
  width: 32px;
  height: 32px;
  right: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
}

.button_btn_comerc:hover {
  -webkit-box-shadow: 0px 0px 15px 0px rgba(230, 219, 67, 0.9);
  -moz-box-shadow: 0px 0px 15px 0px rgba(224, 178, 78, 0.9);
  box-shadow: 0px 0px 15px 0px rgba(212, 133, 109, 0.9);
  animation: animate 8s ease infinite;
  transition: box-shadow, 0.4s;
  border: none;
  font-size: 19px;
  transform: scale(1.05, 1.05);
  background: linear-gradient(90deg, #fdec53, #cc5e4a, #dfad41, #fdec53);
  background-size: 400%;
  animation: animate 7s cubic-bezier(0.75, 0.75, 0.75, 0.75) infinite,
}

@keyframes animate {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 400%;
  }
}

@keyframes spin {
  0% {
    --rotate: 0deg;
  }

  100% {
    --rotate: 360deg;
  }
}

.button_btn_comerc:hover:before {
  filter: blur(20px);
  opacity: 1;
  animation: animate 8s linear infinite;
}

.Perfil_comer_precio {
  width: 100%;
  text-align: center;
  font-size: 16px;
  padding: 5px 0px;
  color: rgb(218, 218, 218);
  text-shadow: 1px 1px 1px #000,
    3px 3px 5px #7d0225;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.Perfil_comer_precio span {
  color: crimson;
}

.cssbuttons-io-button {
  background: #ff3c33;
  color: white;
  font-family: inherit;
  padding: 0.35em;
  padding-left: 1.2em;
  font-size: 14px;
  border-radius: 0.9em;
  border: none;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  height: 2.4em;
  width: 30%;
  margin: 24px auto;
  padding-right: 3.3em;
  box-shadow: rgba(240, 148, 148, 0.705) 0px 1px 1px 0px inset,
    rgba(145, 145, 240, 0.25) 0px 50px 100px -20px,
    rgba(94, 58, 58, 0.3) 0px 30px 60px -30px;
}

.cssbuttons-io-button .icon {
  background: white;
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2em;
  width: 2.2em;
  border-radius: 0.7em;
  right: 0.3em;
  transition: all 0.3s;
}

.cssbuttons-io-button:hover .icon {
  width: calc(100% - 0.6em);
}

.cssbuttons-io-button .icon svg {
  width: 1.1em;
  transition: transform 0.3s;
  color: #7b52b9;
}

.cssbuttons-io-button:hover .icon svg {
  transform: translateX(0.1em);
}

.cssbuttons-io-button:active .icon {
  transform: scale(0.95);
}

.modal_compartir_ {
  background-color: white;
  margin: 8px auto;
  padding: 10px 14px;
  border-radius: 6px;
  position: relative;
}

.modal_compartir_ i {
  position: absolute;
  color: crimson;
  right: 6px;
  top: 6px;
  cursor: pointer;
}

.dropdows_menu_ {
  position: fixed;
  background: rgba(0, 0, 0, 0.418);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
}

.dropdows_menu_compart {
  background-color: white;
  width: 40%;
  padding: 15px;
  position: absolute;
  border-radius: 5px;
}

.fa-regular.fa-rectangle-xmark {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 20px;
  color: crimson;
  cursor: pointer;
}

.dropdows_menu_compart label {
  color: rgb(128, 128, 128);
  margin: 6px;
  font-size: 15px;
  font-weight: 600;
}

.icon_compart_content {
  display: flex;
}

.icon_compart_i {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 6px;
  border-radius: 8px;
}

.icon_compart_i i:hover {
  color: red;
}

.icon_compart_i i {
  font-size: 30px;
  margin: 4px;
  cursor: pointer;
}

.icon_compart_i small {
  font-size: 9px;
  color: #555555;
}

.fa-brands.fa-square-instagram {
  color: #ff5724;
}

.fa-solid.fa-link {
  color: #146ee4;
}

.fa-brands.fa-telegram {
  color: #59abfd;
}

.button_btn_co {
  display: none;
}

.readMore {
  color: crimson;
}


.content_option_perfil {
  max-width: 1025px;
  margin: 5px auto;
}

.content_option_content {
  margin: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  background-image: radial-gradient(circle at 50% -20.71%, #eaecd0 0, #e6edd2 6.25%, #e2eed4 12.5%, #deefd6 18.75%, #daf0d9 25%, #d6f1dc 31.25%, #d3f1df 37.5%, #d0f2e2 43.75%, #cdf2e6 50%, #cbf2ea 56.25%, #c9f2ed 62.5%, #c8f2f1 68.75%, #c7f2f4 75%, #c7f2f7 81.25%, #c8f1fa 87.5%, #c9f1fd 93.75%, #cbf0ff 100%);
}

.content_option_content h2 {
  font-size: 20px;
}

.content_option_content h3 {
  padding: 3px;
  font-size: 17px;
  margin: 8px auto;
  color: #4e4e4e;
  width: 70%;
  text-align: justify;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.social-account-container {
  margin: 25px auto;
}

.social-account-container .title {
  display: block;
  text-align: center;
  font-size: 10px;
  color: rgb(170, 170, 170);
}

.social-account-container .social-accounts {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 5px;
}

.social-account-container .social-accounts .social-button {
  background: linear-gradient(45deg, rgb(0, 0, 0) 0%, rgb(112, 112, 112) 100%);
  border: 5px solid white;
  padding: 5px;
  border-radius: 50%;
  width: 40px;
  aspect-ratio: 1;
  display: grid;
  place-content: center;
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 12px 10px -8px;
  transition: all 0.2s ease-in-out;
}

.social-account-container .social-accounts .social-button:hover {
  transform: scale(1.2);
}

.social-account-container .social-accounts .social-button:active {
  transform: scale(0.9);
}

.content_option_title {
  text-align: center;
  margin: 10px auto;
  padding: 6px 2px;
  font-family: 'Indie Flower', cursive;
  color: #000;
  background: #fff;
  mix-blend-mode: multiply;
  position: relative;
  overflow: hidden;
  font-weight: 600;
  text-shadow: 3px 3px 5px rgba(97, 97, 97, 0.658);
}

.content_option_title:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgb(255, 145, 0), red);
  mix-blend-mode: screen;
  position: absolute;
}

.btn_crear_profileC {
  width: 60%;
  height: 40px;
  margin: 8px auto;
  background: #e3edf7;
  border-radius: 10px;
  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
  transition: transform 0.5s;
}

.btn_crear_profileC:hover {
  box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
    inset -4px -4px 6px -1px rgba(255, 255, 255, 0.7),
    -0.5px -0.5px 0px rgba(255, 255, 255, 1),
    0.5px 0.5px 0px rgba(0, 0, 0, 0.15),
    0px 12px 10px -10px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  transform: translateY(0.5em);
}

.btn_crear_profileC span {
  transition: transform 0.5s;
}

.btn_crear_profileC:hover span {
  transform: scale(0.9);
  fill: #333333;
  color: #fd3636;
}

/**********/
.uiverse_view {
  position: absolute;
  margin: 10px;
  width: 100px;
  height: 40px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  font-weight: bold;
  text-decoration: none;
  color: white;
  background-color: #4158D0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  transition: 0.2s ease-in-out;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  z-index: 200;
  top: 45px;
  right: 50px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.uiverse_view:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.uiverse_view:active {
  background-color: #00DBDE;
  background-image: linear-gradient(90deg, #00DBDE 0%, #FC00FF 100%);
}

.uiverse_view .tooltip {
  position: absolute;
  top: 0;
  font-size: 12px;
  background: #ffffff;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.uiverse_view .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #ffffff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.uiverse_view:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.uiverse_view:hover .tooltip,
.uiverse_view:hover .tooltip::before {
  background: linear-gradient(320deg, rgb(241, 241, 241), rgb(190, 190, 190));
  color: #494949;
}

.btn_content_elimupdate {
  position: absolute;
  z-index: 200;
  top: 15px;
  left: 15px;
  margin: 6px;
  display: flex;
}

.btn_content_elimupdate button {
  margin: 4px 8px;
  padding: 5px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: whitesmoke;
  background-color: crimson;
  box-shadow: rgba(138, 53, 53, 0.308) 0px 3px 8px;
  transition: all .8s ease;
  border: 2px solid crimson;
}

.btn_content_elimupdate button:hover {
  background-color: #fff;
  color: crimson;
  border: 2px solid crimson;
  box-shadow: rgba(255, 37, 37, 0.384) 0px 5px 15px;
}

/****/


@media (max-width : 750px) {

  .Perfil_COMER {
    position: fixed;
    top: 0;
    z-index: 1000000;
    width: 100vw;
    height: 100vh;
  }

  #comercio_content {
    width: 86%;
  }

  .perfil_present_content p {
    font-size: 14px;
  }

  .Perfil_comer_precio {
    font-size: 15px;
  }

  .cssbuttons-io-button {
    width: 40%;
  }

  .dropdows_menu_compart {
    width: 90%;
  }

  .menu_comers_logo {
    font-size: 18px;
  }

  .perfil_comercio_content .perfil_img_avatar {
    width: 100px;
    height: 100px;
  }

  .content_option_content h3 {
    font-size: 13px;
  }

  .uiverse_view {
    font-size: 10px;
    width: 70px;
    height: 30px;
    top: 2px;
    right: 2px;
  }

  .btn_content_elimupdate {
    top: 0;
    left: 0;
    margin: 8px;
  }

  .btn_content_elimupdate button {
    margin: 4px;
    font-size: 10px;
    padding: 2px;
    height: 30px;
  }
}