.reactions{
    display: grid;
    grid-template-columns: repeat(4, 50px);
    text-align: center;
    cursor: pointer;
    overflow: scroll;
    height: 300px;
}
.reactions span{
    margin: 3px 0;
}