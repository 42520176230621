@import url('https://fonts.googleapis.com/css2?family=Bungee+Spice&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gloock&family=Permanent+Marker&family=Shadows+Into+Light&display=swap');

.rj-container-sections .rj-izquierda {
    width: 15%;
    position: sticky;
    top: 80px;
    height: 88vh;
    padding: 8px;
    z-index: 1;
}

.rj-central {
    width: 70%;
    padding: 10px;
}

.rj-derecha {
    width: 15%;
    height: 80vh;
    position: sticky;
    top: 86px;
    padding: 8px;
}

.rj-container-sections {
    display: flex;
    transition: 1.5s;
}

.rj-navbar {
    height: 84vh;
    border-radius: 15px;
    transition: all .2s ease-in-out;
    padding: 4px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.rj-navbar .Logo-apps {
    height: 9%;
    text-align: center;
}

.cariñosas_title_red {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 5px 0px;
}

.cariñosas_title_red h4 {
    font-size: 16px;
}

#MenuHorizontal {
    background: #ffffff;
    width: 100%;
    height: 82px;
    position: sticky;
    top: 0;
    left: 0;
    margin: 0 0 10px 0;
    z-index: 5000;
    /*box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
*/
}

#MenuHorizontal .Logo-header-rj {
    position: absolute;
    left: 15%;
    top: 20px;
}

#logo_css_header {
    font-size: 24px;
    font-weight: 700;
}

#MenuHorizontal .container-header-p {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

#MenuHorizontal .container-header-p .logo {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;

}

#MenuHorizontal .container-header-p .logo img {
    width: 100%;
    margin-top: 12px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

/****menu***********/
#MenuHorizontal .container-header-p .icons-headerP_inicio {
    width: 50%;
    display: flex;
    justify-content: center;
}

#MenuHorizontal .container-header-p .menu {
    width: 42%;
    display: flex;
    justify-content: center;
}

#MenuHorizontal .container-header-p .menu a {
    color: rgb(247, 57, 57);
    text-decoration: none;
    display: inline-block;
    padding: 20px 15px;
    line-height: normal;
    font-size: 14px;
    transition: all 1s ease;
    border-bottom: 4x solid transparent;
}

#MenuHorizontal .container-header-p .menu a:hover {
    color: rgb(248, 33, 4);
    border-bottom: 2px solid #f52c08;
}

#MenuHorizontal .container-header-p #btn-menu {
    display: none;
}

#MenuHorizontal .container-header-p .boton {
    float: right;
    display: none;
}

/****numep****/
.show-element {
    animation-duration: 0.7s;
    animation-fill-mode: both;
    animation-name: fadeIn;
    background-color: rgb(255, 185, 128);
    position: absolute;
    width: 100%;
    height: 50vh;
    top: 78px;
    display: flex;
    padding: 5px 20px;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.show-element .nav_paginas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 600px;
}

.show-element .nav_paginas .div_nav_pafinas {
    margin: 15px 4px;
}

.show-element .nav_paginas .div_nav_pafinas a {
    color: rgb(255, 81, 0);
}

.hide-element {
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-name: fadeOut;
}

#btn_mostrar_menu_link {
    display: none;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes otherAnimation {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes animationTranslateIn {
    from {
        opacity: 0;
        transform: translateX(-200px);
    }

    to {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes animationTranslateOut {
    from {
        opacity: 1;
        transform: translateX(0px);
    }

    to {
        opacity: 1;
        transform: translateX(-200px);
    }
}

/***********************/

#stylos_box_shadow {
    position: fixed;
    margin-left: 200px;
    z-index: 10000;
    top: 100px;
    width: 100px;
    height: 100px;
    background: red;
}

/*-----botones***/
#MenuHorizontal .botones-principal {
    display: flex;
    justify-content: center;
    align-items: center;
}

/******botones login ***/

.btn_header_logre {
    border: none;
    color: #fff;
    background-image: linear-gradient(30deg, #ed25ff, #fc9cfc);
    border-radius: 20px;
    background-size: 100% auto;
    font-family: inherit;
    font-size: 14px;
    padding: 0.2em 1.5em;
    font-weight: 600;
    margin: 0 8px;
    width: 140px;
}

.btn_header_logre i {
    font-size: 13px;
    margin-right: 4px;
}

.btn_header_logre.register {
    background-image: linear-gradient(30deg, #ff0000, #ffcc5d);

}

.btn_header_logre:hover {
    background-position: right center;
    background-size: 200% auto;
    -webkit-animation: pulse 2s infinite;
    animation: pulse512 1.5s infinite;
}

@keyframes pulse512 {
    0% {
        box-shadow: 0 0 0 0 #da1e0566;
    }

    70% {
        box-shadow: 0 0 0 10px rgb(218 103 68 / 0%);
    }

    100% {
        box-shadow: 0 0 0 0 rgb(218 103 68 / 0%);
    }
}


#MenuHorizontal .container-header-p .icons-headerP {
    display: flex;
    justify-content: space-between;
    margin: 0 5px;
    width: 35%;
}

#MenuHorizontal .container-header-p .icons-headerP a {
    color: rgb(255, 115, 0);
}

#MenuHorizontal .container-header-p .icons-headerP a:hover {
    color: #626567;
}

.container-header-p .icons-headerP .icons-header-p {
    width: 46px;
    height: 46px;
    padding: 6px;
    margin: 8px 10px 5px 8px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    color: #b4b6b4;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
    transition: all 1s;
}

#MenuHorizontal .container-header-p .icons-header-p:hover {
    background: #f8c8a8;
    color: #313131;
}

#MenuHorizontal .container-header-p .filter {
    display: none;
}

.dropdown,
.dropleft,
.dropright,
.dropup {
    display: none;
}

/*titulo header incio */
.icons-headerP_inicio .titulo_haader_inicio {
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icons-headerP_inicio .titulo_haader_inicio h3 {
    display: inline-block;
    color: #000;
    background: #fff;
    mix-blend-mode: multiply;
    position: relative;
    font-weight: 700;
    font-family: cursive;
    text-shadow: 1px 1px 2px rgb(112, 112, 112);
}

.icons-headerP_inicio .titulo_haader_inicio h3:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgb(255, 51, 0), rgb(248, 182, 38), rgb(255, 136, 0));
    mix-blend-mode: screen;
    position: absolute;
    top: 0;
    left: 0;
}

.container-header-p .icons-headerP .icons-headerP_btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;
}

.container-header-p .icons-headerP .icons-headerP_btn small {
    color: rgb(141, 138, 138);
    font-size: 12px;
}


@media only screen and (min-width:320px) and (max-width:750px) {
    #MenuHorizontal {
        width: 100%;
        height: 80px;
    }

    #MenuHorizontal .Logo-header-rj {
        position: absolute;
        left: 30%;
        top: 23px;
    }

    #logo_css_header {
        font-size: 34px;
        font-weight: 700;
    }

    #MenuHorizontal .container-header-p {
        padding: 5px;
        position: sticky;
        top: 0;
        left: 0;
        width: 100vw;
        height: 80px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 35px 35px;
    }

    .rj-container-sections .rj-izquierda {
        display: none;
    }

    .rj-central {
        width: 98%;
        margin: 2px auto;
        padding: 4px;
    }

    .rj-derecha {
        display: none;
    }

    #MenuHorizontal .container-header-p .logo {
        grid-column: 1/3;
        width: 100%;
        padding: 5px;
    }

    #MenuHorizontal .container-header-p .filter {
        display: block;
        width: 100%;
        padding: 6px;
    }

    #MenuHorizontal .container-header-p .filter i {
        position: relative;
        float: right;
        margin-right: 12px;
    }

    #MenuHorizontal .container-header-p .logo h1 {
        font-size: 25px;
        margin: 2px;
        position: relative;
    }

    #MenuHorizontal .container-header-p .boton {
        margin-left: 15px;
    }

    #MenuHorizontal .container-header-p .icons-headerP {
        position: absolute;
        top: calc(100vh - 75px);
        height: 75px;
        left: 0;
        background: white;
        padding: 5px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    #MenuHorizontal .container-header-p .botones-principal {
        display: none;
    }

    /******menu***********/
    #MenuHorizontal .container-header-p .menu {
        position: absolute;
        background: rgb(236, 236, 236);
        width: 100%;
        height: calc(100vh - 80px);
        top: 69px;
        left: 0;
        transition: all 500ms ease;
        transform: translate(-100%);
        display: flex;
        flex-direction: column;
    }

    #btn-menu:checked~.menu {
        transform: translate(0);
    }

    #MenuHorizontal .container-header-p .menu a {
        display: block;
        margin-top: 10px;
    }

    #MenuHorizontal .container-header-p .boton {
        display: block;
        width: 50px;
        height: 50px;
    }

    /*********************/

    .dropdown,
    .dropleft,
    .dropright,
    .dropup {
        display: block;
    }

    .icons-headerP .btn:not(:disabled):not(.disabled) {
        width: 45px;
        height: 45px;
        margin: 2px 10px 2px 0px;
        margin-top: 5px;
        background: white;
        color: #626567;
        border-radius: 50%;
        border: none;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    .container-header-p .icons-headerP .icons-headerP_btn small {
        color: rgb(97, 95, 95);
        font-size: 9px;
    }

    .icons-headerP_inicio .titulo_haader_inicio h3 {
        display: none;
    }

}

/*-----*/

.rj-navbar h1 {
    font-size: 15px;
    margin-bottom: 6px;
    margin-top: 4px;
}

.rj-navbar svg {
    width: 11px;
    height: 11px;
    margin-right: 5px;
}


.rj-header a {
    text-decoration: none;
}

.rj-navbar .App-lugares {
    background: #ffffff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 6%;
    min-height: 32%;
    transition: all 1s ease;
}

.rj-navbar .App-lugares:hover {
    box-shadow: rgba(255, 66, 9, 0.329) 0px 3px 8px;
}

.rj-navbar .App-lugares::-webkit-scrollbar {
    display: none;
}

.rj-navbar .App-lugares a {
    text-decoration: none;
}


/***************/

.App-lugares .Lugar-app .popover-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    padding: 18px;
    left: 85%;
}

.Lugar-app .popover-content a {
    margin: 4px;
    font-weight: 400;
    color: rgb(58, 58, 58);
}

.Lugar-app:hover .popover-content a:hover {
    color: rgb(255, 60, 0);
    font-weight: 600;
}

.Lugar-app .popover-content .popover-content-one {
    display: flex;
}

.popover-content .popover-content-one .popover-content-prov {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.Lugar-app:hover .popover-content {
    display: flex;
    flex-direction: column;
}


/**************/

.rj-navbar .App-lugares .Lugar-app {
    display: flex;
    justify-content: space-between;
    border: #f7a196 solid 1px;
    color: rgb(119, 114, 114);
    padding: 4px;
    border-radius: 5px;
    width: 100%;
    height: 14%;
    min-width: 50%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 5px 0px 5px 0px;
    cursor: pointer;
    font-size: 1em;
    transition: all 0.6s ease;
}

.rj-navbar .App-lugares .Lugar-app span {
    font-size: 80%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: rgb(248, 60, 2);
}

.rj-navbar .App-lugares h1 {
    color: rgb(119, 114, 114);
    letter-spacing: .5ch;
}

.rj-navbar .App-lugares svg {
    color: rgb(119, 114, 114);
}

.rj-navbar .App-lugares .Lugar-app:hover,
.rj-navbar .App-lugares .Lugar-app span:hover {
    background: #f3592b;
    color: white;
}

.rj-navbar .App-servicios {
    width: 100%;
    min-height: 28%;
    background-color: #fff;
    border-radius: 10%;
    padding: 5%;
    font-size: 1em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    box-shadow: rgba(151, 150, 150, 0.35) 0px 5px 15px;
    transition: all 1s ease;
}

.App-servicios .Servicios-app {
    width: 100%;
    /* Ancho deseado para el div */
    white-space: nowrap;
    /* Evitar que el texto se divida en varias líneas */
    overflow: hidden;
    /* Ocultar el contenido que no cabe en el div */
    text-overflow: ellipsis;
}

.rj-navbar .App-servicios .Servicios-app span {
    font-size: 90%;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    color: #747272;
    display: inline-block;
    /* Hacer que el span sea un bloque en línea */
    vertical-align: middle;
    /* Alinear verticalmente el texto si es necesario */

}

.rj-navbar .App-servicios h1 {
    color: rgb(119, 114, 114);
    letter-spacing: .5ch;
}

.rj-navbar .App-servicios:hover {
    box-shadow: rgba(255, 117, 62, 0.493) 0px 5px 15px;
}

.rj-navbar .App-servicios span:hover {
    color: #f33f12;
}

.rj-navbar .App-mapa {
    border: #fc4d36 solid 0.5px;
    background: #fc4d36;
    border-radius: 8px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 5px;
    box-shadow: rgba(12, 9, 9, 0.35) 0px 5px 15px;
}

.rj-navbar .App-mapa i {
    color: #f5f4f4;
    font-size: 20px;
}

.rj-navbar .App-mapa span {
    font-size: 20px;
    color: #F4F6F7;
    font-family: fantasy;
    font-weight: 700;
    letter-spacing: .5ch;
}

.rj-navbar .App-mapa:hover {
    background: #f13730;
}

.rj-navbar .App-mapa span {
    margin-left: 10px;
    font-family: 'Courier New', Courier, monospace;
    font-size: 17px;
}

.header-registrar .header-registrar-perfil img {
    width: 25px;
    height: 25px;
}

/*-----lateral-----*/
.item-lugares .dropdown-menu {
    background: rgb(136, 133, 133);
    transform: translate3d(0px, 0px, 0px) !important;
}

/*---- imagens laterales----*/


/************************/


/*----------comments----------*/


/*----------portada_reds--------*/
.container-pruebaPP .portada_red_social {
    display: grid;
    grid-template-columns: 2.1fr 1fr;
    width: 100%;
    height: 300px;
    margin-bottom: 15px;
}

.container-pruebaPP .portada_red_social .uno_portada {
    width: 100%;
    height: 100%;
    overflow: hidden;
}


.uno_portada .img_carousel_portal .portal_content_ {
    position: absolute;
    margin: 20px;
    width: 10%;
    border-radius: 8px;
    background: rgba(66, 66, 66, 0.377);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    /*border: solid 2px rgb(223, 223, 223);*/
    padding: 5px;
    z-index: 3;
}

.uno_portada .img_carousel_portal .portal_content_ h1 {
    position: relative;
    text-align: center;
    font-family: 'Bungee Spice', cursive;
    text-shadow: rgb(0, 255, 234) 1px 0 10px;
}

.uno_portada .img_carousel_portal .portal_content_ p {
    color: white;
    font-family: 'Shadows Into Light', cursive;
    font-size: 22px;
    text-shadow: 1px 1px 2px rgb(0, 52, 255), 0 0 1em rgb(48, 48, 48), 0 0 0.2em rgb(0, 0, 0);
}

.img_carousel_portal .img_segun_portal {
    position: relative;
}

.uno_portada .img_carousel_portal .img_segun_portal img {
    width: 98%;
    height: 230px;
    margin: 5px auto;
    border-radius: 10px;
    object-fit: cover;
    -webkit-filter: blur(3px);
    filter: blur(3px);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.container-pruebaPP .portada_red_social .dos_portada {
    height: 100%;
    padding: 5px;
}

/*----------------*/

.portada_red_social .dos_portada .container_profile_content {
    text-align: center;
}

.dos_portada .container_profile_content a {
    text-decoration: none;
}

.portada_red_social .dos_portada .cariñosas_title_red h3 {
    font-size: 25px;
    text-shadow: 1px 1px 2px rgb(252, 175, 111);
    margin-top: 10px;
    font-weight: 600;
}

.portada_red_social .dos_portada .container_profile_content p {
    font-size: 16px;
    font-family: 'Gloock', serif;
    margin: 15px 0px;
    color: #626567;
}

.portada_red_social .dos_portada .container_profile_content span {
    background: rgb(255, 94, 0);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    letter-spacing: 0.2em;
    padding: 4px 16px;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 600;
    color: rgb(241, 241, 241);
    transition: all .5s;
}

.portada_red_social .dos_portada .container_profile_content span:hover {
    background: rgb(255, 176, 176);
    color: #fc4007;
}

.portada_red_social .dos_portada .container_profile_content i {
    margin-right: 4px;
}

/*-----------collage---------*/

.container_profile_ {
    display: flex;
    justify-content: center;
    width: 96%;
    max-width: 1440px;
    margin: 0 auto;
}

.profile_avatar_ {
    position: relative;
    transition: all 0.3s;
}

.profile_avatar_:hover {
    transform: translateY(20px);
}

.profile_avatar_ img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 3px solid #f7f7f7;
    filter: drop-shadow(-20px 0 10px rgba(0, 0, 0, 0.1));
    cursor: pointer;
    box-shadow: rgba(223, 223, 223, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.profile_avatar_:not(:first-child) img {
    margin-left: -15px;
}

.profile_avatar_ .name {
    position: absolute;
    background-color: #ffb03a;
    color: #fff;
    font-family: "Bebas Neue", cursive;
    padding: 15px 30px;
    border-radius: 100px;
    bottom: -80px;
    left: 50%;
    white-space: nowrap;
    transform: translate(-50%, -50px);
    letter-spacing: 1px;
    font-size: 20px;
    opacity: 0;
    transition: all 0.3s;
}

.profile_avatar_ .name::before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #ffb03a;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
}

.profile_avatar_ img:hover+.name {
    opacity: 1;
    transform: translateX(-50%);
    box-shadow: 0 10px 20px rgba(86, 86, 198, 0.3);
}

/*----------tags depart-----*/

.container-pruebaPP .Provincias_tags {
    display: flex;
    flex-direction: column;
    width: 94%;
    margin: 0px auto;
}

.container-pruebaPP .Provincias_tags button {
    margin: 4px;
}

.container-pruebaPP h3 {
    font-size: 20px;
    margin: 0;
    color: #423531;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.container-pruebaPP h2 {
    width: 100%;
    font-size: 20px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: #7c7c7c;
    text-align: center;
    padding: 8px 0px;
    margin: 30px 0px;
}

.Provincias_tags .provincia_carosusel {
    background: rgb(152, 152, 153);
    display: flex;
}

/*-------------------------------------------------*/
.contenedor_tags {
    width: 96%;
    margin: 2px auto;
}

.contenedor_tags ul {
    margin: 0;
}

ul.boxs-container {
    list-style: none;
    padding: 5px;
}

ul.boxs-container2 {
    padding: 0;
}

ul.boxs-container li {
    display: inline;
    margin: 0px 5px;
}

ul.boxs-container li label {
    display: inline-block;
    background-color: #eeeded;
    border: 1px solid #f5e0e0;
    color: #807777;
    border-radius: 25px;
    white-space: nowrap;
    margin: 3px 0px;
    user-select: none;
    transition: all .2s;
    font-weight: 500;
}

ul.boxs-container li label {
    padding: 8px 12px;
    cursor: pointer;
}

ul.boxs-container li label:hover {
    border: 1px solid #fc7c49;
}

ul.boxs-container li input[type="checkbox"]:checked+label {
    border: 1px solid #fdbf85;
    background-color: #ff9650;
    color: #fff;
    transition: all .2s;
}

ul.boxs-container li input[type="checkbox"] {
    display: absolute;
}

ul.boxs-container li input[type="checkbox"] {
    position: absolute;
    opacity: 0;
}

/*---------------*/

ul.boxs-container2 li {
    display: inline;
    margin: 0px 5px;
}

ul.boxs-container2 li label {
    display: inline-block;
    background-color: #fceeee;
    border: 1px solid #f8dede;
    color: #817373;
    border-radius: 25px;
    white-space: nowrap;
    margin: 3px 0px;
    user-select: none;
    transition: all .2s;
    font-weight: 500;
}

ul.boxs-container2 li label {
    padding: 8px 12px;
    cursor: pointer;
}

ul.boxs-container2 li label:hover {
    border: 1px solid #fd6224;
}

ul.boxs-container2 li input[type="checkbox"]:checked+label {
    border: 1px solid #ff7f7a;
    background-color: #ff2609;
    color: #fff;
    transition: all .2s;
}

ul.boxs-container2 li input[type="checkbox"] {
    display: absolute;
}

ul.boxs-container2 li input[type="checkbox"] {
    position: absolute;
    opacity: 0;
}

/*--------------*/
.cart_anuncios {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 5px 0px;
}

.cart_anuncios .lists_card {
    width: 300px;
    height: 550px;
    overflow: hidden;
}

.lists_card .img_Portal {
    width: 100%;
    height: 80%;
    border-radius: 10px;
}

.img_carousel_ {
    height: 380px;
}

/*
.img_carousel_ .img_segun span {
    position: absolute;
    margin: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 4px;
    background: white;
    cursor: pointer;
    font-size: 24px;
}*/

/*----------footer-img----------*/

.img_segun_footer .title_footer {
    display: flex;
    justify-content: space-between;
    margin: 6px 4px;
    font-size: 14px;
    padding: 0 3px;
}

.img_segun_footer .title_footer h4 {
    font-size: 16.5px;
    margin: 0;
    letter-spacing: 1.5px;
    color: #626567;
    text-shadow: 1px 1px 2px rgb(172, 172, 172);
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
}

.img_segun_footer .title_footer span {
    color: #E74C3C;
    font-weight: 700;
}

.img_segun_footer .content_footer {
    display: flex;
    justify-content: flex-start;
    margin: 0px 4px;
}

.img_segun_footer .content_footer span {
    font-family: cursive;
    color: #616A6B;
}

.img_segun_footer .content_footer img {
    margin: 0 4px;
    width: 22px;
    height: 22px;
}

.img_segun_footer .lugar_footer {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    margin: 0px 4px;
    padding: 0 3px;
}

.img_segun_footer .lugar_footer .lugar_footer_img {
    width: 70%;
    display: flex;
    align-items: center;
    color: red;
}

.img_segun_footer .lugar_footer img {
    margin: 0 4px;
    width: 22px;
    height: 22px;
}

#img_footer_img {
    box-shadow: none;
}

/*------------*/


.img_carousel_ .img_segun {
    width: 96%;
    height: 98%;
    margin: 2px auto;
    border-radius: 15px;
    padding: 2px;
    border: solid 1.5px #9a7d0ae0;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.img_carousel_ .img_segun .img_segun_favorite {
    position: absolute;
    background: rgba(253, 251, 238, 0.582);
    border-radius: 50%;
    border: solid 0.5px #9A7D0A;
    display: flex;
    color: #9A7D0A;
    font-size: 20px;
    padding: 4px;
    margin: 8px;
    cursor: pointer;
}

.img_carousel_ img {
    width: 98%;
    height: 74%;
    margin: 1px auto;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.img_carousel_ .img_segun_vip {
    width: 96%;
    height: 98%;
    margin: 2px auto;
    border: solid 1.5px #7a7a7ad3;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.img_carousel_ .img_segun_vip img {
    width: 98%;
    border-radius: 0;
}

.img_carousel_ .img_segun_vip .img_segun_footer img {
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

.img_carousel_ .img_segun_vip .img_segun_favorite {
    position: absolute;
    background: rgba(253, 251, 238, 0.582);
    border-radius: 50%;
    border: solid 0.5px #A93226;
    display: flex;
    color: #A93226;
    font-size: 20px;
    padding: 4px;
    margin: 8px;
    cursor: pointer;
}

.container-pruebaPP .flesh_directorio {
    font-size: 28px;
    margin-left: 35px;
}

.container-pruebaPP .flesh_directorio i {
    color: red;
}

.settings2 {
    width: 90%;
    margin: 20px auto;
}

.settings2 .slick-slider.slick-initialized .slick-prev::before {
    color: rgb(255, 102, 0);
}

.settings2 .slick-slider.slick-initialized .slick-next::before {
    color: rgb(255, 102, 0);
}

.load_more {
    text-align: center;
}

.load_more button {
    padding: 6px 12px;
    margin: 12px auto;
    border: 1.5px solid rgb(207, 67, 3);
    border-radius: 8px;
    background: rgba(255, 254, 254, 0.932);
    color: rgb(207, 67, 3);
    text-transform: capitalize;
    transition: all .5s;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.load_more button:hover {
    background: rgb(253, 83, 4);
    color: white;
}

/*---- card get perfil---*/

.postcarget_style {
    width: 100%;
    max-width: 1025px;
    margin: 2px auto;
    display: grid;
    grid-template-columns: 1fr;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.card_header_getuser {
    display: flex;
    justify-content: space-between;
    padding: 8px;
}

.card_header_getuser .fa-solid.fa-ellipsis {
    color: rgb(63, 63, 63);
    font-size: 25px;
    margin: 6px;
    cursor: pointer;
}

.card_header_getuser #dropdown-item-headerget {
    position: absolute;
    left: 10px
}

.card_icon_menu_get {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.card_icon_menu_get i {
    font-size: 24px;
    margin: 0 10px;
    cursor: pointer;
}

.card_icon_menu_get img {
    cursor: pointer;
}

.card_body-content_get {
    padding: 5px 12px;
}

/*****contenedor de interes de usuario*****/

.contenedor_interes_btn_ {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 2px;
    margin: 8px auto;
    align-items: center;
}

.contenedor_interes_btn_ button {
    margin: 0 10px;
    padding: 5px 8px;
    border-radius: 8px;
    border: none;
    font-size: 13px;
    color: #616A6B;
    box-shadow: rgba(247, 247, 247, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.contenedor_interes_btn_ button:hover {
    background: rgb(245, 227, 227);
}

.contenedor_card_interes {
    width: 100%;
    margin: 20px auto;
    display: flex;
    padding: 5px;
    flex-direction: column;
}

.card_interes_usuario {
    width: 100%;
    margin: 15px auto;
    overflow: hidden;
    height: 460px;
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    background: #FBFCFC;
    backdrop-filter: blur(6px);
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.card_interes_usuario img {
    width: 98%;
    height: 65%;
    margin-top: 1%;
    position: absolute;
    border-radius: 20px;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.card_interes_usuario .interes_usuario_header {
    width: 92%;
    height: 64%;
    padding: 2%;
    position: absolute;
    top: 3%;
}

#usuario_header_interes {
    background: rgba(124, 124, 124, 0.678);
    color: white;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 17px;
    text-shadow: 0.1em 0.1em 0.2em rgb(68, 68, 68);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

#usuario_header_edad {
    background: #f75858c4;
    backdrop-filter: blur(5px);
    position: absolute;
    right: 1%;
    top: 2%;
    padding: 2px 8px;
    font-weight: 700;
    font-size: 17px;
    color: rgb(254 254 254);
    border-radius: 4px;
    text-shadow: rgb(73, 73, 73) 2px 2px 8px;
    box-shadow: rgba(51, 51, 51, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.card_interes_usuario .interes_usuario_footer {
    position: absolute;
    bottom: 2px;
    width: 96%;
    height: 28%;
    margin: 2%;
    border-radius: 0 0 20px 20px;
    padding: 8px 18px;
    backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.card_interes_usuario .interes_usuario_footer h4 {
    position: absolute;
    top: 10%;
    right: 4%;
    font-size: 18px;
    background: #fa4a04;
    color: #f5f5f5;
    border-radius: 5px;
    padding: 6px 10px;
    cursor: pointer;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.card_interes_usuario .interes_usuario_footer h4:hover {
    background: rgb(255, 207, 179);
    color: #db5a04;
    border: solid 1px #db5a04;
}

.card_interes_usuario .interes_usuario_footer span {
    color: #808B96;
    font-weight: 600;
    /*text-shadow: 1px 1px 2px rgb(17, 1, 59);*/
    z-index: 3;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif, cursiva;
}

#user_card_footer {
    color: rgb(31, 30, 30);
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 20px;
    font-weight: 600;
    color: whitesmoke;
    position: absolute;
    bottom: 8%;
    text-shadow: black 0.1em 0.1em 0.2em;
}

#user_card_footer_n {
    color: #fc2104;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 17px;
    text-shadow: 0.1em 0.1em 0.2em rgb(182, 181, 181)
}

#user_card_footer_p {
    color: #ff0831;
    font-family: cursive;
    font-size: 17px;
    text-shadow: 0.1em 0.1em 0.2em rgb(182, 181, 181)
}

.card_interes_usuario .interes_usuario_footer i {
    z-index: 2;
    color: #969696;
    text-shadow: 1px 1px 2px rgba(102, 102, 102, 0.356);
    font-size: 15px;
}

#user_card_footer_story {
    margin: 5px 0;
    padding: 2px 6px;
}

#user_card_footer_story p {
    font-size: 15.5px;
    color: #3b3b3b;
    font-weight: 500;
}

/*****btn inicio red soacial****/

.content_inicio_btn_3 {
    width: 90%;
    margin: 8px auto;
    padding: 8px;
}

.content_inicio_btn_3 a {
    text-decoration: none;
}

.custom-btn-inicio {
    position: relative;
    width: 80%;
    margin: 5px auto;
    border-radius: 10px;
    border: none;
    background-color: rgb(151, 95, 255);
    color: white;
    box-shadow: 0px 10px 10px rgb(210, 187, 253) inset,
    0px 5px 10px rgba(5, 5, 5, 0.212),
    0px -10px 10px rgb(124, 54, 255) inset;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 14px;
    font-weight: 600;
  }
  
  .custom-btn-inicio::before {
    width: 70%;
    height: 2px;
    position: absolute;
    background-color: rgba(250, 250, 250, 0.678);
    content: "";
    filter: blur(1px);
    top: 7px;
    border-radius: 50%;
  }
  
  .custom-btn-inicio::after {
    width: 70%;
    height: 2px;
    position: absolute;
    background-color: rgba(250, 250, 250, 0.137);
    content: "";
    filter: blur(1px);
    bottom: 7px;
    border-radius: 50%;
  }
  
  .custom-btn-inicio:hover {
    animation: jello-horizontal 0.9s both;
  }
  
  @keyframes jello-horizontal {
    0% {
      transform: scale3d(1, 1, 1);
    }
  
    30% {
      transform: scale3d(1.25, 0.75, 1);
    }
  
    40% {
      transform: scale3d(0.75, 1.25, 1);
    }
  
    50% {
      transform: scale3d(1.15, 0.85, 1);
    }
  
    65% {
      transform: scale3d(0.95, 1.05, 1);
    }
  
    75% {
      transform: scale3d(1.05, 0.95, 1);
    }
  
    100% {
      transform: scale3d(1, 1, 1);
    }
  }
  


@media (max-width: 750px) {

    #user_card_footer_story p {
        font-size: 11.5px;
    }

    .card_interes_usuario {
        height: 390px;
    }

    .card_interes_usuario img {
        width: 94%;
        margin: 2% 3%;
    }

    #usuario_header_interes {
        font-size: 12.5px;
    }

    #usuario_header_edad {
        font-weight: 600;
        font-size: 13px;
        margin: 5px;
    }

    .card_interes_usuario .interes_usuario_header {
        padding: 2% 6%;
        top: 2%;
    }

    .card_interes_usuario .interes_usuario_footer {
        bottom: 2px;
        width: 98%;
        margin: 1%;
        padding: 5px 8px;
    }

    .card_interes_usuario .interes_usuario_footer h4 {
        margin-top: 5px;
        font-size: 10.5px;
    }

    .card_interes_usuario .interes_usuario_footer i {
        font-size: 12px;
    }

    #user_card_footer {
        font-size: 17px;
    }

    #user_card_footer_n {
        font-size: 14px;
    }

    #user_card_footer_p {
        font-size: 14px;
    }

    .contenedor_interes_btn_ button {
        font-size: 10.2px;
    }

}

/*---------------*/

@media (max-width: 920px) {

    .rj-container-sections .rj-section.rj-left {
        display: none;
    }

    .rj-container-sections .rj-section.rj-lateral {
        display: none;
    }

    .rj-header {
        margin-left: 0;
    }

    .container-header-p .botones-principal i {
        display: none;
    }

    .container-header-p .icons-headerP {
        padding: 6px;
    }

    .container-header-p .icons-headerP .icons-header-p {
        width: 40px;
        height: 40px;
        font-size: 20px;
    }

    .container-pruebaPP h2 {
        margin: 30px 0;
        font-size: 17px;
        color: #7c7c7c;
        position: relative;
    }

}

/*------------------------------------------*/

@media (max-width: 920px) {
    .variable-width button {
        font-size: 11px;
    }

    ul.boxs-container li label {
        font-size: 11px;
        padding: 4px 6px;
    }

    ul.boxs-container2 li label {
        font-size: 11px;
        padding: 4px 6px;
    }

    .container-pruebaPP h3 {
        font-size: 15px;
    }
}

@media (max-width: 750px) {
    .img_carousel_ {
        height: 270px;
    }

    .img_carousel_ .img_segun img {
        height: 70%;
    }

    .img_carousel_ .img_segun span {
        font-size: 16px;
    }

    /*-----*/
    .img_segun_footer .title_footer h4 {
        font-size: 12px;
        letter-spacing: 1.5px;
    }

    .img_segun_footer .title_footer span {
        color: #E74C3C;
        font-size: 12px;
        font-weight: 500;
    }

    .img_segun_footer .content_footer {
        margin: 0px 4px;
    }

    .img_segun_footer .content_footer span {
        font-size: 12px;
    }

    .img_segun_footer .content_footer img {
        margin: 0 4px;
        width: 15px;
        height: 15px;
    }

    .img_segun_footer .lugar_footer {
        font-size: 10px;
        margin: 0px 2px;
        padding: 0 1px;
    }

    .img_segun_footer .lugar_footer img {
        margin: 0 4px;
        width: 15px;
        height: 15px;
    }

    .img_segun_footer .lugar_footer .lugar_footer_img span {
        font-size: 10px;
    }

    .img_carousel_ .img_segun_vip img {
        width: 98%;
        height: 70%;
    }

    .img_carousel_ .img_segun_vip .lugar_footer .lugar_footer_span span {
        font-size: 13px;
    }

    .img_carousel_ .img_segun_vip .img_segun_footer img {
        width: 15px;
        height: 15px;
    }

    .img_carousel_ .img_segun .img_segun_favorite {
        font-size: 14px;
    }

    .container-pruebaPP .flesh_directorio {
        font-size: 20px;
        margin-left: 10px;
    }

    /*---------------*/
    .container-pruebaPP .portada_red_social {
        display: grid;
        height: 380px;
        grid-template-columns: 1fr;
        grid-template-rows: 1.2fr 0fr;
    }

    .container-pruebaPP .portada_red_social .uno_portada {
        width: 100%;
        height: 100%;
    }

    .uno_portada .img_carousel_portal .img_segun_portal img {
        height: 145px;
    }

    .portada_red_social .dos_portada .container_profile_content {
        text-align: center;
    }

    .portada_red_social .dos_portada .cariñosas_title_red h3 {
        font-size: 24px;
        font-weight: 600;
    }

    .portada_red_social .dos_portada .container_profile_content p {
        font-size: 14px;
        margin: 8px 0;
        color: #4c4c4d;
    }

    .portada_red_social .dos_portada .container_profile_content span {
        background: rgb(255, 94, 0);
        padding: 6px 20px;
        border-radius: 4px;
        font-size: 12px;
        color: rgb(236, 236, 236);
    }

    .portada_red_social .dos_portada .container_profile_content i {
        margin-right: 4px;
    }

    /*-----------------*/
    .profile_avatar_ .name {
        display: none;
    }

    .container_profile_ {
        margin: 10px auto;
    }

    .portada_red_collage {
        margin: 8px auto;
    }

    /*------------------------*/


    .uno_portada .img_carousel_portal .portal_content_ {
        padding: 5px;
        text-align: center;
    }

    .uno_portada .img_carousel_portal .portal_content_ h1 {
        font-size: 30px;
        position: relative;
        left: 0%;
        margin: 4px;
    }

    .uno_portada .img_carousel_portal .portal_content_ p {
        font-size: 16px;
    }

    #btn_mostrar_menu_link {
        width: 32px;
        height: 32px;
        margin-left: 20px;
        border-radius: 5px;
        position: absolute;
        top: 30px;
        color: #4d4d4d;
        font-size: 22px;
        border: none;
        background: #ffc4a1;
        display: block;
    }

}