.page-vent-contenido {
    max-width: 1025px;
    margin: 8px auto;
    height: 70vh;
    position: relative;
    border-radius: 10px;
    background-color: aliceblue;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.page-vent-contenido h2 {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 10%;
    color: #4b4b4b;
    font-family: 600;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif
}

.bounce {
    background-image: linear-gradient(to right, #fac384 0%, #f4dc8f 51%, #fa8484 100%);
    position: relative;
    padding-bottom: 10px;
    left: 50%;
    width: 120px;
    height: 120px;
    margin-left: -30px;
    border: 6px solid lightgray;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    animation: bounce 5s infinite;
    -webkit-animation: bounce 5s infinite;
    -moz-animation: bounce 5s infinite;
    -o-animation: bounce 5s infinite;
}

#icons-construct {
    font-size: 90px;
    color: #1d0700;
}

@media ( max-width: 766px) {
    .page-vent-contenido h2 {
        font-size: 20px;
    }
    
}

@-webkit-keyframes bounce {
    0% {
        top: 0px;
    }

    25% {
        top: 200px;
    }

    75% {
        top: 50px
    }

    100% {
        top: 100px;
    }
}

/* Standard syntax */
@keyframes bounce {
    0% {
        top: 0px;
    }

    25% {
        top: 200px;
    }

    75% {
        top: 50px
    }

    100% {
        top: 100px;
    }

}