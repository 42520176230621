* {
    padding: 0;
    box-sizing: border-box;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  #theme{
    display: none;
  }
  
  #theme:checked ~ * {
    filter: invert(1);
  }
  
  .App{
    width: 100%;
    min-height: 100vh;
    background: white;
  }
  
  .main {
    width: 100%;
    min-height: 100vh;
    background: #F4F6F6;
  }
  
  img{
    object-fit: cover;
  }
  .avatar{
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

/*** warningmodal **/
.modal-overlay_waring {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500000000000000;
}

.modal-content_waring {
  background: rgb(255, 49, 49);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.modal-content_waring h2 {
  color: #fff;
}

.modal-content_waring p {
  color: #ccc;
  font-size: 20px;
}

.modal-content_waring span {
  color: #fff;
  font-weight: 600;
}

.modal-content_waring button {
  margin: 10px;
  font-size: 16px;
  border: none;
  padding: 5px 10px;
  border-radius: 2px;
  background-color: #424242;
  color: #fff
}

@media (max-width: 750px) {
  .modal-content_waring {
    width: 70%;
  }

  .modal-content_waring h2 {
    font-size: 18px;
  }

  .modal-content_waring p {
    font-size: 16px;
  }

  .modal-content_waring button {
    font-size: 14px;
    padding: 2px 10px;
    margin: 4px 20px
  }
}
  
  /*---auth----*/
  
  @import url("./auth.css");
  
  /*---loading----*/
  
  @import url("./loading.css");
  
  /*---header----*/
  
  @import url("./header.css");
  
  /*---avatar----*/
  
  @import url("./avatar.css");

  /*---Profile----*/
  
  @import url("./profile.css");

   /*---home----*/
  
   @import url("./home.css");

   /*---status_modal----*/
  
   @import url("./status_model.css");

   /* -------- Comments ---------- */
   @import url("./comments.css");

   /* -------- Post Thumb ---------- */
   @import url("./post_thumb.css");

   /* -------- Message---------- */
  @import url("./message.css");

  /* -------- Icons---------- */
  @import url("./icons.css");

  /* -------- Call Modal---------- */
  @import url("./call_modal.css");


  /* --------- Responsive ------ */
  @media (max-width: 766px){

  .header .menu .navbar-nav{
      display: flex;
      justify-content: space-around;
      align-items: center;
  }
  
  .header .search_form{
      width: 60%;
  }
  .header .logo{
      margin: 0px;
  }
  .header .logo h1{
      font-size: 1.3rem;
  }
  
  .message{
    height: calc(100vh - 100px);
  }
  .right_mess,
  .left_mess{
    display: none;
  }
  .status{
    padding: 20px 10px;
  }
  .chat_container{
    transform: translate(0px, -45px);
  }
  .chat_input {
    transform: translate(0px, -45px);
    position: relative;
    z-index: 2;
    top: 45px;
  }
  .message_header, .chat_container{
    position: relative;
  }
  .message_header{
    z-index: 2;
    top: -10px;
  }
  .chat_container{
    z-index: 1;
    top: 30px;
  }

}

.posts {
  max-width: 1025px;
  margin: 0px auto;
}

/*********Chat btn************/
.chat-float {
  position: fixed;
  bottom: 19vh;
  right: 22%;
  background-color: #D1F2EB;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
  z-index: 9999;
  border-radius: 8px;
}

.chatfloat-btn {
  background-color: #03eeb3;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: fixed;
  bottom: 10vh;
  right: 20%;
  z-index: 10000;
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 26px;
  transition: all 0.8s ease;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.chatfloat-btn:hover {
  background-color: #9fdfd1;
  color: #646464;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.btn_input_chat {
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: #e6fff9;
  border: solid 0.6px #9fdfd1;
}

.sumit_form_chat {
  border: none;
  width: 60px;
  height: 30px;
  border-radius: 6px;
  text-align: center;
  background-color: #73ffdc;
  color: rgb(1, 65, 5);
  font-weight: 600;
  transition: all 1s ease;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.sumit_form_chat:hover {
  background-color: #ccc;
}

@media (max-width: 750px) {
  .chatfloat-btn {
    bottom: 15vh;
  right: 5%;
  width: 45px;
  height: 45px;
  font-size: 19px
  }

  .chat-float {
  bottom: 23vh;
  right: 8%;
  }
}


/*--------------------HeaderPro--------------------*/

@import url("../components//headerPro/headerPro.css");

/*-----------Products-----*/

@import url("../components/mainpages/products/products.css");

/*-----------Detail Product-------*/

@import url("../components/mainpages/detailProduct/detailProduct.css");

/*----------cart-----------*/

@import url("../components/mainpages/cart/cart.css");

/*----------cart-detalles-----------*/

@import url('./cart-detalles.css');

/*-------categories-------*/

@import url("../components/mainpages/categories/categories.css");

/*-----------CrearProduct------*/

@import url("../components/mainpages/createProduct/createProduct.css");

/* ----------------- Responsive ------------------ */

@import url("../components/mainpages/products/responsive.css");

/*------MenuDos-----*/

@import url("./menudos.css");
   .mode{
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

/*------buscador-----*/

@import url("./buscador.css");

/*------catalago-----*/

@import url("./catalogo.css");

/*-----pagetiner----*/

@import url("./pagetinder.css");

/*-------Pricipal-----*/

@import url("./principal.css");

/*----start-----*/

@import url("../components/mainpages/start/start.css");

/*-------Mapaubicaciones---------*/

@import url("./Mapaubicaciones.css");

/*-------Carrosel-slick---------*/

@import url("../components/slickcarrosel/ImgCarousel.css");

/*-------anuncio---------*/

@import url("./anuncio.css");

/*-------publicar anuncio---------*/

@import url("./publicaranuncio.css");

/*-------contactanos---------*/

@import url("./contactanos.css");

/*-------terminos---------*/

@import url("./terminosycondiciones.css");

/*-------politicas---------*/

@import url("./politicas.css");

/*-------tiktokefect---------*/

@import url("./tiktokefect.css");

/*-------tiktokefect---------*/

@import url("./errofont.css");

/*-------videoefect---------*/

@import url("./videoefecto.css");

/*-------videosfowers--------*/

@import url("./videosfowers.css");

/*-------ventacontenido--------*/

@import url("./ventacontenido.css");

/*----red social-----*/

@import url("./redsocial.css");

/*----publicidad home-----*/

@import url("./publicidadhome.css");

/*----barraderech-----*/

@import url("./barraderech.css");

/*----barraderech-----*/

@import url("./perfilcomercio.css");

/*----barraderech-----*/

@import url("./notificationS.css");

/*----barraderech-----*/

@import url("./forum.css");

/*----Contenidos hub-----*/

@import url("./contenidohub.css");

/*----Contenidos hub-----*/
@import url("./contentdestacat.css");



