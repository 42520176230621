.call_modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
    background: #0008;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.call_box {
    width: 100%;
    max-width: 450px;
    height: 65%;
    /* background: rgb(9, 179, 209);
    box-shadow: 0 0 5px rgb(94, 204, 238);*/
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    padding: 30px 0;
    border-radius: 5px;
    position: relative;
}

.call_box .img_call_avatar {
    width: 96%;
    height: 100%;
    border-radius: 5px;
    position: absolute;
    z-index: -1;
}

.call_box .text-center {
    margin: 25px 0;
    border-radius: 10px;
    overflow: hidden;
    padding: 15px;
}

.call_box h4,
.call_box h6 {
    margin-top: 10px;
    font-size: 25px;
    text-shadow: 1px 1px 2px rgb(87, 87, 87);
}

.call_menu {
    min-width: 280px;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.call_menu button {
    font-size: 2rem;
    background: #eee;
    padding: 15px;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    outline: none;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.call_modal .timer {
    transform: translateY(-15px);
    font-size: 25px;
}

.call_modal .show_video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    pointer-events: none;
}

.call_modal .other_video {
    width: 100%;
    height: 100%;
}

.call_modal .you_video {
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    max-height: 250px;
    border-radius: 5px;
    border: 1px solid crimson;
    z-index: 10;
}

.call_modal .end_call {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: initial;
    font-size: 2rem;
    background: #eee;
    padding: 15px;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    outline: none;
}

.call_modal .time_video {
    position: absolute;
    bottom: 170px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
}