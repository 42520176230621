
.post_thumb{
    width: 98%;
    max-width: 1025px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 2fr));
    justify-content: center;
    grid-gap: 2px;
    overflow: hidden;
    margin: 10px auto;
    min-height: 350px;
}

.post_thumb_display{
    min-width: 300px;
    height: 450px;
    width: 100%;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.post_thumb_display img,
.post_thumb_display video{
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    border-radius: 3px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.container-fluid .post_thumb_menu{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0008;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: 0.3s;
}
.container-fluid:hover .post_thumb_menu{
    opacity: 1;
}
.container-fluid .post_thumb_menu i{
    font-size: 1.8rem;
    color: white;
    margin: 0 25px;
}

@media (max-width: 766px){
    .post_thumb{
        grid-template-columns: repeat(2, 1fr);
    }
    .post_thumb_display{
        min-width: 100px;
        height: 250px;
        width: 100%;
        position: relative;
        cursor: pointer;
        overflow: hidden;
    }
}

