.title_save_save {
    max-width: 1025px;
    margin: 5px auto;
    color: crimson;
    text-align: center;
}

.cart-detalles {
    display: grid;
    grid-template-columns: 0.6fr 1fr;
    width: 100%;
    max-width: 1025px;
    margin: 5px auto;
    border-radius: 8px;
    height: 350px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.cart-detalles img {
    width: 98%;
    height: 98%;
    max-width: 250px;
    max-height: 320px;
    border-radius: 5px;
    margin: auto;
    object-fit: cover;
    object-position: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.cart-detalles .box-detail-cart {
    padding: 2px;
    position: relative;
}

.cart-detalles .box-detail-cart h2 {
    font-size: 25px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    position: relative;
    left: 50%;
    width: 230px;
    transform: translate(-50%);
    color: rgb(88, 88, 88);
    margin: 5px 0px;
    text-shadow: rgba(100, 100, 100, 0.801) 0.5px 0 5px;
}

/*
.cart-detalles .box-detail-cart h3 {
    color: rgb(255, 35, 35);
    font-size: 23px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin-left: 5px;
}*/

.cart-detalles .box-detail-cart p {
    font-size: 15px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: rgb(73, 73, 73);
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}

.box-detail-cart .delete-cart {
    position: absolute;
    left: 76%;
    margin-top: 8px;
    color: crimson;
    font-weight: 800;
    cursor: pointer;
}


/*------------*/

.box-detail-cart .info_cont_sav {
    display: flex;
    flex-direction: column;
}

.info_cont_sav .info_cont_tit {
    color: rgb(255, 0, 0);
    margin-right: 5px;
    font-size: 20px;
    text-shadow: 1px 1px 2px rgb(160, 160, 160);
    ;
}

.info_cont_sav h4 {
    color: rgb(104, 104, 104);
    font-size: 13px;
    font-weight: 700;
    margin-left: 5px;
}

.info_cont_sav .info_sav {
    display: flex;
}

#p_info {
    margin: 3px 0px;
    font-size: 15px;
    color: rgb(111, 112, 112);
    font-family: cursive;
}

.info_cont_sav .info_sav h3 {
    color: rgb(255, 109, 72);
    font-weight: 600;
    font-size: 16px;
    text-align: center;
}

.box-detail-cart .btn_info_what {
    position: absolute;
    left: 60%;
    top: 70%;
    display: flex;
    justify-content: space-between;
}

.box-detail-cart .btn_info_what a {
    text-decoration: none;
}

.box-detail-cart .btn_info_what span {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 15px;
    font-size: 25px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.btn_info_what .btn_info_1 {
    color: white;
    background: #42FC32;
}

.btn_info_what .btn_info_1:hover {
    background: white;
    color: #42FC32;
    border: solid 1px #42FC32;
}

.btn_info_what .btn_info_2 {
    color: rgb(253, 253, 253);
    background: #CB4335;
}

@media (max-width:750px) {
    .cart-detalles {
        height: 230px;
        margin: 8px auto;
        width: 96%;
    }

    .cart-detalles img {
        width: 90%;
        height: 76%;
    }

    .cart-detalles .box-detail-cart h2 {
        font-size: 14px;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
    }

    .cart-detalles .box-detail-cart h3 {
        font-size: 13px;
        margin: 3px 0;
    }

    .cart-detalles .box-detail-cart p {
        font-size: 11px;
    }

    .box-detail-cart .delete-cart {
        left: 90%;
    }

    #p_info {
        font-size: 12px;
    }

    .info_cont_sav .info_sav h3 {
        font-size: 10px;
    }

    .info_cont_sav .info_sav h4 {
        font-size: 10px;
    }

    .box-detail-cart .btn_info_what span {
        width: 40px;
        height: 40px;
        margin: 0px 5px;
    }
}