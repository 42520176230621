.content_hub_home {
  display: flex;
  width: 96%;
  margin: 8px auto;
}

.content_hub_derach {
  width: 70%;
}

.content_hub_medid {
  width: 100%;
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  /* Permite que las tarjetas se ajusten en múltiples filas */
  gap: 12px;
  /* Espacio entre las tarjetas */
  justify-content: center;
  /* Centra las tarjetas horizontalmente */
  padding: 16px;
}

.content_creadoras_content {
  width: 100%;
  padding: 10px;
}

.content_hub_izder {
  width: 30%;
  margin: 6px;
}

/************************/

.cart_contethub_public_creadoras {
  width: 96%;
  background-color: #ffffff;
  /* Fondo blanco para la tarjeta */
  border-radius: 12px;
  /* Bordes redondeados */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  /* Sombra suave */
  overflow: hidden;
  /* Para que los bordes redondeados funcionen bien */
  margin: 10px auto;
  /* Espaciado entre tarjetas */
  max-height: 250px;
  position: relative;
}

.header_cart_contenthub_creadoras {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  /*border-bottom: 1px solid #ddd;*/
  position: absolute;
  width: 100%;
  /* Líneas sutiles para separar secciones */
}

.header_cart_contenthub_creadoras img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  /* Imagen redondeada */
  object-fit: cover;
  /* Asegura que la imagen se recorte correctamente */
  margin-right: 20px;
  background-color: #fff;
}

.header_cart_contenthub_creadoras h1 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  color: #ffffff;
  text-shadow: -1px 3px 5px rgba(0, 0, 0, 0.747);
  cursor: pointer;
}

.content_hub_derach_h1 {
  width: 100%;
  text-align: center;
  margin: 20px 0;
  font-size: 25px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #4b4b4b;
}

.header_cart_contenthub_creadoras small {
  font-size: 12px;
  color: #888;
}

.content_img_contenhub_creadoras {
  width: 100%;
  height: auto;
  /* Ajusta dinámicamente el alto */
  overflow: hidden;
}

.content_img_contenhub_creadoras img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  /* Deforma la imagen para llenar el contenedor */
}

/* From Uiverse.io by alexmaracinaru */
.cardhub {
  background: white;
  padding: .2em;
  border-radius: 6px;
  max-width: 300px;

  min-height: 350px;
  max-height: 480px;
}

.card-imagehub {
  background-color: rgb(236, 236, 236);
  width: 100%;
  height: 80%;
  border-radius: 6px 6px 0 0;
}

.card-imagehub img {
  width: 100%;
  height: 100%;
}

.card-imagehub:hover {
  transform: scale(0.98);
}

.categoryhub {
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 600;
  color: rgb(63, 121, 230);
  padding: 8px auto;
}

.categoryhub:hover {
  cursor: pointer;
}

.headinghub {
  font-weight: 600;
  color: rgb(88, 87, 87);
  padding: 7px;
  max-height: 50px;
}

#headinghub_h3 {
  font-size: 12px;
  text-align: justify;
  overflow: hidden;
  /* Oculta el contenido que se desborde */
  text-overflow: ellipsis;
  /* Agrega puntos suspensivos al final */
  display: -webkit-box;
  /* Muestra el contenido en un contenedor flexible */
  -webkit-line-clamp: 2;
  /* Limita a 2 líneas de texto */
  -webkit-box-orient: vertical;
  /* Define la orientación del contenedor flexible como vertical */
  max-height: 3em;
  /* Ajusta la altura máxima para 2 líneas de texto (depende del tamaño de fuente) */
  line-height: 1.5em;
  /* Define el espacio entre líneas */
  margin: 0;
  height: auto;
}

.headinghub h3 a {
  color: rgb(87 86 86);
  text-decoration: none;
}


.headinghub:hover {
  cursor: pointer;
}

.authorhub {
  color: gray;
  font-weight: 400;
  font-size: 11px;
  padding-top: 0px;
}

.namehub {
  font-weight: 600;
}

.namehub:hover {
  cursor: pointer;
}

/*/**Videos private***/
/* Galería */
/* Contenedor general */
.gallery-container {
  display: flex;
  flex-direction: column;
  /* Organiza imágenes y videos en columnas */
  gap: 25px;
  /* Espacio entre imágenes y videos */
  padding: 10px;
  background-color: #f9f9f9;
  /* Fondo claro para contraste */
}

/* Galería de imágenes */
.image-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 4px;
  /* Espacio entre imágenes */
}

/* Galería de videos */
.video-gallery {
  display: flex;
  flex-wrap: wrap;
  /* Permite que los videos se ajusten al ancho disponible */
  gap: 16px;
  /* Espacio entre videos */
  justify-content: center;
  /* Centra los videos horizontalmente */
}

/* Estilo común para las imágenes y videos */
.gallery-item {
  position: relative;
  overflow: hidden;
  min-height: 350px;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery-item:hover {
  /*transform: scale(1.02);*/
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

/* Estilo para las imágenes */
.image-gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease, filter 0.3s ease;
  border-radius: 5px;
}

.image-gallery img:hover {
  transform: scale(1.1);
  filter: brightness(0.8);
}

/* Estilo para los videos */
/* Galería de videos */
/* Galería de videos */
.video-gallery {
  display: flex;
  flex-wrap: wrap;
  /* Permite que los videos se ajusten al ancho disponible */
  gap: 16px;
  /* Espacio entre videos */
  justify-content: center;
  /* Centra los videos horizontalmente */
  align-items: flex-start;
  /* Alinea los videos en la parte superior */
}

/* Contenedor de videos */
.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  border-radius: 6px;
  overflow: hidden;
  flex: 0 1 auto;
  /* Adapta el ancho del contenedor según el video */
  max-width: 100%;
  /* Evita que se desborde */
  aspect-ratio: auto;
  /* Adapta dinámicamente al video */
}

/* Video ajustado al contenedor */
.video-container video {
  width: auto;
  /* Ajusta el ancho para videos altos */
  height: auto;
  /* Ajusta el alto para videos anchos */
  max-width: 100%;
  /* Nunca excede el contenedor */
  max-height: 100%;
  /* Mantén el video dentro del contenedor */
  object-fit: contain;
  /* Respeta proporciones originales */
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

/* Responsividad */
@media (max-width: 768px) {
  .content_hub_derach {
    width: 100%;
  }

  .video-gallery {
    flex-direction: column;
    /* Videos en una sola columna en pantallas pequeñas */
    align-items: center;
  }

  .image-gallery {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .content_hub_izder {
    width: 100%;
    margin: 10px 0;
  }

  .cart_contethub_public_creadoras {
    max-height: 200px;
  }

  .content_hub_derach_h1 {
    font-size: 18px;
  }
}

/* Visor de imágenes */
.custom-image-viewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000;
  cursor: zoom-out;
}

.custom-image-viewer-content {
  position: relative;
  cursor: auto;
}

.custom-image-viewer img {
  max-width: 90vw;
  max-height: 90vh;
  transition: transform 0.3s ease;
}

.custom-image-controls {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  gap: 8px;
}

.custom-image-controls button {
  padding: 8px 12px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.custom-image-controls button:hover {
  background: rgba(255, 255, 255, 0.6);
  color: black;
}

/******style modal********/
.btn_content_hub_utls {
  position: relative;
}

.btn_content_hub_utls .content-private-mediahub {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #1f1f1f, #2d2d2e, #2e2e31ec, #29292ee3, rgba(126, 126, 150, 0.8));
  z-index: 2000;
  text-align: center;
  padding: 50px;
  display: flex;
  flex-direction: column;
  margin: 10px auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
  /* Mejora la apariencia */
}

.footer_false_mediahub {
  width: 100%;
  height: 300px;
  background: linear-gradient(0deg, #161616, #262626, #404040);
}

.open-modal-button {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 700;
  color: #4d4d4d;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  width: 40%;
  margin: 8px auto;
  transition: all ease 0.8s;
}

.open-modal-button:hover {
  background-color: #23c483;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  scale: 1.05;
}

.open-modal-button:active {
  transform: translateY(-1px);
}

.content-private-mediahub i {
  font-size: 8em;
  margin: 10px;
  margin-bottom: 20px;
  color: #949494;
  text-shadow: rgba(75, 75, 75, 0.781) 1px 0 10px;
}

.btn_content_hub_utls .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000000000000000000000000000;
}

.btn_content_hub_utls .modal-content_hub {
  background: white;
  border-radius: 10px;
  max-width: 900px;
  /* Limita el ancho máximo */
  max-height: 88vh;
  /* Altura máxima */
  overflow-y: auto;
  /* Añade scroll si el contenido es muy alto */
  position: relative;
  display: flex;
  flex-wrap: wrap;
  /* Permite que los elementos se ajusten en filas */
  gap: 20px;
  /* Espaciado entre elementos */
  padding: 20px;
  /* Espaciado interno */
  justify-content: center;
  align-items: flex-start;
  position: relative;
  transition: width 0.3s ease, height 0.3s ease;
}

.modal-content_hub.expanded {
  width: 420px;
  height: auto;
  /* Ajusta la altura automáticamente según el contenido */
}

.extra-info {
  margin-top: 15px;
  background: #f9f9f9;
  padding: 10px;
  border-top: 1px solid #ddd;
  border-radius: 8px;
  color: #333;
  width: 100%;
}

.modal-content_hub .close-button {
  position: absolute;
  top: -10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: crimson;
}

/****/

.content_money_hub {
  display: flex;
  flex-direction: column;
  padding: 4px;
}

.content_money_hub label {
  display: flex;
  align-items: center;
  width: 80%;
  align-items: center;
}

.content_money_hub label input {
  margin: 2px 6px;
}

/*****/
.btn_content_hub_utls b {
  padding: 0px 4px;
  display: inline-block;
}

.btn_content_hub_utls .con-items {
  display: flex;
  flex-wrap: wrap;
  /* Permite que las tarjetas se apilen en pantallas pequeñas */
  gap: 20px;
  justify-content: space-between;
  align-items: stretch;
  /* Ajusta la altura de las tarjetas */
  width: 100%;
  /* Asegura que se ajusten al contenedor */
  padding: 10px;
}

.btn_content_hub_utls .item {
  flex: 1 1 calc(33.333% - 40px);
  /* Cada tarjeta ocupa un tercio del espacio disponible menos el espacio del gap */
  max-width: 340px;
  min-width: 200px;
  /* Define un ancho mínimo */
  background: #fff;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: transform 0.25s ease;
}

.btn_content_hub_utls .item ul {
  padding: 2px;
}

.btn_content_hub_utls .item.color {
  flex: 1 1 calc(100% - 40px);
  /* Ocupará toda la fila en pantallas pequeñas */
}

.btn_content_hub_utls .item:not(.color):hover {
  transform: scale(1.02);
}

.btn_content_hub_utls .item:hover .con-img {
  transform: scale(1.02);
}

.btn_content_hub_utls .item.color:hover {
  transform: scale(1.02);
}

.btn_content_hub_utls .con-img {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .25s ease;
}

.btn_content_hub_utls .con-img img {
  width: 130px;
}

.btn_content_hub_utls .item.color {
  background: #3dcbab;
  color: #fff;
  transform: scale(1.05);
}

.btn_content_hub_utls .item.color li {
  color: rgba(255, 255, 255, .75);
}

.btn_content_hub_utls .item.color li b {
  color: rgba(255, 255, 255, 1);
}

.btn_content_hub_utls .item.color li i {
  color: rgba(255, 255, 255, 1);
}

.btn_content_hub_utls .item header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.btn_content_hub_utls .item header h3 {
  font-size: 1.2rem;
  margin: 0;
}

.btn_content_hub_utls .item header p {
  font-size: 1.5rem;
  margin: 0;
}

.btn_content_hub_utls .badge {
  position: absolute;
  top: 12px;
  right: 12px;
  background: #fff;
  padding: 5px 10px;
  border-radius: 12px;
  color: rgb(61, 203, 171);
  font-weight: bold;
  font-size: .6rem;
}

.btn_content_hub_utls item ul {
  padding: 10px 0px;
  flex: 1;
  width: 100%;
}

.btn_content_hub_utls .item ul li {
  width: 100%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  color: rgb(var(--color), .5);
  font-size: 13px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
}

.btn_content_hub_utls .item ul li b {
  color: rgb(var(--color), 1);
  margin-left: 8px;
}

.btn_content_hub_utls .item ul li i {
  font-size: 1.6rem;
  margin-right: 15px;
  color: rgb(var(--color), 1)
}

.btn_content_hub_utls .item .btn_plans_medianOpen {
  width: 100%;
  background: rgb(184, 255, 238);
  border: 1px solid rgb(119, 223, 174);
  border-radius: 20px;
  color: #888787;
  font-weight: bold;
  font-size: 1.1rem;
  box-shadow: 0px 10px 25px 0px rgba(61, 203, 171, .35);
  cursor: pointer;
  transition: all .25s ease;
}

.item .btn_plans_medianClose {
  width: 100%;
  background: rgb(255, 71, 111);
  border: 3px solid transparent;
  border-radius: 20px;
  color: #fff;
  font-weight: bold;
  font-size: 1.1rem;
  box-shadow: 0px 10px 25px 0px rgba(61, 203, 171, .35);
  cursor: pointer;
  transition: all .25s ease;
}

.btn_plans_medianOpen.standar {
  background: #000;
}

.btn_plans_medianOpen:not(.border):hover {
  transform: translate(0, 5px);
  box-shadow: 0px 0px 0px 0px rgba(61, 203, 171, .35);
}

.item .btn_plans_medianOpen.border {
  border: 3px solid #fff;
}

.item .btn_plans_medianOpen.border:hover {
  background: #fff;
  color: rgb(61, 203, 171);
}

.modal-content_hub {
  scrollbar-width: none;
  /* Oculta el scroll en Firefox */
}

.modal-content_hub::-webkit-scrollbar {
  display: none;
  /* Oculta el scroll en Webkit */
}

@media (max-width: 768px) {
  .btn_content_hub_utls .item {
    flex: 1 1 calc(50% - 20px);
    /* Reduce a dos columnas en pantallas medianas */
  }

  .btn_content_hub_utls .item.color {
    flex: 1 1 calc(100% - 20px);
    /* Mantén en una fila para destacar */
  }

  .btn_content_hub_utls .item header h3 {
    font-size: 1.1rem;
  }

  .btn_content_hub_utls .item header p {
    font-size: 1.5rem;
  }

  .open-modal-button {
    color: #ffffff;
    background-color: #00e667;
    font-size: 10px;
    transition: all 0.3s ease 0s;
    width: 70%;
  }

  .btn_content_hub_utls .modal-content_hub {
    width: 98%;
    margin: 0x auto;
  }

}

@media (max-width: 480px) {
  .btn_content_hub_utls .item {
    flex: 1 1 100%;
    /* En pantallas pequeñas, todas las tarjetas son de ancho completo */
  }
}

/********Detailles de anuncio ***********/

.content_detail_hubmovie {
  width: 90%;
  margin: 6px auto;
}

.member-details {
  padding-top: 25px;
  padding-bottom: 25px
}

@media (min-width:992px) {
  .member-details {
    padding-top: 20px;
    padding-bottom: 20px
  }
}

.img-container {
  margin: 6px auto;
}

.member-details .member_designation {
  margin-bottom: 30px
}

.member-details .member_designation h2 {
  margin: 15px;
  font-size: 22px;
  color: #525252;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.member-details .member_designation h2 a {
  color: #8a8a8b;
}

@media (min-width:768px) {

  .member-details .member_designation h2 {
    margin-top: 0
  }
}

.member-details .member_designation span {
  font-style: italic
}

.member-details .member_desc li {
  display: block;
  float: unset;
  width: 100%
}

.member-details .member_desc li i {
  color: #0cc652;
  font-size: 14px
}

.member-details .member_desc h4 {
  margin-top: 40px
}

.member-details .member_desc p {
  margin-top: 10px
}

.member-details .member_desc .progress-holder {
  margin-top: 30px
}

.member-details .media-box {
  margin-bottom: 20px
}

@media (min-width:992px) {
  .member-details .media-box {
    margin-bottom: 0
  }
}

.member-details .member_contact {
  padding: 25px;
  position: relative;
  margin-top: 8px 20px;
  background-color: #6161615b;
}

.member-details .member_contact .media-icon {
  font-size: 32px;
  color: #dae0e6;
  position: relative;
  width: 30px;
  text-align: center;
  float: left;
  margin-right: 15px
}

.member-details .member_contact .media-content {
  padding-left: 0;
  float: left
}

.member-details .member_contact .media-content h5 {
  font-size: 15px
}

.member-details .member_contact .media-content h5,
.member-details .member_contact .media-content a {
  color: #dae0e6
}

@media (min-width:992px) {
  .member-details .member_contact .social-icons {
    text-align: right
  }
}

.member-details .member_contact .social-icons .btn-social {
  width: 40px;
  height: 40px;
}

.member-details .member_contact .social-icons .btn {
  background-color: transparent;
  border: 1px solid;
  border-color: #999;
  color: #dae0e6
}

.member-details .member_contact .social-icons .btn:hover {
  background-color: #0cc652;
  border-color: #0cc652;
  opacity: 1
}

.bg-image-holder,
.bg-image {
  background-size: cover !important;
  background-position: 50% 0 !important;
  transition: all .3s linear;
  background: #8a8a8b;
  position: relative;
}

.bg-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bg-image:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .7)
}

.bg-fixed {
  background-attachment: fixed
}

.bg-image .overlay-content {
  position: relative;
  z-index: 5
}


.progress-holder {
  margin-top: 50px
}

.progress-holder .barWrapper .progressText {
  font-size: 15px;
  color: #222
}

.progress-holder .progress {
  margin-bottom: 25px;
  margin-top: 10px;
  overflow: visible;
  background-color: #f5f5f6
}

.progress-holder .progress .progress-bar {
  position: relative
}

.progress-holder .progress .progress-bar:after {
  position: absolute;
  content: '';
  width: 1px;
  height: 33px;
  background-color: #0cc652;
  top: -8px;
  right: 0;
  z-index: 55
}

.img-full {
  width: 100%;
}

.member-details p {
  color: #8b8e93;
  font-weight: 300;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 26px;
}


.styled_list {
  margin-top: 15px;
  position: relative;
  display: inline-block
}

@media (min-width:768px) {
  .styled_list {
    margin-top: 15px
  }
}

.styled_list li {
  font-size: 14px;
  line-height: 30px
}

@media (min-width:768px) {
  .styled_list li {
    font-size: 14px;
    float: left;
    width: 50%
  }
}

.styled_list li i {
  margin-right: 10px;
  font-size: 12px
}

.styled_list li a {
  color: #8b8e93
}

@media (min-width:768px) {
  .styled_list li a {
    font-size: 12px
  }
}

@media (min-width:992px) {
  .styled_list li a {
    font-size: 14px
  }
}

ol.styled_list {
  margin-left: 15px
}

ol.styled_list li {
  padding-left: 10px
}

@media (max-width:750px) {
  .content_detail_hubmovie {
    width: 100%;
  }
}

.content_detail_hubmovie h3 {
  font-size: 22px;
  margin: 40px auto;
  color: #424242;
  padding: 8px;
}

/************/
.content_movie_expo,
.content_movie_retringi {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* Hace que el contenedor ocupe el 100% del ancho */
  height: 100%;
  /* Ajusta esta altura según lo que necesites */
  max-width: 100%;
  /* Asegura que el contenedor no sobrepase el ancho */
  overflow: hidden;
  /* Oculta cualquier desbordamiento */
}

.main-container {
  display: flex;
  padding: 2px;
  margin: 20px auto;
  flex-direction: column;
  gap: 20px;
  /* Espacio entre los reproductores de video */
}

.content_movie_expo {
  width: 100%;
  position: relative;
  padding-top: 56.25%;
  /* Relación de aspecto 16:9 */
  max-height: 90vh;
  /* Limita la altura al 80% de la pantalla en dispositivos grandes */
  overflow: hidden;
  min-height: 50vh;
}

.content_movie_expo iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.conten_relacion_detailhub {
  width: 100%;
  margin: 40px 2px;
  text-align: center;
  padding: 4px;
  font-size: 24px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: #707070;
}

.conten_btn_hubpaypal {
  padding: 8px;
  display: flex;
  width: 30%;
  align-items: center;
  border-radius: 5px;
  background-color: #ffff;
  margin: 10px 0;
  box-shadow: rgba(223, 139, 139, 0.24) 0px 3px 8px;
}

#nombre_multimet_cantidad {
  font-size: 15px;
  font-weight: 700;
  color: crimson;
}

.conten__hubpaypal_he {
  margin: 4px 12px;
  height: 100%;
  width: 150px;
  padding: 5px;
  text-align: justify;
}

.conten__hubpaypal_he span {
  font-size: 10px;
  color: #5a5a5a;
  font-weight: 600;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.preci_content_hub {
  display: flex;
  /* Usa flexbox para facilitar el centrado */
  flex-direction: column;
  /* Alinea los elementos verticalmente */
  align-items: center;
  /* Centra horizontalmente */
  justify-content: center;
  /* Centra verticalmente */
  text-align: center;
  /* Centra el texto */
  padding: 12px 16px;
  /* Añade algo de espacio alrededor */
  background-color: #f7f7f7;
  /* Color de fondo para dar un toque visual */
  border-radius: 8px;
  /* Bordes redondeados */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Sombra ligera */
}

.preci_content_hub h2 {
  margin: 0;
  /* Elimina el margen para ajustar mejor el espacio */
  font-size: 18px;
  /* Ajusta el tamaño de la fuente */
  color: #333;
  /* Color del texto del precio */
}

.preci_content_hub small {
  font-size: 14px;
  /* Tamaño de fuente más pequeño para "mes" */
  color: #666;
  /* Color más claro para "mes" */
}

.paypal-button-container {
  width: 100%;
}

@media (max-width:750px) {
  .extra-info {
    width: 75%;
  }

  .preci_content_hub {
    width: 30%;
  }

  .preci_content_hub h2 {
    font-size: 16px;
  }

  .conten_btn_hubpaypal {
    width: 96%;
    justify-content: space-between;
    font-size: 8px;
    margin: 6px auto;
  }

  .content_detail_hubmovie h3 {
    font-size: 12px;
    margin: 16px auto 0;
  }
}

/************/

.vid_tiktok_title {
  margin: 5px;
  font-size: 18px;
  color: #333;
  text-align: center;
}

.content_tiktok_vid {
  display: flex;
  /* Coloca los elementos en línea */
  overflow-x: auto;
  /* Permite el desplazamiento horizontal */
  padding: 5px 10px;
  gap: 20px;
  /* Espacio entre los videos */
}

.content_tiktok_vid::-webkit-scrollbar {
  height: 8px;
  /* Altura de la barra de desplazamiento */
}

.content_tiktok_vid::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color de la barra */
  border-radius: 4px;
  /* Bordes redondeados */
}

.content_tiktok_vid h2 {
  flex-basis: 100%;
  /* Título ocupa el 100% del ancho antes del carrusel */
}

.content_tiktok_vid>div {
  min-width: 300px;
  /* Ancho mínimo para los videos */
  flex-shrink: 0;
  /* Evita que se redimensionen los elementos */
  display: flex;
  justify-content: center;
  /* Centra el video */
}





@media (max-width: 750px) {

  .content_hub_home {
    flex-direction: column;
    width: 98%;
  }

  .content_hub_medid {
    justify-content: space-around;
    /* Ajusta la alineación en pantallas pequeñas */
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .cardhub {
    width: 90%;
    min-width: 350px;
    /* Reducir el ancho mínimo en pantallas más pequeñas */
  }
}