.message {
    width: 100%;
    max-width: 1025px;
    margin: 0px auto;
    height: calc(100vh - 100px);
    border: 1px solid #ddd;
    margin-top: 15px;
    border-radius: 3px;
    background: #fbfbfb;
}

.message_header {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f8f8f8;
    position: relative;
}

#alert_message {
    position: absolute;
}

.message_header input {
    flex: 1;
    height: 100%;
    border: none;
    outline: none;
    background: #f8f8f8;
    padding: 0 5px;
}

.message_chat_list {
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: auto;
}

.message_chat_list .message_user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid #f3f3f3;
    color: #555;
    cursor: pointer;
}

.message_chat_list .message_user.active {
    background: #eee;
}

.message_chat_list .message_user a {
    color: #555;
}

.message_chat_list .message_user a:hover {
    text-decoration: none;
}

.message_chat_list .message_user .fa-circle {
    font-size: 14px;
    color: #aaa;
}

.message_chat_list .message_user .fa-circle.active {
    color: forestgreen;
}

/* -------- Chat Input----- */
.chat_input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 6px;
    border-top: 1px solid #ddd;
}

.chat_input input {
    width: 100%;
    height: 49px;
    border: none;
    outline: none;
}

.chat_input .icons_chat_me {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 3px;
    position: relative;
    top: -2px;
    background: white;
}

.chat_input button {
    border: none;
    outline: none;
    background: white;
    margin: 0 8px;
    color: #777;
}

.chat_input .file_upload {
    position: relative;
    margin: 0 5px;
}

.chat_input .file_upload i {
    font-size: 20px;
}

.chat_input .file_upload #file {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.show_media {
    width: 100%;
    height: 70px;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
    place-items: center;
    grid-gap: 10px;
    background: #f3f3f3;
    border-radius: 5px;
    padding: 0 5px;
}

.show_media #file_media {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 70px;
    max-height: 70px;
}

.show_media #file_media img,
.show_media #file_media video {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
}

.show_media #file_media span {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 4;
    background: white;
    border: 1px solid crimson;
    padding: 3px 7px;
    color: crimson;
    border-radius: 50%;
    font-size: 10px;
    font-weight: bold;
    cursor: pointer;
}

/* -------- Chat Container ----- */
.chat_container {
    width: 100%;
    height: calc(100% - 110px);
    overflow-y: auto;
    padding: 0 10px;
    background:radial-gradient(circle at 54.11% 54.9%, #ffffff 0, #ffffff 25%, #ffffff 50%, #fdf9fa 75%, #fcf3f6 100%) ;
    background-repeat: no-repeat;
    background-image: url("https://res.cloudinary.com/geocivil/image/upload/v1677368674/iconos/loguito_dvfpux.png");
    background-position: center;
}

.chat_display {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.chat_row {
    display: grid;
    grid-template-columns: 70%;
    margin-bottom: 10px;
}

.you_message {
    justify-content: end;
    justify-items: end;
}

.other_message {
    justify-content: start;
    justify-items: start;
}

.chat_text {
    padding: 9px 14px;
    margin-bottom: 5px;
}

.chat_time {
    font-size: 13px;
    color: #777;
}

.you_message .chat_text {
    background: hsla(24, 92%, 85%, 0.69);
    color: #252413;
    border: 1px solid hsla(24, 92%, 85%, 0.69);
    border-radius: 14px 14px 0 14px;
}

.other_message .chat_text {
    background: #EAF2F8;
    color: #111;
    border: 1px solid #EAF2F8;
    border-radius: 14px 14px 14px 0;
}

.chat_title {
    margin-bottom: 3px;
}

.you_content {
    position: relative;
}

.you_content .fa-trash {
    position: absolute;
    top: 50%;
    left: -15px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 14px;
    opacity: 0;
}

.you_content:hover .fa-trash {
    opacity: 1;
}

.chat_input .dropdown-menu {
    transform: translate3d(-130px, -300px, 0px) !important;
}


.style-picker {
    position: absolute;
    top: 45%;
    left: 50%;
}

@media (max-width: 750px) {
    .style-picker {
        position: absolute;
        left: 10%;
        top: -680%;
        background: white;
    }
}