
.terminos_condiciones .div_title_poli h3 {
    font-size: 24px;
    color: #626567;
}

.terminos_politicas {
    max-width: 1025px;
    margin: 5px auto;
    background: white;
    text-align: justify;
}

.terminos_condiciones .div_title_poli span {
    color: #8b8b8b;
    font-size: 16px;
    margin-left: 10px;
}

.terminos_condiciones .div_title_poli i {
    margin-right: 5px;
}

.terminos_politicas .accordion-item h3 {
    color: #707B7C;
    font-size: 18px;
}
.terminos_politicas .accordion-item h3 i {
    margin-right: 5px;
}

.terminos_politicas .accordion-item .accordion-body {
    color: #424949;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-align: justify;
}

.terminos_politicas .accordion-item .accordion-body h4 {
    font-size: 16px;
    color: #CD6155;
}

@media (max-width: 750px) {
    .politicas_condiciones_page {
        margin-bottom: 94px;
    }
    .terminos_condiciones .div_title_poli h3 {
        font-size: 15.5px;
    }
    .terminos_condiciones .div_title_poli span {
        font-size: 12px;
    }
    .terminos_politicas {
        width: 92%;
    }
    .terminos_politicas .accordion-item h3 {
        font-size: 12px;
    }
    .terminos_politicas .accordion-item .accordion-body p {
        font-size: 12.5px;
    }

    .terminos_politicas .accordion-item .accordion-body li {
        font-size: 12px;
    }

    .terminos_politicas .accordion-item .accordion-body span {
        font-size: 12px;
    }

    .terminos_politicas .accordion-item p {
        font-size: 12.5px;
    }

    .terminos_politicas .accordion-item ul li p {
        font-size: 12.5px;
    }

    .terminos_politicas .accordion-item ul li ul li {
        font-size: 12.5px;
    }

    .accordion-flush .accordion-collapse {
        width: 90%;
        margin: 0 auto;
    }
} 