/*---------*/

.Ubicate-map {
  display: flex;
  width: 100%;
  max-width: 1025px;
  margin: 5px auto;
  height: 100vh;
}

.Ubicate-map .leaflet-container {
  margin: 0px auto;
  width: 98%;
  height: 100vh;
  border-radius: 8px;
}

.Ubicate-map .btn-hide-siderbar {
  position: absolute;
  border: none;
  background: rgba(245, 26, 26, 0.726);
  width: 30px;
  height: 37px;
  border-radius: 4px;
  left: 65%;
  top: 11%;
  font-size: 25px;
  z-index: 1000;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

/*---------*/

.Ubicate-map .sidebar {
  width: 485px;
  height: 100vh;
  padding: 10px;
  transition: all linear 0.75s;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.Ubicate-map .sidebar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #aaa;
  /* or add it to the track */
}

/*
  .Ubicate-map {
    display: flex;
  }*/

.Ubicate-map .sidebar.sbclosed {
  width: 0;
  transition: all linear 0.75s;
}

.sbcontent {
  width: 98%;
  height: 197px;
  padding: 6px;
  margin: 7px;
  border-radius: 15px;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.sbcontent-info-uno {
  display: flex;
  padding: 2px 6px;
  justify-content: space-between;
  font-size: 13px;
}

.sbcontent .sbcontent-info-uno .info-titulo {
  color: rgb(20, 20, 20);
  font-family: "Goudy Bookletter 1911", sans-serif;
  font-weight: 800;
}

.sbcontent .sbcontent-info-uno .info-precio {
  background: red;
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
}

.sbcontent-info-dos {
  display: flex;
  margin: 2px 3px;
  justify-content: center;
  font-size: 12px;
}

.sbcontent-info-dos .info-edad {
  color: rgb(46, 43, 43);
  font-size: 12px;
  background: rgba(78, 78, 78, 0.541);
  padding: 1px 3px;
  border-radius: 2px;
}

.sbcontent-info-dos .info-nacionalidad {
  color: rgb(253, 19, 19);
  font-size: 13px;
  margin-right: 8px;
}

.sbcontent .sbcontent-info-parra p {
  font-size: 13px;
  font-family: Comic Sans MS;
  line-height: 12px;
  margin: 5px 3px;
  color: rgb(66, 66, 66);
}

.sbcontent .info-distrito {
  font-size: 12px;
  margin: 0px 12px;
  font-weight: 800;
  color: rgb(88, 88, 88);
  font-family: "Lucida Sans Typewriter";
}

.sbcontent .info-btn {
  margin: 9px 0px;
  display: flex;
  justify-content: space-between;
  margin: 4px 7px;
}

.sbcontent .info-btn a {
  text-decoration: none;
  color: white;
}

.info-btn button {
  border: none;
  font-size: 14.5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.info-btn .info-btn-perfil {
  background: rgb(255, 65, 65);
  height: 100%;
  width: 65px;
  border: none;
  color: white;
  border-radius: 2px;
  cursor: pointer;
}

.info-btn .info-btn-whatsapp {
  background: #25d366;
  width: 25px;
  height: 25px;
  margin-left: 5px;
  color: white;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-btn .info-btn-location {
  background: #ff9742;
  width: 25px;
  height: 25px;
  color: white;
  border-radius: 2px;
  cursor: pointer;
}

.sbcontent .sbcontent-img-cont img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

@media (max-width: 725px) {
  .Ubicate-map .btn-hide-siderbar {
    width: 25px;
    height: 30px;
    font-size: 18px;
    position: absolute;
    top: 55%;
    left: 4%;
    z-index: 1000;
  }

  .Ubicate-map .leaflet-container {
    margin: 0px auto;
    width: 98%;
    height: 100vh;
    border-radius: 8px;
    z-index: 2;
  }

  .Ubicate-map .sidebar {
    width: 100%;
    height: 184px;
    top: calc(100vh - 250px);
    margin: 0 auto;
    display: grid;
    padding: 0px;
    grid-auto-flow: column;
    /*grid-auto-columns: 40%;*/
    column-gap: 0.6rem;
    position: absolute;
    z-index: 100;
    overflow-x: auto;
    overscroll-behavior-inline: contain;
  }

  .sidebar::-webkit-scrollbar {
    display: none;
  }

  .Ubicate-map .sbcontent {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    background: rgba(255, 255, 255, 0.616);
    width: 255px;
    height: 175px;
    padding: 2px 4px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
      rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  }

  .sbcontent .sbcontent-img-cont {
    display: flex;
    justify-content: center;
    padding: 5px auto;
  }

  .sbcontent .sbcontent-info-uno {
    display: flex;
    justify-content: space-between;
    padding: 6px 8px;
  }

  .sbcontent .sbcontent-info-uno .info-titulo {
    color: rgb(20, 20, 20);
    font-family: "Goudy Bookletter 1911", sans-serif;
    font-weight: 800;
  }

  .sbcontent .sbcontent-info-uno .info-precio {
    background: red;
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 12px;
  }

  .sbcontent .sbcontent-info-dos {
    display: flex;
    margin: 0px 1px;
    justify-content: center;
    padding: 0;
  }

  .sbcontent-info-dos .info-nacionalidad {
    color: rgb(253, 5, 5);
    margin-right: 10px;
    font-size: 10px;
    font-weight: 600;
  }

  .sbcontent-info-dos .info-edad {
    color: rgb(46, 43, 43);
    font-size: 12px;
    background: rgba(78, 78, 78, 0.541);
    padding: 1px 3px;
    border-radius: 2px;
  }

  .sbcontent .sbcontent-info-parra p {
    font-size: 10px;
    font-family: Comic Sans MS;
    line-height: 11px;
    margin: 2px 5px;
    color: rgb(66, 66, 66);
  }

  .sbcontent .info-distrito {
    font-size: 10px;
    color: rgb(153, 4, 153);
    font-family: "Lucida Sans Typewriter";
  }

  .sbcontent .info-btn {
    margin: 0px;
    padding: 4px 12px;
    display: flex;
    justify-content: space-between;
  }

  .info-btn .info-btn-perfil {
    background: rgb(255, 65, 65);
    border: none;
    color: white;
    border-radius: 2px;
    cursor: pointer;
  }

  .sbcontent-img-cont img {
    width: 125%;
    height: 105%;
    position: relative;
    top: -3px;
    left: -5px;
    border-radius: 5px;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
}

/*****geocoder----*/
/*
  .leaflet-container .geocoder-control-input {
    background-image: url("https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/search-512.png");
  }
  
  .leaflet-container .geocoder-control-input-disabled {
    background-image: url("/images/search-disabled.png");
  }
  
  .leaflet-container .geocoder-control-input.geocoder-control-loading {
    background-image: url("https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif");
  }
  
  @media only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2 / 1),
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min-device-pixel-ratio: 2) {
    .leaflet-container .geocoder-control-input {
      background-image: url("/images/search@2x.png");
    }
  
    .leaflet-container .geocoder-control-input-disabled {
      background-image: url("/images/search@2x-disabled.png");
    }
  
    .leaflet-container .geocoder-control-input.geocoder-control-loading {
      background-image: url("/images/loading@2x.gif");
    }
  }*/


/*-------*/
.leaflet-touch .leaflet-bar a:last-child {
  color: #4e4e4e;
}

/*-----------*/

.marker-pin {
  width: 44px;
  height: 44px;
  border-radius: 50% 50% 50% 0;
  background: #a2ff7e;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.marker-pin img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  position: relative;
  transform: rotate(45deg);
  left: 2px;
  top: 2px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  animation: alex 65s infinite linear;
}

@keyframes alex {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*
  .marker-pin .marker-state {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: linear-gradient(45deg, #66f, #f80, #ffc);
    position: relative;
    left: 1px;
    top: 1px;
    animation: alex 60s infinite linear;
  }*/

/*
  .marker-pin::after {
    content: "";
    width: 24px;
    height: 24px;
    margin: 3px 0 0 3px;
    background: #fff;
    position: absolute;
    border-radius: 50%;
  }*/

.content-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-popup h1 {
  margin: 3px auto;
  font-size: 22px;
  letter-spacing: 4px;
  text-shadow: black 0.1em 0.1em 0.2em
}

.content-popup .content-ppopu-img {
  display: flex;
  width: 100%;
  margin-bottom: 5px;
}

.content-popup .content-ppopu-img img {
  border-radius: 5px;
  height: 150px;
  margin: 2px auto;
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.content-popup span {
  color: rgb(100, 100, 100);
  font-size: 13px;
  font-weight: 500;
}

.cart-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.cart-btns .add-cart {
  background: rgb(192, 25, 25);
  color: #fff;
  width: 90px;
  height: 30px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 2px;
}

.cart-btns .add-cart i {
  font-size: 17px;
  margin-right: 5px;
}

.cart-btns .buy-now {
  background: #25d366;
  margin-left: 10px;
}

/*----------*/

.Ubicate-map .float {
  position: fixed;
  width: 52px;
  height: 52px;
  left: 3%;
  top: 40%;
  background-color: #25d366;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: btnrot 10s infinite linear;
}

@keyframes btnrot {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.my-float {
  margin-top: 16px;
}