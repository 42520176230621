 /*---status----*/

 .home {
     background-color: hsl(228deg 20% 95%);
     max-width: 1350px;
     margin: 0 auto;
     display: flex;
 }

 .status {
     background: white;
     box-shadow: 0 0 5px #ddd;
     padding: 20px;
     border-radius: 5px;
     border: 1px solid rgb(0, 0, 0, .125);

 }

 .status .statusBtn {
     background: #f1f1f1;
     border: none;
     outline: none;
     border-radius: 30px;
     color: rgb(70, 69, 69);
     padding: 0 10px;
     text-align: left;
     margin: 0 10px;
     font-size: 15px;
 }

 .status .statusBtn:hover {
     background: #ddd;
 }

 #statusBtnPubli {
     color: rgb(252, 64, 102);
     font-weight: 600;
     border: solid 1px rgb(255, 209, 218);
 }

 #statusPublicidad {
     background-color: #F7F9F9;
 }

 /*****/
 .home .main_left {
     width: 25%;
     display: flex;
     flex-direction: column;
     position: sticky;
     align-items: center;
     justify-content: space-around;
     top: 70px;
     height: 90vh;
 }


 .home .main_content {
     width: 55%;
     display: flex;
     flex-direction: column;
     padding: 0px 25px;
 }

 .home .main_rigth {
     width: 30%;
     height: 85vh;
     position: sticky;
     top: 75px;
     display: flex;
     flex-direction: column;
 }

 .main_rigth h1 {
     font-size: 21px;
     font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
     font-weight: 600;
     letter-spacing: 1px;
     text-align: center;
     margin: 8px auto;
 }

 .main_rigth .main_rigth_sugeVip {
     width: 100%;
     max-height: 100%;
     overflow: hidden;
 }

 .main_rigth_sugeVip_content {
     background-color: rgb(255, 255, 255);
     width: 94%;
     max-height: 90%;
     border-radius: 20px;
     box-shadow: rgba(190, 190, 190, 0.1) 0px 0px 16px;
     padding: 6px;
     margin: 4px auto;
     overflow-y: scroll;
 }

 .main_rigth_sugeVip_content::-webkit-scrollbar {
     display: none;
 }

 .main_rigth_suge_content {
     background-color: rgb(255, 255, 255);
     width: 300px;
     border-radius: 20px;
     box-shadow: rgba(190, 190, 190, 0.1) 0px 0px 16px;
     padding: 6px;
 }

 .main_rigth .main_rigth_suger {
     max-width: 80%;
     margin: 5px auto;
     height: 400px;
 }

 /* ------------Posts ------ */
 .posts .card {
     box-shadow: 0 0 4px #ddd;
 }

 /* --------- Post Card Header --------- */
 .posts .card_header {
     display: flex;
     justify-content: space-between;
     align-items: center;
     cursor: pointer;
     padding: 15px 25px;
 }

 .posts .card_header .card_name {
     transform: translateY(3px);
     margin-left: 8px;
 }

 .card_header .card_name small {
     color: rgb(156, 156, 156);
 }

 .card_name h6 {
     font-family: Arial, Helvetica, sans-serif;
     margin: 0;
     letter-spacing: 1px;
 }

 .posts .card_header .dropdown-menu {
     position: absolute;
     right: 0;
     left: inherit;
 }

 .posts .card_header .dropdown-item {
     display: flex;
     margin: 5px 0;
 }

 /* ---------- Post Card Body ---------- */
 .posts .card_body-content {
     padding: 0 25px;
     margin-top: -10px;
     margin-bottom: 10px;
 }

 .posts .card_body-content .readMore {
     color: crimson;
     cursor: pointer;
 }

 /* ---------- Post Card Footer ---------- */
 .posts .card_icon_menu {
     display: flex;
     justify-content: space-between;
     padding: 0 15px;
     cursor: pointer;
 }


 .card_footer.recomend {
     padding: 5px 8px;
 }

 .posts .card_icon_menu i {
     font-size: 28px;
     margin: 10px;
 }

 .card_header.recomend {
     display: flex;
     justify-content: space-between;
     padding: 8px 10px;
 }

 .card_body-content.recomend {
     padding: 6px 10px;
     margin: 8px auto;
 }

 .title_recomend_user {
     color: #353535;
     font-size: 24px;
     width: 100%;
     text-align: center;
     margin: 8px auto 20px;
 }

 .card_name.recomend h6 {
     font-size: 18px;
     font-weight: 600;
     padding: 8px;
 }

 /***/

 .card_icon_menu.recomend {
     display: flex;
     justify-content: space-between;
     padding: 8px 10px;
     font-size: 22px;
 }

 .card_icon_menu.recomend i {
     margin: 0px 6px;
 }

 .posts .card_icon_menu img {
     width: 28px;
     height: 28px;
     transform: translate(5px, -5px);
     opacity: 0.9;
 }


 /*------------*/

 .ml-0,
 .mx-0 {
     background: #e5e7e9;
 }

 .col-md-4 {
     background: white;
 }

 /*-----------------*/

 .slick-next {
     right: -11px;
 }

 .slick-prev {
     left: -11px;
 }

 /*****Card-Body********/

 .content_cardN {
     width: 100%;
     background-color: white;
     margin: 8px auto;
     border-radius: 5px;
 }

 .fa-solid.fa-ellipsis {
     color: hsl(33, 9%, 75%);
     transition: all 0.8s ease;
     font-size: 20px;
 }

 .fa-solid.fa-ellipsis:hover {
     border-radius: 50%;
     background-color: rgb(160, 160, 160);
     text-align: center;
     padding: 8px;
     color: #555;
 }

 .card_body-content span {
     color: #555;
     font-family: 'Segoe UI', Tahoma, Verdana, sans-serif;
 }

 .content-carousel-img {
     width: 70%;
     margin: 6px auto;
     border-radius: 20px;
 }

 .content-carousel-img img {
     border-radius: 20px;
     object-fit: cover;
 }

 .card_icon_menu i {
     color: rgb(146, 146, 146);
 }

 .card_icon_menu i:hover {
     color: #555;
 }

 .btn_seguir_sugerSe {
     width: 75px;
     border: solid 1.5px rgb(250, 80, 1);
     border-radius: 10px;
     background: none;
     font-size: 12px;
     margin: auto 1px;
     color: rgb(221, 101, 2);
     transition: all 0.5s ease;
 }

 .btn_seguir_sugerSe:hover {
     background-color: rgb(252, 115, 52);
     color: #ddd;
     border: solid 1.5s rgb(235, 0, 0);
 }

 .suggestions_actulizacion .fa-redo {
     color: rgba(204, 204, 204, 0.897);
     margin: 8px;
     cursor: pointer;
 }

 .main_left .custom-btn {
     width: 200px;
     height: 50px;
     color: rgb(255, 255, 255);
     border-radius: 5px;
     padding: 10px 25px;
     font-family: 'Lato', sans-serif;
     font-weight: 500;
     font-size: 20px;
     background: transparent;
     cursor: pointer;
     transition: all 0.3s ease;
     position: relative;
     display: inline-block;
     box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.589),
         7px 7px 20px 0px rgba(0, 0, 0, .1),
         4px 4px 5px 0px rgba(0, 0, 0, .1);
     text-shadow: 1px 1px 2px rgb(61, 61, 61);
     outline: none;
 }

 /* 8 */
 .main_left .btn-8 {
     background-color: #fdba2a;
     background-image: linear-gradient(315deg, #fffaf6 0%, #ff9a60 40%);
     line-height: 42px;
     padding: 0;
     border: none;
 }

 .main_left .btn-8 a {
     color: white;
     text-decoration: none;
 }

 .main_left .btn-8 span {
     position: relative;
     display: block;
     width: 100%;
     height: 100%;
 }

 .main_left .btn-8:before,
 .main_left .btn-8:after {
     position: absolute;
     content: "";
     right: 0;
     bottom: 0;
     background: #ebc097;
     /*box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
                -4px -4px 6px 0 rgba(116, 125, 136, .2), 
      inset -4px -4px 6px 0 rgba(255,255,255,.5),
      inset 4px 4px 6px 0 rgba(116, 125, 136, .3);*/
     transition: all 0.3s ease;
 }

 .main_left .btn-8:before {
     height: 0%;
     width: 2px;
 }

 .main_left .btn-8:after {
     width: 0%;
     height: 2px;
 }

 .main_left .btn-8:hover:before {
     height: 100%;
 }

 .main_left .btn-8:hover:after {
     width: 100%;
 }

 .main_left .btn-8:hover {
     background: transparent;
 }

 .main_left .btn-8 span:hover {
     color: #ebbd97;
 }

 .main_left .btn-8 span:before,
 .main_left .btn-8 span:after {
     position: absolute;
     content: "";
     left: 0;
     top: 0;
     background: #ebc797;
     /*box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
                -4px -4px 6px 0 rgba(116, 125, 136, .2), 
      inset -4px -4px 6px 0 rgba(255,255,255,.5),
      inset 4px 4px 6px 0 rgba(116, 125, 136, .3);*/
     transition: all 0.3s ease;
 }

 .main_left .btn-8 span:before {
     width: 2px;
     height: 0%;
 }

 .main_left .btn-8 span:after {
     height: 2px;
     width: 0%;
 }

 .main_left .btn-8 span:hover:before {
     height: 100%;
 }

 .main_left .btn-8 span:hover:after {
     width: 100%;
 }

 /******container card*******/

 .card-container {
     background-color: #ffffff;
     border-radius: 5px;
     box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.75);
     color: #6d6d6c;
     padding-top: 30px;
     position: relative;
     width: 96%;
     margin: 4px auto;
     text-align: center;
 }

 .card-container p {
     font-size: 13px;
     margin: 10px 4px;
     max-height: 80px;
     overflow: hidden;
 }

 .card-container .pro {
     color: #231E39;
     background-color: #FEBB0B;
     border-radius: 3px;
     font-size: 14px;
     font-weight: bold;
     padding: 3px 7px;
     position: absolute;
     top: 30px;
     left: 30px;
 }

 .card-container .round {
     border: 2px solid #ff7e14;
     border-radius: 50%;
     padding: 7px;
     width: 120px;
     height: 120px;
 }

 button.primary {
     background-color: #ec5700;
     border: 1px solid #ec5700;
     border-radius: 3px;
     color: white;
     font-family: Montserrat, sans-serif;
     font-size: 16px;
     font-weight: 500;
     padding: 4px 10px;
     margin: 0 6px;
     transition: all 0.7s ease;
 }

 button.primary:hover {
     background-color: rgb(121, 121, 121);
     border: none;
 }

 button.primary.ghost {
     background-color: transparent;
     color: #ec5700;
 }

 button.primary.ghost a {
     color: #ec5700;
     text-decoration: none;
 }

 button.primary.ghost:hover {
     background-color: rgb(138, 138, 138);
     border: none;
 }

 .skills {
     background-color: #f0eff5;
     text-align: left;
     padding: 15px;
     margin-top: 30px;
     display: flex;
     flex-direction: column;
 }

 .skills_container_ {
     display: flex;
     justify-content: space-around;
 }

 .skills_container_div {
     display: flex;
     flex-direction: column;
     text-align: center;
     font-weight: 700;
 }

 .main_left_search {
     border: solid 1.5px rgba(151, 151, 151, 0.801);
     width: 100%;
     border-radius: 20px;
     color: #8d8d8d;
     transition: all 0.6s ease;
 }

 .main_left_search a {
     color: #8d8d8d;
 }

 .main_left_search:hover {
     background-color: rgb(255, 187, 131);
     color: white;
     border: 1px solid white;
     box-shadow: 0px 10px 20px -10px rgba(255, 127, 52, 0.75);
 }

 .main_left_search a:hover {
     text-decoration: none;
     color: #C0392B;
 }

 /*-------container_avisos--------*/

 .list_anuncio_container {
     position: relative;
     background: #e5e7e9;
     max-width: 1025px;
     margin: 5px auto;
     padding: 5px;
     display: flex;
     flex-direction: column;
     transition: all 1s;
 }

 .list_anuncio_filter_btn {
     display: flex;
     justify-content: center;
 }

 /*******panel informe de suscripcion*******/

 .panel_informe_suscripsion {
     background: rgb(247, 105, 74);
     background: linear-gradient(90deg, rgba(247, 105, 74, 1) 0%, rgba(244, 161, 82, 0.9500175070028011) 50%, rgba(240, 116, 49, 0.9612219887955182) 100%);
     border-radius: 10px;
     display: flex;
     flex-direction: column;
     width: 100%;
     height: 300px;
     justify-content: center;
     align-items: center;
     text-align: center;
     padding: 10px;
     margin: 8px auto;
     box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
 }

 .panel_informe_suscripsion i {
     font-size: 45px;
     color: rgb(70, 69, 69);
     text-shadow: rgba(138, 138, 138, 0.432) 0.1em 0.1em 0.2em
 }

 .panel_informe_suscripsion h2 {
     color: rgb(54, 54, 54);
     text-align: center;
     font-size: 22px;
     margin: 15px 0;
 }

 .panel_informe_suscripsion span {
     font-size: 18px;
     font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
     color: rgb(75, 71, 71);
     margin: 8px;
     text-shadow: rgba(250, 244, 244, 0.432) 0.1em 0.1em 0.2em
 }

 .panel_informe_suscripsion button {
     margin: 12px;
     border: none;
     padding: 1px 8px;
     border-radius: 5px;
     font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
     color: white;
     background: #f80317;
     box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
 }


 #span_infor_panel {
     color: red;
     font-weight: 600;
     font-size: 20px;
 }

 #activebuttoH {
     background: rgb(49, 49, 49);
     color: rgb(255, 255, 255);
 }

 .scroll-to-top {
     z-index: 1000;
     position: fixed;
     bottom: 13vh;
     right: 8%;
     font-size: 18px;
     background: none;
     border: solid 1.2px rgb(110, 110, 110);
     padding: 6px;
     border-radius: 4px;
     color: rgb(110, 110, 110);
 }

 /*******card_moderador****/

 .cardanuncio_vista_moderador {
     display: flex;
 }

 .cardanuncio_vista_moderador .card_moderador_img {
     width: 25%;
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 5px;
 }

 .cardanuncio_vista_moderador .card_moderador_img img {
     width: 90%;
     height: 90%;
     object-fit: cover;
     border-radius: 10px;
 }

 .cardanuncio_vista_moderador .card_moderador_info {
     width: 75%;
     display: flex;
     flex-direction: column;
 }

 .card_moderador_info_caja1 {
     box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
     padding: 8px;
     text-align: center;
     border-radius: 8px;
     position: relative;
 }

 .card_moderador_info_caja1 .card_moderador_remove {
     position: absolute;
     display: flex;
     justify-content: center;
     align-items: center;
     font-size: 70%;
     left: 85%;
     top: 5px;
     cursor: pointer;
 }

 .card_moderador_info_caja1 .card_moderador_remove span {
     background: rgba(255, 2, 2, 0.904);
     color: white;
     font-family: Arial, Helvetica, sans-serif;
     font-weight: 600;
     padding: 5px;
     border-radius: 5px;
 }

 .card_moderador_info_caja1 h3 {
     font-size: 18px;
     margin: 0;
     color: rgb(230, 70, 30);
 }

 .card_moderador_info_caja1 h4 {
     font-size: 16px;
     letter-spacing: 2px;
     margin: 0;
     color: rgb(59, 57, 55);
     font-weight: 600;
 }

 .card_moderador_info_caja1 span {
     color: rgb(236, 104, 104);
 }

 .card_moderador_info_caja2 {
     display: grid;
     grid-template-columns: 1fr 1fr;
 }

 .card_moderador_info_sub3 {
     box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
     border-radius: 8px;
     margin: 2px 0;
     padding: 3px;
 }

 .card_moderador_info_sub3 h3 {
     font-size: 16px;
     margin: 0;
     font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
 }

 .card_moderador_info_sub4 {
     margin: 5px;
     padding: 8px;
     box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
 }

 .card_moderador_info_sub4 h4 {
     font-size: 15px;
     margin-bottom: 1px;
     font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
 }

 .card_moderador_info_sub4 h4 span {
     color: rgb(44, 44, 44);
     font-weight: 600;
 }

 .card_moderador_info_sub4 p {
     margin: 0px;
 }

 .card_moderador_info_sub3 h3 span {
     color: rgb(250, 92, 92);
     font-size: 16.5px;
 }

 .card_moderador_info_sub2 {
     padding: 5px;
     margin: 5px;
     box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
     border-radius: 8px;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     overflow: auto;
 }

 .card_moderador_info__ {
     width: 100%;
     padding: 4px 8px;
 }

 .card_moderador_info__ .h1default {
     color: #353535;
     background-color: #6d6d6c;
     width: 100%;
     font-size: 21px;
     font-family: 'Times New Roman', Times, serif;
 }

 .card_moderador_info__ .h1activeuno {
     color: #4b4b4b;
     background-color: #ffb95e;
     width: 100%;
     font-size: 21px;
     font-family: 'Times New Roman', Times, serif;
 }

 .card_moderador_info__ .h1activedos {
     color: #353535;
     background-color: crimson;
     width: 100%;
     font-size: 21px;
     font-family: 'Times New Roman', Times, serif;
 }

 .card_moderador_info__ .h2activetres {
     color: #353535;
     background-color: #aeff44;
     width: 100%;
     font-size: 21px;
     font-family: 'Times New Roman', Times, serif;
 }

 .card_moderador_info__ .h2activecuatro {
     color: #353535;
     background-color: #ff6b7e;
     width: 100%;
     font-size: 21px;
     font-family: 'Times New Roman', Times, serif;
 }

 .card_moderador_info__ .h2activecinco {
     color: #353535;
     background-color: #c444ff;
     width: 100%;
     font-size: 21px;
     font-family: 'Times New Roman', Times, serif;
 }

 .card_moderador_info__ .h1default span {
     font-size: 17px;
     color: rgb(77, 77, 77);
     font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
 }

 /*------*/

 #card_anuncios_ {
     padding: 10px;
 }

 .caranuncio_header {
     display: flex;
     justify-content: space-between;
 }

 .btn_anuncio_create {
     max-width: 1025px;
     margin: 20px auto;
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 10px;
 }

 .btn_anuncio_create button {
     margin: 2px 10px;
     width: 25%;
     border-radius: 10px;
     border: none;
     background: white;
     color: rgb(255, 60, 0);
     box-shadow: rgba(228, 190, 190, 0.267) 0px 5px 15px;
 }

 /******contenido del total de usuario*****/
 .content_user_tot_modera {
     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
     background: white;
     border-radius: 25px;
     margin: 4px;
     padding: 5px;
 }

 .content_user_tot_modera .content_user_tot_header {
     position: relative;
 }

 .content_user_tot_modera .content_user_tot_header .content_user_tot_edit {
     position: absolute;
     left: 90%;
     top: 10%;
 }

 .content_user_tot_modera .content_user_tot_header .content_user_tot_edit span {
     background: red;
     padding: 4px 8px;
     border-radius: 8px;
     font-weight: 600;
     color: white;
     cursor: pointer;
 }

 .content_user_tot_modera .content_user_tot_header h4 {
     margin: 4px 4%;
     font-size: 25px;
     color: rgb(70, 68, 68);
 }

 .content_user_tot {
     box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
     display: flex;
     padding: 8px;
     width: 94%;
     margin: 10px auto;
     border-radius: 10px;
     background: white;
 }

 .content_user_tot .user_tot_avatar {
     width: 20%;
     padding: 1px;
     margin: 5px;
     overflow: hidden;
 }

 .content_user_tot .user_tot_avatar img {
     width: 98%;
     height: 98%;
     position: relative;
     border-radius: 14px;
 }

 .content_user_tot .user_tot_info {
     width: 40%;
     box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
     margin: 5px;
     overflow: hidden;
     padding: 8px;
 }

 .content_user_tot .user_tot_info .user_tot_info_span1 {
     background: #AED6F1;
     padding: 2px 8px;
     border-radius: 8px;
     color: rgb(255, 254, 254);
 }

 .content_user_tot .user_tot_info .user_tot_info_span2 {
     background: #E74C3C;
     padding: 2px 8px;
     border-radius: 8px;
     color: rgb(255, 254, 254);
 }

 .content_user_tot .user_tot_info h3 {
     font-size: 19px;
 }

 .content_user_tot .user_tot_info h4 {
     font-size: 16px;
 }

 .content_user_tot .user_tot_info h4 span {
     font-size: 16.5px;
     color: rgb(211, 52, 4);
 }

 .content_user_tot .user_tot_suscrip {
     width: 40%;
     box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
     margin: 5px;
     overflow: hidden;
     padding: 8px;
 }

 .content_user_tot .user_tot_suscrip .span_Activo {
     background: #82E0AA;
     padding: 1px 8px;
     border-radius: 6px;
     color: #C0392B;
 }

 .content_user_tot .user_tot_suscrip .span_Inactivo {
     background: #E74C3C;
     padding: 1px 8px;
     border-radius: 6px;
     color: white;
 }

 .content_user_tot .user_tot_suscrip h3 {
     font-size: 19px;
 }

 .content_user_tot .user_tot_suscrip h4 {
     font-size: 16px;
 }

 .content_user_tot .user_tot_suscrip h4 span {
     font-size: 16.5px;
     color: rgb(211, 52, 4);
 }

 #menu_nav_item {
     display: flex;
     flex-direction: column;
     justify-content: center;
     text-align: center;
 }

 #menu_nav_item .menu_nav_item_smoll {
     font-size: 12px;
 }

 #Menutres_contenido_link {
     display: flex;
     flex-direction: column;
     text-align: center;
     color: rgb(158, 158, 158);
 }

 #Menutres_contenido_link small {
     font-size: 9px;
 }

 /********************/

 .btn-hover_new {
     width: 120px;
     font-size: 14px;
     font-weight: 600;
     color: #fff;
     cursor: pointer;
     margin: 5px;
     height: 40px;
     text-align: center;
     border: none;
     background-size: 300% 100%;
     border-radius: 50px;
     -o-transition: all .4s ease-in-out;
     -webkit-transition: all .4s ease-in-out;
     transition: all .4s ease-in-out;
 }

 .btn-hover_new:hover {
     background-position: 100% 0;
     -o-transition: all .4s ease-in-out;
     -webkit-transition: all .4s ease-in-out;
     transition: all .4s ease-in-out;
 }

 .btn-hover_new:focus {
     outline: none;
 }

 .btn-hover_new.color-2 {
     background-image: linear-gradient(to right, #f5ce62, #e43603, #fa7199, #e85a19);
     box-shadow: 0 4px 15px 0 rgba(229, 66, 10, 0.75);
 }

 /*****videos tiktok home******/

 .videos_tiktok_container {
     background: white;
     width: 90%;
     margin: 5px auto;
     padding: 5px;
 }

 .videos_tiktok_container_perfil {
     background: white;
     width: 86%;
     margin: 5px auto;
     padding: 5px;
 }

 .videos_tiktok_container_perfil button {
     border: none;
     border-radius: 6px;
     padding: 2px 6px;
     margin: 10px;
     background: crimson;
     color: white;
     font-weight: 500;
     transition: all 0.6s;
 }

 .videos_tiktok_container_perfil button:hover {
     background: rgb(250, 157, 176);
     color: crimson;

 }

 .notpost {
     min-height: 45vh;
     color: crimson;
 }


 /*****Card recomendacion*/

 .recomend_content_user {
    padding: 6px;
    position: relative;
    background-color: #fff;
 }

.content_img_recoment {
    position: absolute;
    z-index: 100;
    margin: 16px;
    display: flex;
}

.content_img_recoment h6 {
    color: #f1f1f1;
    font-size: 18px;
    margin: 8px 12px;
    text-shadow: rgb(24, 24, 23) 1px 0 10px;
}

 .contenet_img_recomend {
    position: relative;
    width: 96%;
    height: 80%;
    object-fit: cover;
    border-radius: 10px;
    margin: 6px auto;
    overflow: hidden;
 }

 .contenet_img_recomend img {
    width: 100%;
    object-fit: cover;
 }

 @media (max-width: 750px) {

     .home {
         flex-direction: column;
     }

     .posts .card_icon_menu i {
         font-size: 23px;
     }

     .home .main_content {
         width: 100%;
         padding: 0 5px;
     }

     .home .main_rigth {
         width: 100%;
         height: auto;
         overflow-y: none;
     }

     .content-carousel-img {
         width: 98%;
     }

     .cardanuncio_vista_moderador {
         flex-direction: column;
     }

     .cardanuncio_vista_moderador .card_moderador_info {
         width: 98%;
         margin: 0 auto;
     }

     .card_moderador_info_caja1 h4 {
         font-size: 13px;
     }

     .card_moderador_info_caja1 h3 {
         font-size: 13px;
     }

     .card_moderador_info_caja1 span {
         font-size: 13px;
     }

     .card_moderador_info_sub3 h3 {
         font-size: 14px;
     }

     .card_moderador_info_sub3 h3 span {
         font-size: 14px;
     }

     .card_moderador_info_sub4 {
         font-size: 13px;
     }

     .card_moderador_info_sub4 h4 {
         font-size: 13px;
     }

     .card_moderador_info_sub4 p {
         font-size: 14px;
     }

     .card_moderador_info_sub2 {
         font-size: 13px;
     }

     .cardanuncio_vista_moderador .card_moderador_img {
         width: 60%;
         margin: 0 auto;
     }

     .card_moderador_info_caja2 {
         grid-template-columns: 1fr;
     }

     .btn_anuncio_create button {
         width: 60%;
         font-size: 15px;
     }

     .card_moderador_info_caja1 .card_moderador_remove span {
         font-size: 9px;
         padding: 2px;
         margin: 2px;
     }

     .content_user_tot {
         flex-direction: column;
     }

     .content_user_tot .user_tot_suscrip h3,
     .content_user_tot .user_tot_info h3 {
         font-size: 15px;
         letter-spacing: 2px;
         font-weight: 600;
     }

     .content_user_tot .user_tot_info,
     .content_user_tot .user_tot_suscrip {
         width: 90%;
         margin: 5px auto;
     }

     .content_user_tot .user_tot_info h4,
     .content_user_tot .user_tot_suscrip h4 {
         font-size: 12px;
     }

     .content_user_tot .user_tot_info h4 span,
     .content_user_tot .user_tot_suscrip h4 span {
         font-size: 15px;
     }

     .content_user_tot_modera .content_user_tot_header .content_user_tot_edit {
         left: 75%;
     }

     .btn-hover_new {
         width: 90px;
         font-size: 11px;
         font-weight: 500;
         margin: 0px;
         height: 25px;
     }

     .home .main_left {
         display: none;
     }

     .panel_informe_suscripsion h2 {
         font-size: 16px;
     }

     .panel_informe_suscripsion span {
         font-size: 13px;
     }

 }