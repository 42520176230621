@import url('https://fonts.googleapis.com/css2?family=Lilita+One&family=Signika:wght@500&display=swap');
.detail-app-main {
  max-width: 1025px;
  margin: 5px auto;
  box-shadow: rgba(83, 83, 83, 0.16) 0px 1px 4px;
}

.detail {
  width: 70%;
  height: 45%;
  border-radius: 15px;
  display: grid;
  margin: 0 auto;
  grid-template-columns: 1.2fr 2fr;
  border: solid 1px blue;
}

/*--------panelPrueba---*/

* {
  outline: none;
  box-sizing: border-box;
}

.book-store {
  width: 100%;
  margin-bottom: 40px;
}

.book {
  background: #fff;
}

.book-cell {
  position: relative;
  display: flex;
  padding: 7px;
  width: 100%;
  height: 215px;
  margin-right: 1px;
}

.book-cell .book-cell-imges {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: rgb(31, 31, 31);
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.book-cell .book-cell-imges img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
}

.book-photo {
  width: 180px;
  flex-shrink: 0;
  bottom: -35px;
  left: 35px;
  border-radius: 2px;
  box-shadow: -2px 6px 19px 0px #7f818e;
  transition: 0.3s ease;
}

.book-photo:hover {
  transform: scale(1.03);
}

.book-img {
  flex-shrink: 0;
  z-index: 2;
}

.book-title {
  text-overflow: ellipsis;
  width: 100%;
}

.book-title h1 {
  color: #fff;
  font-size: 1.5rem;
  position: relative;
  width: 100%;
  text-align: center;
  font-family: 'Lilita One', cursive;
  text-shadow: 0.1em 0.1em 0.2em black
}

.book-title i {
  margin-right: 4px;
}

.book-title span {
  position: absolute;
  font-size: 14px;
  font-family: Chalkduster, fantasy;
  letter-spacing: 2px;
  top: 5%;
  left: 80%;
  background: rgba(255, 21, 4, 0.925);
  border-radius: 5px;
  padding: 6px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  cursor: pointer;
}

.book-author h2 {
  margin: 4px;
  font-size: 15px;
  letter-spacing: 1px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: 'Signika', sans-serif;
  color: #ffe7c7;
  text-shadow: 0.1em 0.1em 0.2em black
}

.book-content {
  padding: 0 10px;
  color: #fff;
  overflow: hidden;
  z-index: 3;
}

fieldset,
label {
  margin: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
}

.rating {
  border: none;
}

.rating>input {
  display: none;
}

.rating>label:before {
  margin-right: 5px;
  margin-top: 10px;
  font-size: 0.9em;
  font-family: FontAwesome;
  display: inline-block;
  content: "\f005";
}

.rating>label {
  color: #fff;
  float: right;
}

.rating>input:checked~label,
.rating:not(:checked)>label:hover,
.rating:not(:checked)>label:hover~label {
  color: #d85d61;
}

.rating>input:checked+label:hover,
.rating>input:checked~label:hover,
.rating>label:hover~input:checked~label,
.rating>input:checked~label:hover~label {
  color: #d85d61;
}

.blue>input:checked~label,
.blue:not(:checked)>label:hover,
.blue:not(:checked)>label:hover~label {
  color: #458997;
}

.blue>input:checked+label:hover,
.blue>input:checked~label:hover,
.blue>label:hover~input:checked~label,
.blue>input:checked~label:hover~label {
  color: #458997;
}

.purple>input:checked~label,
.purple:not(:checked)>label:hover,
.purple:not(:checked)>label:hover~label {
  color: #a76287;
}

.purple>input:checked+label:hover,
.purple>input:checked~label:hover,
.purple>label:hover~input:checked~label,
.purple>input:checked~label:hover~label {
  color: #a76287;
}

.yellow>input:checked~label,
.yellow:not(:checked)>label:hover,
.yellow:not(:checked)>label:hover~label {
  color: #ffad58;
}

.yellow>input:checked+label:hover,
.yellow>input:checked~label:hover,
.yellow>label:hover~input:checked~label,
.yellow>input:checked~label:hover~label {
  color: #ffad58;
}

.dark-purp>input:checked~label,
.dark-purp:not(:checked)>label:hover,
.dark-purp:not(:checked)>label:hover~label {
  color: #905587;
}

.dark-purp>input:checked+label:hover,
.dark-purp>input:checked~label:hover,
.dark-purp>label:hover~input:checked~label,
.dark-purp>input:checked~label:hover~label {
  color: #905587;
}

.book-voters {
  color: #fff;
  vertical-align: sub;
  font-size: 13px;
  margin-left: 7px;
  white-space: nowrap;
  margin-top: 7px;
}

.book-sum {
  margin: 6px 0px;
  max-width: 90%;
  overflow: hidden;
  height: 40%;
}

.book-sum p {
  height: 97%;
  margin: 8px 0px;
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #c4c4c4;
  text-shadow: 0.1em 0.1em 0.2em black
}

.book-see {
  position: absolute;
  padding: 6px;
  width: 80%;
  display: flex;
  justify-content: center;
}

.book-see span {
  color: #414141;
  background: #ebe5e58a;
  margin: 0 8px;
  padding: 6px 10px;
  border-radius: 4px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  font-family: Proxima Nova, system-ui, sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: transform 0.2s;
  /* Animation */
}

.book-see .buttons-panels {
  font-size: 13px;
}

.book-see .buttons-panels a {
  text-decoration: none;
}

.book-see .buttons-panels i {
  margin: 0 5px;
}

.book-see .buttos-panel {
  margin: 0;
  border-radius: 0px;
  letter-spacing: 1px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}

.book-see span:hover {
  transform: scale(1.2);
  background: rgba(250, 42, 5, 0.527);
}

.book-see .buttos-perfil {
  background: rgb(255, 2, 86);
  color: white;
}

.book-see .buttos-seguir {
  background: red;
  color: white;
}

.book-see .buttos-llamar {
  background: rgb(108, 243, 95);
  padding: 6px 12px;
  color: rgb(15, 15, 15);
  border-radius: 20px;
}

.book-see:hover {
  color: #ff6e72;
}

.book-see.book-blue {
  color: #a4e0eb;
}

.book-see.book-blue:hover {
  color: #22cdec;
}

.book-see.book-pink {
  color: #edb9d6;
}

.book-see.book-pink:hover {
  color: #ff6dbe;
}

.book-see.book-yellow {
  color: #fdca95;
}

.book-see.book-yellow:hover {
  color: #fb9124;
}

.book-see.book-purple {
  color: #cbb5e2;
}

.book-see.book-purple:hover {
  color: #a764ec;
}

.main-wrapper {
  width: 100%;
  display: flex;
  flex-grow: 1;
  margin-top: 100px;
  overflow: hidden;
}

.books-of {
  width: 320px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  flex-shrink: 0;
}

.popular-books {
  flex-grow: 1;
  padding: 0 30px 50px;
  overflow-y: auto;
}

.genre {
  font-weight: 500;
  font-size: 15px;
}

.main-menu {
  white-space: nowrap;
  padding-bottom: 15px;
  border-bottom: 1px solid #dcddde;
  position: sticky;
  top: 0;
  right: 0;
  background-color: var(--page-bg-color);
  z-index: 2;
}

.book-types {
  margin-left: auto;
}

.book-types a {
  text-decoration: none;
  color: var(--body-color);
  font-size: 14px;
}

.book-type+.book-type {
  margin-left: 20px;
}

.book-type {
  position: relative;
  transition: 0.2s;
}

.book-type.active,
.book-type:hover {
  -webkit-text-stroke: 0.3px;
}

.book-type.active:before,
.book-type:hover:before {
  content: "";
  position: absolute;
  width: 28px;
  height: 2px;
  bottom: -17px;
  right: 15px;
  background-color: #67d4ea;
  box-shadow: 0px -1px 5px 0px #67d4ea;
}

.book-type:nth-child(2):before {
  right: 12px;
}

.book-type:nth-child(3):before {
  right: 8px;
}

.book-type:nth-child(4):before {
  right: 6px;
}

.book-type:nth-child(5):before {
  right: 20px;
}

.week {
  padding: 0 30px;
}

.author+.author {
  margin-top: 20px;
}

.author-name {
  font-size: 14px;
}

.author:last-child {
  margin-bottom: 40px;
}

.author-title {
  padding: 0 0 20px;
  font-weight: 500;
  font-size: 15px;
}

.author-img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 16px;
  object-fit: cover;
  object-position: center;
}

.year-book-img {
  width: 45px;
  margin-right: 16px;
}

.year-book-name {
  margin-bottom: 15px;
  font-weight: 500;
}

.year-book-author {
  font-size: 13px;
}

.year-book+.year-book {
  margin-top: 20px;
}

.year-book-content {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.overlay {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 340px;
  flex-shrink: 0;
  background: linear-gradient(to bottom,
      rgba(255, 255, 255, 0) 0%,
      #f2f5f7 65%,
      #f2f5f7 100%);
  height: 60px;
  margin-left: -35px;
}

.book-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  padding-top: 40px;
  position: relative;
}

.book-card {
  margin-top: 20px;
  background-color: #fff;
  height: 270px;
  box-shadow: -1px 3px 8px -1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 0 0 20px 20px;
  color: var(--body-color-light);
}

.book-card-img {
  width: 160px;
  margin-top: -35px;
  border-radius: 2px;
  box-shadow: 0px 1px 7px 2px #c7c9d3;
  border-bottom: 1px solid #dcddde;
  object-fit: cover;
  margin-bottom: 20px;
  transition: 0.3s ease;
}

.book-card-img:hover {
  transform: scale(1.04);
}

.card-content {
  color: var(--body-color);
  padding: 30px;
  overflow: hidden;
  position: relative;
}

.book-name {
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.book-by {
  font-size: 13px;
  color: var(--body-color-light);
  margin-top: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.book-rate>label {
  color: #ccc;
}

.rate {
  display: inline-block;
  white-space: nowrap;
}

.book-rate>input:checked~label,
.book-rate:not(:checked)>label:hover,
.book-rate:not(:checked)>label:hover~label {
  color: #ff9700;
}

.book-rate>input:checked+label:hover,
.book-rate>input:checked~label:hover,
.book-rate>label:hover~input:checked~label,
.book-rate>input:checked~label:hover~label {
  color: #ff9700;
}

.card-vote {
  color: var(--body-color-light);
}

.card-sum {
  color: var(--body-color-light);
  font-size: 13px;
  line-height: 1.6em;
  -webkit-line-clamp: 4;
  margin-top: 15px;
}

.content-wrapper {
  display: flex;
  border-bottom: 1px solid #ebedef;
  position: relative;
}

.content-wrapper:before {
  content: "";
  position: absolute;
  background-color: #8f98a9;
  background-color: #aaaebc;
  box-shadow: 0 -6px 0 0 #aaaebc, 0 6px 0 0 #aaaebc;
  width: 4px;
  height: 4px;
  border: 0;
  padding: 0;
  right: 12px;
  top: 17px;
  border-radius: 50%;
  margin-left: auto;
}

.like-profile {
  margin-top: 8px;
}

.like-profile+.like-profile {
  margin-left: -5px;
}

.like-img {
  border-radius: 50%;
  width: 28px;
  object-fit: cover;
  border: 2px solid #fff;
}

.like-name {
  font-size: 13px;
  margin-left: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.like-name span {
  font-weight: 600;
}

.pruebasdetail .container-descripcion-detail {
  width: 600px;
  margin: 15px auto;
}

.pruebasdetail p {
  color: #626567;
}

.demo-content {
  margin: 10px 0px;
}


@media (max-width: 1103px) {
  .book-cell {
    width: 100%;
    margin: 0 auto;
  }
}

@media (max-width: 765px) {
  .book-cell {
    width: 100%;
  }
  .pruebasdetail .container-descripcion-detail {
    width: 94%;
  }
}

@media (max-width: 575px) {
  .book-cell {
    width: 100%;
  }
}

@media (max-width: 458px) {
  .book-photo {
    width: 180px;
  }

  .book-voters {
    display: none;
  }
}

@media (max-width: 420px) {
  .book-see {
    width: 120px;
    font-size: 13px;
  }

  .book-photo {
    width: 130px;
  }

  .main-wrapper {
    margin-top: 50px;
  }
}

@media (max-width: 360px) {
  .rating>label:before {
    font-size: 0.8em;
  }
}

@media (max-width: 800px) {
  .book-see {
    width: 78%;
    left: 50%;
  }

  .book-see .buttons-panels {
    font-size: 10px;
  }
}

@media (max-width: 1085px) {
  .book-rate>label {
    font-size: 0.7em;
  }
}

@media (max-width: 767px) {
  .book-title {
    display: flex;
    justify-content: start;
  }

  .book-title h1 {
    font-size: 12px;
    margin: 0;
  }

  .book-title span {
    font-size: 10px;
    top: 22%;
    left: 70%;
  }

  .book-content {
    padding: 0 2px;
  }

  .book-cell {
    padding: 5px 4px;
  }

  .book-content .book-author h3 {
    font-size: 10px;
    margin: 1px 0px;
  }

  .book-content .book-sum p {
    font-size: 10px;
  }

  .book-see {
    position: absolute;
    top: 85%;
    left: 0%;
    width: 98%;
    margin: 0;
    padding: 0;
    display: flex;
  }

  .book-see .buttons-panels {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .book-see span {
    font-size: 12px;
    margin: 4px;
  }

  .book-see i {
    margin: 0px 10%;
  }

  .book-see .book-cards {
    grid-template-columns: 1fr;
  }

  .book-types {
    display: none;
  }
}

@media (max-width: 372px) {
  .card-content {
    padding: 20px;
  }
}

/*----------------*/

.box-detail .row {
  display: flex;
}

.box-detail .row-content p {
  font-size: 15px;
}

.detail .img-detail-principal {
  background: red;
  padding: 5px;
}

.detail .img-detail-principal .img-principal {
  width: 75%;
  margin: 2px auto;
  height: 100%;
  background: rgb(117, 233, 123);
  border-radius: 10px;
}

.detail .img-detail-principal img {
  width: 90%;
  height: 90%;
  border-radius: 8px;
}

.detail .box-detail {
  padding: 2px;
  background: green;
}

/*
  .detail {
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    margin: 5px auto;
    border: 1px solid #7a7a7a;
    border-radius: 8px;
  }
  
  .detail .img-detail-principal {
    margin: 2px;
  }
  
  .detail .img-detail-principal img {
    max-width: 400px;
    width: 100%;
    margin: 10px;
    height: 400px;
    border-radius: 8px;
    object-fit: cover;
    display: block;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
  
  .detail .box-detail {
    max-width: 500px;
    width: 100%;
    margin: 15px auto;
    padding: 5px auto;
    border: solid 2px green;
  }
  
  .box-detail .row {
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
  }
  
  .box-detail h2 {
    text-transform: uppercase;
    color: rgb(85, 85, 85);
    letter-spacing: 2px;
    font-weight: 1rem;
    font-size: 1.4rem;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .box-detail p {
    line-height: 0.8;
    margin: 10px 0;
    font-size: 0.7rem;
    color: #797979;
  }
  
  .box-detail .cart {
    background: #333;
    color: white;
    margin-top: 10px;
    padding: 10px 25px;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 2px;
  }*/

/*-------ddetalles/por seccion----*/

.product-details {
  background: #f0f1f5;
  padding: 30px;
  display: grid;
  grid-template-columns: 0.9fr 1.1fr;
  gap: 30px;
}

.product-page-img {
  width: 100%;
  border: 1px solid #eee;
  padding: 5px;
  overflow: hidden;
}

.product-page-img .big-images {
  width: 100%;
  height: 500px;
  overflow: hidden;
  position: relative;
}

.mySlides {
  position: relative;
  height: 100%;
  width: 100%;
}

.mySlides .numbertext {
  color: #f2f2f2;
  text-shadow: 0 0 4px blue;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.mySlides img {
  width: 100%;
  height: 100%;
  display: inline-block;
  object-fit: cover;
}

.product-page-img .prev,
.product-page-img .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  padding: 10px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.product-page-img .next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.product-page-img .prev:hover,
.product-page-img .next:hover {
  background: rgba(0, 0, 0, 0.8);
}

.product-page-img .slider-img {
  width: 100%;
  height: 70px;
  overflow-x: scroll;
  display: flex;
  user-select: none;
  margin-top: 5px;
}

.product-page-img .slider-img::-webkit-scrollbar {
  display: none;
}

.product-page-img .slider-img .slider-box {
  width: 50%;
  height: 100%;
  min-width: 50px;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid #ddd;
  padding: 2px;
  opacity: 0.6;
}

.product-page-img .slider-img .slider-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: inline-block;
}

.product-page-img .slider-img .slider-box:hover {
  border: 2px solid teal;
  opacity: 1;
}

.product-page-img .slider-img .slider-box.active {
  border: 2px solid crimson;
  opacity: 1;
}

.product-page-details strong {
  color: #333;
  font-size: 1.3rem;
  font-weight: 700;
  text-transform: capitalize;
}

.product-page-details i {
  font-size: 28px;
  margin-left: 15px;
  cursor: pointer;
}

.product-page-details i:hover {
  color: red;
}

.vinculo-web-aviso p {
  margin: 6px 0px;
}

.vinculo-web-aviso i {
  font-size: 14px;
  color: #007bff;
  margin-right: 6px;
}

.product-page-details .product-category {
  font-size: 0.9rem;
  color: #6b6b6b;
  font-weight: 600;
  text-transform: capitalize;
}

.product-page-details .product-price {
  color: #333;
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.product-page-details .product-price del {
  color: #992153;
  font-weight: 400;
  font-size: 0.85rem;
  margin-left: 8px;
}

.product-page-details .small-desc {
  color: #4b4b4b;
  font-size: 1rem;
  background: #f0f1f5;
  font-weight: 400;
  padding: 10px;
  border: 1px solid #f7f7f7;
}

.product-page-details .detail-adition {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  justify-content: space-between;
  font-family: Georgia, 'Times New Roman', Times, serif;
  margin: 8px 0;
  height: 25px;
  font-size: 85%;
  width: 100%;
}

.product-page-details .detail-edad-pais span {
  color: #f8f8f8;
  font-size: 100%;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  background: rgba(0, 0, 0, 0.719);
  margin: 0 6px;
  padding: 8px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  cursor: pointer;
}

.product-page-details .detail-edad-pais .i-locate i {
  font-size: 14px;
}

.i-locate_span_ i {
 text-align: center;
  color: #ffffff;
  font-size: 20px;
  padding: 1px 4px;
}

.i-locate_span_:hover {
  background: #999999;
}

#fa-location-dot {
  margin: 0;
}
.detail-adition .detail-clock-span {
  color: #5c5b5b;
}

.detail-adition .detail-clock-span i {
  font-size: 80%;
  margin-right: 0px;
}

/*-----botones redes- optiones---*/
.nshare {
  display: flex;
  --nshare-spacing: .5rem;
  /* separacion interna */
  padding-top: 0;
  /* separacion superior */
  padding-bottom: 0;
  /* separacion inferior */
  margin: 0px 5px;
}

.nshare span {
  margin: 5px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.nshare-item {
  margin-right: var(--nshare-spacing);
  margin-top: var(--nshare-spacing);
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.nshare-item,
.nshare-item:hover,
.nshare-item:active {
  text-decoration: none;
  color: inherit;
}

.nshare-item {
  transition: background .3s, color .3s;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.nshare-item i {
  transition: transform .3s;
  margin: 0;
}

.nshare .nshare-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.nshare-tb {
  background-color: var(--nshare-tumblr);
}

.nshare-rd {
  background-color: var(--nshare-reddit);
}

.nshare-ws {
  background-color: var(--nshare-whatsapp);
}

.nshare-tlg {
  background-color: var(--nshare-telegram);
}

.nshare-you {
  background-color: var(--nshare-yout);
}

.nshare-tb:hover {
  color: var(--nshare-tumblr);
}

.nshare-rd:hover {
  color: var(--nshare-reddit);
}

.nshare-ws:hover {
  color: var(--nshare-whatsapp);
}

.nshare-tlg:hover {
  color: var(--nshare-telegram);
}

.nshare-you:hover {
  color: var(--nshare-yout);
}

.nshare-item:hover i {
  transform: rotate(360deg);
}

/*}} Fin del core {{*/

/*! Personalización */
/* Contenedor
  -----------------------------------------*/

/* Redes sociales
  -----------------------------------------*/
.nshare {
  --nshare-tumblr: #1B1B1C;
  --nshare-reddit: #FF4500;
  --nshare-whatsapp: #4FCE5D;
  --nshare-telegram: #0088CC;
  --nshare-email: #464EB8;
  --nshare-yout: #8d8d8d;
}

/* Botones
  -----------------------------------------*/
.nshare-item {
  font-size: 18px;
  /* tamaño de iconos */
  width: 35px;
  /* ancho */
  height: 35px;
  /* alto */
  border-radius: .5rem;
  /* bordes redondeados */
  color: #fff;
  /* color */
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .1),
    0 2px 5px -1px rgba(0, 0, 0, .05);
  /* sombra */
}

.nshare-item:hover {
  background-color: #fff;
  /* fondo (hover) */
}


.detailt_descripcort {
  width: 100%;
  color: #383838;
  font-size: 1rem;
  padding: 5px 8px;
  margin: 0;
}

/*----botones redes---responsive-----*/

@media (max-width:750px) {
  .nshare i {
    font-size: 15px;
  }

  .nshare-item {
    width: 30px;
    /* ancho */
    height: 30px;
  }

  .nshare-item {
    font-size: 17px;
  }
  .detail-adition .detail-clock-span {
    color: #5c5b5b;
    font-size: 10px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}

/*------------*/

.product-page-details .product-page-offer {
  display: inline-block;
  color: #fff;
  font-size: 0.8rem;
  padding: 4px 10px;
  border-radius: 10px;
  background: #ec1111;
  margin-top: 10px;
}

.product-page-details .product-page-offer i {
  margin-right: 3px;
  font-size: 12px;
}

.product-page-details .product-page-offer a{
  text-decoration: none;
}


.product-page-details .product-sold {
  margin: 16px 0;
  display: flex;
  align-items: center;
}


.product-page-details .cart-btns {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
}

.product-page-details .cart-btns .add-cart {
  background: rgb(192, 25, 25);
  color: #fff;
  width: 150px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  text-decoration: none;
  transition: all 1s ease;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.product-page-details .cart-btns .add-cart:hover {
  background-color: #ff7f44;
  box-shadow: rgba(255, 173, 106, 0.35) 0px 5px 15px;
}

.product-page-details .cart-btns .add-cart i {
  font-size: 17px;
  margin-right: 5px;
}

.product-page-details .cart-btns .buy-now {
  background: #25d366;
  margin-left: 10px;
  transition: all 1s ease;
}

.product-page-details .cart-btns .buy-now:hover {
  background-color: #ff9e44;
  box-shadow: rgba(255, 203, 106, 0.35) 0px 5px 15px;
}

.product-all-info {
  margin-top: 20px;
}

.product-all-info .product-info-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  list-style: none;
  padding: 8px;
}

.product-all-info .product-info-menu li {
  width: 100%;
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
  padding: 10px 20px;
  border: 1px solid #e9e9e9;
  background: #fff;
  color: #252525;
  letter-spacing: 1px;
  user-select: none;
  cursor: pointer;
}

.product-all-info .product-info-menu li.active {
  color: #fff;
  background: #252525;
}

.product-all-info .info-container {
  opacity: 0;
  background: #fff;
  width: 98%;
  height: 0px;
  overflow: hidden;
  transition: 0.5s;
}

.product-all-info .info-container.active {
  margin: auto;
  opacity: 1;
  border: 1px solid #eee;
  padding: 8px;
  height: auto;
}

.product-all-info .info-container p {
  color: #333;
  font-size: 0.9rem;
}

.product-sold i {
  font-size: 20px;
  color: rgb(107, 102, 102);
  margin-right: 5px;
}

/*---------table--------*/
.container-tables-info {
  width: 100%;
  height: 40%;
  padding: 2%;
  margin: 5px 0px;
}

.container-tables-info .pricing-table {
  width: 90%;
  height: 100%;
  margin: 2px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.pricing-table .table-1 {
  width: 80%;
  max-width: 300px;
  max-height: 300px;
  padding: 5%;
  height: auto;
  margin: 5px auto;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 6px;
}

.pricing-table .table-1 .table-title {
  font-size: 1.5rem;
  margin-bottom: 8px;
  color: #4b4b4b;
}

.table-1 .detail-especificos p {
  font-size: 0.9rem;
  color: #535353;
  margin: 8px;
}

.table-1 .detail-especificos span {
  font-size: 0.9rem;
  color: red;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.table-3 .table-etiquetas {
  padding: 4px;
}

.table-3 .table-etiquetas h1 {
  font-size: 1.2rem;
}

.table-3 .table-etiquetas i {
  font-size: 20px;
  margin-right: 6px;
}

/*----tabla2----*/
.styled-table {
  border-collapse: collapse;
  margin: 10px auto;
  font-size: 0.9rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #abadad;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 10px 10px;
  font-family: revert;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #abadad;
}

.styled-table tbody tr.active-row {
  color: #555454;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

/*----------*/

.pricing-table .table-3 {
  width: 100%;
  height: 100%;
}

.pricing-table .table-3 .table-etiquetas h1 {
  font-size: 1em;
  color: #5c5b5b;
  margin-left: 7px;
  margin: 3px 8px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.pricing-table .table-3 .table-etiquetas button {
  margin: 2px;
  border: none;
  border-radius: 6px;
}

.pricing-table .table-3 .table-etiquetas span {
  font-size: 0.9rem;
  color: rgb(252, 74, 74);
}

/*----Mapa-ubicacion-----*/

.seccion-ubicacion-aviso {
  width: 100%;
  height: 50vh;
  padding: 4px;
  margin: 8px 0px;
}

.seccion-ubicacion-aviso h1 {
  font-size: 1.4rem;
  color: #5c5b5b;
  margin-left: 10px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.seccion-ubicacion-aviso .container-mapa-ubicacion {
  width: 98%;
  height: 98%;
  background: gold;
  border-radius: 10px;
  margin: 0px auto;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.pruebasdetail .detalles-particulares {
  margin: 30px 0;
  border: solid 1px rgba(179, 177, 177, 0.637);
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #494a4b;
  font-weight: 600;
}

.pruebasdetail .detalles-particulares span {
  margin: 8px;
  background: rgba(250, 169, 169, 0.411);
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  cursor: pointer;
}

.pruebasdetail .detalles-particulares span a {
  text-decoration: none;
  color: #414141;
}


.relative_anuncios {
  width: 96%;
  margin: 5px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

#title_anuncio_relative {
  color: rgb(107, 106, 106);
  font-size: 24px;
  position: relative;
  width: 100%;
  text-align: center;
}

/*----responsive------*/

@media (max-width: 1000px) {
  .product-details {
    grid-template-columns: 1fr;
  }

  .pricing-table .table-1 {
    height: 90%;
  }

  .pricing-table .table-1 h1 {
    font-size: 0.8rem;
  }
}

@media (max-width: 724px) {
  .detail {
    width: 96%;
    height: 210px;
  }

  .detail .row h2 {
    font-size: 22px;
    margin: 0;
  }

  .detail .row-content p {
    font-size: 10px;
  }

  .product-details {
    padding: 15px;
  }

  .product-all-info .product-info-menu li {
    font-size: 0.8rem;
  }

  .product-all-info .product-info-menu {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .product-all-info .product-price {
    font-size: 1rem;
  }

  .container-tables-info .pricing-table {
    width: 98%;
    margin: 5px auto;
    display: flex;
    flex-direction: column;
  }

  .container-tables-info .pricing-table .table-1 {
    width: 92%;
    height: 80%;
    min-height: 230px;
  }

  .pricing-table .table-1 .table-title {
    font-size: 16px;
  }

  .table-1 .detail-especificos p {
    font-size: 7px;
  }

  .table-1 .detail-especificos span {
    font-size: 14px;
    font-weight: 600;
  }

  .pricing-table .table-3 {
    grid-column: 1/3;
  }

  .container-tables-info .pricing-table .styled-table {
    font-size: 0.9rem;
    width: 300px;
    margin: 12px auto;
  }

  .pricing-table .table-3 .table-etiquetas span {
    font-size: 0.7rem;
  }

  .seccion-ubicacion-aviso {
    width: 100%;
    height: 30vh;
    min-height: 250px;
  }

  .seccion-ubicacion-aviso h1 {
    font-size: 0.9rem;
    color: #5c5b5b;
    margin-left: 10px;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
  }

  .container-tables-info {
    width: 100%;
    height: 40%;
    padding: 0;
    margin: 5px 0px;
  }

  .pruebasdetail .detalles-particulares span {
    font-size: 10px;
    padding: 3px;
    border-radius: 2px;
  }

  .relative_anuncios {
    grid-template-columns: 1fr 1fr;
  }

  #title_anuncio_relative {
    font-size: 18px;
  }
}

@media (max-width: 450px) {
  .product-page-img .big-images {
    height: 380px;
  }

  .product-all-info .product-info-menu {
    display: grid;
    grid-template-columns: 1fr;
  }

  .product-details .cart-btns {
    flex-direction: column;
    width: 100%;
  }

  .product-details .cart-btns .add-cart {
    width: 100%;
  }

  .product-details .cart-btns .buy-now {
    margin-left: 0;
    margin-top: 10px;
  }
}