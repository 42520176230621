/* ----------------- Responsive ------------------ */
@media (max-width: 820px) {
  .header-pro .logo {
    min-width: 115px;
    flex: none;
  }

  .header-pro ul {
    padding: 10px 0;
  }
}

@media (max-width: 720px) {
  .header-pro ul {
    position: fixed;
    top: 0px;
    left: -100%;
    width: 100%;
    height: 60vh;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.98;
    z-index: 99;
    transition: 0.5s ease-in;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  }

  .header-pro .menupro {
    display: block;
    cursor: pointer;
    margin-left: 8px;
  }

  .header-pro ul li {
    margin: 8px 0px;
  }

  .header-pro ul li .menupro {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }

  .header-pro {
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;

  }
}

@media (max-width: 570px) {
  .filter_menu .row span {
    display: none;
  }
}

@media (max-width: 500px) {

  .detail {
    font-size: 100%;
    padding: 0;
  }

  .filter_menu input {
    margin: 0;
  }

  .filter_menu .sort {
    flex: 1;
  }

  .filter_menu .sort select {
    width: 100%;
    min-width: 290px;
    margin: 5px 0;
  }

  .login-page {
    padding: 30px 15px;
  }

  .delete-all {
    margin: 20px 0;
  }

  .create_product .upload {
    margin: 20px 0;
  }
}