/*@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600&display=swap");*/
/*
:root {
    --body-font: "Manrope", sans-serif;
    --body-color: #b3b3b3;
    --body-bg-color: #f2f2f2;
    --theme-bg-color: #fafafa;
    --border-color: #efefef;
    --logo-color: #f13a2f;
    --main-color: #808080;
    --header-bg-color: #fff;
}*/
/*
.card-app {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

/*---- header ----*/
/*
.headerUx {
    position: sticky;
    height: 75px;
    width: 100%;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    background: rgb(250 250 250);
    align-items: center;
    padding: 0 20px;
    font-size: 15px;
    top: 0px;
    z-index: 100;
    box-shadow: rgba(90, 90, 90, 0.09) 0px 2px 1px, rgba(102, 101, 101, 0.09) 0px 4px 2px, rgba(141, 141, 141, 0.09) 0px 8px 4px, rgba(167, 167, 167, 0.09) 0px 16px 8px, rgba(211, 209, 209, 0.09) 0px 32px 16px;
}

.headerUx .header-left {
    display: flex;
    align-items: center;
    height: 65px;
    width: 30%;
}

.headerUx .header-left svg {
    width: 22px;
    margin-right: 25px;
    flex-shrink: 0;
}

.headerUx .header-left .logo {
    width: 75px;
    height: 34px;
    border: 3px solid var(--logo-color);
}

.headerUx .header-menu {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100;
    width: 20%;
}

.headerUx .user-settings {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.headerUx .user-settings>*+* {
    margin-left: 18px;
}

.headerUx .user-settings svg {
    width: 25px;
    flex-shrink: 0;
}

.headerUx .user-settings .button {
    display: flex;
    background-color: transparent;
    align-items: center;
    border: 2px solid var(--border-color);
    border-radius: 25px;
    color: var(--body-color);
    padding: 8px 16px;
    font-family: var(--body-font);
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
}

.headerUx .user-settings .button svg {
    margin-right: 8px;
    width: 15px;
    fill: var(--body-color);
}*/

/*---------------*/

/*-----Search---------*/
/*
.headerUx .search-bar {
    height: 35px;
    width: 50%;
    position: relative;
}

.headerUx .search-bar input {
    height: 100%;
    width: 100%;
    display: block;
    background-color: transparent;
    padding: 0 10px 0 54px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='18' height='18' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cdefs%3e%3cpath d='M18.5 17h-.79l-.28-.27a6.5 6.5 0 10-.7.7l.27.28v.79l5 4.99L23.49 22l-4.99-5zm-6 0a4.5 4.5 0 11-.01-8.99A4.5 4.5 0 0112.5 17z' id='a'/%3e%3c/defs%3e%3cg transform='translate(-6 -6)' fill='none' fill-rule='evenodd'%3e%3cmask id='b' fill='%23fff'%3e%3cuse xlink:href='%23a'/%3e%3c/mask%3e%3cg mask='url(%23b)' fill='%23D8D8D8'%3e%3cpath d='M3 3h24v24H3z'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 25px 50%;
    color: #7c7c7c;
    border-radius: 8px;
}

.headerUx .search-bar input::placeholder {
    color: var(--body-color);
}*/

/*--------------------*/

/*----user------*/
/*
.user-settings .user-profile {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.user-settings .user-profile+svg {
    width: 14px;
}

.header-menu .notify {
    position: relative;
}

.header-menu .notify:before {
    content: "";
    position: absolute;
    background-color: var(--logo-color);
    width: 5px;
    height: 5px;
    border-radius: 50%;
    right: -6px;
    bottom: 15px;
}

.header-menu .menu-item .active {
    color: var(--logo-color);
}*/

/*------*/
/*
.wrapperUx {
    width: 100%;
    display: flex;
    flex-grow: 1;
    overflow: auto;
    background-color: var(--theme-bg-color);
}

.wrapperUx .left-side {
    width: 13%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex-shrink: 0;
}

.wrapperUx .left-side .side-wrapper svg {
    width: 15px;
    fill: var(--body-color);
    margin-right: 8px;
    flex-shrink: 0;
}*/
/*
.side-wrapper .side-menu .username {
    color: var(--main-color);
    font-size: 15px;
}

.left-side .side-wrapper .side-menu a {
    text-decoration: none;
    font-weight: 300;
    color: var(--main-color);
    display: flex;
    align-items: center;
    font-size: 15px;
    white-space: nowrap;
}

.left-side .side-wrapper .side-menu a:not(:last-child) {
    margin-bottom: 16px;
    margin-top: 8px;
}

.side-wrapper .side-menu .side-title {
    font-size: 16px;
    color: rgb(245, 113, 24);
    margin-top: 15px;
    margin-bottom: 10px;
}

.left-side .side-wrapper .side-menu {
    padding: 13px;
}

.side-wrapper .side-menu .user {
    display: flex;
    align-items: center;
}

.side-wrapper .side-menu .user+.user {
    margin-top: 16px;
}

.side-menu .user .user-img {
    border-radius: 50%;
    width: 26px;
    height: 26px;
    margin-right: 8px;
    object-fit: cover;
    object-position: center;
}

.wrapperUx .left-side::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
}

.wrapperUx .left-side::-webkit-scrollbar-thumb:hover {
    background: #f0b8b8;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.2);
}

.wrapperUx .main-container {
    flex-grow: 1;
    overflow: auto;
    width: 68%;
    padding: 0px 26px;
}

.wrapperUx .main-container::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
}

.wrapperUx .main-container::-webkit-scrollbar-thumb:hover {
    background: #f0b8b8;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.2);
}*/

/*----se usa---*/
.main-container .profile {
    height: 45vh;
    max-height: 350px;
    min-height: 270px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    border-radius: 50px;
}

.main-container .profile img {
    max-width: 100%;
}

.main-container .profile .profile-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
}

/*
.main-container .profile .profile-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--header-bg-color);
    width: 100%;
    box-shadow: -1px 3px 8px -1px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 10px 10px;
    padding: 0 25px;
    z-index: 2;
}*/
/*
.main-container .profile .profile-menu .profile-img {
    border-radius: 50%;
    width: 42px;
    height: 42px;
    object-fit: cover;
    margin-right: 15px;
}*/
/*
.main-container .profile .profile-menu .profile-avatar {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}*/
/*
.profile-info {
    justify-content: center;
    display: flex;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    z-index: 2;
}

.profile-item {
    display: flex;
    align-items: center;
    color: #fff;
    padding: 26px 16px;
    white-space: nowrap;
}

.profile-item svg {
    width: 22px;
    margin-right: 10px;
}*/
/*
.profile-contact-info {
    position: absolute;
    display: flex;
    right: 10px;
    top: 20px;
    color: #fff;
}

.profile-contact-info svg {
    width: 18px;
}*/
/*
.profile-contact {
    border: 1px solid rgba(239, 239, 239, 0.3);
    padding: 16px;
    margin-left: 10px;
    border-radius: 50%;
    cursor: pointer;
}*/
/*
.follow-buttons {
    display: flex;
}*/

.main-container .profile .follow {
    border: 2px solid var(--border-color);
    border-radius: 25px 0 0 25px;
    color: var(--body-color);
    padding: 8px 16px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    background-color: transparent;
}

.main-container .profile .follow:hover {
    background-color: var(--header-bg-color);
}

.main-container .profile .follow-option {
    border-radius: 0 25px 25px 0;
    margin-left: -2px;
}


/*******header inicio*********/
#navMenu {
    color: #ff5c40;
    background: rgb(235, 235, 235);
    cursor: pointer;
    padding: 8px;
    font-size: 20px;
    border-radius: 6px;
    transition: all 1s ease;
}

#navMenu:hover {
    background: #ff5c40;
    color: rgb(235, 235, 235);
}

#publicar_auncios_btn {
    background-color: crimson;
    color: white;
    padding: 2px 4px;
    border-radius: 4px;
    transition: all 1s ease;
}

#publicar_auncios_btn i{
    color: white;
}

#publicar_auncios_btn:hover {
    background-color: rgb(255, 155, 88);
    color: #595959;
}

.menu_opcion {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #e4e4e4;
    width: 20%;
    margin: 8px;
    padding: 8px;
    border-radius: 8px;
}

.menu_opcion a {
    text-decoration: none;
    color: #fd6208;
    margin: 6px;
    font-size: 16px;
    font-weight: 600;
}

.menu_opcion a:hover {
    color: rgb(204, 0, 51);
    text-decoration: none;
}

.menu_opcion a i {
    margin-right: 2px;
    font-size: 12px;
    color: #ff4208;
}

@media (max-width: 750px) {
    #navMenu {
        display: none;
    }
}

/*
.trends {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 45px;
}

.trends a {
    text-decoration: none;
    color: var(--body-color);
    display: flex;
    align-items: center;
    font-size: 15px;
    white-space: nowrap;
}

.trends svg {
    width: 20px;
    margin-right: 16px;
}*/
/*
.follow-option .active {
    background-color: var(--header-bg-color);
    margin-left: -3px;
}

.play-all {
    background-color: var(--logo-color);
    color: #fff;
    padding: 10px 20px;
    border-radius: 30px;
    display: flex;
    align-items: center;
}

.play-all svg {
    width: 12px;
    flex-shrink: 0;
    margin-right: 8px;
}*/

/*--------------*/
.main-container .card-principal {
    margin: 30px 60px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 25px;
    grid-auto-rows: minmax(380px, auto);
}

.load-more {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    cursor: pointer;
    color: rgb(255, 81, 0);
    font-size: 16px;
    font-family: sans-serif;
}

.load-more button {
    width: 9em;
    height: 3em;
    border-radius: 30em;
    font-size: 15px;
    font-family: inherit;
    border: none;
    position: relative;
    overflow: hidden;
    z-index: 1;
    box-shadow: 6px 6px 12px #c5c5c5,
                -6px -6px 12px #ffffff;
}

.load-more button::before {
    content: '';
    width: 0;
    height: 3em;
    border-radius: 30em;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(to right, #d8980f 0%, #f97047 100%);
    transition: .5s ease;
    display: block;
    z-index: -1;
   }

   .load-more button:hover::before {
    width: 9em;
   }
/*
@keyframes load {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}*/

.footer-row .language {
    margin-bottom: 8px;
    background: rgb(241, 236, 236);
    color: #f76538;
    width: 110px;
    height: 40px;
    border-radius: 8px;
    text-decoration: none;
    border: none;
    font-size: 13px;
    transition: all 0.2s;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.footer-row .language:hover {
    background: rgb(236, 208, 200);
}

.footer-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-links a {
    text-decoration: none;
    color: rgb(129, 129, 129);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.footer-links a+a {
    margin-left: 8px;
}

.footer-links {
    display: flex;
}

.link-footer a {
    color: rgb(124, 122, 122);
    font-size: 14px;
}

.footer {
    padding: 30px 0;
}

.footer-last {
    border-top: 1px solid var(--border-color);
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #595959;
}

.social-media {
    display: flex;
    align-items: center;
}

.social-media svg {
    width: 100%;
}

.social-media a {
    border-radius: 50%;
    color: var(--body-color);
    padding: 8px;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 12px;
}

.policy a {
    text-decoration: none;
    color: var(--body-color);
}

/***revisar*-**/
@media (max-width: 1120px) {
    .footer-row {
        flex-direction: column;
    }

    .footer-row+.footer-row {
        margin-top: 10px;
    }

    .footer-row .button {
        display: none;
    }
}
/**************/

@media (max-width: 900px) {
    .play-all {
        color: transparent;
        white-space: nowrap;
        width: 30px;
        padding: 0;
        fill: #fff;
        height: 30px;
        position: relative;
    }

    /*.profile-item {
        padding: 20px 10px;
    }*/

    .play-all svg {
        position: absolute;
        left: 58%;
        top: 50%;
        transform: translate(-50%, -50%);
        fill: #fff;
    }
}

@media (max-width: 840px) {
    /*.profile-contact {
        padding: 6px;
    }*/

    .profile-item,
    .profile-avatar {
        flex-direction: column;
    }

  /*  .profile-item svg {
        margin-right: 0;
    }*/

    /*.profile-item {
        text-align: center;
    }*/

    /*.profile-img {
        margin-right: 0;
        margin-top: 10px;
    }*/

    /*.profile-menu {
        flex-direction: column;
    }*/

    .menu-items {
        order: 1;
    }
}

/******este es la falla por profile***/
@media (max-width: 980px) {
    .main-container .card-principal {
        margin: 5px 20px;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 15px;
        grid-row-gap: 15px;
        grid-auto-rows: minmax(280px, auto);
    }
/*
    .profile {
        min-height: 380px;
        max-height: 380px;
    }*/
}

@media (max-width: 800px) {
    .main-container .card-principal {
        grid-auto-rows: minmax(250px, auto);
    }
/*
    .trends .follow-buttons {
        display: none;
    }*/
}

@media (max-width: 750px) {

    /*.main-container .card-principal {
        margin: 5px 20px;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 15px;
        grid-row-gap: 15px;
        grid-auto-rows: minmax(280px, auto);
    }*/
    .main-container .card-principal {
        margin: 12px 0px;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 12px;
        grid-row-gap: 12px;
        grid-auto-rows: minmax(290px, auto);
    }

    .left-side {
        display: none;
    }

    .header-menu {
        display: none;
    }

    .search-bar input {
        max-width: 140px;
    }

    .user-settings button {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        padding: 0;
        position: relative;
        color: transparent;
    }

    .user-settings button svg {
        margin-right: 0;
        position: absolute;
        display: block;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
/*
@media (max-width: 440px) {

    .user-settings svg {
        display: none;
    }

    .main-container .card-principal {
        grid-template-columns: 1fr 1fr;
    }
}*/
/*
.dark-light {
    position: fixed;
    bottom: 30px;
    right: 30px;
    background-color: var(--header-bg-color);
    box-shadow: -1px 3px 8px -1px rgba(0, 0, 0, 0.2);
    padding: 8px;
    border-radius: 50%;
    z-index: 3;
}

.dark-light svg {
    width: 28px;
}

.dark-mode .dark-light svg {
    fill: #ffce45;
    stroke: #ffce45;
}

.dark-light svg {
    fill: transparent;
    transition: 0.5s;
}*/
/*
@media (max-width: 475px) {
    .footer-links {
        flex-direction: column;
    }

    .footer-links a+a {
        margin-left: 0;
    }

    .footer-row:last-child {
        align-items: flex-end;
    }

    .footer-row {
        align-items: flex-start;
    }

    .footer {
        display: flex;
        justify-content: space-between;
    }

    .footer-links a {
        margin-bottom: 5px;
    }

    .policy {
        display: none;
    }

    .dark-light {
        bottom: 60px;
    }
}*/

/*----- right-----*/
/*
.wrapperUx .right-side {
    width: 20%;
    padding: 1%;
    overflow: auto;
    flex-direction: column;
}

.wrapperUx .right-side img {
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 0 20px rgb(145, 144, 143);
    margin-top: 8px;
}

.right-side .right-coments .comment-main-level {
    display: flex;
    margin-top: 12px;
}*/

/**
* Avatar
---------------------------*/
/*
.comment-main-level .comment-avatar {
    width: 15%;
    height: 40px;
    position: relative;
    z-index: 99;
    float: left;
    border: 3px solid #FFF;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}

.comment-main-level .comment-box {
    width: 80%;
    float: right;
    position: relative;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
    left: 5%;
}

.comment-main-level .comment-box:before,
.comment-main-level .comment-box:after {
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    display: block;
    border-width: 10px 12px 10px 0;
    border-style: solid;
    border-color: transparent #FCFCFC;
    top: 8px;
    left: -11px;
}

.comment-main-level .comment-box:before {
    border-width: 11px 13px 11px 0;
    border-color: transparent rgba(0, 0, 0, 0.05);
    left: -12px;
}*/

/*
.comment-box .comment-head {
    background: #FCFCFC;
    padding: 6px 8px;
    border-bottom: 1px solid #E5E5E5;
    overflow: hidden;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
}

.comment-box .comment-head i {
    float: right;
    margin-left: 14px;
    position: relative;
    top: 2px;
    color: #A6A6A6;
    cursor: pointer;
    -webkit-transition: color 0.3s ease;
    -o-transition: color 0.3s ease;
    transition: color 0.3s ease;
}

.comment-box .comment-head i:hover {
    color: #03658c;
}

.comment-box .comment-name {
    color: #283035;
    font-size: 12px;
    font-weight: 700;
    float: left;
    margin-right: 5px;
}

.comment-box .comment-name a {
    color: #283035;
}

.comment-box .comment-head span {
    float: left;
    color: #999;
    font-size: 10px;
    position: relative;
    top: 1px;
}

.comment-box .comment-content {
    background: #FFF;
    padding: 8px;
    font-size: 10px;
    color: #595959;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
}*/


/*----------*/
/*


.wrapperUx .right-side::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
}

.wrapperUx .right-side::-webkit-scrollbar-thumb:hover {
    background: #f0b8b8;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.2);
}*/

/*---------*/

.card-principal .card-secun {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    box-shadow: 0px 0px 4px 1px rgba(163, 161, 161, 0.5);
    cursor: pointer;
}

.card-principal .card-secun .img-card {
    width: 96%;
    height: 98%;
    overflow: hidden;
    position: relative;
    margin: 2% auto;
    object-fit: cover;
    border-radius: 10px;
}

.card-principal .card-secun .img-card img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transition: all .7s ease;
}

.card-secun .img-card img:hover {
    transform: scale(1.08);
}

.card-secun .img-card span {
    font-size: 30px;
    position: absolute;
    left: 78%;
    top: 5%;
    color: rgb(161, 160, 160);
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 1px 1px 2px 2px rgba(190, 189, 189, 0.521);
}

.card-secun .img-card span:hover {
    color: red;
}

/*------------*/
.card-secun .card-footer1 {
    position: absolute;
    width: 94%;
    height: 28%;
    bottom: 8px;
    border-radius: 0px 0px 8px 8px;
    padding: 4%;
    margin: 1px 3%;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    background-color: #2222228f;
}

.card-secun .card-footer1 .card-nombre {
    display: flex;
    justify-content: space-between;
}

.card-secun .card-footer1 .card-nombre i {
    color: #D4AC0D;
}

.card-secun .card-footer1 .card-nombre h1 {
    text-shadow: 1px 1px 2px black;
}

.card-secun .card-footer1 .card-footer-nacio {
    display: flex;
}

.card-secun .card-footer1 .card-footer-nacio span {
    font-size: 13px;
    margin-left: 5px;
    color: #6affff;
    font-weight: 500;
    text-shadow: 1px 1px 2px rgba(32, 32, 32, 0.726);
    font-family: 'Permanent Marker', cursive;
}

.card-secun .card-footer1 .card-footer-nacio img {
    width: 16px;
    height: 16px;
}

.card-secun .card-footer1 .card-nombre span {
    color: rgb(255, 255, 255);
    font-weight: 600;
    text-shadow: 1px 1px 2px rgb(7, 7, 7);
    font-size: 13px;
    background: rgba(172, 2, 2, 0.466);
    padding: 0 4px;
    border-radius: 6px;
}

.card-secun .card-footer1 .card-nombre .card-name-nombre {
    display: flex;
}

.card-secun .card-footer1 .card-nombre img {
    width: 20px;
    height: 20px;
    margin-right: 8px
}

.card-secun .card-footer1 .card-nombre h1 {
    font-size: 80%;
    color: #efefef;
    padding-right: 5px;
    font-family: Verdana, Arial, fantasy sans-serif;
}

.card-secun .card-footer1 .card-lugar span {
    font-size: 12.5px;
    font-weight: 600;
    color: #ffd64f;
    text-shadow: 1px 1px 2px rgba(34, 34, 34, 0.774);
}

.card-secun .card-footer1 .card-lugar {
    justify-content: space-between;
    display: flex;
}

.card-secun .card-footer1 .card-lugar img {
    width: 20px;
    height: 20px;
    margin-right: 4px;
}

.card-secun .card-footer1 .card-lugar small {
    background: rgba(255, 255, 255, 0.692);
    padding: 0 4px;
    text-align: center;
    font-weight: 600;
    border-radius: 5px;
    color: #00014b;
}

.card-secun .card-footer1 .card-lugar h2 {
    font-size: 76%;
    color: #fafafa;
    font-family: "Century Gothic", Verdana, sans-serif;
}

@media (max-width: 750px) {
    .card-secun .card-footer1 {
        position: absolute;
        width: 94%;
        height: 30%;
        overflow: hidden;
        bottom: 4px;
        border-radius: 0px 0px 8px 8px;
        padding: 4%;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    }

    .card-secun .card-footer1 .card-footer-nacio span {
        font-size: 11px;
        margin-left: 4px;
        color: #50ffe8;
    }

    .card-secun .card-footer1 .card-footer-nacio img {
        width: 14px;
        height: 14px;
    }

    .card-secun .card-footer1 .card-nombre i {
        font-size: 10px;
    }

    .card-secun .card-footer1 .card-nombre span {
        font-size: 11px;
        padding: 0 3px;
        border-radius: 6px;
    }

    .card-secun .card-footer1 .card-nombre h1 {
        font-size: 70%;
        color: #efefef;
        padding-right: 5px;
        font-family: Verdana, Arial, fantasy sans-serif;
    }

    .card-secun .card-footer1 .card-lugar span {
        font-size: 9px;
        color: #ffdd19;
        text-shadow: 1px 1px 2px rgb(61, 61, 61);
    }
    .card-secun .card-footer1 .card-lugar small {
        font-size: 11px;
    }
}
/******/