.catalogo {
    column-count: 3;
    background-color: #f4f6f6;
    margin: 15px auto;
    max-width: 1025px;
}

.anuncios_vip {
    max-width: 1025px;
    margin: 25px auto;
    padding: 5px;
}

.anuncios_vip h2 {
    color: hsl(24, 98%, 43%);
    font-size: 18px;
    margin-left: 15px;
    text-shadow: 1px 1px 2px rgb(148, 148, 148);
}

.filtro_anuncio_ {
    max-width: 1025px;
    height: 10%;
    margin: 0 auto;
    /*text-shadow: 1px 1px 2px rgb(228, 128, 128);*/
}

.filtro_anuncio_ h2 {
    color: #d35502;
    margin-left: 8px;
    font-size: 22px;
}

.filtro_anuncio_ .Provincias_tags .slick-next:before {
    color: #fd9d5d;
}

.filtro_anuncio_ .Provincias_tags .slick-prev:before {
    color: #fd9d5d;
}

.filtro_anuncio_ .Provincias_tags button {
    width: auto;
    font-size: 18px;
    margin: 2px 6px;
}

.filtro_anuncio_ h3 {
    font-size: 16px;
    margin: 4px 0;
    color: #808B96;
    text-shadow: none;
}

/*---------check filter-----------*/
#btn-menus {
    display: none;
}

.menu-c .abrir-menu {
    display: none;
}

#cerrar-menu {
    display: none;
}

/****nuevo*****/

.btn_filtro_catgori {
    display: flex;
    justify-content: center;
    max-width: 1025px;
    margin: 28px auto;
    padding: 10px 15px;
}

.contentcat {
    border: solid 2px #9c9c9c80;
    padding: 8px 14px;
    margin: 2px 8px;
    border-radius: 8px;
    font-size: 18px;
    color: #767676;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.5s ease;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.contentcat:hover {
    background: rgb(255, 91, 50);
    color: #fff;
    border: solid 1px #fff;
}

.contentcat span {
    margin-left: 6px;
}

#link_btn {
    text-decoration: none;
}

/***btn anunciar***/

.btnp {
    padding: 10px 20px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: #565656;
    font-weight: 600;
    box-shadow: 0 0 20px rgb(117 117 117 / 44%);
    border-radius: 10px;
   }
  
  /* Demo Stuff End -> */
  
  /* <- Magic Stuff Start */
  
  .btnp:hover {
    background-position: right center; /* change the direction of the change here */
  }

  .btn-3p {
    background-image: linear-gradient(to right, #84fab0 0%, #8fd3f4 51%, #84fab0 100%);
  }

  .Provincias_tags .outline_secondary {
    border: solid #808b96e5 1px;
    color: #808b96e5;
    padding: 4px 8px;
    border-radius: 8px;
    transition: all 0.6s ease;
  }

  .Provincias_tags .outline_secondary:hover {
    background-color: #565656;
    color: whitesmoke;
  }

  .directorio_h2 {
    width: 1025px;
    margin: 22px auto;
    padding: 10px 5px;
    color: hsl(24, 98%, 43%);
    font-size: 22px;
    text-shadow: 1px 1px 2px rgb(148, 148, 148);
  }

  @media (max-width: 750px) {
    .btnp {
        padding: 5px 10px;
        text-align: center;
        text-transform: uppercase;
        transition: 0.5s;
        color: #565656;
        font-weight: 600;
        border-radius: 8px;
        font-size: 13px;
       }
  }

@media (max-width: 750px) {
    .btn_filtro_catgori {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 10px auto;
    }

    .contentcat {
        font-size: 13px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 4px 6px;
        margin: 2px 3px;
    }

    .contentcat i {
        font-size: 24px;
        margin: 5px auto;
    }

    .directorio_h2 {
width: 98%;
    }
}


/***************/

@media (max-width: 750px) {
    .catalogo {
        column-count: 2;
        margin: 8px;
        margin-top: 15px
    }

    .anuncios_vip {
        width: 90%;
    }

    .anuncios_vip h2 {
        font-size: 15px;
    }

    .filtro_anuncio_ {
        width: 90%;
    }

    .filtro_anuncio_ .Provincias_tags {
        width: 88%;
        margin: 0 auto;
    }

    .filtro_anuncio_ .Provincias_tags button {
        font-size: 12px;
        padding: 0 2px;
        margin: 2.5px;
    }

    /****check box****/
    #btn-menus {
        display: none;
    }

    .filtro_anuncio_ h2 {
        display: none;
    }

    .menu-c .abrir-menu {
        display: flex;
        justify-content: center;
        text-align: center;
        position: fixed;
        top: 120px;
        left: 82%;
        z-index: 100;
        background: rgb(233, 233, 233);
        border-radius: 50%;
        padding: 8px;
        font-size: 17px;
        color: #86746b;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        cursor: pointer;
        overflow: auto;
        overflow: overlay;
    }

    .menu-c .abrir-menu::-webkit-scrollbar {
        display: none;
    }

    .menu-c .abrir-menu:hover::-webkit-scrollbar {
        display: initial;
    }

    .menu-c .abrir-menu::-webkit-scrollbar-thumb {
        background-color: #09C;
    }

    .menu-c h3 {
        font-size: 12.5px;
    }

    #cerrar-menu {
        position: absolute;
        color: #ff6600;
        font-size: 22px;
        display: block;
        cursor: pointer;
        bottom: 10px;
        right: 5%;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        text-align: center;
        background-color: white;
    }

    .filtro_anuncio_ h3 {
        font-size: 14px;
    }

    .menu-c .Provincias_tags button {
        font-size: 10px;
        padding: 1px 2px;
        margin: 2.5px;
    }

    #menus {
        position: fixed;
        top: 96px;
        left: 0;
        z-index: 100;
        background: rgb(241, 241, 241);
        transition: all 200ms ease-out;
        width: 0%;
        visibility: hidden;
        overflow-y: scroll;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    #btn-menus:checked~#menus {
        width: 100%;
        height: 75vh;
        visibility: visible;
        margin-left: 0;
        padding: 10px;
        top: 79px;
        overflow-y: scroll;
        z-index: 10000;
    }

    ul.boxs-container li label {
        text-shadow: none
    }

    ul.boxs-container2 li label {
        text-shadow: none
    }
}

@import url("../components/mainpages/products/filterCatalogo.css");

@import url("../components/mainpages/utils/productItem/catalogoItem.css");