.content_info_red {
    max-width: 1025px;
    margin: 5px auto;
    background: #fff;
}

.content_info_red img {
    width: 100%;
    margin: 5px auto;
}

.content_img_ {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 15px auto;
    text-align: center;
}

.content_img_ img {
    width: 70%;
}

.content_img_ h3 {
    display: inline-block;
    color: #000;
    background: #fff;
    mix-blend-mode: multiply;
    position: relative;
    font-weight: 700;
    font-family: cursive;
    text-shadow: 1px 1px 2px rgb(112, 112, 112);
    margin: 25px;
    font-size: 50px;
}

.content_img_ h3::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgb(255, 51, 0), rgb(248, 182, 38), rgb(255, 136, 0));
    mix-blend-mode: screen;
    position: absolute;
    top: 0;
    left: 0;
}

.content_img_ p {
    font-size: 16.5px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: rgb(104, 103, 103);
    margin: 8px;
}

.content_redsocial .content_info_img {
    width: 100%;
    height: 400px;
    display: flex;
    position: relative;
}

.content_info_img .content_uno_img {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content_info_img .content_uno_img h1 {
    position: absolute;
    color: rgb(252, 53, 4);
    font-weight: 700;
    font-family: cursive;
    text-shadow: 5px 5px 10px rgba(248, 212, 212, 0.589);
    font-size: 65px;
    left: 5%;
    top: 25%;
}

#info_redsocial_span1 {
    font-size: 32px;
    font-family: cursive;
    color: #fff;
    text-shadow: 1px 1px 2px rgb(112, 112, 112);
    margin: 10px auto;
}

#info_redsocial_span2 {
    font-size: 18px;
    color: rgb(255, 119, 78);
    padding: 5px;
}

#info_redsocial_span3 {
    color: red;
}

.content_info_img .content_uno_img img {
    width: 100%;
    height: 100%;
    margin: 0;
}

.content_info_img .content_uno_info {
    width: 50%;
    height: 100%;
    background: #040404;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content_btn_log {
    background: red;
    padding: 8px 10px;
    border-radius: 10px;
    cursor: pointer;
    margin: 20px;
}

.content_uno_info span {
    color: #fff;
}

#content_h3_img {
    position: absolute;
    width: 50%;
    display: flex;
    flex-direction: column;
    left: 35%;
    top: 20%;
    padding: 8px;
}

#content_h3_img h3 {
    font-size: 32px;
    color: rgb(255, 94, 0);
    text-align: center;
    font-weight: 600;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

#content_span_img {
    font-size: 18px;
    color: rgb(214, 214, 214);
    font-weight: 500;
    text-align: center;
    margin: 8px 0px;
}

#content_title_img {
    text-align: center;
    font-size: 50px;
    font-family: cursive;
    font-weight: 600;
    color: rgb(255, 6, 6);
    text-shadow: 1px 2px 6px rgba(94, 94, 94, 0.699);
}


.icons_redsocial_img .wrapper {
    display: flex;
    margin: 8px 0px;
    list-style: none;
    width: 100%;
    padding: 0;
    justify-content: space-around;
}

.icons_redsocial_img .wrapper .icon {
    position: relative;
    background: #ffffff;
    border-radius: 50%;
    padding: 15px;
    margin: 10px;
    width: 50px;
    height: 50px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.icons_redsocial_img .wrapper .tooltip {
    position: absolute;
    top: 0;
    font-size: 14px;
    background: #ffffff;
    color: #ffffff;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.icons_redsocial_img .wrapper .tooltip::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background: #ffffff;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.icons_redsocial_img .wrapper .icon:hover .tooltip {
    top: -45px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.icons_redsocial_img .wrapper .icon:hover span,
.icons_redsocial_img .wrapper .icon:hover .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.icons_redsocial_img .wrapper .facebook:hover,
.icons_redsocial_img .wrapper .facebook:hover .tooltip,
.icons_redsocial_img .wrapper .facebook:hover .tooltip::before {
    background: #1877F2;
    color: #ffffff;
}

.icons_redsocial_img .wrapper .twitter:hover,
.icons_redsocial_img .wrapper .twitter:hover .tooltip,
.icons_redsocial_img .wrapper .twitter:hover .tooltip::before {
    background: #1DA1F2;
    color: #ffffff;
}

.icons_redsocial_img .wrapper .instagram:hover,
.icons_redsocial_img .wrapper .instagram:hover .tooltip,
.icons_redsocial_img .wrapper .instagram:hover .tooltip::before {
    background: #E4405F;
    color: #ffffff;
}

.icons_redsocial_img .wrapper .github:hover,
.icons_redsocial_img .wrapper .github:hover .tooltip,
.icons_redsocial_img .wrapper .github:hover .tooltip::before {
    background: #333333;
    color: #ffffff;
}

.icons_redsocial_img .wrapper .youtube:hover,
.icons_redsocial_img .wrapper .youtube:hover .tooltip,
.icons_redsocial_img .wrapper .youtube:hover .tooltip::before {
    background: #CD201F;
    color: #ffffff;
}

.icons_redsocial_img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
}

.icons_redsocial_img h3 {
    font-size: 25px;
    margin: 5px auto;
    text-align: center;
    color: rgb(78, 78, 78);
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.icons_redsocial_img h4 {
    font-size: 17px;
    color: rgb(114, 114, 114);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.wrapper a {
    color: #333333;
}

.footer_redsocial_infos {
    max-width: 1025px;
    padding: 10px;
    margin: 4px auto;
}


@media (max-width: 750px) {
    .content_img_ {
        grid-template-columns: 1fr;
    }

    .content_img_ p {
        font-size: 13px;
    }

    .content_img_ h3 {
        font-size: 30px;
        margin: 5px;
    }

    .content_info_img .content_uno_img h1 {
        font-size: 30px;
    }

    #info_redsocial_span1 {
        font-size: 17px;
        margin: 5px auto;
    }

    #info_redsocial_span2 {
        font-size: 12px;
        margin: 2px auto;
    }

    #info_redsocial_span3 {
        font-size: 11.5px;
    }

    .content_info_img .content_uno_img {
        width: 40%;
    }

    .content_info_img .content_uno_info {
        width: 60%;
    }

    #content_h3_img {
        width: 62%;
        left: 20%;
    }

    #content_h3_img h3 {
        font-size: 17px;
    }

    #content_span_img {
        font-size: 15px;
    }

    .icons_redsocial_img h3 {
        font-size: 15px;
    }

    .icons_redsocial_img h4 {
        font-size: 12px;
        text-align: center;
        margin-bottom: 50px;
    }

    .content_info_img.contenet {
        flex-direction: column;
        width: 100%;
        height: 500px;
    }

    .content_info_img.contenet .content_uno_img {
        width: 100%;
        height: 40%;
    }

    .content_info_img.contenet .content_uno_info {
        width: 100%;
        height: 60%;
    }
}