.header .menuDos {
    display: flex;
    justify-content: end;
    margin-left: 20px;
}

.header .menuDos .listaDos {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0px 6px 0px 6px;
}

.header .menuDos .listaDos small {
    font-size: 9px;
}

.header .menuDos .numeroC {
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 14px;
    background: red;
    width: 20px;
    height: 20px;
    font-weight: 800;
    text-align: center;
    color: rgb(255, 254, 254);
    border-radius: 50%;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
}

.header .menuDos .listaDos .material-icons {
    font-size: 25px;
}


/*--header Menu---*/


.header .menuDos .dropdown-menu {
    position: absolute;
    left: inherit;
    right: 0;
}

.header .menuDos .notify_length {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bold;
    font-size: 10px;
}

@media (max-width: 766px) {
    .header .menuDos {
        margin: 0;
        justify-content: start;
    }
    .header .menuDos .numeroC{
        width: 18px;
        height: 18px;
        top: 2px;
        left: -5px;
    }
}
/*---popop---
.header .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    visibility: hidden;
    opacity: 0;
}

.header .overlay:target {
    visibility: visible;
    opacity: 1;
}

.header .popup {
    margin: 30px auto;
    padding: 10px;
    background: rgb(255, 255, 255);
    border-radius: 5px;
    width: 50%;
    position: relative;
    height: 85%;
}

.header .popup h2 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
}

.header .popup .close {
    position: absolute;
    top: 12px;
    left: 8%;
    transition: all 200ms;
    font-size: 25px;
    font-weight: bold;
    text-decoration: none;
    color: rgb(51, 51, 51);
}

.header .popup .close:hover {
    color: #f10a0a;
}

.header .popup .content {
    height: 90%;
    overflow: auto;
    background: rgb(243, 220, 220);
    padding-left: 15px;
    padding-right: 15px;
}

@media screen and (max-width: 500px) {
    .header .box {
        width: 60%;
    }

    .header .popup {
        width: 90%;
    }

    .header .popup .close {
        position: absolute;
        top: 12px;
        left: 8%;
    }
}*/