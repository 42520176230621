.supper-avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: -20px;
  object-fit: cover;
  transform: translate(-50%, 50%);
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.big-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 4px;
}

.medium-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.small-avatar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.portal-avatar {
  width: 100%;
  height: 135px;
  border-radius: 5px;
  margin-bottom: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.avatar-suger {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.portal-avatar_content {
  filter: blur(3px);
}


@media (max-width: 766px) {
  .portal-avatar {
    margin-bottom: 85px;
    height: 100px;
    object-fit: cover;
    filter: brightness(70%);
    image-rendering: crisp-edges;
  }

  .supper-avatar {
    width: 110px;
    height: 110px;
    position: absolute;
    top: -12px;
  }
}