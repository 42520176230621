.video-home-page {
    display: flex;
    max-width: 1025px;
    margin: 4px auto;
    background: rgb(92 92 92);
}

.app-home-page {
    width: 100%;
}

#content_auncios_page {
    max-width: 670px;
    padding: 5px;
    margin: 5px auto;
}

.app-home-page .btn-page-videohome {
    display: flex;
    justify-content: space-around;
    margin: 10px auto 0px;
}

.app-home-page .btn-page-videohome h3 {
    font-size: 20px;
    color: rgb(179 179 179);
    cursor: pointer;
}

#activebutton {
    color: rgb(245 178 178);
    font-weight: 600;
    text-decoration: underline;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.app__videos_flowers {
    width: 100%;
}

.card_headerflowers {
    background: #2c2b2b;
    margin: 4px auto 0px;
    width: 98%;
    display: flex;
    justify-content: space-between;
    padding: 10px 6px 1px;
    color: #fff;
}

.card_headerflowers .card_name h6 a {
    color: white;
    font-size: 20px;
    letter-spacing: 2px;
    margin-left: 5px;
}

.card_headerflowers .card_name small {
    color: #b8b8b8;
}

.card-body-page {
    margin: 0px auto;
    width: 98%;
}
.content-body-page {
    padding: 4px;
    background: #2c2b2b;
}

.content-body-page .readMore {
    color: rgb(90, 6, 3);
}
.content-body-page span {
    font-size: 17px;
    color: rgb(221 221 221);
}

.card-footer-page {
    background: #2c2b2b;
    margin: 0px auto;
    width: 98%;
}

.card-footer-page .page-content-between {
    display: flex;
    justify-content: space-between;
    color: rgb(187 181 181);
    padding: 5px;
}

.card-footer-page .page-content-between h6 {
    font-size: 18px;
}

.card-icon-page {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px 2px;
    font-size: 25px;
    color: rgb(197, 197, 197);
}

.card-page-footer {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 8px;
}

.card-content-info {
    margin: 10px 0px;
    background: #645f5f;
}

/******Video comments page******/

#inputspage {
    background: #262626;
    padding: 5px 2px;
    height: 60px;
}

#inputspage input {
    background: #858484;
    height: 100%;
}

#inputspage button {
    background: #fd4f28;
    width: 10%;
    height: 100%;
    color: #fff;
}

/*cmabios*/

#headervip_home {
    margin: 25px auto;
}

#headervip_home a {
    text-decoration: none;
}

#headervip_home a h3 {
    transition: all 0.6s ease;
}

#headervip_home a h3:hover {
    color: rgb(255, 91, 41);
    text-shadow: 0.2em 0.2em 0.4em rgba(24, 24, 24, 0.856)
}

@media (max-width:750px) {

    #headervip_home {
        margin: 15px auto;
    }

    #headervip_home a h3 {
        font-size: 14px;
    }



    .video-home-page {
        flex-direction: column;
    }

    .app-home-page .btn-page-videohome h3 {
        font-size: 15px;
        margin: 2px auto;
    }

    .card_headerflowers {
        width: 100%;
    }

    .card-body-page {
        width: 100%;
    }

    .content-body-page span {
        font-size: 14px;
    }

    .card-footer-page {
        width: 100%;
    }

    .card-footer-page .page-content-between {
        padding: 4px 5px 0px;
    }
    
    .card-footer-page .page-content-between h6 {
        font-size: 14px;
    }

    .card-icon-page {
        
        font-size: 18px;
    }

    .card-content-info {
        margin: 15px 0px;
    }

    #content_auncios_page {
        width: 330px;
        padding: 5px;
    }

    #righSidebar {
        display: none;
    }
   
}