@import url('https://fonts.googleapis.com/css2?family=Anton&family=Kanit:ital@1&family=Lilita+One&family=Lobster&display=swap');

.conteinerBus {
    margin: 8px auto;
    max-width: 1025px;
}

.conteinerBus .retun {
    position: absolute;
    left: 0%;
    top: 21%;
}

.conteinerBus .retun .material-icons {
    position: relative;
    z-index: 100;
    cursor: pointer;
}

.header__subtitle {
    font-size: 1.5rem;
    color: #949fb0;
    font-family: 'Yesteryear', cursive;
    font-weight: 500;
    line-height: 1.1;
    margin-left: 20px;
}

.Sugerencias {
    max-width: 1000px;
    background-color: #fff;
    box-shadow: 0 0 5px 0 rgb(199, 199, 199);
    border-radius: 8px;
    margin: 0px auto;
}

.Cardsugerecias {
    padding: 10px;
    display: flex;
    flex-flow: row wrap;
}

.cards {
    margin: 15px;
    width: calc((100% / 4) - 30px);
    transition: all 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    box-shadow: 3px 3px 3px rgb(161, 161, 161);
    border-radius: 6px;
    height: 350px;
    position: relative;
    overflow: hidden;
}

.cards:hover {
    -ms-transform: scale(1.04);
    /* IE 9 */
    -webkit-transform: scale(1.04);
    /* Safari 3-8 */
    transform: scale(1.04);
}

.cards .ml-1 {
    position: absolute;
    bottom: 10px;
    width: 100%;
    padding: 5px;
}

.cards .ml-1 small {
    font-family: 'Kanit', sans-serif;
    color: #f7f7f7;
    font-size: 14px;
}

.cards .ml-1 .d-block {
    font-size: 17px;
    font-family: 'Anton', sans-serif;
    color: white;
    text-shadow: 1px 1px 1px rgba(32, 32, 32, 0.596);
}

.btn-sugger-seguir {
    background: rgb(252, 103, 3);
    border: none;
    border-radius: 4px;
    font-size: 12px;
    padding: 4px 10px;
    font-family:Arial, Helvetica, sans-serif;
    color:white;
    font-family: 800;
}


.btn-sugger-noseguir {
    background: rgb(252, 61, 61);
    border: none;
    border-radius: 4px;
    font-size: 12px;
    padding: 4px 8px;
    font-family:Arial, Helvetica, sans-serif;
    color:white;
    font-family: 800;
}

/*---Header Search---*/

.conteinerBus .search_form {
    position: relative;
    margin-bottom: 20px;
    margin-top: 15px;
    height: 30px;
    width: 90%;
    left: 5%;
    background: rgb(244 246 246);
}

.conteinerBus .search_form #search {
    background: #ECEFF1;
    border: 1px solid #ECEFF1;
    width: 90%;
    outline: none;
    text-indent: 5px;
    height: 33px;
    border-radius: 20px;
    position: relative;
    left: 37px;
    box-shadow: 1px 1px 5px 1px rgb(196, 196, 195);
}

.conteinerBus .search_form #search:hover {
    background-color: rgb(204, 209, 209);
    ;
}

.conteinerBus .search_form .search_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    pointer-events: none;
    z-index: 2;
}

.conteinerBus .search_form .search_icon .material-icons {
    font-size: 14px;
    transform: translateY(3px);
}

.conteinerBus .search_form .close_search {
    position: relative;
    bottom: 100%;
    left: 85%;
    cursor: pointer;
    font-weight: 900;
    color: crimson;
    width: 20px;
}

.conteinerBus .search_form .users {
    position: absolute;
    width: 100%;
    min-width: 250px;
    background: #fafafa;
    max-height: calc(100vh - 150px);
    overflow: auto;
    margin-top: 3px;
    top: 2.5rem;
    right: 0rem;
    z-index: 40;
}

.conteinerBus .search_form .loading {
    position: absolute;
    top: 14px;
    left: 50%;
    width: 15px;
    height: 15px;
    transform: translateY(-50%);
}

.conteinerBus .search_form .button_search {
    width: 29px;
    height: 29px;
    background: #95A5A6;
    border: none;
    color: #332f2f;
    position: absolute;
    border-radius: 50%;
    top: 8%;
    right: 0%;
}

.conteinerBus .search_form #search[type=text]:hover,
.conteinerBus .search_form #search[type=password]:hover {
    border: 1.2px solid #A3E4D7;
}

.conteinerBus .search_form #search[type=text]:focus,
.conteinerBus .search_form #search[type=password]:focus {
    border: 1.2px solid #A3E4D7;
    color: #0f0f0f;
}

@media (max-width: 750px) {
    .cards {
        margin: 15px;
        width: calc((100% / 2) - 30px);
        height: 250px;
    }

    .Sugerencias {
        max-width: 400px;
        background-color: rgb(250, 250, 250);
        box-shadow: 0 0 5px 0 rgb(199, 199, 199, 01.5rem);
        margin: 10px;
    }

    .cards .ml-1 .d-block {
        font-size: 13px;
    }

    .cards .ml-1 small {
        font-size: 12px;
    }

    .header__subtitle {
        font-size: 1rem;
    }

    .conteinerBus .search_form #search:hover {
        background-color: rgb(204, 209, 209);
    }

    .cards:hover {
        -ms-transform: scale(1.04);
        /* IE 9 */
        -webkit-transform: scale(1.04);
        /* Safari 3-8 */
        transform: scale(1.04);
    }
}