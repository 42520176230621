@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

:root {
    --surface-color: #fff;
    --curve: 40;
}

.home_public {
    max-width: 1000px;
    margin: 8px auto;
}

.home_public .profile_tab_public {
    display: flex;
    justify-content: center;
    padding: 8px;
    width: 80%;
    margin: 2px auto;
    background: hsla(0, 0%, 98%, 1);
    border-radius: 10px;
}

.home_public .profile_tab_public button {
    margin: 0 10px;
    width: 25%;
    border: none;
    background-color: #999999;
    border-radius: 4px;
    color: whitesmoke;
    transition: all 0.7s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile_tab_public button span {
    font-size: 14px;
}

.home_public .profile_tab_public button:hover {
    background-color: #ffddb6;
    color: #696969;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(255, 221, 189, 0.25) 0px 54px 55px, rgba(255, 189, 145, 0.12) 0px -12px 30px, rgba(255, 211, 170, 0.12) 0px 4px 6px, rgba(255, 205, 147, 0.17) 0px 12px 13px, rgba(255, 190, 130, 0.09) 0px -3px 5px;
}

#activebuttoPublic {
    background-color: #ff351b;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fdfdfd;
}

.home_public .cardsHome {
    width: 100%;
    max-width: 1025px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin: 4rem 5vw;
    padding: 20px 10px;
    list-style-type: none;
    margin: 10px auto 80px;
}

.title_publici_cardsHome {
    margin: 16px 0;
    font-size: 26px;
    font-family: 'Pacifico', cursive;
    padding: 0px 6px;
    color: #252525;
}

.home_public .card {
    position: relative;
    display: block;
    height: 100%;
    border-radius: calc(var(--curve) * 1px);
    overflow: hidden;
    text-decoration: none;
}

.home_public .card__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.home_public .card__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    border-radius: calc(var(--curve) * 1px);
    background-color: var(--surface-color);
    transform: translateY(100%);
    transition: .2s ease-in-out;
}

.card:hover .card__overlay {
    transform: translateY(0);
}

.home_public .card__header {
    position: relative;
    display: flex;
    align-items: center;
    gap: 2em;
    padding: 2em;
    border-radius: calc(var(--curve) * 1px) 0 0 0;
    background-color: var(--surface-color);
    transform: translateY(-100%);
    transition: .2s ease-in-out;
}

.home_public .card__arc {
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: 100%;
    right: 0;
    z-index: 1;
}

.home_public .card__arc path {
    fill: var(--surface-color);
}

.home_public .card:hover .card__header {
    transform: translateY(0);
}

.home_public .card__thumb {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.home_public .card__title {
    font-size: 1em;
    margin: 0 0 .3em;
    color: #6A515E;
}

.home_public .card__tagline {
    display: block;
    margin: 1em 0;
    font-family: "MockFlowFont";
    font-size: .8em;
    color: #D7BDCA;
}

.home_public .card__status {
    font-size: .8em;
    color: #D7BDCA;
}

.home_public .card__description {
    padding: 0 2em 2em;
    margin: 0;
    color: #D7BDCA;
    font-family: "MockFlowFont";
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.main_content_public {
    width: 70%;
    margin: 10px auto;
}

.avisoshome {
    display: flex;
    max-width: 1025px;
    margin: 5px auto;
}

.link_content_btn {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 15px 0px;
    margin: 15px 0px;
}

.link_content_btn a:hover {
    text-decoration: none;
}

.btn_public_acce {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-family: inherit;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    color: #708fb8;
    background-color: #e0e8ef;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-color: rgba(255, 255, 255, 0.333);
    border-radius: 40px 40px 40px 40px;
    padding: 20px 30px;
    transform: translate(0px, 0px) rotate(0deg);
    transition: 0.2s;
    box-shadow: -4px -2px 16px 0px #ffffff, 4px 2px 16px 0px rgb(95 157 231 / 48%);
}

.btn_public_acce:hover {
    color: white;
    background-color: #ffab8a;
    box-shadow: -2px -1px 8px 0px #ffd8b4, 2px 1px 8px 0px rgba(231, 161, 95, 0.48);
}

.btn_public_acce:active {
    box-shadow: none;
}


.btn_profilenew__div {
    display: flex;
    justify-content: center;
}

.btn_profilenew_ {
    background-color: rgb(255, 165, 91);
    width: 25%;
    min-width: 100px;
    border: none;
    border-radius: 5px;
    position: relative;
    margin: 8px auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: #ffffff;
    transition: all ease 1s;
}

.btn_profilenew_:hover {
    background-color: rgb(238, 238, 238);
    color: #808080;
    box-shadow: rgba(255, 143, 14, 0.35) 0px 5px 15px;
}

@media (max-width : 750px) {
    .home_public {
        margin-bottom: 85px;
    }

    .home_public .cardsHome {
        padding: 20px 25px;
        margin: 10px auto 80px;
    }

    .home_public .profile_tab_public {
        width: 96%;
    }

    .home_public .profile_tab_public button {
        width: 44%;
        font-size: 16px;
        margin: 0 5px;
    }

    .profile_tab_public button span {
        font-size: 10.5px;
    }

    .title_publici_cardsHome {
        font-size: 18px;
    }

    .main_content_public {
        width: 96%;
    }

    .link_content_btn {
        padding: 5px 0px;
        margin: 5px 0px;
    }

    .btn_public_acce {
        font-size: 12px;
        font-weight: 500;
        padding: 20px 30px;
    }

    .avisoshome {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}