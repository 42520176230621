.card_barraderech {
  --width: 90%;
  --height: 180px;
  --primary: #00b9ae;
  --text: #f9f9f9b2;
  --secondary: #f9f9f933;
  --radius: 20px;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  width: var(--width);
  height: var(--height);
  background: linear-gradient(180deg,
      rgba(22, 24, 30, 0) 0%,
      rgba(22, 24, 30, 0.7) 65.28%),
    linear-gradient(0deg, rgba(22, 24, 30, 0.4), rgba(22, 24, 30, 0.4));
  background-size: cover;
  border-radius: var(--radius);
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  padding: 1em;
  padding-top: 1.4em;
  margin: 10px auto;
  position: relative;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.subtitle_barderch_ {
  font-size: 24px;
  color: rgb(99, 99, 99);
  width: 100%;
  font-weight: 600;
  text-align: center;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#card_barraderech_img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  z-index: 1;
}

/*  */

.card_barraderech .card-top {
  z-index: 3;
}

.card_barraderech .card__title {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 0.2rem;
  z-index: 3;
}

.card__title .rating {
  margin-top: 0.5em;
  width: 85px;
  height: 16px;
  background-image: url("data:image/svg+xml,%20%3Csvg%0A%20%20%20%20%20%20%20%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20%20%20%20%20%20%20%20width%3D%2217%22%0A%20%20%20%20%20%20%20%20%20%20height%3D%2217%22%0A%20%20%20%20%20%20%20%20%20%20viewBox%3D%220%200%2017%2017%22%0A%20%20%20%20%20%20%20%20%20%20fill%3D%22none%22%0A%20%20%20%20%20%20%20%20%3E%0A%20%20%20%20%20%20%20%20%20%20%3Cpath%0A%20%20%20%20%20%20%20%20%20%20%20%20d%3D%22M8.51948%201.625C9.1214%201.625%2010.0427%204.16625%2010.4636%205.43013C10.6014%205.8437%2010.9837%206.13054%2011.4192%206.14904C12.7373%206.20505%2015.375%206.39722%2015.375%207.0384C15.375%207.66696%2013.5161%209.17543%2012.5322%209.92976C12.1816%2010.1986%2012.0365%2010.6604%2012.1687%2011.082C12.5631%2012.34%2013.2755%2014.8755%2012.7573%2015.3009C12.2506%2015.717%2010.2147%2014.2326%209.15246%2013.4009C8.77021%2013.1016%208.22949%2013.1012%207.84719%2013.4004C6.78473%2014.2321%204.75246%2015.717%204.28166%2015.3009C3.79912%2014.8745%204.47615%2012.3275%204.84741%2011.0727C4.97086%2010.6555%204.82425%2010.2029%204.47885%209.93826C3.49798%209.18681%201.625%207.66933%201.625%207.0384C1.625%206.3962%204.2711%206.20444%205.5871%206.14878C6.0197%206.13048%206.3998%205.84769%206.53973%205.43793C6.97041%204.17673%207.91633%201.625%208.51948%201.625Z%22%0A%20%20%20%20%20%20%20%20%20%20%20%20fill%3D%22%2300B9AE%22%0A%20%20%20%20%20%20%20%20%20%20%20%20stroke%3D%22%2300B9AE%22%0A%20%20%20%20%20%20%20%20%20%20%20%20stroke-width%3D%222%22%0A%20%20%20%20%20%20%20%20%20%20%20%20stroke-linecap%3D%22round%22%0A%20%20%20%20%20%20%20%20%20%20%20%20stroke-linejoin%3D%22round%22%0A%20%20%20%20%20%20%20%20%20%20%2F%3E%0A%20%20%20%20%20%20%20%20%3C%2Fsvg%3E");
  background-repeat: space;
  z-index: 2;
}

.card_barraderech .card__info {
  display: flex;
  margin-top: auto;
  font-size: 0.8rem;
  color: var(--text);
  font-weight: 400;
  justify-content: space-between;
  z-index: 2;
}

.card_barraderech .card__btns {
  display: flex;
  gap: 0.6rem;
  z-index: 2;
}

.card_barraderech button {
  border: none;
  background: none;
  cursor: pointer;
  background: var(--primary);
  color: #fff;
  border-radius: var(--radius);
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  transition: 0.2s;
  z-index: 2;
}

.card_barraderech button:hover {
  opacity: 0.8;
  transform: scale(0.9);
}

.card_barraderech .add-btn {
  color: #fff;
  padding: 0.5em 0.8em;
  background: rgba(252, 73, 2, 0.548);
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.card_barraderech .watch-btn {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}