.container_publicar_anuncio {
    width: 100%;
    max-width: 1025px;
    margin: 0 auto;
}

.container_publicar_anuncio .pricing-table-month a {
    text-decoration: none;
}

.container_publicar_anuncio .pricing-table-month a:hover {
    color: #ABB8C0;
}

.container_publicar_anuncio .pricing-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 5px;
}

.container_publicar_anuncio .pricing-wrap .pricing-table {
    width: 60%;
    margin: 10px auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/*****/

/*--blog----*/

.sec-title {
    position: relative;
    margin-bottom: 70px;
}

.sec-title .title {
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 1em;
    color: #ff8a01;
    font-weight: 500;
    background: rgb(247, 0, 104);
    background: -moz-linear-gradient(to left,
            rgba(247, 0, 104, 1) 0%,
            rgba(68, 16, 102, 1) 25%,
            rgba(247, 0, 104, 1) 75%,
            rgba(68, 16, 102, 1) 100%);
    background: -webkit-linear-gradient(to left,
            rgba(247, 0, 104, 1) 0%,
            rgba(68, 16, 102, 1) 25%,
            rgba(247, 0, 104, 1) 75%,
            rgba(68, 16, 102, 1) 100%);
    background: linear-gradient(to left,
            rgba(247, 0, 104) 0%,
            rgba(68, 16, 102, 1) 25%,
            rgba(247, 0, 104, 1) 75%,
            rgba(68, 16, 102, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F70068', endColorstr='#441066', GradientType=1);
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 15px;
}

.sec-title h2 {
    position: relative;
    display: inline-block;
    font-size: 30px;
    line-height: 1.2em;
    color: #414141;
    font-weight: 700;
}

.sec-title .text {
    position: relative;
    font-size: 16px;
    line-height: 28px;
    color: #888888;
    margin-top: 30px;
}

.sec-title.light h2,
.sec-title.light .title {
    color: #ffffff;
    -webkit-text-fill-color: inherit;
}

.pricing-section {
    position: relative;
    padding: 100px 0 80px;
    overflow: hidden;
}

.pricing-section .outer-box {
    max-width: 1100px;
    margin: 0 auto;
}

.pricing-section .row {
    margin: 0 -30px;
    display: flex;
    justify-content: space-around;
}

.pricing-block {
    position: relative;
    padding: 0 30px;
    margin-bottom: 40px;
}

.pricing-block .inner-box {
    position: relative;
    background-color: #ffffff;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
    padding: 0 0 30px;
    max-width: 370px;
    margin: 0 auto;
    border-bottom: 20px solid #40cbb4;
}

.pricing-block .icon-box {
    position: relative;
    padding: 50px 30px 0;
    background-color: #40cbb4;
    text-align: center;
}

.pricing-block .icon-box:before {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 75px;
    width: 100%;
    border-radius: 50% 50% 0 0;
    background-color: #ffffff;
    content: "";
}

.pricing-block .icon-box .icon-outer {
    position: relative;
    height: 150px;
    width: 150px;
    background-color: #ffffff;
    border-radius: 50%;
    margin: 0 auto;
    padding: 10px;
}

.pricing-block .icon-box i {
    position: relative;
    display: block;
    height: 130px;
    width: 130px;
    line-height: 120px;
    border: 5px solid #40cbb4;
    border-radius: 50%;
    font-size: 50px;
    color: #40cbb4;
    -webkit-transition: all 600ms ease;
    -ms-transition: all 600ms ease;
    -o-transition: all 600ms ease;
    -moz-transition: all 600ms ease;
    transition: all 600ms ease;
}

.pricing-block .inner-box:hover .icon-box i {
    transform: rotate(360deg);
}

.pricing-block .price-box {
    position: relative;
    text-align: center;
    padding: 10px 20px;
}

.pricing-block .title {
    position: relative;
    display: block;
    font-size: 24px;
    line-height: 1.2em;
    color: #222222;
    font-weight: 600;
}

.pricing-block .price {
    display: block;
    font-size: 30px;
    color: #222222;
    font-weight: 700;
    color: #40cbb4;
}

.pricing-block .features {
    position: relative;
    max-width: 230px;
    margin: 0 auto 20px;
}

.pricing-block .features li {
    position: relative;
    display: block;
    font-size: 14px;
    line-height: 30px;
    color: #848484;
    font-weight: 500;
    padding: 5px 0;
    padding-left: 30px;
    border-bottom: 1px dashed #dddddd;
}

.pricing-block .features li:before {
    position: absolute;
    left: 0;
    top: 50%;
    font-size: 16px;
    color: #2bd40f;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    content: "\f058";
    font-family: "Font Awesome 5 Free";
    margin-top: -8px;
}

.pricing-block .features li.false:before {
    color: #e1137b;
    content: "\f057";
}

.pricing-block .features li a {
    color: #848484;
}

.pricing-block .features li:last-child {
    border-bottom: 0;
}

.pricing-block .btn-box {
    position: relative;
    text-align: center;
}

.pricing-block .btn-box a {
    position: relative;
    display: inline-block;
    font-size: 14px;
    line-height: 25px;
    color: #ffffff;
    font-weight: 500;
    padding: 8px 30px;
    background-color: #40cbb4;
    border-radius: 10px;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    -webkit-transition: all 400ms ease;
    -moz-transition: all 400ms ease;
    -ms-transition: all 400ms ease;
    -o-transition: all 400ms ease;
    transition: all 300ms ease;
    cursor: pointer;
}

.pricing-block .btn-box a:hover {
    color: #ffffff;
}

.pricing-block .inner-box:hover .btn-box a {
    color: #40cbb4;
    background: none;
    border-radius: 0px;
    border-color: #40cbb4;
}

.pricing-block:nth-child(2) .icon-box i,
.pricing-block:nth-child(2) .inner-box {
    border-color: #1d95d2;
}

.pricing-block:nth-child(2) .btn-box a,
.pricing-block:nth-child(2) .icon-box {
    background-color: #1d95d2;
}

.pricing-block:nth-child(2) .inner-box:hover .btn-box a {
    color: #1d95d2;
    background: none;
    border-radius: 0px;
    border-color: #1d95d2;
}

.pricing-block:nth-child(2) .icon-box i,
.pricing-block:nth-child(2) .price {
    color: #1d95d2;
}

.pricing-block:nth-child(3) .icon-box i,
.pricing-block:nth-child(3) .inner-box {
    border-color: #ffc20b;
}

.pricing-block:nth-child(3) .btn-box a,
.pricing-block:nth-child(3) .icon-box {
    background-color: #ffc20b;
}

.pricing-block:nth-child(3) .icon-box i,
.pricing-block:nth-child(3) .price {
    color: #ffc20b;
}

.pricing-block:nth-child(3) .inner-box:hover .btn-box a {
    color: #ffc20b;
    background: none;
    border-radius: 0px;
    border-color: #ffc20b;
}

/****/

.pricing-table .pricing-table-month {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #fff;
    text-align: center;
    font-size: 14px;
    display: flex;
}

.pricing-table-month-one {
    width: 40%;
}

.pricing-table-month-one.basico {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pricing-table-month-two {
    width: 60%;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.pricing-table .pricing-table-month ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.pricing-table-month .pricing-table-head {
    color: #173D50;
    padding: 25px 0px;
}

.pricing-table-month .pricing-table-head h2 {
    font-size: 16px;
    letter-spacing: 2px;
    font-weight: bold;
}

.pricing-table-month .pricing-table-head h3 {
    font-size: 50px;
    font-weight: 700;
    display: inline;
    text-shadow: 2px 1px 6px rgba(62, 101, 99, 0.69)
}

.pricing-table-month .pricing-table-head h3 sup,
.pricing-table-head h3 sub {
    font-size: 20px;
    color: #ABB8C0;
    font-weight: 600;
}

.pricing-table-month .pricing-table-head h3 sub {
    font-size: 13px;
}

.pricing-table-month .pricing-table-head.estandar-title h2,
.pricing-table-month .pricing-table-head.estandar-title h3,
.pricing-table-month .pricing-table-head.estandar-title h3 sup,
.pricing-table-month .pricing-table-head.estandar-title h3 sub {
    color: #E97D68;
}


.pricing-table-list li {
    background: #F1F3F5;
    padding: 10px 0;
}

.pricing-table-list li:nth-child(2n) {
    background: #fff;
}

.pricing-table-button {
    display: block;
    width: 100%;
    padding: 20px 0;
    background: #0C1F28;
    color: #fff;
    margin-top: 23px;
    cursor: pointer;
    transition: all 1s ease;
}

.pricing-table-button:hover {
    background: #ffb444;
    color: rgb(255, 1, 1);
    box-shadow: rgba(238, 107, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.pricing-table-button.estandar {
    background: #E97D68;
    color: white;
    font-weight: 600;
}

.pricing-table-button.estandar:hover {
    background-color: #18fd04;
    box-shadow: rgba(95, 238, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.container_publicar_anuncio .header_precios {
    margin: 20px 2px 25px;
    color: #f7f7f7;
    padding: 5px 10px;
    background: hsla(141, 81%, 87%, 1);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background: linear-gradient(90deg, hsla(141, 81%, 87%, 1) 0%, hsla(41, 88%, 75%, 1) 50%, hsla(358, 82%, 71%, 1) 100%);

    background: -moz-linear-gradient(90deg, hsla(141, 81%, 87%, 1) 0%, hsla(41, 88%, 75%, 1) 50%, hsla(358, 82%, 71%, 1) 100%);

    background: -webkit-linear-gradient(90deg, hsla(141, 81%, 87%, 1) 0%, hsla(41, 88%, 75%, 1) 50%, hsla(358, 82%, 71%, 1) 100%);

    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#C5F9D7", endColorstr="#F7D486", GradientType=1);
}

.header_precios li {
    margin: 10px 0;
    font-weight: 600;
    color: #2c2c2c;
    text-shadow: 0.1em 0.1em 0.2em rgba(15, 15, 15, 0.575);
    list-style: none;

}

.container_publicar_anuncio .preci_suscripción {
    max-width: 94%;
    margin: 8px auto;
    background: white;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/**************/
.container_publicar_anuncio .title_publicación {
    margin: 15px auto;
    font-size: 30px;
    line-height: 1.2em;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: rgb(95, 95, 95);
    text-align: center;
}

/************/

.preci_suscripción .price-table.dos {
    display: none;
}

.preci_suscripción .price-table {
    width: 100%;
    border-collapse: collapse;
    border: 0 none;
}

.preci_suscripción .price-table a {
    color: red;
}

.preci_suscripción .price-table tr:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}

.preci_suscripción .price-table tr td {
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    padding: 8px 24px;
    font-size: 14px;
}

.preci_suscripción .price-table tr td:first-child {
    border-left: 0 none;
}

.preci_suscripción .price-table tr td:not(:first-child) {
    text-align: center;
}

.preci_suscripción .price-table tr:nth-child(even) {
    background-color: #FFFFFF;
}

.preci_suscripción .price-table tr:hover {
    background-color: #EEEEEE;
}

.preci_suscripción .price-table .fa-check {
    color: #f7351b;
}

.preci_suscripción .price-table .fa-times {
    color: #D8D6E3;
}

/* Highlighted column */
.preci_suscripción .price-table tr:nth-child(2n) td:nth-child(3) {
    background-color: rgba(216, 214, 227, 0.25);
}

.preci_suscripción .price-table tr td:nth-child(3) {
    background-color: rgba(216, 214, 227, 0.15);
    padding: 8px 48px;
}

.preci_suscripción .price-table tr.price-table-head td {
    font-size: 16px;
    font-weight: 600;
    font-family: "Montserrat";
    text-transform: uppercase;
}

.preci_suscripción .price-table tr.price-table-head {
    background-color: #5336ca;
    color: #FFFFFF;
}

.preci_suscripción .price-table td.price {
    color: #f43f54;
    padding: 16px 24px;
    font-size: 20px;
    font-weight: 600;
    font-family: "Montserrat";
}

.preci_suscripción .price-table td.price a {
    background-color: #e97d68;
    color: #FFFFFF;
    padding: 12px 32px;
    margin-top: 16px;
    font-size: 12px;
    font-weight: 600;
    font-family: "Montserrat";
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    border-radius: 64px;
    transition: all 0.2s;
}

.preci_suscripción .price-table td.price a:hover {
    background-color: #ebe1df;
    color: #e97d68;
    border: solid 1px #e97d68;
}

.preci_suscripción .price-table td.price-table-popular {
    font-family: "Montserrat";
    border-top: 3px solid #e97d68;
    color: #e97d68;
    text-transform: uppercase;
    font-size: 12px;
    padding: 12px 48px;
    font-weight: 700;
}

.preci_suscripción .price-table .price-blank {
    background-color: #fafafa;
    border: 0 none;
}

.preci_suscripción .price-table svg {
    width: 90px;
    fill: #5336ca;
}

/*************************/

.pricing-wrap .miswitch_model {
    height: 100vh;
    width: 100%;
    background: rgba(17, 17, 17, 0.514);
    position: fixed;
    max-width: 1025px;
    top: 0;
    z-index: 12000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.miswitch_model .miswitch_model_btn {
    height: 10%;
    width: 25%;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.miswitch_model_btn i {
    font-size: 60px;
    cursor: pointer;
}

#close_btn_anuncios {
    margin-left: 30%;
    font-size: 25px;
    background: crimson;
    border-radius: 50%;
    color: #ffefef;
    cursor: pointer;
}

#infor_public_anuncio {
    margin: 10px;
    color: #ffffff;
    text-shadow: 2px 2px 4px rgb(32, 32, 32);
    font-size: 20px;
    font-weight: 700;
}

.btn_red_precio1,
.btn_red_precio2 {
    background: #fff;
    padding: 8px;
    margin: 0px 4px;
    border-radius: 8px;
    transition: all 0.7s ease;
}

.btn_red_precio1:hover {
    background: rgb(255, 192, 168);
    color: #686868;
    box-shadow: 4px 4px 8px rgba(255, 213, 158, 0.438);
}

.btn_red_precio2:hover {
    background: rgb(255, 192, 168);
    color: #686868;
    box-shadow: 4px 4px 8px rgba(255, 213, 158, 0.281);
}

.btn_red_precio1 {
    color: rgb(62, 139, 255);
}

.btn_red_precio2 {
    color: rgb(3, 175, 46);
}

@media screen and (max-width: 750px) {
    .pricing-section .container {
        width: 85%;
    }

    .sec-title {
        margin-bottom: 10px;
    }

    .pricing-section {
        padding: 30px 0;
    }

    .sec-title h2 {
        font-size: 20px;
    }

    .miswitch_model .miswitch_model_btn {
        height: 12%;
        width: 40%;
    }

    #infor_public_anuncio {
        margin: 10px;
        font-size: 12px;
    }

    #close_btn_anuncios {
        margin-left: 54%;
    }

    .pricing-wrap .miswitch_model {
        left: 0;
    }
}


@media screen and (max-width: 750px) {

    .container_publicar_anuncio {
        width: 98%;
    }

    .container_publicar_anuncio .pricing-wrap {
        flex-direction: column;
        width: 98%;
        margin: 5px auto;

    }

    .pricing-table-month-one {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }

    .container_publicar_anuncio .pricing-wrap .pricing-table {
        margin: 20px auto;
        width: 100%;
    }

    .pricing-table-month .pricing-table-head h2 {
        font-size: 8px;
    }

    .pricing-table .pricing-table-month {
        font-size: 11px;
    }

    .pricing-table-month .pricing-table-head {
        padding: 5px 0px;
    }

    .pricing-table-month .pricing-table-head h3 {
        font-size: 22px;
    }

    .pricing-table-month .pricing-table-head h3 sup,
    .pricing-table-head h3 sub {
        font-size: 13px;
    }

    .pricing-table-list li {
        padding: 5px 0;
    }

    .pricing-table-button {
        padding: 10px 0;
        background: #0C1F28;
        color: #fff;
        margin-top: 5px;
    }

    .container_publicar_anuncio .header_precios {
        margin: 0px 2px;
        margin-bottom: 100px;
    }

    .container_publicar_anuncio .header_precios li {
        margin: 5px 0;
        font-size: 12px;
    }

    .preci_suscripción .price-table td.price a {
        font-size: 10px;
        margin-top: 0px;
        padding: 10px 30px;
    }

    .preci_suscripción .price-table tr td {
        font-size: 10.2px;
    }

    .preci_suscripción .price-table td.price-table-popular {
        font-size: 10px;
    }

    .container_publicar_anuncio .title_publicación {
        margin: 15px 10px;
        font-size: 17px;
    }

    .preci_suscripción .price-table.dos {
        display: block;
    }

    .preci_suscripción .price-table.uno {
        display: none;
    }
}