
.product_img{
    max-width: 300px;
    overflow: hidden;
    height: 220px;
    padding: 5px;
    box-shadow: 0 0 15px #ddd;
    margin: 10px 0;
    position: relative;
}
.product_img img{
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    border-radius: 15px;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.product_img:hover {
	transform: translateY(0.1rem) scale(1);
	box-shadow: 0 0.5em 3rem -1rem rgba(0,0,0,0.5);
}

.product_img h2 {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    color: rgb(233, 233, 233);
    text-shadow: 1px 1px 2px black;
}

#view{
    color: #FCFCFC;
    position: relative;
    top: -18%;
    right: -12%;
    font-size: 14px;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.title-avisos-home {
    color: rgb(120, 121, 121);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 500;
    margin: 5px 2px 0px 4px;
}

@import url('./CardCarousel.css');