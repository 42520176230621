.errMsg{
    background: rgb(214, 10, 10);
    color: #fff9;
    text-align: center;
    padding: 10px 0;
    letter-spacing: 1.3px;
}

.successMsg{
    background: rgb(9, 158, 54);
    color: #fff9;
    text-align: center;
    padding: 10px 0;
    letter-spacing: 1.3px;
}

.successMsg_a {
    text-decoration: none;
    color: aliceblue;
    font-weight: 600;
    font-family: 20px;
}