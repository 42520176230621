.tiktok-effect {
    max-width: 1025px;
    width: 98%;
    margin: 5px auto;
    padding: 8px;
  }

  .content_linktiktok {
    width: 90%;
    /*border: solid 1px #ff0080;*/
    margin: 8px auto;
    background-color: #ffffff;
  }
  
  .content_linktiktok .tiktok-embed {
    /*border: solid 1px blue;*/
    background-color: #ffffff;
    padding: 8px;
  }