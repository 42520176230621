.anuncio_modal {
  position: fixed;
  top: 0;
  left: 0;
  background: #0008;
  z-index: 10000;
  width: 100%;
  height: 100vh;
  overflow: auto;
}

.anuncio_modal form {
  max-width: 680px;
  width: 98%;
  background: white;
  margin: 30px auto;
  padding: 20px;
  border-radius: 5px;
}

.anuncio_body .parrafo_initial {
  font-size: 18px;
  font-family: 'Kanit', sans-serif;
  color: rgb(218, 86, 86);
}

.anuncio_body .ubication_map {
  width: 100%;
  height: 500px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.anuncio_modal .anuncio_body h6 {
  font-size: 16px;
  margin: 10px 12px;
  font-family: 'Lilita One', cursive;
  font-weight: 500;
  color: rgb(108, 109, 109);
}

.anuncio_modal .anuncio_body span {
  font-size: 16px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: rgb(100, 100, 100);
}

.anuncio_modal .anuncio_body .content_datos_user {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;
  margin: 8px 0px;
}

.anuncio_modal .anuncio_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  transform: translateY(-13px);
}

.anuncio_header span {
  font-size: 2rem;
  font-weight: 900;
  cursor: pointer;
  transform: translateY(-5px);
}

.anuncio_body textarea {
  width: 100%;
  min-height: 100px;
  border: none;
  outline: none;
  resize: none;
}

.anuncio_body .input_images {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.anuncio_body i {
  font-size: 2rem;
  cursor: pointer;
}

.anuncio_body .input_images .file_upload {
  overflow: hidden;
  margin: 0 10px;
  position: relative;
}

.anuncio_body .input_images .file_upload #file {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.anuncio_body .Content_Check {
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px;
  margin: 6px 0px;
  background: rgb(243, 241, 241);
}

.anuncio_body .Descripcion_anuncion {
  width: 100%;
  margin: 8px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.anuncio_body .Descripcion_anuncion .titulo_anuncio_pr {
  grid-column: 1/4;
  margin: 5px 0;
}

.anuncio_body .Descripcion_anuncion .titulo_anuncio_pr input {
  width: 98%;
  margin: 0px auto;
}

.anuncio_body .Descripcion_anuncion .textearea_content {
  grid-column: 1/4;
}

.anuncio_body .Descripcion_anuncion .textearea_content textarea {
  border: solid 1px rgb(184, 182, 182);
}

.anuncio_body .Descripcion_anuncion .textearea_content #dcorta {
  height: 50px;
}

.anuncio_body .Descripcion_anuncion .textearea_content #descripcion {
  height: 220px;
}

.anuncio_body .Descripcion_anuncion input {
  margin: 2px;
}

.anuncio_body .Descripcion_anuncion .input_mobile_anuncio {
  grid-column: 1/4;
  margin: 6px 0px;
}

.anuncio_body .content_ubicacion {
  width: 100%;
  margin: 8px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2px;
}

.anuncio_body .content_ubicacion .input_coordenadas {
  display: flex;
  justify-content: center;
  margin: 12px 0px;
  grid-column: 1/3;
  position: relative;
}

.content_ubicacion .input_coordenadas .coordenas_inputs {
  display: flex;
  flex-direction: column;
  position: relative;
}

.content_ubicacion .input_coordenadas .coordenas_inputs .coordenas_div {
  display: flex;
  flex-direction: column;
  margin: 8px 0px;
  padding: 4px 30px;
  position: absolute;
  z-index: 2000;
  background-color: #acacaccb;
  top: 60px;
  right: 0;
  border-radius: 10px;
}

.anuncio_body .content_ubicacion .input_coordenadas .coordenadas_latlong {
  width: 50%;
  margin: 2px;
}

.anuncio_body .content_detail_ad textarea {
  border: solid 1px rgba(180, 180, 180, 0.712);
  padding: 8px;
  border-radius: 10px;
  height: 50px;

}

.anuncio_body .content_servicios {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 6px 0px;
  padding: 10px;
  background: rgb(236, 236, 236);
}

.anuncio_body .show_images {
  max-height: 270px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  place-items: center;
  padding: 10px 0;
  grid-gap: 10px;
}

.anuncio_body .show_images #file_img {
  position: relative;
  width: 100%;
  height: 100%;
}

.anuncio_body .show_images #file_img img,
.anuncio_body .show_images #file_img video {
  display: block;
  object-fit: contain;
  width: 100%;
  height: 100%;
  max-height: 100px;
}

.anuncio_body .show_images #file_img span {
  position: absolute;
  top: -1px;
  right: -2px;
  z-index: 4;
  background: white;
  color: crimson;
  padding: 3px 7px;
  border: 1px solid crimson;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bolder;
  cursor: pointer;
}

.anuncio_body .stream span {
  position: absolute;
  top: -10px;
  right: 5px;
  color: crimson;
  font-size: 2rem;
  font-weight: 900;
  cursor: pointer;
}

.anuncio_body .dropdown-menu {
  transform: translate3d(-206px, -237px, 0px) !important;
}

.style-picker-status {
  position: absolute;
  z-index: 100;
}

/************/
.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #89849b;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  -webkit-animation-name: bounce;
  -moz-animation-name: bounce;
  -o-animation-name: bounce;
  -ms-animation-name: bounce;
  animation-name: bounce;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  -ms-animation-duration: 1s;
  animation-duration: 1s;
}

.pin:after {
  content: '';
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #2f2f2f;
  position: absolute;
  border-radius: 50%;
}

.pulse {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  -webkit-transform: rotateX(55deg);
  -moz-transform: rotateX(55deg);
  -o-transform: rotateX(55deg);
  -ms-transform: rotateX(55deg);
  transform: rotateX(55deg);
  z-index: -2;
}

.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  -webkit-animation: pulsate 1s ease-out;
  -moz-animation: pulsate 1s ease-out;
  -o-animation: pulsate 1s ease-out;
  -ms-animation: pulsate 1s ease-out;
  animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-box-shadow: 0 0 1px 2px #89849b;
  box-shadow: 0 0 1px 2px #89849b;
  -webkit-animation-delay: 1.1s;
  -moz-animation-delay: 1.1s;
  -o-animation-delay: 1.1s;
  -ms-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

@-moz-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }

  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }

  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}

@-o-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }

  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }

  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}

@-moz-keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}

@-webkit-keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}

@-o-keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}

.anuncio_statusP_veranuncio {
  max-width: 50vw;
  margin: 5px auto;
}

/****/
.input_contet_form label {
  font-size: 11px;
  margin-left: 5px;
  color: #ff7777;
}

.input_contet_CO {
  width: 40%;
}

#simbolprecio {
  border: none;
  height: 40px;
  color: #5c5c5c;
}

.content_textarea_co {
  margin: 14px auto;
  padding: 3px;
}

#estyle_back_ {
  margin: 8px auto;
}

#estyle_back_err {
  background: #ffd3d396;
}

#input-group_mb-3 {
  display: flex;
  flex-direction: column;
}

#input-group_mb-3 label {
  font-size: 12px;
  color: #89849b;
}

/**************/

.btn_btn-secondar-sumit {
  position: relative;
  display: inline-block;
  border: none;
  background-color: #2196F3;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  margin: 10px auto;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.320, 1);
}

.btn_btn-secondar-sumit:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120%;
  height: 120%;
  background-color: rgb(206, 206, 206);
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.320, 1);
}

.btn_btn-secondar-sumit span {
  position: relative;
  z-index: 1;
}

.btn_btn-secondar-sumit:hover {
  color: #2196F3;
  background-color: rgb(224, 223, 223);
  box-shadow: 0 0 20px hsla(263, 77%, 31%, 0);
}

.btn_btn-secondar-sumit:active {
  scale: 0.9;
}

.btn_btn-secondar-sumit:hover:before {
  transform: translate(-50%, -50%) rotate(45deg);
  opacity: 1;
}






/**************/


@media (max-width:725px) {

  .anuncio_modal form {
    max-width: 680px;
    width: 96%;
  }

  .anuncio_body .content_servicios {
    font-size: 13.5px;
  }

  .content_ubicacion .input_coordenadas .coordenas_inputs .coordenas_div {
    font-size: 15px;
    display: flex;
    flex-direction: column;
    width: 50%;
    overflow: hidden;
    margin: 8px;
    padding: 4px 10px;
  }

  .anuncio_body .content_ubicacion .input_coordenadas .coordenadas_latlong {
    margin: 8px 25px;
    width: 90%;
  }

  .anuncio_modal .anuncio_body .content_datos_user {
    display: grid;
    grid-template-columns: 1fr;
    margin: 10px 0px;
  }

  .anuncio_body .Content_Check {
    display: flex;
    width: 100%;
  }

  .anuncio_body .Descripcion_anuncion {
    display: flex;
    flex-direction: column;
  }

  .anuncio_statusP_veranuncio {
    max-width: 98%;
    width: 98%;
  }

  #statusBtnPubli {
    font-size: 12px;
  }

  .anuncio_modal .anuncio_body span {
    font-size: 17.5px;
  }
}