.comment_input{
    display: flex;
    align-items: center;
}
.comment_input input{
    background: #f7f7f7;
    border: none;
    outline: none;
    flex: 1;
    overflow: auto;
}
.comment_input .postBtn {
    border: none;
    outline: none;
    background: #f7f7f7;
    color: rgb(255, 38, 0);
    font-weight: 600;
}

.comment_input .postBtn:hover {
    color: rgb(13, 247, 255);
}

.comment_input .dropdown-menu{
    transform: translate3d(-140px, -270px, 0px) !important;
}
/* ---------- Comments ---------- */
.comment_display{
    padding: 10px 25px;
}
.comment_card .comment_content{
    background: #eee;
    padding: 7px;
    border-radius: 10px;
    border-top-left-radius: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.comment_card .comment_content .readMore{
    cursor: pointer;
    color: crimson;
}

.comment_card .comment_content .nav-item{
    cursor: pointer;
    opacity: 1;
    display: block
}
.comment_card .comment_content:hover .nav-item{
    opacity: 1;
}
.comment_card .comment_content .dropdown-menu{
    position: absolute;
    right: 0;
    left: inherit;
}
.comment_card .comment_content .dropdown-item{
    display: flex;
    margin: 10px 0;
}
.comment_card .comment_content textarea{
    width: 100%;
    border: none;
    outline: none;
}