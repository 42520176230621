.header-pro {
  min-height: 35px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  background: #f0f1f3;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  padding: 0px;
  border-radius: 5px;
  margin-top: 5px;
}

.header-pro .logo {
  flex: 1;
}

.header-pro a {
  text-transform: uppercase;
  color: #555;
}

.header-pro ul {
  margin: 0px;
  padding: 14px;
  font-size: 12px;
}

.header-pro ul li {
  display: inline-block;
  opacity: 0.7;
  padding: 0 10px;
  font-size: 11px;
}

.li-precios-nlc {
  display: none;
}

.header-pro ul li:hover {
  opacity: 1;
}

.menupro {
  display: none;
}

.cart-icon {
  position: relative;
  margin-right: 20px;
}

.cart-icon span {
  background: crimson;
  border-radius: 20px;
  color: white;
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 5px 7px;
  font-size: 10px;
}

@media (max-width:750px) {
  .header-pro ul li {
    display: inline-block;
    opacity: 0.7;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 500;
  }

  .li-precios-nlc {
    border: 1px solid crimson;
    border-radius: 4px;
  }
}