.auth_page-register,
.auth_page-login {
    min-width: 400px;
    width: 40%;
    height: auto;
    margin: 5px auto;
    padding: 25px;
    border-radius: 5px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background: hsla(298, 68%, 90%, 1);
    background: linear-gradient(90deg, hsla(298, 68%, 90%, 1) 0%, hsla(30, 82%, 91%, 1) 100%);
    background: -moz-linear-gradient(90deg, hsla(298, 68%, 90%, 1) 0%, hsla(30, 82%, 91%, 1) 100%);
    background: -webkit-linear-gradient(90deg, hsla(298, 68%, 90%, 1) 0%, hsla(30, 82%, 91%, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#F6D5F7", endColorstr="#FBE9D7", GradientType=1);
}

.auth_page-login .titulo_login_ {
    width: 50%;
    text-align: center;
    margin: 6px auto;
    margin-bottom: 10px;
}

.auth_page-login .titulo_login_ h3 {
    display: inline-block;
    color: #000;
    background: #fff;
    mix-blend-mode: multiply;
    position: relative;
    font-weight: 700;
    font-family: cursive;
    text-shadow: 1px 1px 2px rgb(112, 112, 112);
}

.auth_page-login .titulo_login_ h3:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgb(255, 51, 0), rgb(248, 182, 38), rgb(255, 136, 0));
    mix-blend-mode: screen;
    position: absolute;
    top: 0;
    left: 0;
}

.auth_page-register button {
    margin: 10px 0;
}

.auth_page-register .logo-register {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 5px 0;
}

.auth_page-register .logo-register h3 {
    display: inline-block;
    color: #000;
    background: #fff;
    mix-blend-mode: multiply;
    position: relative;
    font-weight: 700;
    font-family: cursive;
    text-shadow: 1px 1px 2px rgb(112, 112, 112);
}

.auth_page-register .logo-register h3:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgb(255, 51, 0), rgb(248, 182, 38), rgb(255, 136, 0));
    mix-blend-mode: screen;
    position: absolute;
    top: 0;
    left: 0;
}

#content-register {
    background: rgb(212, 210, 210);
    padding: 6px 10px;
    margin: 5px 0px;
    border-radius: 10px;
    display: flex;
}

#content-register label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100px;
    margin: 0px;
}

.condiciones-check {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.condiciones-check h6 {
    margin-right: 8px;
}

.condiciones-check-uno {
    background: rgb(182, 180, 180);
    display: flex;
    align-items: center;
    padding: 0px 8px;
    justify-content: space-between;
    width: auto;
    border-radius: 10px;
}


.btn_check-bt {
    width: 100%;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 4px;
    color: rgb(75, 75, 75);
    font-weight: 600;
}

.btn-dark-bt {
    background-color: #525252;
    color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: all ease 1s;
    width: 100%;
    border: none;
    cursor: pointer;
}

.btn-dark-bt:hover {
    background-color: #9b9b9b;
    color: rgb(235, 235, 235);
    box-shadow: rgba(248, 166, 99, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.btn-disabled-bt {
    background-color: #ccc;
    /* Cambia este color al que desees */
    cursor: not-allowed;
}

/***btn google ***/

.nsm7Bb-HzV7m-LgbsSe {
    background-color: rgb(245, 245, 245);
    margin: 10px auto;
    font-weight: 700;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: rgb(6, 122, 255);
    border: solid 1px rgb(123, 191, 255);
}

.form-control small i {
    font-size: 20px;
    color: #525252;
}

@media (max-width: 725px) {

    .auth_page-register,
    .auth_page-login {
        width: 96%;
        min-width: 96%;
        height: auto;
        margin-bottom: 85px;
    }

    .auth_page-register form {
        font-size: 14px;
    }

    .condiciones-check-uno {
        margin: 0;
    }

    .condiciones-check-uno h6 {
        font-size: 12px;
        margin: 0 3px;
    }

    #content-register label {
        display: flex;
        justify-content: center;
    }

    #content-register input {
        margin-left: 8px;
    }

    .auth_page-login .titulo_login_ h3 {
        font-size: 25px;
    }
}