@import url(https://fonts.googleapis.com/css2?family=Lilita+One&family=Signika:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&family=Kanit:ital@1&family=Lilita+One&family=Lobster&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bungee+Spice&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Gloock&family=Permanent+Marker&family=Shadows+Into+Light&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Caprasimo&family=Indie+Flower&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fuggles&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Protest+Revolution&display=swap);
.auth_page-register,
.auth_page-login {
    min-width: 400px;
    width: 40%;
    height: auto;
    margin: 5px auto;
    padding: 25px;
    border-radius: 5px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background: hsla(298, 68%, 90%, 1);
    background: linear-gradient(90deg, hsla(298, 68%, 90%, 1) 0%, hsla(30, 82%, 91%, 1) 100%);
    background: -webkit-linear-gradient(90deg, hsla(298, 68%, 90%, 1) 0%, hsla(30, 82%, 91%, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#F6D5F7", endColorstr="#FBE9D7", GradientType=1);
}

.auth_page-login .titulo_login_ {
    width: 50%;
    text-align: center;
    margin: 6px auto;
    margin-bottom: 10px;
}

.auth_page-login .titulo_login_ h3 {
    display: inline-block;
    color: #000;
    background: #fff;
    mix-blend-mode: multiply;
    position: relative;
    font-weight: 700;
    font-family: cursive;
    text-shadow: 1px 1px 2px rgb(112, 112, 112);
}

.auth_page-login .titulo_login_ h3:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgb(255, 51, 0), rgb(248, 182, 38), rgb(255, 136, 0));
    mix-blend-mode: screen;
    position: absolute;
    top: 0;
    left: 0;
}

.auth_page-register button {
    margin: 10px 0;
}

.auth_page-register .logo-register {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 5px 0;
}

.auth_page-register .logo-register h3 {
    display: inline-block;
    color: #000;
    background: #fff;
    mix-blend-mode: multiply;
    position: relative;
    font-weight: 700;
    font-family: cursive;
    text-shadow: 1px 1px 2px rgb(112, 112, 112);
}

.auth_page-register .logo-register h3:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgb(255, 51, 0), rgb(248, 182, 38), rgb(255, 136, 0));
    mix-blend-mode: screen;
    position: absolute;
    top: 0;
    left: 0;
}

#content-register {
    background: rgb(212, 210, 210);
    padding: 6px 10px;
    margin: 5px 0px;
    border-radius: 10px;
    display: flex;
}

#content-register label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100px;
    margin: 0px;
}

.condiciones-check {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.condiciones-check h6 {
    margin-right: 8px;
}

.condiciones-check-uno {
    background: rgb(182, 180, 180);
    display: flex;
    align-items: center;
    padding: 0px 8px;
    justify-content: space-between;
    width: auto;
    border-radius: 10px;
}


.btn_check-bt {
    width: 100%;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 4px;
    color: rgb(75, 75, 75);
    font-weight: 600;
}

.btn-dark-bt {
    background-color: #525252;
    color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: all ease 1s;
    width: 100%;
    border: none;
    cursor: pointer;
}

.btn-dark-bt:hover {
    background-color: #9b9b9b;
    color: rgb(235, 235, 235);
    box-shadow: rgba(248, 166, 99, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.btn-disabled-bt {
    background-color: #ccc;
    /* Cambia este color al que desees */
    cursor: not-allowed;
}

/***btn google ***/

.nsm7Bb-HzV7m-LgbsSe {
    background-color: rgb(245, 245, 245);
    margin: 10px auto;
    font-weight: 700;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: rgb(6, 122, 255);
    border: solid 1px rgb(123, 191, 255);
}

.form-control small i {
    font-size: 20px;
    color: #525252;
}

@media (max-width: 725px) {

    .auth_page-register,
    .auth_page-login {
        width: 96%;
        min-width: 96%;
        height: auto;
        margin-bottom: 85px;
    }

    .auth_page-register form {
        font-size: 14px;
    }

    .condiciones-check-uno {
        margin: 0;
    }

    .condiciones-check-uno h6 {
        font-size: 12px;
        margin: 0 3px;
    }

    #content-register label {
        display: flex;
        justify-content: center;
    }

    #content-register input {
        margin-left: 8px;
    }

    .auth_page-login .titulo_login_ h3 {
        font-size: 25px;
    }
}
.loading{
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading svg{
    font-size: 5px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    animation: text 1s ease-in-out infinite;
}
@keyframes text{
    50%{ opacity: 0.1 }
}
.loading polygon{
    stroke-dasharray: 22;
    stroke-dashoffset: 1;
    animation: dash 4s cubic-bezier(0.45, 0.03, 0.515, 0.955)  
    infinite alternate-reverse;
}
@keyframes dash{
    to {stroke-dashoffset: 234;}
}
.header {
    width: 100%;
    min-height: 70px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10000;
    box-shadow: 0 0 1px rgba(255, 208, 177, 0.384);
    background-color: hsl(0, 0%, 100%);
}

.header .header_encabezado {
    width: 100%;
    height: 5px;
    background: rgb(251, 104, 8);
    background: linear-gradient(306deg, rgba(251, 104, 8, 1) 14%, rgba(255, 201, 162, 1) 29%, rgba(251, 148, 70, 1) 45%, rgba(255, 165, 84, 1) 60%, rgba(255, 173, 87, 1) 77%, rgba(249, 116, 0, 1) 91%);
}

.menus-header {
    display: flex;
    justify-content: space-between;
}

/******btn anuncios******/
.btn-p {
    flex: 1 1 auto;
    margin: 2px auto;
    padding: 8px 15px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px rgba(126, 126, 126, 0.493);
    border-radius: 10px;
    font-weight: 600;
    text-decoration: none;
}

/* Demo Stuff End -> */

/* <- Magic Stuff Start */

.btn-p:hover {
    background-position: right center;
    color: #7c7b7b;
}

.btn-1p {
    background-image: linear-gradient(to right, #ffd23c 0%, #f57a07 50%, #ff8e32 100%);
}

@media (max-width: 766px) {
    .btn-p {
        padding: 5px 12px;
        font-size: 12px;
        border-radius: 4px;
        margin: 2px auto;
    }
}

/*********logo********/
#logo_css {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: 700;
    font-size: 1.5em;
    text-align: center;
    cursor: pointer;
    text-shadow: 0.1em 0.1em 0.2em rgba(253, 176, 132, 0.37)
}

.gradient-text {
    background-color: #ca4246;
    background-image: linear-gradient(45deg,
            #ca4246 16.666%,
            #e16541 16.666%,
            #e16541 33.333%,
            #f18f43 33.333%,
            #f18f43 60%,
            #8b9862 20%,
            #8b9862 66.666%,
            #476098 66.666%,
            #476098 83.333%,
            #a7489b 83.333%);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: rainbow-text-simple-animation-rev 0.75s ease forwards;
}

.gradient-text:hover {
    animation: rainbow-text-simple-animation 0.5s ease-in forwards;
}

@keyframes rainbow-text-simple-animation-rev {
    0% {
        background-size: 650%;
    }

    40% {
        background-size: 650%;
    }

    100% {
        background-size: 100%;
    }
}

@keyframes rainbow-text-simple-animation {
    0% {
        background-size: 100%;
    }

    80% {
        background-size: 650%;
    }

    100% {
        background-size: 650%;
    }
}


/*--switch---*/

.toggle {
    position: relative;
    background-color: white;
    border-radius: 50%;
    display: flex;
    cursor: pointer;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    color: #a09e9e;
    transition: all 1s ease;
    margin-left: 20px;
    box-shadow: rgba(224, 224, 224, 0.425) 0px 30px 60px -12px inset, rgba(206, 206, 206, 0.836) 0px 18px 36px -18px inset;
}

.toggle:hover {
    background-color: #ff8667;
    color: white;
    box-shadow: 0 20px 30px -6px rgba(253, 143, 137, 0.5);
}

/***********************/

.header .navbar {
    width: 100%;
    height: 100%;
    padding-bottom: 7px;
    display: flex;
    justify-content: space-around;
}

.header .navbar .Menutres {
    display: flex;
    justify-content: center;
}

.header .navbar .Menutres span {
    color: #7c7b7b;
    font-size: 35px;
}

.header .navbar .Menutres span:hover {
    color: aquamarine;
}

.header .navbar .Menutres div {
    margin: 10px;
}

.header .logo h1 {
    font-size: 1.8rem;
}


/*--header Menu---*/

.header .menu li {
    opacity: 0.5;
}

.header .menu li.active {
    opacity: 1;
}

.header .menu .material-icons {
    font-size: 30px;
}

.header .menu .dropdown-menu {
    position: absolute;
    left: inherit;
    right: 0;
}

.header .menu label {
    cursor: pointer;
}

#notify_length {
    position: absolute;
    background: red;
    width: 18.5px;
    height: 18.5px;
    text-align: center;
    color: #ECEFF1;
    font-weight: 700;
    top: 24%;
    left: 35%;
    transform: translate(-50%, -50%);
    color: rgb(253, 253, 253);
    font-size: 14px;
    border-radius: 50%;
    padding-bottom: 8px;
}

/*--switch---*/

#theme[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
}

#toggle_inicio {
    cursor: pointer;
    text-indent: -9999px;
    width: 60px;
    height: 34px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
}

#toggle_inicio:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 26px;
    height: 26px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

#theme:checked+#toggle_inicio {
    background: #f70a0a;
}

#theme:checked+#toggle_inicio:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}

#toggle_inicio:active:after {
    width: 52px;
}

/*----------*/

.logo-app-web {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-app-web #font_logo {
    font-size: 24px;
    color: #f74e00;
    font-family: cursive;
    margin-left: 5px;
}

.logo-app-web img {
    width: 45px;
}

.navbar .logo {
    text-decoration: none;
}

/*********anuncios*********/

.header_btn_anuncios {
    transition: all 0.3s ease-in-out;
    font-family: "Dosis", sans-serif;
    width: 100px;
    height: 40px;
    border-radius: 40px;
    background-image: linear-gradient(135deg, #feb692 0%, #ea5455 100%);
    box-shadow: 0 20px 30px -6px rgba(253, 143, 137, 0.5);
    outline: none;
    cursor: pointer;
    border: none;
    font-size: 19px;
    color: white;
}

.header_btn_anuncios:hover {
    transform: translateY(3px);
    box-shadow: none;
}

.header_btn_anuncios:active {
    opacity: 0.5;
}

/*--responsive---*/

@media (max-width: 766px) {
    .header .navbar {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 2px 8px;
    }

    .header .header_encabezado {
        height: 4px;
    }

    .header .navbar a {
        grid-column: 1/3;
    }

    #logo_css {
        font-size: 2em;
        margin-bottom: 2px;
    }

    .menus-header {
        display: flex;
        justify-content: end;

    }

    .header_btn_anuncios {
        width: 65px;
        height: 25px;
        font-size: 12px;
    }

    .toggle {
        margin: 0;
        margin-left: 8px;
        width: 29px;
        height: 29px;
        font-size: 20px;
    }

    .logo-app-web {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .header .menuDos {
        width: 100%;
        justify-content: start;
    }

    .header .menuDos .listaDos .material-icons {
        font-size: 25px;
    }

    .logo-app-web #font_logo {
        font-size: 22px;
        margin: auto 4px;
    }

    .logo-app-web img {
        width: 26px;
    }

    .header .navbar .drackcolor-boton {
        display: flex;
        justify-content: end;
    }

    .header .navbar .Menutres {
        display: flex;
        justify-content: space-between;
        padding: 2px;
    }

    .header .navbar .Menutres div {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        padding: 6px;
        margin: 0px 8px 0 8px;
        justify-content: center;
        align-items: center;
    }

    .header .navbar .Menutres span {
        font-size: 1.6rem;
        color: #7c7b7b;
        border-radius: 50%;
        padding: 4px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    .header .menu {
        position: absolute;
        top: calc(100vh - 60px);
        left: 0;
        width: 100%;
        height: 60px;
        box-shadow: 0 0 10px #ddd;
        z-index: 2;
        background: rgba(255, 255, 255, 0.8);
        border: 1px solid rgb(0, 0, 0, .125);
    }

    .header .menu .dropdown-menu {
        bottom: 100%;
        top: auto;
    }

    .conteinerBus .search_form {
        position: relative;
        width: 98%;
        margin-bottom: 10px;
    }

    .conteinerBus .search_form #search {
        background: #ECEFF1;
        width: 80%;
        left: 37px;
        position: relative;
    }

    .conteinerBus .search_form .users {
        top: 2.5rem;
        right: 1.2rem;
        z-index: 40;
    }


    .conteinerBus .search_form .search_icon {
        top: 50%;
        left: 40%;
    }

    .conteinerBus .search_form .close_search {
        top: -28px;
        left: 70%;
    }

    .conteinerBus .search_form .loading {
        top: 14px;
        left: 70%;
    }

    .header .logo {
        margin: auto;
    }

    .header .logo h1 {
        font-size: 1.5rem;
    }

    /*--switch---*/

    #toggle_inicio {
        width: 42px;
        height: 22px;
    }

    #toggle_inicio:after {
        width: 15px;
        height: 15px;
    }

    #toggle_inicio:active:after {
        width: 38px;
    }

    #notify_length {
        top: 25%;
        left: 15%;
        text-align: center;
    }

    /*----------*/
}


/*-----btn de servicios-----*/
/*
:root{
    --gradient: linear-gradient(to right, #e2336b, #fcac46);
}

 .btn2{
    display: flex;
    align-items: center;
    padding: 7px;
    border-radius: 8px;
    background: var(--gradient);
    margin-right: 0.5rem;
}
 .btn2 i{
    color: hsla(0, 25%, 98%, 0.6);
    margin-right: 10px;
}
*/

/*---suggestesPrueba---*/

.suggestesPrueba {
    position: absolute;
    background: white;
}
.supper-avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: -20px;
  object-fit: cover;
  transform: translate(-50%, 50%);
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.big-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 4px;
}

.medium-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.small-avatar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.portal-avatar {
  width: 100%;
  height: 135px;
  border-radius: 5px;
  margin-bottom: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.avatar-suger {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.portal-avatar_content {
  -webkit-filter: blur(3px);
          filter: blur(3px);
}


@media (max-width: 766px) {
  .portal-avatar {
    margin-bottom: 85px;
    height: 100px;
    object-fit: cover;
    -webkit-filter: brightness(70%);
            filter: brightness(70%);
    image-rendering: crisp-edges;
  }

  .supper-avatar {
    width: 110px;
    height: 110px;
    position: absolute;
    top: -12px;
  }
}
.profile {
    width: 100%;
    min-height: 100vh;
}

/* ------ Info ---------- */

.info {
    width: 100%;
    max-width: 1000px;
    margin: 0px auto;
    margin-bottom: 8px;
    padding: 3px 10px;
}

.informe {
    margin: 18px 0px;
    font-size: 18px;
}

.informe .informe_fullname span {
    color: #566573;
    font-size: 17px;
}

.informe .informe_fullname i {
    font-size: 17px;
    color: #616A6B;
    margin-Right: 4px;
}

.informe .informe_sobrenombre span {
    margin-left: 5px;
}

.informe .informe_sobrenombre small {
    font-size: 16px;
}

.informe .informe_sobrenombre i {
    font-size: 14px;
}

.informe .informacion_user span {
    font-size: 18px;
    margin-left: 5px;
}

.informe .informacion_user i {
    font-size: 14px;
}

.informe .informe_website {
    max-width: 96%;
    overflow: hidden;
    height: 28px;
    white-space: nowrap;
}

.informe .informe_website a {
    font-size: 18px;
}

._info_addres {
    max-width: 96%;
    overflow: hidden;
    height: 28px;
    white-space: nowrap;
}

.informe .informe_history p {
    font-size: 16px;
    margin: 8px auto;
    color: rgb(87, 87, 87);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.informe span {
    color: #566573;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif,
}

.info_container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    position: relative;
    margin: 3px auto;
}

.info_content {
    min-width: 280px;
    max-width: 550px;
    width: 100%;
    flex: 1 1;
    opacity: 0.9;
    margin: 5px 15px;
}

#icon_location_ {
    margin-right: 5px;
    position: relative;
    top: 5px;
}


.info_content .pais-origen {
    display: flex;
    width: 100%;
    color: rgb(112, 111, 111);
    justify-content: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.info_content_title {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    flex-wrap: wrap;
}

.info_content_title h2 {
    font-size: 2rem;
    font-weight: 400;
    transform: translateY(4px);
    flex: 3 1;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: #56585a
}

.info_content_title button {
    width: 116px;
    height: 35px;
    font-size: 12px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    padding: 0;
}

.info_container-img {
    width: 100%;
    margin-bottom: 80px;
}

#nacio_stile {
    display: block;
}

#nacio_stile_none {
    display: none;
}

/*---*/
.veri_orientacion {
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    top: -2px;
    margin-left: 5px;
}


/*--------Profile edit-----------*/
.edit_profile {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100vw;
    height: 100%;
    background: #0008;
    z-index: 90000;
    overflow: auto;
}

.edit_profile form {
    max-width: 450px;
    width: 98%;
    background: white;
    padding: 20px;
    border-radius: 5px;
    margin: 20px auto;
}

.edit_profile .btn_close {
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.edit_profile .info_avatar {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    margin: 15px auto;
    border: 1px solid #ddd;
    cursor: pointer;
}

.edit_profile .info_avatar img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}

.edit_profile .info_avatar span {
    position: absolute;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: 50%;
    text-align: center;
    color: orange;
    transition: 0.3 ease-in-out;
    background: #fff5;
}

.edit_profile .info_avatar:hover span {
    bottom: -15%;
}

.edit_profile .info_avatar #file_up {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}

::-webkit-file-upload-button {
    cursor: pointer;
}

/*-----Follow------*/

.follow {
    position: fixed;
    top: 0;
    left: 0;
    background: #0008;
    width: 100%;
    height: 100vh;
    z-index: 4;
}

.follow_box {
    width: 350px;
    height: 400px;
    border: 1px solid #222;
    border-radius: 5px;
    background: white;
    padding: 20px 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: auto;
}

.follow_box .close {
    position: absolute;
    top: 0;
    right: 3px;
    font-size: 3rem;
    cursor: pointer;
}

/* --------- Save Tab ----------- */
.profile_tab {
    width: 100%;
    display: flex;
    justify-content: center;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.profile_tab button {
    outline: none;
    border: none;
    background: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 5px 20px;
    margin: 0 25px;
    opacity: 0.5;
}

.profile_tab button.active {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    opacity: 0.8;
}

/* --------- botton chat ----------- */

.btn-info {
    margin: 7px;
}

/*-----------------*/

.info_container .follow_btn {
    margin: 5px auto;
    position: relative;
    color: #56585a;
    width: 100%;
    height: 75px;
    display: flex;
}

.follow_btn .follow_btn-segu {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    width: 50%;
    height: 94%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 5px;
    cursor: pointer;
    transition: all 0.8s ease;
    margin: 6px;
}

.follow_btn .follow_btn-segu .mr-seguidor {
    margin: 0px auto;
    font-size: 22px;
    font-weight: 700;
    color: #474747;
}

.follow_btn .follow_btn-segu small {
    color: #8d7777;
}

.follow_btn .follow_btn-segu:hover {
    background: rgba(255, 229, 219, 0.479);
    box-shadow: rgba(255, 161, 124, 0.699) 0px 2px 8px 0px;
}


#verify-user {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    font-size: 22px;
    top: 14%;
    left: 55.5%;
    background: #84effd;
    color: white;
    font-weight: 900;
}

.pruebas_stylos {
    display: none;
}

.pruebas_stylos_dos {
    display: block;
}

.btn_desordenar_datos_home {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    border-radius: 50%;
    border: none;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    font-size: 22px;
    margin-left: 5px;
    color: #7a7979;
}

.status_modal_alert_sus {
    position: fixed;
    top: 200px;
    left: 0;
    background: #0008;
    z-index: 10000;
    width: 100%;
    height: 100%;
    overflow: auto;
}

#alerta-titulo {
    background: rgb(255, 65, 65);
    width: 45%;
    height: 50vh;
    position: absolute;
    top: 25vh;
    left: 25%;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
}

#alerta-titulo h4 {
    font-size: 18px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: whitesmoke;
    font-weight: 500;
}

#alerta-titulo i {
    font-size: 50px;
    margin: 15px 8px;
    color: #ffffff;
}

#alerta-titulo .outline-secondary {
    background: rgb(112, 112, 112);
    margin: 10px;
    border: none;
    border-radius: 8px;
    color: rgb(236, 236, 236);
    padding: 2px 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    font-weight: 600;
}

a .outline-secondary {
    background: rgb(112, 112, 112);
    margin: 10px;
    border: none;
    border-radius: 8px;
    color: rgb(236, 236, 236);
    padding: 2px 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    font-weight: 600;
}

#alerta-titulo .outline-danger {
    background: rgb(66, 43, 43);
    margin: 10px;
    border: none;
    border-radius: 8px;
    color: whitesmoke;
    padding: 2px 10px;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/*
style={{ display: `${user.informacionuno.length == 0 ? 'none' : 'block'}` }}
*/

/*
style={{ display: `${user.mobile.length == 0 ? 'none' : 'block'}` }}
*/

.home-page-btn {
    display: flex;
    justify-content: center;
    padding: 2px 0px;
}

.home-page-btn a {
    width: 25%;
}

.home-page-btn a button {
    width: 100%;
    border: none;
    color: #464646;
    border-left: solid 1px #c7c6c6;
    border-right: solid 1px #c7c6c6;
}

.home-page-btn a button span {
    margin-left: 4px;
}

.home-page-btn a button img {
    width: 20px;
}

#activebuttoP {
    background: rgb(53, 52, 52);
    color: rgb(255, 255, 255);
}

.cantidad_content {
    max-width: 1025px;
    margin: 4px auto;
    display: flex;
    justify-content: space-around;
    transition: all 1s ease;
}

.cantidad_content h4 {
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: 20px;
    transition: all 1s ease;
    background-color: #FDFEFE;
}

.cantidad_content h4:hover {
    background: rgb(250, 240, 255);
    background: linear-gradient(306deg, rgba(250, 240, 255, 1) 18%, rgba(252, 246, 255, 1) 39%, rgba(255, 236, 236, 1) 61%, rgba(255, 234, 234, 1) 83%);
}

.cantidad_content h4 span {
    color: #464646;
    font-size: 20px;
}

.list_pots_container {
    max-width: 1025px;
    margin: 10px auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px 20px;
    gap: 10px 20px;
}

.card_list_potsu {
    background: white;
    overflow: hidden;
    text-align: center;
}

.card_list_potsu span {
    color: rgb(139, 3, 3);
    background: rgba(253, 67, 67, 0.541);
}

.card_list_potsu img,
.card_list_potsu video {
    width: 100%;
    height: 450px;
}


/******************/

#alerta-titulo-telegram {
    position: fixed;
    height: 50px;
    z-index: 1000;
    width: 100%;
    background: rgb(109, 154, 253);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 82px;
}

#alerta-titulo-telegram a {
    text-decoration: none;
}

#alerta-titulo-telegram a span {
    color: #ffffff;
    font-size: 20px;
}

#alerta-titulo-telegram button {
    position: absolute;
    border: none;
    width: 30px;
    height: 30px;
    margin: 5px;
    right: 5px;
    color: #f1f1f1;
    background: rgb(180 200 255 / 85%);
    border-radius: 4px;
}

#btn_LoadMoreBtn {
    max-width: 1025px;
    margin: 4px auto;
    padding: 8px;
    display: flex;
    justify-content: center;
}

#btn_LoadMoreBtn button {
    width: 50%;
    border: none;
    background-color: white;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    transition: all 1s ease;
    border: solid 1px white;
}

#btn_LoadMoreBtn button:hover {
    background-color: rgb(255, 169, 89);
    border: solid 1px rgb(252, 211, 178);
    box-shadow: rgba(255, 187, 147, 0.25) 0px 54px 55px, rgba(255, 141, 88, 0.12) 0px -12px 30px, rgba(250, 106, 49, 0.12) 0px 4px 6px, rgba(253, 79, 35, 0.17) 0px 12px 13px, rgba(255, 65, 8, 0.09) 0px -3px 5px;
}

/******edition date for Public*******/

.content_info_user {
    display: flex;
    flex-direction: column;
}

.content_info_user h2 {
    font-size: 19px;
    color: #474747;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.content_info_public {
    display: flex;
    flex-direction: column;
    padding: 8px 10px;
}

.content_info_public h4 {
    font-size: 15px;
    color: #222;
}

.content_info_public h4 span {
    color: crimson;
}

@media (max-width: 750px) {
    
    #alerta-titulo-telegram {
        height: 42px;
        justify-content: start;
        padding: 0 1px;
    }

    #alerta-titulo-telegram a {
        margin: 4px;
        padding: 4px;
    }

    #alerta-titulo-telegram a span {
        color: #ffffff;
        font-size: 13px;
    }

    #alerta-titulo-telegram button {
        position: relative;
        width: 24px;
        height: 24px;
    }
}

@media (max-width: 750px) {
    .profile_tabNew button {
        font-size: 16px;
        margin: 10px 0px 0px;
        height: 100%;
        width: 100%;
        padding: 2px 0;
    }

    .cantidad_content h4 {

        font-size: 16px;
    }

    .cantidad_content h4 span {
        font-size: 12px;
    }

}

@media (max-width: 750px) {
    .home-page-btn a button {
        font-size: 12px;
    }

    .home-page-btn a button img {
        width: 18px;
    }

}


@media (max-width: 750px) {
    .info_content_title {
        display: flex;
        flex-direction: column;
    }

    .info_content_title .title-perfil {
        position: relative;
        display: flex;
        justify-content: center;
    }

    .info_content_title .title-perfil h2 {
        margin: 2px auto;
        position: relative;
        font-size: 17px;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        color: #56585a;
    }

    .info_container-img {
        margin: 5px;
        height: 142px;
    }

    .edit_profile .btn_close {
        position: fixed;
        z-index: 100;
    }

    #verify-user {
        position: absolute;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        text-align: center;
        font-size: 18px;
        top: 14%;
        left: 61%;
        color: white;
    }

    .info_container .follow_btn {
        margin: 2.5px 0px;
        overflow: hidden;
        position: relative;
        top: 0px;
        color: #56585a;
        width: 100%;
        height: 50px;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .follow_btn .follow_btn-segu {
        display: inline-grid;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        width: 96%;
        height: 90%;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        padding: 5px;
        cursor: pointer;
        margin: 0px 2px;
    }

    .follow_btn .follow_btn-segu .mr-seguidor {
        margin: 0px auto;
        font-size: 16px;
        font-weight: 700;
        color: #474747;
    }

    .follow_btn .follow_btn-segu small {
        color: #8d7777;
        font-size: 10px;
    }

    .informe {
        position: relative;
        font-size: 14px;
    }

    .informe .informacion_user span {
        font-size: 12.5px;
    }

    .informe .informe_fullname span {
        font-size: 11.5px;
    }

    .informe .informe_sobrenombre small {
        font-size: 13px;
    }

    .informe .informacion_user i {
        font-size: 12px;
    }

    .informe .informe_sobrenombre i {
        font-size: 12px;
    }

    .informe .informe_website a {
        font-size: 11.8px;
    }

    .informe .informe_website {
        max-width: 96%;
        overflow: hidden;
        height: 20px;
        white-space: nowrap;
    }

    #icon_location_ {
        margin-right: 5px;
        position: relative;
        top: 4px;
        font-size: 14px;
    }

    ._info_addres {
        max-width: 96%;
        overflow: hidden;
        height: 20px;
        white-space: nowrap;
    }

    .informe .informe_fullname i {
        font-size: 12px;
    }

    .informe .informe_history p {
        margin: 5px auto;
        font-size: 12px;
    }

    .info_content_title button {
        width: 115px;
        height: 30px;
        font-size: 12px;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    }

    .profile_tab button {
        padding: 3px 15px;
        margin: 0 15px;
    }

    #alerta-titulo {
        bottom: 10px;
        left: 10%;
        width: 80%;
        height: 55vh;
    }

    #profiles_btn_perfil button {
        width: 75px;
        font-size: 12px;
        padding: 0;
    }

    .list_pots_container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px 20px;
        gap: 10px 20px;
    }

    .card_list_potsu h4 {
        font-size: 17px;
        color: crimson;
    }

    .card_list_potsu img,
    .card_list_potsu video {
        width: 100%;
        height: 350px;
    }

}
 /*---status----*/

 .home {
     background-color: hsl(228deg 20% 95%);
     max-width: 1350px;
     margin: 0 auto;
     display: flex;
 }

 .status {
     background: white;
     box-shadow: 0 0 5px #ddd;
     padding: 20px;
     border-radius: 5px;
     border: 1px solid rgb(0, 0, 0, .125);

 }

 .status .statusBtn {
     background: #f1f1f1;
     border: none;
     outline: none;
     border-radius: 30px;
     color: rgb(70, 69, 69);
     padding: 0 10px;
     text-align: left;
     margin: 0 10px;
     font-size: 15px;
 }

 .status .statusBtn:hover {
     background: #ddd;
 }

 #statusBtnPubli {
     color: rgb(252, 64, 102);
     font-weight: 600;
     border: solid 1px rgb(255, 209, 218);
 }

 #statusPublicidad {
     background-color: #F7F9F9;
 }

 /*****/
 .home .main_left {
     width: 25%;
     display: flex;
     flex-direction: column;
     position: -webkit-sticky;
     position: sticky;
     align-items: center;
     justify-content: space-around;
     top: 70px;
     height: 90vh;
 }


 .home .main_content {
     width: 55%;
     display: flex;
     flex-direction: column;
     padding: 0px 25px;
 }

 .home .main_rigth {
     width: 30%;
     height: 85vh;
     position: -webkit-sticky;
     position: sticky;
     top: 75px;
     display: flex;
     flex-direction: column;
 }

 .main_rigth h1 {
     font-size: 21px;
     font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
     font-weight: 600;
     letter-spacing: 1px;
     text-align: center;
     margin: 8px auto;
 }

 .main_rigth .main_rigth_sugeVip {
     width: 100%;
     max-height: 100%;
     overflow: hidden;
 }

 .main_rigth_sugeVip_content {
     background-color: rgb(255, 255, 255);
     width: 94%;
     max-height: 90%;
     border-radius: 20px;
     box-shadow: rgba(190, 190, 190, 0.1) 0px 0px 16px;
     padding: 6px;
     margin: 4px auto;
     overflow-y: scroll;
 }

 .main_rigth_sugeVip_content::-webkit-scrollbar {
     display: none;
 }

 .main_rigth_suge_content {
     background-color: rgb(255, 255, 255);
     width: 300px;
     border-radius: 20px;
     box-shadow: rgba(190, 190, 190, 0.1) 0px 0px 16px;
     padding: 6px;
 }

 .main_rigth .main_rigth_suger {
     max-width: 80%;
     margin: 5px auto;
     height: 400px;
 }

 /* ------------Posts ------ */
 .posts .card {
     box-shadow: 0 0 4px #ddd;
 }

 /* --------- Post Card Header --------- */
 .posts .card_header {
     display: flex;
     justify-content: space-between;
     align-items: center;
     cursor: pointer;
     padding: 15px 25px;
 }

 .posts .card_header .card_name {
     transform: translateY(3px);
     margin-left: 8px;
 }

 .card_header .card_name small {
     color: rgb(156, 156, 156);
 }

 .card_name h6 {
     font-family: Arial, Helvetica, sans-serif;
     margin: 0;
     letter-spacing: 1px;
 }

 .posts .card_header .dropdown-menu {
     position: absolute;
     right: 0;
     left: inherit;
 }

 .posts .card_header .dropdown-item {
     display: flex;
     margin: 5px 0;
 }

 /* ---------- Post Card Body ---------- */
 .posts .card_body-content {
     padding: 0 25px;
     margin-top: -10px;
     margin-bottom: 10px;
 }

 .posts .card_body-content .readMore {
     color: crimson;
     cursor: pointer;
 }

 /* ---------- Post Card Footer ---------- */
 .posts .card_icon_menu {
     display: flex;
     justify-content: space-between;
     padding: 0 15px;
     cursor: pointer;
 }


 .card_footer.recomend {
     padding: 5px 8px;
 }

 .posts .card_icon_menu i {
     font-size: 28px;
     margin: 10px;
 }

 .card_header.recomend {
     display: flex;
     justify-content: space-between;
     padding: 8px 10px;
 }

 .card_body-content.recomend {
     padding: 6px 10px;
     margin: 8px auto;
 }

 .title_recomend_user {
     color: #353535;
     font-size: 24px;
     width: 100%;
     text-align: center;
     margin: 8px auto 20px;
 }

 .card_name.recomend h6 {
     font-size: 18px;
     font-weight: 600;
     padding: 8px;
 }

 /***/

 .card_icon_menu.recomend {
     display: flex;
     justify-content: space-between;
     padding: 8px 10px;
     font-size: 22px;
 }

 .card_icon_menu.recomend i {
     margin: 0px 6px;
 }

 .posts .card_icon_menu img {
     width: 28px;
     height: 28px;
     transform: translate(5px, -5px);
     opacity: 0.9;
 }


 /*------------*/

 .ml-0,
 .mx-0 {
     background: #e5e7e9;
 }

 .col-md-4 {
     background: white;
 }

 /*-----------------*/

 .slick-next {
     right: -11px;
 }

 .slick-prev {
     left: -11px;
 }

 /*****Card-Body********/

 .content_cardN {
     width: 100%;
     background-color: white;
     margin: 8px auto;
     border-radius: 5px;
 }

 .fa-solid.fa-ellipsis {
     color: hsl(33, 9%, 75%);
     transition: all 0.8s ease;
     font-size: 20px;
 }

 .fa-solid.fa-ellipsis:hover {
     border-radius: 50%;
     background-color: rgb(160, 160, 160);
     text-align: center;
     padding: 8px;
     color: #555;
 }

 .card_body-content span {
     color: #555;
     font-family: 'Segoe UI', Tahoma, Verdana, sans-serif;
 }

 .content-carousel-img {
     width: 70%;
     margin: 6px auto;
     border-radius: 20px;
 }

 .content-carousel-img img {
     border-radius: 20px;
     object-fit: cover;
 }

 .card_icon_menu i {
     color: rgb(146, 146, 146);
 }

 .card_icon_menu i:hover {
     color: #555;
 }

 .btn_seguir_sugerSe {
     width: 75px;
     border: solid 1.5px rgb(250, 80, 1);
     border-radius: 10px;
     background: none;
     font-size: 12px;
     margin: auto 1px;
     color: rgb(221, 101, 2);
     transition: all 0.5s ease;
 }

 .btn_seguir_sugerSe:hover {
     background-color: rgb(252, 115, 52);
     color: #ddd;
     border: solid 1.5s rgb(235, 0, 0);
 }

 .suggestions_actulizacion .fa-redo {
     color: rgba(204, 204, 204, 0.897);
     margin: 8px;
     cursor: pointer;
 }

 .main_left .custom-btn {
     width: 200px;
     height: 50px;
     color: rgb(255, 255, 255);
     border-radius: 5px;
     padding: 10px 25px;
     font-family: 'Lato', sans-serif;
     font-weight: 500;
     font-size: 20px;
     background: transparent;
     cursor: pointer;
     transition: all 0.3s ease;
     position: relative;
     display: inline-block;
     box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.589),
         7px 7px 20px 0px rgba(0, 0, 0, .1),
         4px 4px 5px 0px rgba(0, 0, 0, .1);
     text-shadow: 1px 1px 2px rgb(61, 61, 61);
     outline: none;
 }

 /* 8 */
 .main_left .btn-8 {
     background-color: #fdba2a;
     background-image: linear-gradient(315deg, #fffaf6 0%, #ff9a60 40%);
     line-height: 42px;
     padding: 0;
     border: none;
 }

 .main_left .btn-8 a {
     color: white;
     text-decoration: none;
 }

 .main_left .btn-8 span {
     position: relative;
     display: block;
     width: 100%;
     height: 100%;
 }

 .main_left .btn-8:before,
 .main_left .btn-8:after {
     position: absolute;
     content: "";
     right: 0;
     bottom: 0;
     background: #ebc097;
     /*box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
                -4px -4px 6px 0 rgba(116, 125, 136, .2), 
      inset -4px -4px 6px 0 rgba(255,255,255,.5),
      inset 4px 4px 6px 0 rgba(116, 125, 136, .3);*/
     transition: all 0.3s ease;
 }

 .main_left .btn-8:before {
     height: 0%;
     width: 2px;
 }

 .main_left .btn-8:after {
     width: 0%;
     height: 2px;
 }

 .main_left .btn-8:hover:before {
     height: 100%;
 }

 .main_left .btn-8:hover:after {
     width: 100%;
 }

 .main_left .btn-8:hover {
     background: transparent;
 }

 .main_left .btn-8 span:hover {
     color: #ebbd97;
 }

 .main_left .btn-8 span:before,
 .main_left .btn-8 span:after {
     position: absolute;
     content: "";
     left: 0;
     top: 0;
     background: #ebc797;
     /*box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
                -4px -4px 6px 0 rgba(116, 125, 136, .2), 
      inset -4px -4px 6px 0 rgba(255,255,255,.5),
      inset 4px 4px 6px 0 rgba(116, 125, 136, .3);*/
     transition: all 0.3s ease;
 }

 .main_left .btn-8 span:before {
     width: 2px;
     height: 0%;
 }

 .main_left .btn-8 span:after {
     height: 2px;
     width: 0%;
 }

 .main_left .btn-8 span:hover:before {
     height: 100%;
 }

 .main_left .btn-8 span:hover:after {
     width: 100%;
 }

 /******container card*******/

 .card-container {
     background-color: #ffffff;
     border-radius: 5px;
     box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.75);
     color: #6d6d6c;
     padding-top: 30px;
     position: relative;
     width: 96%;
     margin: 4px auto;
     text-align: center;
 }

 .card-container p {
     font-size: 13px;
     margin: 10px 4px;
     max-height: 80px;
     overflow: hidden;
 }

 .card-container .pro {
     color: #231E39;
     background-color: #FEBB0B;
     border-radius: 3px;
     font-size: 14px;
     font-weight: bold;
     padding: 3px 7px;
     position: absolute;
     top: 30px;
     left: 30px;
 }

 .card-container .round {
     border: 2px solid #ff7e14;
     border-radius: 50%;
     padding: 7px;
     width: 120px;
     height: 120px;
 }

 button.primary {
     background-color: #ec5700;
     border: 1px solid #ec5700;
     border-radius: 3px;
     color: white;
     font-family: Montserrat, sans-serif;
     font-size: 16px;
     font-weight: 500;
     padding: 4px 10px;
     margin: 0 6px;
     transition: all 0.7s ease;
 }

 button.primary:hover {
     background-color: rgb(121, 121, 121);
     border: none;
 }

 button.primary.ghost {
     background-color: transparent;
     color: #ec5700;
 }

 button.primary.ghost a {
     color: #ec5700;
     text-decoration: none;
 }

 button.primary.ghost:hover {
     background-color: rgb(138, 138, 138);
     border: none;
 }

 .skills {
     background-color: #f0eff5;
     text-align: left;
     padding: 15px;
     margin-top: 30px;
     display: flex;
     flex-direction: column;
 }

 .skills_container_ {
     display: flex;
     justify-content: space-around;
 }

 .skills_container_div {
     display: flex;
     flex-direction: column;
     text-align: center;
     font-weight: 700;
 }

 .main_left_search {
     border: solid 1.5px rgba(151, 151, 151, 0.801);
     width: 100%;
     border-radius: 20px;
     color: #8d8d8d;
     transition: all 0.6s ease;
 }

 .main_left_search a {
     color: #8d8d8d;
 }

 .main_left_search:hover {
     background-color: rgb(255, 187, 131);
     color: white;
     border: 1px solid white;
     box-shadow: 0px 10px 20px -10px rgba(255, 127, 52, 0.75);
 }

 .main_left_search a:hover {
     text-decoration: none;
     color: #C0392B;
 }

 /*-------container_avisos--------*/

 .list_anuncio_container {
     position: relative;
     background: #e5e7e9;
     max-width: 1025px;
     margin: 5px auto;
     padding: 5px;
     display: flex;
     flex-direction: column;
     transition: all 1s;
 }

 .list_anuncio_filter_btn {
     display: flex;
     justify-content: center;
 }

 /*******panel informe de suscripcion*******/

 .panel_informe_suscripsion {
     background: rgb(247, 105, 74);
     background: linear-gradient(90deg, rgba(247, 105, 74, 1) 0%, rgba(244, 161, 82, 0.9500175070028011) 50%, rgba(240, 116, 49, 0.9612219887955182) 100%);
     border-radius: 10px;
     display: flex;
     flex-direction: column;
     width: 100%;
     height: 300px;
     justify-content: center;
     align-items: center;
     text-align: center;
     padding: 10px;
     margin: 8px auto;
     box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
 }

 .panel_informe_suscripsion i {
     font-size: 45px;
     color: rgb(70, 69, 69);
     text-shadow: rgba(138, 138, 138, 0.432) 0.1em 0.1em 0.2em
 }

 .panel_informe_suscripsion h2 {
     color: rgb(54, 54, 54);
     text-align: center;
     font-size: 22px;
     margin: 15px 0;
 }

 .panel_informe_suscripsion span {
     font-size: 18px;
     font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
     color: rgb(75, 71, 71);
     margin: 8px;
     text-shadow: rgba(250, 244, 244, 0.432) 0.1em 0.1em 0.2em
 }

 .panel_informe_suscripsion button {
     margin: 12px;
     border: none;
     padding: 1px 8px;
     border-radius: 5px;
     font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
     color: white;
     background: #f80317;
     box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
 }


 #span_infor_panel {
     color: red;
     font-weight: 600;
     font-size: 20px;
 }

 #activebuttoH {
     background: rgb(49, 49, 49);
     color: rgb(255, 255, 255);
 }

 .scroll-to-top {
     z-index: 1000;
     position: fixed;
     bottom: 13vh;
     right: 8%;
     font-size: 18px;
     background: none;
     border: solid 1.2px rgb(110, 110, 110);
     padding: 6px;
     border-radius: 4px;
     color: rgb(110, 110, 110);
 }

 /*******card_moderador****/

 .cardanuncio_vista_moderador {
     display: flex;
 }

 .cardanuncio_vista_moderador .card_moderador_img {
     width: 25%;
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 5px;
 }

 .cardanuncio_vista_moderador .card_moderador_img img {
     width: 90%;
     height: 90%;
     object-fit: cover;
     border-radius: 10px;
 }

 .cardanuncio_vista_moderador .card_moderador_info {
     width: 75%;
     display: flex;
     flex-direction: column;
 }

 .card_moderador_info_caja1 {
     box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
     padding: 8px;
     text-align: center;
     border-radius: 8px;
     position: relative;
 }

 .card_moderador_info_caja1 .card_moderador_remove {
     position: absolute;
     display: flex;
     justify-content: center;
     align-items: center;
     font-size: 70%;
     left: 85%;
     top: 5px;
     cursor: pointer;
 }

 .card_moderador_info_caja1 .card_moderador_remove span {
     background: rgba(255, 2, 2, 0.904);
     color: white;
     font-family: Arial, Helvetica, sans-serif;
     font-weight: 600;
     padding: 5px;
     border-radius: 5px;
 }

 .card_moderador_info_caja1 h3 {
     font-size: 18px;
     margin: 0;
     color: rgb(230, 70, 30);
 }

 .card_moderador_info_caja1 h4 {
     font-size: 16px;
     letter-spacing: 2px;
     margin: 0;
     color: rgb(59, 57, 55);
     font-weight: 600;
 }

 .card_moderador_info_caja1 span {
     color: rgb(236, 104, 104);
 }

 .card_moderador_info_caja2 {
     display: grid;
     grid-template-columns: 1fr 1fr;
 }

 .card_moderador_info_sub3 {
     box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
     border-radius: 8px;
     margin: 2px 0;
     padding: 3px;
 }

 .card_moderador_info_sub3 h3 {
     font-size: 16px;
     margin: 0;
     font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
 }

 .card_moderador_info_sub4 {
     margin: 5px;
     padding: 8px;
     box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
 }

 .card_moderador_info_sub4 h4 {
     font-size: 15px;
     margin-bottom: 1px;
     font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
 }

 .card_moderador_info_sub4 h4 span {
     color: rgb(44, 44, 44);
     font-weight: 600;
 }

 .card_moderador_info_sub4 p {
     margin: 0px;
 }

 .card_moderador_info_sub3 h3 span {
     color: rgb(250, 92, 92);
     font-size: 16.5px;
 }

 .card_moderador_info_sub2 {
     padding: 5px;
     margin: 5px;
     box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
     border-radius: 8px;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     overflow: auto;
 }

 .card_moderador_info__ {
     width: 100%;
     padding: 4px 8px;
 }

 .card_moderador_info__ .h1default {
     color: #353535;
     background-color: #6d6d6c;
     width: 100%;
     font-size: 21px;
     font-family: 'Times New Roman', Times, serif;
 }

 .card_moderador_info__ .h1activeuno {
     color: #4b4b4b;
     background-color: #ffb95e;
     width: 100%;
     font-size: 21px;
     font-family: 'Times New Roman', Times, serif;
 }

 .card_moderador_info__ .h1activedos {
     color: #353535;
     background-color: crimson;
     width: 100%;
     font-size: 21px;
     font-family: 'Times New Roman', Times, serif;
 }

 .card_moderador_info__ .h2activetres {
     color: #353535;
     background-color: #aeff44;
     width: 100%;
     font-size: 21px;
     font-family: 'Times New Roman', Times, serif;
 }

 .card_moderador_info__ .h2activecuatro {
     color: #353535;
     background-color: #ff6b7e;
     width: 100%;
     font-size: 21px;
     font-family: 'Times New Roman', Times, serif;
 }

 .card_moderador_info__ .h2activecinco {
     color: #353535;
     background-color: #c444ff;
     width: 100%;
     font-size: 21px;
     font-family: 'Times New Roman', Times, serif;
 }

 .card_moderador_info__ .h1default span {
     font-size: 17px;
     color: rgb(77, 77, 77);
     font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
 }

 /*------*/

 #card_anuncios_ {
     padding: 10px;
 }

 .caranuncio_header {
     display: flex;
     justify-content: space-between;
 }

 .btn_anuncio_create {
     max-width: 1025px;
     margin: 20px auto;
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 10px;
 }

 .btn_anuncio_create button {
     margin: 2px 10px;
     width: 25%;
     border-radius: 10px;
     border: none;
     background: white;
     color: rgb(255, 60, 0);
     box-shadow: rgba(228, 190, 190, 0.267) 0px 5px 15px;
 }

 /******contenido del total de usuario*****/
 .content_user_tot_modera {
     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
     background: white;
     border-radius: 25px;
     margin: 4px;
     padding: 5px;
 }

 .content_user_tot_modera .content_user_tot_header {
     position: relative;
 }

 .content_user_tot_modera .content_user_tot_header .content_user_tot_edit {
     position: absolute;
     left: 90%;
     top: 10%;
 }

 .content_user_tot_modera .content_user_tot_header .content_user_tot_edit span {
     background: red;
     padding: 4px 8px;
     border-radius: 8px;
     font-weight: 600;
     color: white;
     cursor: pointer;
 }

 .content_user_tot_modera .content_user_tot_header h4 {
     margin: 4px 4%;
     font-size: 25px;
     color: rgb(70, 68, 68);
 }

 .content_user_tot {
     box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
     display: flex;
     padding: 8px;
     width: 94%;
     margin: 10px auto;
     border-radius: 10px;
     background: white;
 }

 .content_user_tot .user_tot_avatar {
     width: 20%;
     padding: 1px;
     margin: 5px;
     overflow: hidden;
 }

 .content_user_tot .user_tot_avatar img {
     width: 98%;
     height: 98%;
     position: relative;
     border-radius: 14px;
 }

 .content_user_tot .user_tot_info {
     width: 40%;
     box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
     margin: 5px;
     overflow: hidden;
     padding: 8px;
 }

 .content_user_tot .user_tot_info .user_tot_info_span1 {
     background: #AED6F1;
     padding: 2px 8px;
     border-radius: 8px;
     color: rgb(255, 254, 254);
 }

 .content_user_tot .user_tot_info .user_tot_info_span2 {
     background: #E74C3C;
     padding: 2px 8px;
     border-radius: 8px;
     color: rgb(255, 254, 254);
 }

 .content_user_tot .user_tot_info h3 {
     font-size: 19px;
 }

 .content_user_tot .user_tot_info h4 {
     font-size: 16px;
 }

 .content_user_tot .user_tot_info h4 span {
     font-size: 16.5px;
     color: rgb(211, 52, 4);
 }

 .content_user_tot .user_tot_suscrip {
     width: 40%;
     box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
     margin: 5px;
     overflow: hidden;
     padding: 8px;
 }

 .content_user_tot .user_tot_suscrip .span_Activo {
     background: #82E0AA;
     padding: 1px 8px;
     border-radius: 6px;
     color: #C0392B;
 }

 .content_user_tot .user_tot_suscrip .span_Inactivo {
     background: #E74C3C;
     padding: 1px 8px;
     border-radius: 6px;
     color: white;
 }

 .content_user_tot .user_tot_suscrip h3 {
     font-size: 19px;
 }

 .content_user_tot .user_tot_suscrip h4 {
     font-size: 16px;
 }

 .content_user_tot .user_tot_suscrip h4 span {
     font-size: 16.5px;
     color: rgb(211, 52, 4);
 }

 #menu_nav_item {
     display: flex;
     flex-direction: column;
     justify-content: center;
     text-align: center;
 }

 #menu_nav_item .menu_nav_item_smoll {
     font-size: 12px;
 }

 #Menutres_contenido_link {
     display: flex;
     flex-direction: column;
     text-align: center;
     color: rgb(158, 158, 158);
 }

 #Menutres_contenido_link small {
     font-size: 9px;
 }

 /********************/

 .btn-hover_new {
     width: 120px;
     font-size: 14px;
     font-weight: 600;
     color: #fff;
     cursor: pointer;
     margin: 5px;
     height: 40px;
     text-align: center;
     border: none;
     background-size: 300% 100%;
     border-radius: 50px;
     transition: all .4s ease-in-out;
 }

 .btn-hover_new:hover {
     background-position: 100% 0;
     transition: all .4s ease-in-out;
 }

 .btn-hover_new:focus {
     outline: none;
 }

 .btn-hover_new.color-2 {
     background-image: linear-gradient(to right, #f5ce62, #e43603, #fa7199, #e85a19);
     box-shadow: 0 4px 15px 0 rgba(229, 66, 10, 0.75);
 }

 /*****videos tiktok home******/

 .videos_tiktok_container {
     background: white;
     width: 90%;
     margin: 5px auto;
     padding: 5px;
 }

 .videos_tiktok_container_perfil {
     background: white;
     width: 86%;
     margin: 5px auto;
     padding: 5px;
 }

 .videos_tiktok_container_perfil button {
     border: none;
     border-radius: 6px;
     padding: 2px 6px;
     margin: 10px;
     background: crimson;
     color: white;
     font-weight: 500;
     transition: all 0.6s;
 }

 .videos_tiktok_container_perfil button:hover {
     background: rgb(250, 157, 176);
     color: crimson;

 }

 .notpost {
     min-height: 45vh;
     color: crimson;
 }


 /*****Card recomendacion*/

 .recomend_content_user {
    padding: 6px;
    position: relative;
    background-color: #fff;
 }

.content_img_recoment {
    position: absolute;
    z-index: 100;
    margin: 16px;
    display: flex;
}

.content_img_recoment h6 {
    color: #f1f1f1;
    font-size: 18px;
    margin: 8px 12px;
    text-shadow: rgb(24, 24, 23) 1px 0 10px;
}

 .contenet_img_recomend {
    position: relative;
    width: 96%;
    height: 80%;
    object-fit: cover;
    border-radius: 10px;
    margin: 6px auto;
    overflow: hidden;
 }

 .contenet_img_recomend img {
    width: 100%;
    object-fit: cover;
 }

 @media (max-width: 750px) {

     .home {
         flex-direction: column;
     }

     .posts .card_icon_menu i {
         font-size: 23px;
     }

     .home .main_content {
         width: 100%;
         padding: 0 5px;
     }

     .home .main_rigth {
         width: 100%;
         height: auto;
         overflow-y: none;
     }

     .content-carousel-img {
         width: 98%;
     }

     .cardanuncio_vista_moderador {
         flex-direction: column;
     }

     .cardanuncio_vista_moderador .card_moderador_info {
         width: 98%;
         margin: 0 auto;
     }

     .card_moderador_info_caja1 h4 {
         font-size: 13px;
     }

     .card_moderador_info_caja1 h3 {
         font-size: 13px;
     }

     .card_moderador_info_caja1 span {
         font-size: 13px;
     }

     .card_moderador_info_sub3 h3 {
         font-size: 14px;
     }

     .card_moderador_info_sub3 h3 span {
         font-size: 14px;
     }

     .card_moderador_info_sub4 {
         font-size: 13px;
     }

     .card_moderador_info_sub4 h4 {
         font-size: 13px;
     }

     .card_moderador_info_sub4 p {
         font-size: 14px;
     }

     .card_moderador_info_sub2 {
         font-size: 13px;
     }

     .cardanuncio_vista_moderador .card_moderador_img {
         width: 60%;
         margin: 0 auto;
     }

     .card_moderador_info_caja2 {
         grid-template-columns: 1fr;
     }

     .btn_anuncio_create button {
         width: 60%;
         font-size: 15px;
     }

     .card_moderador_info_caja1 .card_moderador_remove span {
         font-size: 9px;
         padding: 2px;
         margin: 2px;
     }

     .content_user_tot {
         flex-direction: column;
     }

     .content_user_tot .user_tot_suscrip h3,
     .content_user_tot .user_tot_info h3 {
         font-size: 15px;
         letter-spacing: 2px;
         font-weight: 600;
     }

     .content_user_tot .user_tot_info,
     .content_user_tot .user_tot_suscrip {
         width: 90%;
         margin: 5px auto;
     }

     .content_user_tot .user_tot_info h4,
     .content_user_tot .user_tot_suscrip h4 {
         font-size: 12px;
     }

     .content_user_tot .user_tot_info h4 span,
     .content_user_tot .user_tot_suscrip h4 span {
         font-size: 15px;
     }

     .content_user_tot_modera .content_user_tot_header .content_user_tot_edit {
         left: 75%;
     }

     .btn-hover_new {
         width: 90px;
         font-size: 11px;
         font-weight: 500;
         margin: 0px;
         height: 25px;
     }

     .home .main_left {
         display: none;
     }

     .panel_informe_suscripsion h2 {
         font-size: 16px;
     }

     .panel_informe_suscripsion span {
         font-size: 13px;
     }

 }
.status_modal{
    position: fixed;
    top: 0;
    left: 0;
    background: #0008;
    z-index: 10000;
    width: 100%;
    height: 100vh;
    overflow: auto;
}

.status_modal form{
    max-width: 450px;
    width: 98%;
    background: white;
    margin: 30px auto;
    padding: 20px;
    border-radius: 5px;
}
.status_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    transform: translateY(-13px);
}
.status_header span{
        font-size: 2rem;
        font-weight: 900;
        cursor: pointer;
        transform: translateY(-5px);
}
.status_body textarea{

    width: 100%;
    min-height: 150px;
    border: none;
    outline: none;
    resize: none;
}
.status_body .input_images{
    display: flex;
    justify-content: center;
    margin: 10px 0;
}
.status_body i{
        font-size: 2rem;
        cursor: pointer;
}
.status_body .input_images .file_upload{
        overflow: hidden;
        margin: 0 10px;
        position: relative;
}
.status_body .input_images .file_upload  #file{
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
}
.status_body .show_images{
    max-height: 270px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    place-items: center;
    padding: 10px 0;
    grid-gap: 10px;
}
.status_body .show_images #file_img{
    position: relative;
    width: 100%;
    height: 100%;
}
.status_body .show_images #file_img img,
.status_body .show_images #file_img video{
    display: block;
    object-fit: contain;
    width: 100%;
    height: 100%;
    max-height: 100px;
}
.status_body .show_images #file_img span{
    position: absolute;
    top: -1px;
    right: -2px;
    z-index: 4;
    background: white;
    color: crimson;
    padding: 3px 7px;
    border: 1px solid crimson;
    border-radius: 50%;
    font-size: 10px;
    font-weight: bolder;
    cursor: pointer;
}
.status_body .stream span{
    position: absolute;
    top: -10px;
    right: 5px;
    color: crimson;
    font-size: 2rem;
    font-weight: 900;
    cursor: pointer;
}
.status_body .dropdown-menu{
    transform: translate3d(-206px, -237px, 0px) !important;
}

.style-picker-status {
    position: absolute;
    z-index: 100;
}





.comment_input{
    display: flex;
    align-items: center;
}
.comment_input input{
    background: #f7f7f7;
    border: none;
    outline: none;
    flex: 1 1;
    overflow: auto;
}
.comment_input .postBtn {
    border: none;
    outline: none;
    background: #f7f7f7;
    color: rgb(255, 38, 0);
    font-weight: 600;
}

.comment_input .postBtn:hover {
    color: rgb(13, 247, 255);
}

.comment_input .dropdown-menu{
    transform: translate3d(-140px, -270px, 0px) !important;
}
/* ---------- Comments ---------- */
.comment_display{
    padding: 10px 25px;
}
.comment_card .comment_content{
    background: #eee;
    padding: 7px;
    border-radius: 10px;
    border-top-left-radius: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.comment_card .comment_content .readMore{
    cursor: pointer;
    color: crimson;
}

.comment_card .comment_content .nav-item{
    cursor: pointer;
    opacity: 1;
    display: block
}
.comment_card .comment_content:hover .nav-item{
    opacity: 1;
}
.comment_card .comment_content .dropdown-menu{
    position: absolute;
    right: 0;
    left: inherit;
}
.comment_card .comment_content .dropdown-item{
    display: flex;
    margin: 10px 0;
}
.comment_card .comment_content textarea{
    width: 100%;
    border: none;
    outline: none;
}

.post_thumb{
    width: 98%;
    max-width: 1025px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 2fr));
    justify-content: center;
    grid-gap: 2px;
    overflow: hidden;
    margin: 10px auto;
    min-height: 350px;
}

.post_thumb_display{
    min-width: 300px;
    height: 450px;
    width: 100%;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.post_thumb_display img,
.post_thumb_display video{
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    border-radius: 3px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.container-fluid .post_thumb_menu{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0008;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: 0.3s;
}
.container-fluid:hover .post_thumb_menu{
    opacity: 1;
}
.container-fluid .post_thumb_menu i{
    font-size: 1.8rem;
    color: white;
    margin: 0 25px;
}

@media (max-width: 766px){
    .post_thumb{
        grid-template-columns: repeat(2, 1fr);
    }
    .post_thumb_display{
        min-width: 100px;
        height: 250px;
        width: 100%;
        position: relative;
        cursor: pointer;
        overflow: hidden;
    }
}


.message {
    width: 100%;
    max-width: 1025px;
    margin: 0px auto;
    height: calc(100vh - 100px);
    border: 1px solid #ddd;
    margin-top: 15px;
    border-radius: 3px;
    background: #fbfbfb;
}

.message_header {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f8f8f8;
    position: relative;
}

#alert_message {
    position: absolute;
}

.message_header input {
    flex: 1 1;
    height: 100%;
    border: none;
    outline: none;
    background: #f8f8f8;
    padding: 0 5px;
}

.message_chat_list {
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: auto;
}

.message_chat_list .message_user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid #f3f3f3;
    color: #555;
    cursor: pointer;
}

.message_chat_list .message_user.active {
    background: #eee;
}

.message_chat_list .message_user a {
    color: #555;
}

.message_chat_list .message_user a:hover {
    text-decoration: none;
}

.message_chat_list .message_user .fa-circle {
    font-size: 14px;
    color: #aaa;
}

.message_chat_list .message_user .fa-circle.active {
    color: forestgreen;
}

/* -------- Chat Input----- */
.chat_input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 6px;
    border-top: 1px solid #ddd;
}

.chat_input input {
    width: 100%;
    height: 49px;
    border: none;
    outline: none;
}

.chat_input .icons_chat_me {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 3px;
    position: relative;
    top: -2px;
    background: white;
}

.chat_input button {
    border: none;
    outline: none;
    background: white;
    margin: 0 8px;
    color: #777;
}

.chat_input .file_upload {
    position: relative;
    margin: 0 5px;
}

.chat_input .file_upload i {
    font-size: 20px;
}

.chat_input .file_upload #file {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.show_media {
    width: 100%;
    height: 70px;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
    place-items: center;
    grid-gap: 10px;
    background: #f3f3f3;
    border-radius: 5px;
    padding: 0 5px;
}

.show_media #file_media {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 70px;
    max-height: 70px;
}

.show_media #file_media img,
.show_media #file_media video {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
}

.show_media #file_media span {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 4;
    background: white;
    border: 1px solid crimson;
    padding: 3px 7px;
    color: crimson;
    border-radius: 50%;
    font-size: 10px;
    font-weight: bold;
    cursor: pointer;
}

/* -------- Chat Container ----- */
.chat_container {
    width: 100%;
    height: calc(100% - 110px);
    overflow-y: auto;
    padding: 0 10px;
    background:radial-gradient(circle at 54.11% 54.9%, #ffffff 0, #ffffff 25%, #ffffff 50%, #fdf9fa 75%, #fcf3f6 100%) ;
    background-repeat: no-repeat;
    background-image: url("https://res.cloudinary.com/geocivil/image/upload/v1677368674/iconos/loguito_dvfpux.png");
    background-position: center;
}

.chat_display {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.chat_row {
    display: grid;
    grid-template-columns: 70%;
    margin-bottom: 10px;
}

.you_message {
    justify-content: end;
    justify-items: end;
}

.other_message {
    justify-content: start;
    justify-items: start;
}

.chat_text {
    padding: 9px 14px;
    margin-bottom: 5px;
}

.chat_time {
    font-size: 13px;
    color: #777;
}

.you_message .chat_text {
    background: hsla(24, 92%, 85%, 0.69);
    color: #252413;
    border: 1px solid hsla(24, 92%, 85%, 0.69);
    border-radius: 14px 14px 0 14px;
}

.other_message .chat_text {
    background: #EAF2F8;
    color: #111;
    border: 1px solid #EAF2F8;
    border-radius: 14px 14px 14px 0;
}

.chat_title {
    margin-bottom: 3px;
}

.you_content {
    position: relative;
}

.you_content .fa-trash {
    position: absolute;
    top: 50%;
    left: -15px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 14px;
    opacity: 0;
}

.you_content:hover .fa-trash {
    opacity: 1;
}

.chat_input .dropdown-menu {
    transform: translate3d(-130px, -300px, 0px) !important;
}


.style-picker {
    position: absolute;
    top: 45%;
    left: 50%;
}

@media (max-width: 750px) {
    .style-picker {
        position: absolute;
        left: 10%;
        top: -680%;
        background: white;
    }
}
.reactions{
    display: grid;
    grid-template-columns: repeat(4, 50px);
    text-align: center;
    cursor: pointer;
    overflow: scroll;
    height: 300px;
}
.reactions span{
    margin: 3px 0;
}
.call_modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
    background: #0008;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.call_box {
    width: 100%;
    max-width: 450px;
    height: 65%;
    /* background: rgb(9, 179, 209);
    box-shadow: 0 0 5px rgb(94, 204, 238);*/
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    padding: 30px 0;
    border-radius: 5px;
    position: relative;
}

.call_box .img_call_avatar {
    width: 96%;
    height: 100%;
    border-radius: 5px;
    position: absolute;
    z-index: -1;
}

.call_box .text-center {
    margin: 25px 0;
    border-radius: 10px;
    overflow: hidden;
    padding: 15px;
}

.call_box h4,
.call_box h6 {
    margin-top: 10px;
    font-size: 25px;
    text-shadow: 1px 1px 2px rgb(87, 87, 87);
}

.call_menu {
    min-width: 280px;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.call_menu button {
    font-size: 2rem;
    background: #eee;
    padding: 15px;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    outline: none;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.call_modal .timer {
    transform: translateY(-15px);
    font-size: 25px;
}

.call_modal .show_video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    pointer-events: none;
}

.call_modal .other_video {
    width: 100%;
    height: 100%;
}

.call_modal .you_video {
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    max-height: 250px;
    border-radius: 5px;
    border: 1px solid crimson;
    z-index: 10;
}

.call_modal .end_call {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: initial;
    font-size: 2rem;
    background: #eee;
    padding: 15px;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    outline: none;
}

.call_modal .time_video {
    position: absolute;
    bottom: 170px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
}
.header-pro {
  min-height: 35px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  background: #f0f1f3;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  padding: 0px;
  border-radius: 5px;
  margin-top: 5px;
}

.header-pro .logo {
  flex: 1 1;
}

.header-pro a {
  text-transform: uppercase;
  color: #555;
}

.header-pro ul {
  margin: 0px;
  padding: 14px;
  font-size: 12px;
}

.header-pro ul li {
  display: inline-block;
  opacity: 0.7;
  padding: 0 10px;
  font-size: 11px;
}

.li-precios-nlc {
  display: none;
}

.header-pro ul li:hover {
  opacity: 1;
}

.menupro {
  display: none;
}

.cart-icon {
  position: relative;
  margin-right: 20px;
}

.cart-icon span {
  background: crimson;
  border-radius: 20px;
  color: white;
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 5px 7px;
  font-size: 10px;
}

@media (max-width:750px) {
  .header-pro ul li {
    display: inline-block;
    opacity: 0.7;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 500;
  }

  .li-precios-nlc {
    border: 1px solid crimson;
    border-radius: 4px;
  }
}
.product_card{
    max-width: 300px;
    overflow: hidden;
    height: 500px;
    padding: 15px;
    box-shadow: 0 0 15px #ddd;
    margin: 10px 0;
    position: relative;
}
.product_card img{
    width: 100%;
    height: 300px;
    display: block;
    object-fit: cover;
}
.product_box h2{
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-transform: capitalize;
    cursor: pointer;
    color: #1b10ac;
}
.product_card span{
    color: crimson;
}
.product_box p{
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    height: 50px;
    overflow: hidden;
    color: #323232;
}
.row_btn{
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}
.row_btn a{
    width: 50%;
    text-align: center;
    text-transform: uppercase;
    color: white;
    font-weight: 600;
    letter-spacing: 2px;
    padding: 6px;
}
#btn_buy{
    background: #555;
    margin-right: 5px;
}
#btn_view{
    background: teal;
    margin-left: 5px;
}
.product_card input{
    position: absolute;
    width: 25px;
    height: 25px;
}


  .container_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
  }
  .btn {
    flex: 1 1 auto;
    margin: 10px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
   /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
   }
  
  /* Demo Stuff End -> */
  
  /* <- Magic Stuff Start */
  
  .btn:hover {
    background-position: right center; /* change the direction of the change here */
  }
  
  .btn-1 {
    background-image: linear-gradient(to right, #f6d365 0%, #fda085 51%, #f6d365 100%);
  }
  
  .btn-2 {
    background-image: linear-gradient(to right, #fbc2eb 0%, #a6c1ee 51%, #fbc2eb 100%);
  }
  
  .btn-3 {
    background-image: linear-gradient(to right, #84fab0 0%, #8fd3f4 51%, #84fab0 100%);
  }
  
  .btn-4 {
    background-image: linear-gradient(to right, #a1c4fd 0%, #c2e9fb 51%, #a1c4fd 100%);
  }
  
  
  
  /* Magic Stuff End -> */
.products{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    justify-items: center;
    margin: 20px 0;
}

/* ----------- Product Item ----------------- */

.delete-all{
    text-align: right;
    margin: 20px;
}
.delete-all input{
    height: 25px;
    width: 25px;
    transform: translateY(5px);
    margin: 0 15px;
}
.delete-all span{
    text-transform: uppercase;
    color: rgb(3, 165, 206);
    letter-spacing: 1.3px;
}
.delete-all button{
    border: 1px solid crimson;
    padding: 10px 25px;
    color: crimson;
    text-transform: uppercase;
}

/* ------------ Filters Menu ----------------- */
.filter_menu{
    width: 100%;
    min-height: 40px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 15px 0;
}
.filter_menu select, input{
    border: 1px solid #ccc;
    outline: none;
    height: 40px;
    padding: 0 5px;
}
.filter_menu input{
    flex: 1 1;
    margin: 0 10px;
}

/* -------------------- Load More -------
.load_more{
    text-align: center;
}
.load_more button{
    padding: 10px 25px;
    margin-bottom: 20px;
    border: 1px solid #555;
    text-transform: capitalize;
}-------- */

/*------ btn category -----*/


.detail-app-main {
  max-width: 1025px;
  margin: 5px auto;
  box-shadow: rgba(83, 83, 83, 0.16) 0px 1px 4px;
}

.detail {
  width: 70%;
  height: 45%;
  border-radius: 15px;
  display: grid;
  margin: 0 auto;
  grid-template-columns: 1.2fr 2fr;
  border: solid 1px blue;
}

/*--------panelPrueba---*/

* {
  outline: none;
  box-sizing: border-box;
}

.book-store {
  width: 100%;
  margin-bottom: 40px;
}

.book {
  background: #fff;
}

.book-cell {
  position: relative;
  display: flex;
  padding: 7px;
  width: 100%;
  height: 215px;
  margin-right: 1px;
}

.book-cell .book-cell-imges {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: rgb(31, 31, 31);
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.book-cell .book-cell-imges img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
}

.book-photo {
  width: 180px;
  flex-shrink: 0;
  bottom: -35px;
  left: 35px;
  border-radius: 2px;
  box-shadow: -2px 6px 19px 0px #7f818e;
  transition: 0.3s ease;
}

.book-photo:hover {
  transform: scale(1.03);
}

.book-img {
  flex-shrink: 0;
  z-index: 2;
}

.book-title {
  text-overflow: ellipsis;
  width: 100%;
}

.book-title h1 {
  color: #fff;
  font-size: 1.5rem;
  position: relative;
  width: 100%;
  text-align: center;
  font-family: 'Lilita One', cursive;
  text-shadow: 0.1em 0.1em 0.2em black
}

.book-title i {
  margin-right: 4px;
}

.book-title span {
  position: absolute;
  font-size: 14px;
  font-family: Chalkduster, fantasy;
  letter-spacing: 2px;
  top: 5%;
  left: 80%;
  background: rgba(255, 21, 4, 0.925);
  border-radius: 5px;
  padding: 6px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  cursor: pointer;
}

.book-author h2 {
  margin: 4px;
  font-size: 15px;
  letter-spacing: 1px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: 'Signika', sans-serif;
  color: #ffe7c7;
  text-shadow: 0.1em 0.1em 0.2em black
}

.book-content {
  padding: 0 10px;
  color: #fff;
  overflow: hidden;
  z-index: 3;
}

fieldset,
label {
  margin: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
}

.rating {
  border: none;
}

.rating>input {
  display: none;
}

.rating>label:before {
  margin-right: 5px;
  margin-top: 10px;
  font-size: 0.9em;
  font-family: FontAwesome;
  display: inline-block;
  content: "\f005";
}

.rating>label {
  color: #fff;
  float: right;
}

.rating>input:checked~label,
.rating:not(:checked)>label:hover,
.rating:not(:checked)>label:hover~label {
  color: #d85d61;
}

.rating>input:checked+label:hover,
.rating>input:checked~label:hover,
.rating>label:hover~input:checked~label,
.rating>input:checked~label:hover~label {
  color: #d85d61;
}

.blue>input:checked~label,
.blue:not(:checked)>label:hover,
.blue:not(:checked)>label:hover~label {
  color: #458997;
}

.blue>input:checked+label:hover,
.blue>input:checked~label:hover,
.blue>label:hover~input:checked~label,
.blue>input:checked~label:hover~label {
  color: #458997;
}

.purple>input:checked~label,
.purple:not(:checked)>label:hover,
.purple:not(:checked)>label:hover~label {
  color: #a76287;
}

.purple>input:checked+label:hover,
.purple>input:checked~label:hover,
.purple>label:hover~input:checked~label,
.purple>input:checked~label:hover~label {
  color: #a76287;
}

.yellow>input:checked~label,
.yellow:not(:checked)>label:hover,
.yellow:not(:checked)>label:hover~label {
  color: #ffad58;
}

.yellow>input:checked+label:hover,
.yellow>input:checked~label:hover,
.yellow>label:hover~input:checked~label,
.yellow>input:checked~label:hover~label {
  color: #ffad58;
}

.dark-purp>input:checked~label,
.dark-purp:not(:checked)>label:hover,
.dark-purp:not(:checked)>label:hover~label {
  color: #905587;
}

.dark-purp>input:checked+label:hover,
.dark-purp>input:checked~label:hover,
.dark-purp>label:hover~input:checked~label,
.dark-purp>input:checked~label:hover~label {
  color: #905587;
}

.book-voters {
  color: #fff;
  vertical-align: sub;
  font-size: 13px;
  margin-left: 7px;
  white-space: nowrap;
  margin-top: 7px;
}

.book-sum {
  margin: 6px 0px;
  max-width: 90%;
  overflow: hidden;
  height: 40%;
}

.book-sum p {
  height: 97%;
  margin: 8px 0px;
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #c4c4c4;
  text-shadow: 0.1em 0.1em 0.2em black
}

.book-see {
  position: absolute;
  padding: 6px;
  width: 80%;
  display: flex;
  justify-content: center;
}

.book-see span {
  color: #414141;
  background: #ebe5e58a;
  margin: 0 8px;
  padding: 6px 10px;
  border-radius: 4px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  font-family: Proxima Nova, system-ui, sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: transform 0.2s;
  /* Animation */
}

.book-see .buttons-panels {
  font-size: 13px;
}

.book-see .buttons-panels a {
  text-decoration: none;
}

.book-see .buttons-panels i {
  margin: 0 5px;
}

.book-see .buttos-panel {
  margin: 0;
  border-radius: 0px;
  letter-spacing: 1px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}

.book-see span:hover {
  transform: scale(1.2);
  background: rgba(250, 42, 5, 0.527);
}

.book-see .buttos-perfil {
  background: rgb(255, 2, 86);
  color: white;
}

.book-see .buttos-seguir {
  background: red;
  color: white;
}

.book-see .buttos-llamar {
  background: rgb(108, 243, 95);
  padding: 6px 12px;
  color: rgb(15, 15, 15);
  border-radius: 20px;
}

.book-see:hover {
  color: #ff6e72;
}

.book-see.book-blue {
  color: #a4e0eb;
}

.book-see.book-blue:hover {
  color: #22cdec;
}

.book-see.book-pink {
  color: #edb9d6;
}

.book-see.book-pink:hover {
  color: #ff6dbe;
}

.book-see.book-yellow {
  color: #fdca95;
}

.book-see.book-yellow:hover {
  color: #fb9124;
}

.book-see.book-purple {
  color: #cbb5e2;
}

.book-see.book-purple:hover {
  color: #a764ec;
}

.main-wrapper {
  width: 100%;
  display: flex;
  flex-grow: 1;
  margin-top: 100px;
  overflow: hidden;
}

.books-of {
  width: 320px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  flex-shrink: 0;
}

.popular-books {
  flex-grow: 1;
  padding: 0 30px 50px;
  overflow-y: auto;
}

.genre {
  font-weight: 500;
  font-size: 15px;
}

.main-menu {
  white-space: nowrap;
  padding-bottom: 15px;
  border-bottom: 1px solid #dcddde;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
  background-color: var(--page-bg-color);
  z-index: 2;
}

.book-types {
  margin-left: auto;
}

.book-types a {
  text-decoration: none;
  color: var(--body-color);
  font-size: 14px;
}

.book-type+.book-type {
  margin-left: 20px;
}

.book-type {
  position: relative;
  transition: 0.2s;
}

.book-type.active,
.book-type:hover {
  -webkit-text-stroke: 0.3px;
}

.book-type.active:before,
.book-type:hover:before {
  content: "";
  position: absolute;
  width: 28px;
  height: 2px;
  bottom: -17px;
  right: 15px;
  background-color: #67d4ea;
  box-shadow: 0px -1px 5px 0px #67d4ea;
}

.book-type:nth-child(2):before {
  right: 12px;
}

.book-type:nth-child(3):before {
  right: 8px;
}

.book-type:nth-child(4):before {
  right: 6px;
}

.book-type:nth-child(5):before {
  right: 20px;
}

.week {
  padding: 0 30px;
}

.author+.author {
  margin-top: 20px;
}

.author-name {
  font-size: 14px;
}

.author:last-child {
  margin-bottom: 40px;
}

.author-title {
  padding: 0 0 20px;
  font-weight: 500;
  font-size: 15px;
}

.author-img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 16px;
  object-fit: cover;
  object-position: center;
}

.year-book-img {
  width: 45px;
  margin-right: 16px;
}

.year-book-name {
  margin-bottom: 15px;
  font-weight: 500;
}

.year-book-author {
  font-size: 13px;
}

.year-book+.year-book {
  margin-top: 20px;
}

.year-book-content {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.overlay {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
  width: 340px;
  flex-shrink: 0;
  background: linear-gradient(to bottom,
      rgba(255, 255, 255, 0) 0%,
      #f2f5f7 65%,
      #f2f5f7 100%);
  height: 60px;
  margin-left: -35px;
}

.book-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  padding-top: 40px;
  position: relative;
}

.book-card {
  margin-top: 20px;
  background-color: #fff;
  height: 270px;
  box-shadow: -1px 3px 8px -1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 0 0 20px 20px;
  color: var(--body-color-light);
}

.book-card-img {
  width: 160px;
  margin-top: -35px;
  border-radius: 2px;
  box-shadow: 0px 1px 7px 2px #c7c9d3;
  border-bottom: 1px solid #dcddde;
  object-fit: cover;
  margin-bottom: 20px;
  transition: 0.3s ease;
}

.book-card-img:hover {
  transform: scale(1.04);
}

.card-content {
  color: var(--body-color);
  padding: 30px;
  overflow: hidden;
  position: relative;
}

.book-name {
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.book-by {
  font-size: 13px;
  color: var(--body-color-light);
  margin-top: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.book-rate>label {
  color: #ccc;
}

.rate {
  display: inline-block;
  white-space: nowrap;
}

.book-rate>input:checked~label,
.book-rate:not(:checked)>label:hover,
.book-rate:not(:checked)>label:hover~label {
  color: #ff9700;
}

.book-rate>input:checked+label:hover,
.book-rate>input:checked~label:hover,
.book-rate>label:hover~input:checked~label,
.book-rate>input:checked~label:hover~label {
  color: #ff9700;
}

.card-vote {
  color: var(--body-color-light);
}

.card-sum {
  color: var(--body-color-light);
  font-size: 13px;
  line-height: 1.6em;
  -webkit-line-clamp: 4;
  margin-top: 15px;
}

.content-wrapper {
  display: flex;
  border-bottom: 1px solid #ebedef;
  position: relative;
}

.content-wrapper:before {
  content: "";
  position: absolute;
  background-color: #8f98a9;
  background-color: #aaaebc;
  box-shadow: 0 -6px 0 0 #aaaebc, 0 6px 0 0 #aaaebc;
  width: 4px;
  height: 4px;
  border: 0;
  padding: 0;
  right: 12px;
  top: 17px;
  border-radius: 50%;
  margin-left: auto;
}

.like-profile {
  margin-top: 8px;
}

.like-profile+.like-profile {
  margin-left: -5px;
}

.like-img {
  border-radius: 50%;
  width: 28px;
  object-fit: cover;
  border: 2px solid #fff;
}

.like-name {
  font-size: 13px;
  margin-left: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.like-name span {
  font-weight: 600;
}

.pruebasdetail .container-descripcion-detail {
  width: 600px;
  margin: 15px auto;
}

.pruebasdetail p {
  color: #626567;
}

.demo-content {
  margin: 10px 0px;
}


@media (max-width: 1103px) {
  .book-cell {
    width: 100%;
    margin: 0 auto;
  }
}

@media (max-width: 765px) {
  .book-cell {
    width: 100%;
  }
  .pruebasdetail .container-descripcion-detail {
    width: 94%;
  }
}

@media (max-width: 575px) {
  .book-cell {
    width: 100%;
  }
}

@media (max-width: 458px) {
  .book-photo {
    width: 180px;
  }

  .book-voters {
    display: none;
  }
}

@media (max-width: 420px) {
  .book-see {
    width: 120px;
    font-size: 13px;
  }

  .book-photo {
    width: 130px;
  }

  .main-wrapper {
    margin-top: 50px;
  }
}

@media (max-width: 360px) {
  .rating>label:before {
    font-size: 0.8em;
  }
}

@media (max-width: 800px) {
  .book-see {
    width: 78%;
    left: 50%;
  }

  .book-see .buttons-panels {
    font-size: 10px;
  }
}

@media (max-width: 1085px) {
  .book-rate>label {
    font-size: 0.7em;
  }
}

@media (max-width: 767px) {
  .book-title {
    display: flex;
    justify-content: start;
  }

  .book-title h1 {
    font-size: 12px;
    margin: 0;
  }

  .book-title span {
    font-size: 10px;
    top: 22%;
    left: 70%;
  }

  .book-content {
    padding: 0 2px;
  }

  .book-cell {
    padding: 5px 4px;
  }

  .book-content .book-author h3 {
    font-size: 10px;
    margin: 1px 0px;
  }

  .book-content .book-sum p {
    font-size: 10px;
  }

  .book-see {
    position: absolute;
    top: 85%;
    left: 0%;
    width: 98%;
    margin: 0;
    padding: 0;
    display: flex;
  }

  .book-see .buttons-panels {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .book-see span {
    font-size: 12px;
    margin: 4px;
  }

  .book-see i {
    margin: 0px 10%;
  }

  .book-see .book-cards {
    grid-template-columns: 1fr;
  }

  .book-types {
    display: none;
  }
}

@media (max-width: 372px) {
  .card-content {
    padding: 20px;
  }
}

/*----------------*/

.box-detail .row {
  display: flex;
}

.box-detail .row-content p {
  font-size: 15px;
}

.detail .img-detail-principal {
  background: red;
  padding: 5px;
}

.detail .img-detail-principal .img-principal {
  width: 75%;
  margin: 2px auto;
  height: 100%;
  background: rgb(117, 233, 123);
  border-radius: 10px;
}

.detail .img-detail-principal img {
  width: 90%;
  height: 90%;
  border-radius: 8px;
}

.detail .box-detail {
  padding: 2px;
  background: green;
}

/*
  .detail {
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    margin: 5px auto;
    border: 1px solid #7a7a7a;
    border-radius: 8px;
  }
  
  .detail .img-detail-principal {
    margin: 2px;
  }
  
  .detail .img-detail-principal img {
    max-width: 400px;
    width: 100%;
    margin: 10px;
    height: 400px;
    border-radius: 8px;
    object-fit: cover;
    display: block;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
  
  .detail .box-detail {
    max-width: 500px;
    width: 100%;
    margin: 15px auto;
    padding: 5px auto;
    border: solid 2px green;
  }
  
  .box-detail .row {
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
  }
  
  .box-detail h2 {
    text-transform: uppercase;
    color: rgb(85, 85, 85);
    letter-spacing: 2px;
    font-weight: 1rem;
    font-size: 1.4rem;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .box-detail p {
    line-height: 0.8;
    margin: 10px 0;
    font-size: 0.7rem;
    color: #797979;
  }
  
  .box-detail .cart {
    background: #333;
    color: white;
    margin-top: 10px;
    padding: 10px 25px;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 2px;
  }*/

/*-------ddetalles/por seccion----*/

.product-details {
  background: #f0f1f5;
  padding: 30px;
  display: grid;
  grid-template-columns: 0.9fr 1.1fr;
  grid-gap: 30px;
  gap: 30px;
}

.product-page-img {
  width: 100%;
  border: 1px solid #eee;
  padding: 5px;
  overflow: hidden;
}

.product-page-img .big-images {
  width: 100%;
  height: 500px;
  overflow: hidden;
  position: relative;
}

.mySlides {
  position: relative;
  height: 100%;
  width: 100%;
}

.mySlides .numbertext {
  color: #f2f2f2;
  text-shadow: 0 0 4px blue;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.mySlides img {
  width: 100%;
  height: 100%;
  display: inline-block;
  object-fit: cover;
}

.product-page-img .prev,
.product-page-img .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  padding: 10px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0 3px 3px 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.product-page-img .next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.product-page-img .prev:hover,
.product-page-img .next:hover {
  background: rgba(0, 0, 0, 0.8);
}

.product-page-img .slider-img {
  width: 100%;
  height: 70px;
  overflow-x: scroll;
  display: flex;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-top: 5px;
}

.product-page-img .slider-img::-webkit-scrollbar {
  display: none;
}

.product-page-img .slider-img .slider-box {
  width: 50%;
  height: 100%;
  min-width: 50px;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid #ddd;
  padding: 2px;
  opacity: 0.6;
}

.product-page-img .slider-img .slider-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: inline-block;
}

.product-page-img .slider-img .slider-box:hover {
  border: 2px solid teal;
  opacity: 1;
}

.product-page-img .slider-img .slider-box.active {
  border: 2px solid crimson;
  opacity: 1;
}

.product-page-details strong {
  color: #333;
  font-size: 1.3rem;
  font-weight: 700;
  text-transform: capitalize;
}

.product-page-details i {
  font-size: 28px;
  margin-left: 15px;
  cursor: pointer;
}

.product-page-details i:hover {
  color: red;
}

.vinculo-web-aviso p {
  margin: 6px 0px;
}

.vinculo-web-aviso i {
  font-size: 14px;
  color: #007bff;
  margin-right: 6px;
}

.product-page-details .product-category {
  font-size: 0.9rem;
  color: #6b6b6b;
  font-weight: 600;
  text-transform: capitalize;
}

.product-page-details .product-price {
  color: #333;
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.product-page-details .product-price del {
  color: #992153;
  font-weight: 400;
  font-size: 0.85rem;
  margin-left: 8px;
}

.product-page-details .small-desc {
  color: #4b4b4b;
  font-size: 1rem;
  background: #f0f1f5;
  font-weight: 400;
  padding: 10px;
  border: 1px solid #f7f7f7;
}

.product-page-details .detail-adition {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  justify-content: space-between;
  font-family: Georgia, 'Times New Roman', Times, serif;
  margin: 8px 0;
  height: 25px;
  font-size: 85%;
  width: 100%;
}

.product-page-details .detail-edad-pais span {
  color: #f8f8f8;
  font-size: 100%;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  background: rgba(0, 0, 0, 0.719);
  margin: 0 6px;
  padding: 8px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  cursor: pointer;
}

.product-page-details .detail-edad-pais .i-locate i {
  font-size: 14px;
}

.i-locate_span_ i {
 text-align: center;
  color: #ffffff;
  font-size: 20px;
  padding: 1px 4px;
}

.i-locate_span_:hover {
  background: #999999;
}

#fa-location-dot {
  margin: 0;
}
.detail-adition .detail-clock-span {
  color: #5c5b5b;
}

.detail-adition .detail-clock-span i {
  font-size: 80%;
  margin-right: 0px;
}

/*-----botones redes- optiones---*/
.nshare {
  display: flex;
  --nshare-spacing: .5rem;
  /* separacion interna */
  padding-top: 0;
  /* separacion superior */
  padding-bottom: 0;
  /* separacion inferior */
  margin: 0px 5px;
}

.nshare span {
  margin: 5px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.nshare-item {
  margin-right: var(--nshare-spacing);
  margin-top: var(--nshare-spacing);
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.nshare-item,
.nshare-item:hover,
.nshare-item:active {
  text-decoration: none;
  color: inherit;
}

.nshare-item {
  transition: background .3s, color .3s;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.nshare-item i {
  transition: transform .3s;
  margin: 0;
}

.nshare .nshare-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.nshare-tb {
  background-color: var(--nshare-tumblr);
}

.nshare-rd {
  background-color: var(--nshare-reddit);
}

.nshare-ws {
  background-color: var(--nshare-whatsapp);
}

.nshare-tlg {
  background-color: var(--nshare-telegram);
}

.nshare-you {
  background-color: var(--nshare-yout);
}

.nshare-tb:hover {
  color: var(--nshare-tumblr);
}

.nshare-rd:hover {
  color: var(--nshare-reddit);
}

.nshare-ws:hover {
  color: var(--nshare-whatsapp);
}

.nshare-tlg:hover {
  color: var(--nshare-telegram);
}

.nshare-you:hover {
  color: var(--nshare-yout);
}

.nshare-item:hover i {
  transform: rotate(360deg);
}

/*}} Fin del core {{*/

/*! Personalización */
/* Contenedor
  -----------------------------------------*/

/* Redes sociales
  -----------------------------------------*/
.nshare {
  --nshare-tumblr: #1B1B1C;
  --nshare-reddit: #FF4500;
  --nshare-whatsapp: #4FCE5D;
  --nshare-telegram: #0088CC;
  --nshare-email: #464EB8;
  --nshare-yout: #8d8d8d;
}

/* Botones
  -----------------------------------------*/
.nshare-item {
  font-size: 18px;
  /* tamaño de iconos */
  width: 35px;
  /* ancho */
  height: 35px;
  /* alto */
  border-radius: .5rem;
  /* bordes redondeados */
  color: #fff;
  /* color */
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .1),
    0 2px 5px -1px rgba(0, 0, 0, .05);
  /* sombra */
}

.nshare-item:hover {
  background-color: #fff;
  /* fondo (hover) */
}


.detailt_descripcort {
  width: 100%;
  color: #383838;
  font-size: 1rem;
  padding: 5px 8px;
  margin: 0;
}

/*----botones redes---responsive-----*/

@media (max-width:750px) {
  .nshare i {
    font-size: 15px;
  }

  .nshare-item {
    width: 30px;
    /* ancho */
    height: 30px;
  }

  .nshare-item {
    font-size: 17px;
  }
  .detail-adition .detail-clock-span {
    color: #5c5b5b;
    font-size: 10px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}

/*------------*/

.product-page-details .product-page-offer {
  display: inline-block;
  color: #fff;
  font-size: 0.8rem;
  padding: 4px 10px;
  border-radius: 10px;
  background: #ec1111;
  margin-top: 10px;
}

.product-page-details .product-page-offer i {
  margin-right: 3px;
  font-size: 12px;
}

.product-page-details .product-page-offer a{
  text-decoration: none;
}


.product-page-details .product-sold {
  margin: 16px 0;
  display: flex;
  align-items: center;
}


.product-page-details .cart-btns {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
}

.product-page-details .cart-btns .add-cart {
  background: rgb(192, 25, 25);
  color: #fff;
  width: 150px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  text-decoration: none;
  transition: all 1s ease;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.product-page-details .cart-btns .add-cart:hover {
  background-color: #ff7f44;
  box-shadow: rgba(255, 173, 106, 0.35) 0px 5px 15px;
}

.product-page-details .cart-btns .add-cart i {
  font-size: 17px;
  margin-right: 5px;
}

.product-page-details .cart-btns .buy-now {
  background: #25d366;
  margin-left: 10px;
  transition: all 1s ease;
}

.product-page-details .cart-btns .buy-now:hover {
  background-color: #ff9e44;
  box-shadow: rgba(255, 203, 106, 0.35) 0px 5px 15px;
}

.product-all-info {
  margin-top: 20px;
}

.product-all-info .product-info-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  list-style: none;
  padding: 8px;
}

.product-all-info .product-info-menu li {
  width: 100%;
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
  padding: 10px 20px;
  border: 1px solid #e9e9e9;
  background: #fff;
  color: #252525;
  letter-spacing: 1px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}

.product-all-info .product-info-menu li.active {
  color: #fff;
  background: #252525;
}

.product-all-info .info-container {
  opacity: 0;
  background: #fff;
  width: 98%;
  height: 0px;
  overflow: hidden;
  transition: 0.5s;
}

.product-all-info .info-container.active {
  margin: auto;
  opacity: 1;
  border: 1px solid #eee;
  padding: 8px;
  height: auto;
}

.product-all-info .info-container p {
  color: #333;
  font-size: 0.9rem;
}

.product-sold i {
  font-size: 20px;
  color: rgb(107, 102, 102);
  margin-right: 5px;
}

/*---------table--------*/
.container-tables-info {
  width: 100%;
  height: 40%;
  padding: 2%;
  margin: 5px 0px;
}

.container-tables-info .pricing-table {
  width: 90%;
  height: 100%;
  margin: 2px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.pricing-table .table-1 {
  width: 80%;
  max-width: 300px;
  max-height: 300px;
  padding: 5%;
  height: auto;
  margin: 5px auto;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 6px;
}

.pricing-table .table-1 .table-title {
  font-size: 1.5rem;
  margin-bottom: 8px;
  color: #4b4b4b;
}

.table-1 .detail-especificos p {
  font-size: 0.9rem;
  color: #535353;
  margin: 8px;
}

.table-1 .detail-especificos span {
  font-size: 0.9rem;
  color: red;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.table-3 .table-etiquetas {
  padding: 4px;
}

.table-3 .table-etiquetas h1 {
  font-size: 1.2rem;
}

.table-3 .table-etiquetas i {
  font-size: 20px;
  margin-right: 6px;
}

/*----tabla2----*/
.styled-table {
  border-collapse: collapse;
  margin: 10px auto;
  font-size: 0.9rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #abadad;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 10px 10px;
  font-family: revert;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #abadad;
}

.styled-table tbody tr.active-row {
  color: #555454;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

/*----------*/

.pricing-table .table-3 {
  width: 100%;
  height: 100%;
}

.pricing-table .table-3 .table-etiquetas h1 {
  font-size: 1em;
  color: #5c5b5b;
  margin-left: 7px;
  margin: 3px 8px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.pricing-table .table-3 .table-etiquetas button {
  margin: 2px;
  border: none;
  border-radius: 6px;
}

.pricing-table .table-3 .table-etiquetas span {
  font-size: 0.9rem;
  color: rgb(252, 74, 74);
}

/*----Mapa-ubicacion-----*/

.seccion-ubicacion-aviso {
  width: 100%;
  height: 50vh;
  padding: 4px;
  margin: 8px 0px;
}

.seccion-ubicacion-aviso h1 {
  font-size: 1.4rem;
  color: #5c5b5b;
  margin-left: 10px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.seccion-ubicacion-aviso .container-mapa-ubicacion {
  width: 98%;
  height: 98%;
  background: gold;
  border-radius: 10px;
  margin: 0px auto;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.pruebasdetail .detalles-particulares {
  margin: 30px 0;
  border: solid 1px rgba(179, 177, 177, 0.637);
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #494a4b;
  font-weight: 600;
}

.pruebasdetail .detalles-particulares span {
  margin: 8px;
  background: rgba(250, 169, 169, 0.411);
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  cursor: pointer;
}

.pruebasdetail .detalles-particulares span a {
  text-decoration: none;
  color: #414141;
}


.relative_anuncios {
  width: 96%;
  margin: 5px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  gap: 10px;
}

#title_anuncio_relative {
  color: rgb(107, 106, 106);
  font-size: 24px;
  position: relative;
  width: 100%;
  text-align: center;
}

/*----responsive------*/

@media (max-width: 1000px) {
  .product-details {
    grid-template-columns: 1fr;
  }

  .pricing-table .table-1 {
    height: 90%;
  }

  .pricing-table .table-1 h1 {
    font-size: 0.8rem;
  }
}

@media (max-width: 724px) {
  .detail {
    width: 96%;
    height: 210px;
  }

  .detail .row h2 {
    font-size: 22px;
    margin: 0;
  }

  .detail .row-content p {
    font-size: 10px;
  }

  .product-details {
    padding: 15px;
  }

  .product-all-info .product-info-menu li {
    font-size: 0.8rem;
  }

  .product-all-info .product-info-menu {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .product-all-info .product-price {
    font-size: 1rem;
  }

  .container-tables-info .pricing-table {
    width: 98%;
    margin: 5px auto;
    display: flex;
    flex-direction: column;
  }

  .container-tables-info .pricing-table .table-1 {
    width: 92%;
    height: 80%;
    min-height: 230px;
  }

  .pricing-table .table-1 .table-title {
    font-size: 16px;
  }

  .table-1 .detail-especificos p {
    font-size: 7px;
  }

  .table-1 .detail-especificos span {
    font-size: 14px;
    font-weight: 600;
  }

  .pricing-table .table-3 {
    grid-column: 1/3;
  }

  .container-tables-info .pricing-table .styled-table {
    font-size: 0.9rem;
    width: 300px;
    margin: 12px auto;
  }

  .pricing-table .table-3 .table-etiquetas span {
    font-size: 0.7rem;
  }

  .seccion-ubicacion-aviso {
    width: 100%;
    height: 30vh;
    min-height: 250px;
  }

  .seccion-ubicacion-aviso h1 {
    font-size: 0.9rem;
    color: #5c5b5b;
    margin-left: 10px;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
  }

  .container-tables-info {
    width: 100%;
    height: 40%;
    padding: 0;
    margin: 5px 0px;
  }

  .pruebasdetail .detalles-particulares span {
    font-size: 10px;
    padding: 3px;
    border-radius: 2px;
  }

  .relative_anuncios {
    grid-template-columns: 1fr 1fr;
  }

  #title_anuncio_relative {
    font-size: 18px;
  }
}

@media (max-width: 450px) {
  .product-page-img .big-images {
    height: 380px;
  }

  .product-all-info .product-info-menu {
    display: grid;
    grid-template-columns: 1fr;
  }

  .product-details .cart-btns {
    flex-direction: column;
    width: 100%;
  }

  .product-details .cart-btns .add-cart {
    width: 100%;
  }

  .product-details .cart-btns .buy-now {
    margin-left: 0;
    margin-top: 10px;
  }
}
.cart{
    position: relative;
    border: 1px solid rgb(230, 219, 219);
    transform: scaleY(0.98);
}
.amount span{
    color: crimson;
    padding: 0 20px;
}
.amount button{
    width: 40px;
    height: 40px;
    border: 1px solid #777;
}
.delete{
    position: absolute;
    top:0;
    right: 5px;
    color: crimson;
    font-weight: 900;
    cursor: pointer;
}
.total{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.total h3{
    color: crimson;
}
.title_save_save {
    max-width: 1025px;
    margin: 5px auto;
    color: crimson;
    text-align: center;
}

.cart-detalles {
    display: grid;
    grid-template-columns: 0.6fr 1fr;
    width: 100%;
    max-width: 1025px;
    margin: 5px auto;
    border-radius: 8px;
    height: 350px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.cart-detalles img {
    width: 98%;
    height: 98%;
    max-width: 250px;
    max-height: 320px;
    border-radius: 5px;
    margin: auto;
    object-fit: cover;
    object-position: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.cart-detalles .box-detail-cart {
    padding: 2px;
    position: relative;
}

.cart-detalles .box-detail-cart h2 {
    font-size: 25px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    position: relative;
    left: 50%;
    width: 230px;
    transform: translate(-50%);
    color: rgb(88, 88, 88);
    margin: 5px 0px;
    text-shadow: rgba(100, 100, 100, 0.801) 0.5px 0 5px;
}

/*
.cart-detalles .box-detail-cart h3 {
    color: rgb(255, 35, 35);
    font-size: 23px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin-left: 5px;
}*/

.cart-detalles .box-detail-cart p {
    font-size: 15px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: rgb(73, 73, 73);
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}

.box-detail-cart .delete-cart {
    position: absolute;
    left: 76%;
    margin-top: 8px;
    color: crimson;
    font-weight: 800;
    cursor: pointer;
}


/*------------*/

.box-detail-cart .info_cont_sav {
    display: flex;
    flex-direction: column;
}

.info_cont_sav .info_cont_tit {
    color: rgb(255, 0, 0);
    margin-right: 5px;
    font-size: 20px;
    text-shadow: 1px 1px 2px rgb(160, 160, 160);
    ;
}

.info_cont_sav h4 {
    color: rgb(104, 104, 104);
    font-size: 13px;
    font-weight: 700;
    margin-left: 5px;
}

.info_cont_sav .info_sav {
    display: flex;
}

#p_info {
    margin: 3px 0px;
    font-size: 15px;
    color: rgb(111, 112, 112);
    font-family: cursive;
}

.info_cont_sav .info_sav h3 {
    color: rgb(255, 109, 72);
    font-weight: 600;
    font-size: 16px;
    text-align: center;
}

.box-detail-cart .btn_info_what {
    position: absolute;
    left: 60%;
    top: 70%;
    display: flex;
    justify-content: space-between;
}

.box-detail-cart .btn_info_what a {
    text-decoration: none;
}

.box-detail-cart .btn_info_what span {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 15px;
    font-size: 25px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.btn_info_what .btn_info_1 {
    color: white;
    background: #42FC32;
}

.btn_info_what .btn_info_1:hover {
    background: white;
    color: #42FC32;
    border: solid 1px #42FC32;
}

.btn_info_what .btn_info_2 {
    color: rgb(253, 253, 253);
    background: #CB4335;
}

@media (max-width:750px) {
    .cart-detalles {
        height: 230px;
        margin: 8px auto;
        width: 96%;
    }

    .cart-detalles img {
        width: 90%;
        height: 76%;
    }

    .cart-detalles .box-detail-cart h2 {
        font-size: 14px;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
    }

    .cart-detalles .box-detail-cart h3 {
        font-size: 13px;
        margin: 3px 0;
    }

    .cart-detalles .box-detail-cart p {
        font-size: 11px;
    }

    .box-detail-cart .delete-cart {
        left: 90%;
    }

    #p_info {
        font-size: 12px;
    }

    .info_cont_sav .info_sav h3 {
        font-size: 10px;
    }

    .info_cont_sav .info_sav h4 {
        font-size: 10px;
    }

    .box-detail-cart .btn_info_what span {
        width: 40px;
        height: 40px;
        margin: 0px 5px;
    }
}
.categories{
    max-width: 700px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 20px 15px;
    padding: 4px;
}
.categories form{
    width: 290px;
    margin-bottom: 20px;
    margin-right: 10px;
}
.categories label{
    display: block;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 10px;
}
.categories input, button{
    height: 35px;
    border: none;
    outline: none;
    border-bottom: 1px solid #555;
}
.categories input{
    width: 210px;
}
.categories button{
    width: 70px;
    background: #555;
    color: white;
    margin-left: 10px;
}
.categories .row{
    min-width: 290px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
}
.create_product{
    width: 100%;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}
.upload{
    max-width: 450px;
    height: 500px;
    width: 100%;
    border: 1px solid #ddd;
    padding: 15px;
    margin: 20px;
    position: relative;
}
#file_up{
    position: relative;
    width: 100%;
    height: 100%;
    outline: none;
}
#file_up::before{
    content: "+";
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    background: white;
    color: rgb(250, 200, 107);
    font-size: 17rem;
    text-align: center;
    cursor: pointer;
    margin: auto;
}
#file_img{
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left: 0;
    background: white;
}
#file_img img{
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
#file_img span{
    position: absolute;
    top: -13px;
    right: -13px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 50%;
    padding: 6px 10px;
    cursor: pointer;
    font-weight: 900;
    color: crimson;
}

.create_product form{
    max-width: 500px;
    min-width: 290px;
    width: 100%;
    margin: 15px 30px;
}
.create_product form .row{
    width: 100%;
    margin: 15px 0;
}
.create_product form input, textarea{
    width: 100%;
    min-height: 40px;
    padding: 0 5px;
}
.create_product form button{
    width: 200px;
    height: 40px;
    background: #555;
    color: white;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 700;
}
/* ----------------- Responsive ------------------ */
@media (max-width: 820px) {
  .header-pro .logo {
    min-width: 115px;
    flex: none;
  }

  .header-pro ul {
    padding: 10px 0;
  }
}

@media (max-width: 720px) {
  .header-pro ul {
    position: fixed;
    top: 0px;
    left: -100%;
    width: 100%;
    height: 60vh;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.98;
    z-index: 99;
    transition: 0.5s ease-in;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  }

  .header-pro .menupro {
    display: block;
    cursor: pointer;
    margin-left: 8px;
  }

  .header-pro ul li {
    margin: 8px 0px;
  }

  .header-pro ul li .menupro {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }

  .header-pro {
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;

  }
}

@media (max-width: 570px) {
  .filter_menu .row span {
    display: none;
  }
}

@media (max-width: 500px) {

  .detail {
    font-size: 100%;
    padding: 0;
  }

  .filter_menu input {
    margin: 0;
  }

  .filter_menu .sort {
    flex: 1 1;
  }

  .filter_menu .sort select {
    width: 100%;
    min-width: 290px;
    margin: 5px 0;
  }

  .login-page {
    padding: 30px 15px;
  }

  .delete-all {
    margin: 20px 0;
  }

  .create_product .upload {
    margin: 20px 0;
  }
}
.header .menuDos {
    display: flex;
    justify-content: end;
    margin-left: 20px;
}

.header .menuDos .listaDos {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0px 6px 0px 6px;
}

.header .menuDos .listaDos small {
    font-size: 9px;
}

.header .menuDos .numeroC {
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 14px;
    background: red;
    width: 20px;
    height: 20px;
    font-weight: 800;
    text-align: center;
    color: rgb(255, 254, 254);
    border-radius: 50%;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
}

.header .menuDos .listaDos .material-icons {
    font-size: 25px;
}


/*--header Menu---*/


.header .menuDos .dropdown-menu {
    position: absolute;
    left: inherit;
    right: 0;
}

.header .menuDos .notify_length {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bold;
    font-size: 10px;
}

@media (max-width: 766px) {
    .header .menuDos {
        margin: 0;
        justify-content: start;
    }
    .header .menuDos .numeroC{
        width: 18px;
        height: 18px;
        top: 2px;
        left: -5px;
    }
}
/*---popop---
.header .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    visibility: hidden;
    opacity: 0;
}

.header .overlay:target {
    visibility: visible;
    opacity: 1;
}

.header .popup {
    margin: 30px auto;
    padding: 10px;
    background: rgb(255, 255, 255);
    border-radius: 5px;
    width: 50%;
    position: relative;
    height: 85%;
}

.header .popup h2 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
}

.header .popup .close {
    position: absolute;
    top: 12px;
    left: 8%;
    transition: all 200ms;
    font-size: 25px;
    font-weight: bold;
    text-decoration: none;
    color: rgb(51, 51, 51);
}

.header .popup .close:hover {
    color: #f10a0a;
}

.header .popup .content {
    height: 90%;
    overflow: auto;
    background: rgb(243, 220, 220);
    padding-left: 15px;
    padding-right: 15px;
}

@media screen and (max-width: 500px) {
    .header .box {
        width: 60%;
    }

    .header .popup {
        width: 90%;
    }

    .header .popup .close {
        position: absolute;
        top: 12px;
        left: 8%;
    }
}*/
.conteinerBus {
    margin: 8px auto;
    max-width: 1025px;
}

.conteinerBus .retun {
    position: absolute;
    left: 0%;
    top: 21%;
}

.conteinerBus .retun .material-icons {
    position: relative;
    z-index: 100;
    cursor: pointer;
}

.header__subtitle {
    font-size: 1.5rem;
    color: #949fb0;
    font-family: 'Yesteryear', cursive;
    font-weight: 500;
    line-height: 1.1;
    margin-left: 20px;
}

.Sugerencias {
    max-width: 1000px;
    background-color: #fff;
    box-shadow: 0 0 5px 0 rgb(199, 199, 199);
    border-radius: 8px;
    margin: 0px auto;
}

.Cardsugerecias {
    padding: 10px;
    display: flex;
    flex-flow: row wrap;
}

.cards {
    margin: 15px;
    width: calc((100% / 4) - 30px);
    transition: all 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    box-shadow: 3px 3px 3px rgb(161, 161, 161);
    border-radius: 6px;
    height: 350px;
    position: relative;
    overflow: hidden;
}

.cards:hover {
    /* IE 9 */
    /* Safari 3-8 */
    transform: scale(1.04);
}

.cards .ml-1 {
    position: absolute;
    bottom: 10px;
    width: 100%;
    padding: 5px;
}

.cards .ml-1 small {
    font-family: 'Kanit', sans-serif;
    color: #f7f7f7;
    font-size: 14px;
}

.cards .ml-1 .d-block {
    font-size: 17px;
    font-family: 'Anton', sans-serif;
    color: white;
    text-shadow: 1px 1px 1px rgba(32, 32, 32, 0.596);
}

.btn-sugger-seguir {
    background: rgb(252, 103, 3);
    border: none;
    border-radius: 4px;
    font-size: 12px;
    padding: 4px 10px;
    font-family:Arial, Helvetica, sans-serif;
    color:white;
    font-family: 800;
}


.btn-sugger-noseguir {
    background: rgb(252, 61, 61);
    border: none;
    border-radius: 4px;
    font-size: 12px;
    padding: 4px 8px;
    font-family:Arial, Helvetica, sans-serif;
    color:white;
    font-family: 800;
}

/*---Header Search---*/

.conteinerBus .search_form {
    position: relative;
    margin-bottom: 20px;
    margin-top: 15px;
    height: 30px;
    width: 90%;
    left: 5%;
    background: rgb(244 246 246);
}

.conteinerBus .search_form #search {
    background: #ECEFF1;
    border: 1px solid #ECEFF1;
    width: 90%;
    outline: none;
    text-indent: 5px;
    height: 33px;
    border-radius: 20px;
    position: relative;
    left: 37px;
    box-shadow: 1px 1px 5px 1px rgb(196, 196, 195);
}

.conteinerBus .search_form #search:hover {
    background-color: rgb(204, 209, 209);
    ;
}

.conteinerBus .search_form .search_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    pointer-events: none;
    z-index: 2;
}

.conteinerBus .search_form .search_icon .material-icons {
    font-size: 14px;
    transform: translateY(3px);
}

.conteinerBus .search_form .close_search {
    position: relative;
    bottom: 100%;
    left: 85%;
    cursor: pointer;
    font-weight: 900;
    color: crimson;
    width: 20px;
}

.conteinerBus .search_form .users {
    position: absolute;
    width: 100%;
    min-width: 250px;
    background: #fafafa;
    max-height: calc(100vh - 150px);
    overflow: auto;
    margin-top: 3px;
    top: 2.5rem;
    right: 0rem;
    z-index: 40;
}

.conteinerBus .search_form .loading {
    position: absolute;
    top: 14px;
    left: 50%;
    width: 15px;
    height: 15px;
    transform: translateY(-50%);
}

.conteinerBus .search_form .button_search {
    width: 29px;
    height: 29px;
    background: #95A5A6;
    border: none;
    color: #332f2f;
    position: absolute;
    border-radius: 50%;
    top: 8%;
    right: 0%;
}

.conteinerBus .search_form #search[type=text]:hover,
.conteinerBus .search_form #search[type=password]:hover {
    border: 1.2px solid #A3E4D7;
}

.conteinerBus .search_form #search[type=text]:focus,
.conteinerBus .search_form #search[type=password]:focus {
    border: 1.2px solid #A3E4D7;
    color: #0f0f0f;
}

@media (max-width: 750px) {
    .cards {
        margin: 15px;
        width: calc((100% / 2) - 30px);
        height: 250px;
    }

    .Sugerencias {
        max-width: 400px;
        background-color: rgb(250, 250, 250);
        box-shadow: 0 0 5px 0 rgb(199, 199, 199, 01.5rem);
        margin: 10px;
    }

    .cards .ml-1 .d-block {
        font-size: 13px;
    }

    .cards .ml-1 small {
        font-size: 12px;
    }

    .header__subtitle {
        font-size: 1rem;
    }

    .conteinerBus .search_form #search:hover {
        background-color: rgb(204, 209, 209);
    }

    .cards:hover {
        /* IE 9 */
        /* Safari 3-8 */
        transform: scale(1.04);
    }
}
.filter_catalogo {
    margin: 5px auto;
    padding: 15px;
    background: #E5E7E9;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    max-width: 1025px;
}

.filter_catalogo select {
    margin: 0 auto;
    background: #E91E63;
    color: #FBFCFC;
    cursor: pointer;
    border-radius: 2px;
}

.filter_catalogo select option {
    background-color: #eeffff;
    color: #424242;
    outline: none;
    cursor: pointer;
    opacity: 0.5;
}

.filter_catalogo .filter_shorp,
.filter_catalogo .filter_temp {
    width: 20%;
}

.filter_catalogo .filter_shorp span,
.filter_catalogo .filter_temp span {
    font-size: 10px;
    background: #E0F7FA;
    border-radius: 5px;
    position: relative;
    padding: 4px;
    color: rgb(124, 2, 2);
    left: 4px;
    top: 5%;
    float: left;
}

.filter_catalogo .filter_shorp select,
.filter_catalogo .filter_temp select {
    height: 100%;
    width: 9rem;
    float: right;
    border: none;
    text-align: center;
}

.filter_catalogo .filter_input {
    width: 60%;
    position: relative;

}

.filter_catalogo .filter_input input:hover {
    border: 1px solid #F8BBD0;
    background-color: #FFEBEE;
    box-shadow: 0 0 20px #FCE4EC;
}

.filter_catalogo .filter_input input {
    width: 100%;
    background: #F5F5F5;
    border-radius: 5px;
}

@media only screen and (max-width: 966px) and (min-width: 760px) {
    .filter_catalogo{
        margin: 6px;
    }
    .filter_catalogo .filter_shorp span,
    .filter_catalogo .filter_temp span {
        display: none;
    }
}

@media (max-width: 760px) {
    .filter_catalogo {
        padding: 10px 10px;
        border-radius: 8px;
        display: block;
        overflow: hidden;
        margin: 6px;
    }

    .filter_catalogo .filter_shorp select {
        position: absolute;
        font-size: 13px;
        left: 5%;
        margin: 2px auto;

    }

    .filter_catalogo .filter_temp select {
        position: absolute;
        font-size: 13px;
        left: 16%;
    }

    .filter_catalogo .filter_shorp span,
    .filter_catalogo .filter_temp span {
        font-size: 9px;
        background: #E0F7FA;
        border-radius: 5px;
        z-index: 100;
        position: relative;
        padding: 0px;
        color: rgb(124, 2, 2);
        left: 0%;
        top: -44%;
        background: none;
    }

    .filter_catalogo .filter_shorp {
        width: 20px;
        height: 10px;
        position: relative;
        float: right;
        width: 45%;
        height: 30px;
        display: flex;
    }

    .filter_catalogo .filter_temp {
        width: 20px;
        height: 10px;
        position: relative;
        float: left;
        height: 30px;
        width: 45%;
        display: flex;
    }

    .filter_catalogo .filter_input {
        width: 100%;
        position: relative;
        margin: 0px 0px 20px 0px;
    }

    .filter_catalogo .filter_input input {
        width: 100%;
    }

}
* {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.catalogo_card {
    height: 420px;
    margin: 1rem;
    background: #FDFEFE;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border-radius: 12px;
    overflow: hidden;
    transition: all 0.2s linear;
    cursor: pointer;
    position: relative;
}

.catalogo_card:hover {
    box-shadow: 2px 8px 20px #0004;
    transform: translate3d(0, -3px, 0);
}

.catalogo_card h2 {
    text-align: center;
    padding: 1rem 0;
    background: linear-gradient(120deg, #fbc2eb 0%, #a6c1ee 100%);
    font-size: 1.1rem;
    color: white;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 0;
    font-family: 'Impact', 'fantasy';
    text-shadow: 1px 1px 2px black;
}

.catalogo_card .spanh1 {
    color: #F0F3F4;
    font-family: 'Jazz LET', 'fantasy';
    font-size: 12px;
}

/*---h2 degradado---*/

.catalogo_card:nth-child(2n) h2 {
    background: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
}

.catalogo_card:nth-child(4n) h2 {
    background: linear-gradient(120deg, #ff9a9e 0%, #fecfef 100%);
}

.catalogo_card:nth-child(5n) h2 {
    background: linear-gradient(120deg, #93b1f1 0%, #44e4f0 100%);
}

.catalogo_card:nth-child(6n) h2 {
    background: linear-gradient(120deg, #ffc3a0 0%, #ffafbd 100%);
}

.catalogo_card:nth-child(7n) h2 {
    background: linear-gradient(120deg, #f7bf9a 0%, #f37f7b);
}

.catalogo_card:nth-child(8n) h2 {
    background: linear-gradient(120deg, #f098dd 0%, #f8c27b 100%);
}

.catalogo_card:nth-child(9n) h2 {
    background: linear-gradient(120deg, 100% #d5db7c 0%, #f8bf23 100%);
}

.catalogo_card:nth-child(10n) h2 {
    background: linear-gradient(120deg, #9be8ff 0%, #7885ff 100%);
}

.catalogo_card:nth-child(11n) h2 {
    background: linear-gradient(120deg, #f098dd 0%, #f8c27b 100%);
}

/*-----------*/

.catalogo_card img {
    display: block;
    width: 100%;
    height: 90%;
    object-fit: cover;
    object-position: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

.catalogo_card .image {
    position: relative;
    bottom: 20%;
    left: 6%;
    display: grid;
    grid-template-columns: [first] 22px [line2] 200px;
    grid-template-rows: [row1-start] 22px [row1-end] 22px;
    grid-gap: 5px;
}

#img_cart_ubication {
width: 70%;
height: 90%;
margin: 2px auto;
}

.catalogo_card .image img {
    width: 100%;
    border-radius: 4px;
    height: 100%;
}

.catalogo_card .image .spanuno {
    font-family: 'Stencil Std', 'fantasy';
    color: #FBFCFC;
    font-size: 15px;
    text-shadow: 1px 1px 2px black;
    font-weight: 600;
}

.catalogo_card .image .spandos {
    font-family: 'Snell Roundhand', cursive;
    color: #D5D8DC;
    font-size: 13.5px;
    text-shadow: 1px 1px 2px black;
}

.catalogo_card .material-icons {
    position: relative;
    top: -98%;
    left: 82%;
    color: #dbdedf;
    font-size: 32px;
}

.catalogo_card .material-icons:hover {
    box-shadow: 2px 8px 20px rgba(230, 228, 228, 0.363);
    color: red;
}

/*----material-hover---*/
[data-md-tooltip] {
    position: relative;
}

[data-md-tooltip]:before {
    content: attr(data-md-tooltip);
    position: absolute;
    bottom: -35px;
    left: 50%;
    padding: 8px;
    transform: translateX(-50%) scale(0);
    transition: transform 0.3s ease-in-out;
    transform-origin: top;
    background: #616161e6;
    color: white;
    border-radius: 2px;
    font-size: 12px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
}

[data-md-tooltip]:hover:before {
    transform: translateX(-50%) scale(1);
}

/*-----btn guradar---------*/

.row_btnCatalogo {
    position: absolute;
    top: 15%;
    left: 74%;
}


/*----------*/

.catalogo_card .botones {
    position: relative;
    top: -98%;
    left: -4%;
}


/*--- Responsive----*/

@media (min-width: 600px) {
    .catalogo_card {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: calc(50% - 2rem)
    }
}

@media (min-width: 900px) {
    .catalogo_card {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: calc(33% - 2rem)
    }
}

@media (max-width: 750px) {

    .catalogo_card h2 {
        text-align: center;
        padding: 0.8rem 0;
        font-size: 13px;
        color: white;
        letter-spacing: 2px;
        margin-bottom: 0;
    }

    .catalogo_card {
        height: 340px;
        margin: 16px 0 16px 0;
    }

    .catalogo_card img {
        width: 100%;
        height: 90%;
        object-fit: cover;
    }

    .catalogo_card .image {
        grid-template-columns: [first] 18px [line2] 200px;
        grid-template-rows: [row1-start] 18px [row1-end] 18px;
        grid-gap: 5px;
    }

    .catalogo_card .image .spanuno {
        font-size: 13px;
    }

    .catalogo_card .image .spandos {
        font-size: 11px;
    }

    .catalogo_card .material-icons {
        top: -98%;
        left: 78%;
        font-size: 28px;
    }

    .catalogo_card .spanh1 {
        font-size: 10px;
    }

    .row_btnCatalogo {
        top: 15%;
        left: 63%;
    }
}
.catalogo {
    -webkit-column-count: 3;
            column-count: 3;
    background-color: #f4f6f6;
    margin: 15px auto;
    max-width: 1025px;
}

.anuncios_vip {
    max-width: 1025px;
    margin: 25px auto;
    padding: 5px;
}

.anuncios_vip h2 {
    color: hsl(24, 98%, 43%);
    font-size: 18px;
    margin-left: 15px;
    text-shadow: 1px 1px 2px rgb(148, 148, 148);
}

.filtro_anuncio_ {
    max-width: 1025px;
    height: 10%;
    margin: 0 auto;
    /*text-shadow: 1px 1px 2px rgb(228, 128, 128);*/
}

.filtro_anuncio_ h2 {
    color: #d35502;
    margin-left: 8px;
    font-size: 22px;
}

.filtro_anuncio_ .Provincias_tags .slick-next:before {
    color: #fd9d5d;
}

.filtro_anuncio_ .Provincias_tags .slick-prev:before {
    color: #fd9d5d;
}

.filtro_anuncio_ .Provincias_tags button {
    width: auto;
    font-size: 18px;
    margin: 2px 6px;
}

.filtro_anuncio_ h3 {
    font-size: 16px;
    margin: 4px 0;
    color: #808B96;
    text-shadow: none;
}

/*---------check filter-----------*/
#btn-menus {
    display: none;
}

.menu-c .abrir-menu {
    display: none;
}

#cerrar-menu {
    display: none;
}

/****nuevo*****/

.btn_filtro_catgori {
    display: flex;
    justify-content: center;
    max-width: 1025px;
    margin: 28px auto;
    padding: 10px 15px;
}

.contentcat {
    border: solid 2px #9c9c9c80;
    padding: 8px 14px;
    margin: 2px 8px;
    border-radius: 8px;
    font-size: 18px;
    color: #767676;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.5s ease;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.contentcat:hover {
    background: rgb(255, 91, 50);
    color: #fff;
    border: solid 1px #fff;
}

.contentcat span {
    margin-left: 6px;
}

#link_btn {
    text-decoration: none;
}

/***btn anunciar***/

.btnp {
    padding: 10px 20px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: #565656;
    font-weight: 600;
    box-shadow: 0 0 20px rgb(117 117 117 / 44%);
    border-radius: 10px;
   }
  
  /* Demo Stuff End -> */
  
  /* <- Magic Stuff Start */
  
  .btnp:hover {
    background-position: right center; /* change the direction of the change here */
  }

  .btn-3p {
    background-image: linear-gradient(to right, #84fab0 0%, #8fd3f4 51%, #84fab0 100%);
  }

  .Provincias_tags .outline_secondary {
    border: solid #808b96e5 1px;
    color: #808b96e5;
    padding: 4px 8px;
    border-radius: 8px;
    transition: all 0.6s ease;
  }

  .Provincias_tags .outline_secondary:hover {
    background-color: #565656;
    color: whitesmoke;
  }

  .directorio_h2 {
    width: 1025px;
    margin: 22px auto;
    padding: 10px 5px;
    color: hsl(24, 98%, 43%);
    font-size: 22px;
    text-shadow: 1px 1px 2px rgb(148, 148, 148);
  }

  @media (max-width: 750px) {
    .btnp {
        padding: 5px 10px;
        text-align: center;
        text-transform: uppercase;
        transition: 0.5s;
        color: #565656;
        font-weight: 600;
        border-radius: 8px;
        font-size: 13px;
       }
  }

@media (max-width: 750px) {
    .btn_filtro_catgori {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 10px auto;
    }

    .contentcat {
        font-size: 13px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 4px 6px;
        margin: 2px 3px;
    }

    .contentcat i {
        font-size: 24px;
        margin: 5px auto;
    }

    .directorio_h2 {
width: 98%;
    }
}


/***************/

@media (max-width: 750px) {
    .catalogo {
        -webkit-column-count: 2;
                column-count: 2;
        margin: 8px;
        margin-top: 15px
    }

    .anuncios_vip {
        width: 90%;
    }

    .anuncios_vip h2 {
        font-size: 15px;
    }

    .filtro_anuncio_ {
        width: 90%;
    }

    .filtro_anuncio_ .Provincias_tags {
        width: 88%;
        margin: 0 auto;
    }

    .filtro_anuncio_ .Provincias_tags button {
        font-size: 12px;
        padding: 0 2px;
        margin: 2.5px;
    }

    /****check box****/
    #btn-menus {
        display: none;
    }

    .filtro_anuncio_ h2 {
        display: none;
    }

    .menu-c .abrir-menu {
        display: flex;
        justify-content: center;
        text-align: center;
        position: fixed;
        top: 120px;
        left: 82%;
        z-index: 100;
        background: rgb(233, 233, 233);
        border-radius: 50%;
        padding: 8px;
        font-size: 17px;
        color: #86746b;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        cursor: pointer;
        overflow: auto;
        overflow: overlay;
    }

    .menu-c .abrir-menu::-webkit-scrollbar {
        display: none;
    }

    .menu-c .abrir-menu:hover::-webkit-scrollbar {
        display: inline;
        display: initial;
    }

    .menu-c .abrir-menu::-webkit-scrollbar-thumb {
        background-color: #09C;
    }

    .menu-c h3 {
        font-size: 12.5px;
    }

    #cerrar-menu {
        position: absolute;
        color: #ff6600;
        font-size: 22px;
        display: block;
        cursor: pointer;
        bottom: 10px;
        right: 5%;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        text-align: center;
        background-color: white;
    }

    .filtro_anuncio_ h3 {
        font-size: 14px;
    }

    .menu-c .Provincias_tags button {
        font-size: 10px;
        padding: 1px 2px;
        margin: 2.5px;
    }

    #menus {
        position: fixed;
        top: 96px;
        left: 0;
        z-index: 100;
        background: rgb(241, 241, 241);
        transition: all 200ms ease-out;
        width: 0%;
        visibility: hidden;
        overflow-y: scroll;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    #btn-menus:checked~#menus {
        width: 100%;
        height: 75vh;
        visibility: visible;
        margin-left: 0;
        padding: 10px;
        top: 79px;
        overflow-y: scroll;
        z-index: 10000;
    }

    ul.boxs-container li label {
        text-shadow: none
    }

    ul.boxs-container2 li label {
        text-shadow: none
    }
}
.home_card_tinder {
	display: flex;
	justify-content: center;
	width: 100%;
}

.home_card_tinder .card_user_interes {
	width: 18%;
}

/*-------filter datos---------*/


.home_card_tinder .card_tinder_filter {
	width: 15%;
	min-width: 200px;
	border: 2.5px solid #CACFD2;
	height: 90%;
	position: -webkit-sticky;
	position: sticky;
	top: 78px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.home_card_tinder .card_tinder_filter h1 {
	margin: 20px 0;
	font-size: 28px;
	font-family: fantasy;
	color: #858585;
	letter-spacing: 0.25em;
	word-spacing: 0.25em;
	text-shadow: 2px 4px 4px rgb(228, 195, 195);
	cursor: pointer;
}

.home_card_tinder .card_tinder_filter span {
	background: rgb(255, 81, 0);
	padding: 5px 30px;
	color: #f8f6f6;
	border: 2px solid rgb(253, 96, 5);
	border-radius: 8px;
	font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
	cursor: pointer;
	transition: 0.5s;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.home_card_tinder .card_tinder_filter span:hover {
	background: rgb(255, 155, 97);
	color: rgb(133, 32, 1);
}

.home_card_tinder .card_tinder_filter a {
	text-decoration: none;
	margin: 25px 0;
}

.home_card_tinder .card_tinder_filter ul {
	display: flex;
	color: #858585;
	flex-direction: column;
	grid-gap: 15px;
	gap: 15px;
	width: 100%;
	position: relative;
}

.home_card_tinder .card_tinder_filter ul li {
	list-style: none;
	position: relative;
	width: 100%;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-size: 20px;
	font-family: cursive;
	position: relative;
	left: -5px;
}

.home_card_tinder .card_tinder_filter ul li:hover {
	color: rgb(255, 95, 2);
}

.home_card_tinder .card_tinder_filter ul li i {
	margin-right: 8px;
}

/****card****/

.home_card_tinder .card_tinder {
	width: 50%;
	height: 3500px;
	/*border: blue solid 1px;*/
}

.home_card_tinder .card_tinder .Card_perfil {
	width: 60%;
	height: 100%;
	background: #f4f6f6;
	margin: 0 auto;
	position: relative;
	display: flex;
	justify-content: center;
}

.app-card_up {
	position: absolute;
	background: #f4f6f6;
	border-radius: 50px;
	width: 90%;
	height: 100%;
	margin-bottom: 15px;
}

/*------tarjeta------*/

.tarjeta .tinder_card_new .img_principalCard {
	width: 80%;
	height: 68vh;
	overflow: hidden;
	margin: 4px auto;
	position: relative;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
	border-radius: 25px;
	margin-bottom: 40px;
}

.tarjeta .tinder_card_new .img_principalCard span {
	position: absolute;
	background: rgb(161, 161, 161);
	border-radius: 8px;
	padding: 2px 10px;
	font-size: 18px;
	margin: 5%;
	color: #fdfdfd;
	font-weight: 700;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.tinder_card_new .img_principalCard img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 25px;
}

.tinder_card_new {
	position: relative;
}

.tinder_card_new .icon_profile_ {
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: center;
	position: absolute;
	top: 62vh;
}

.tinder_card_new .icon_profile_ a {
	text-decoration: none;
}

.tinder_card_new .icon_profile_ .icon_ {
	width: 75px;
	height: 75px;
	margin: 10px;
	background: rgb(223, 223, 223);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 28px;
	/*
	box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
	*/
	transition: 0.5s;
	cursor: pointer;
	border: none;
	color: #505050;
}

.icon_profile_ .icon_:hover {
	transform: scale(1.15);
	background: rgb(255, 81, 0);
	color: whitesmoke;
}

.tinder_card_new .boxtitle {
	/*background: red;*/
	padding: 2px;
}

.boxtitle .boxtitle_inicio {
	display: flex;
	justify-content: space-between;
	padding: 5px;
	margin: 5px 0px;
	/*background: blueviolet;*/
	width: 100%;
	border-radius: 4px;
}

.boxtitle .Sinteres {
	font-size: 19px;
	font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
	color: #7c7c7c;
	margin: 5px 0;
}

.boxtitle .boxtitle_inicio .boxtitle_inicio_title {
	display: flex;
	width: 52%;
	justify-content: start;
	color: #4b4b4b;
	align-items: center;
}

.boxtitle .boxtitle_inicio .boxtitle_inicio_title .span_title {
	font-size: 24px;
	margin-left: 5px;
	font-family: fantasy;
	letter-spacing: 1px;
	text-shadow: 5px 5px 5px rgba(189, 187, 187, 0.836);
}

.boxtitle .boxtitle_inicio .boxtitle_inicio_title .span_edad {
	font-size: 18px;
	font-family: cursive;
}

.boxtitle_inicio .boxtitle_inicio_precio {
	display: flex;
	justify-content: center;
	align-items: center;
}

.boxtitle_inicio .boxtitle_inicio_precio span {
	font-size: 20px;
	color: crimson;
	font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

/*---botones interes---*/

.tarjeta .interes {
	display: flex;
	align-items: center;
	position: relative;
	justify-content: space-between;
	width: 90%;
	margin: 5px auto;
	background: #e9e9e9;
	border-radius: 10px;
	box-shadow: 0px 0px 8px 8px rgba(206, 204, 204, 0.308)
}

.interes .no i {
	color: #f13800;
	font-size: 24px;
}

.interes .star {
	background-color: rgb(14, 240, 44);
	width: 50px;
	height: 50px;
	box-shadow: 0 1px 20px 0 rgba(0, 20, 20, 0.2);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50px;
	margin: 4px 10% 4px 5px;
	transition: 0.2s transform;
	font-size: 30px;
	color: white;
}

.interes .star a {
	color: #f4f6f8;
	font-size: 35px;
}

.interes .heart i {
	color: red;
	font-size: 16px;
}

.interes .heart {
	background-color: rgb(247, 243, 243);
	width: 50px;
	height: 50px;
	box-shadow: 0 1px 20px 0 rgba(0, 20, 20, 0.2);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50px;
	margin: 4px 5px 4px 10%;
	transition: 0.2s transform;
}

.interes .no {
	background-color: #fff;
	width: 50px;
	height: 50px;
	box-shadow: 0 1px 20px 0 rgba(0, 20, 20, 0.2);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50px;
	margin: 4px;
	transition: 0.2s transform;
}

.interes .no:hover,
.interes .star:hover,
.interes .heart:hover {
	transform: scale(1.1);
}

/*------*/

/*--efecto tarBos---*/

.tarjeta .tarBox {
	position: relative;
	width: 98%;
	background: rgb(202, 207, 210);
	font-size: 17px;
	border-radius: 10px;
	padding: 5px;
	margin: 5px auto;
	overflow-y: scroll;
	box-shadow: 0 50px 50px 0 rgb(10, 17, 17);
}

.tarjeta .tarBox::-webkit-scrollbar {
	width: 10px;
	width: var(--scroll-size, 10px);
	height: 10px;
	height: var(--scroll-size, 10px);
}

.tarjeta .tarBox p {
	font-family: 'Bradley Hand', cursive;
	color: rgb(112, 123, 124);
}

.tarjeta .tarBox {
	--scrollbar-width: 0.5px;
	--mask-height: 10px;
	--mask-image-content: linear-gradient(to bottom,
			transparent,
			rgb(252, 19, 19) var(--mask-height),
			rgb(250, 11, 11) calc(100% - var(--mask-height)),
			transparent);
	--mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
	--mask-image-scrollbar: linear-gradient(rgb(175, 172, 172), rgb(241, 178, 178));
	--mask-size-scrollbar: var(--scrollbar-width) 100%;
	-webkit-mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
	        mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
	-webkit-mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
	        mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
	-webkit-mask-repeat: no-repeat, no-repeat;
	        mask-repeat: no-repeat, no-repeat;
}

/*-----------------*/

.boxtitle .boxtitle_content {
	background: #f4f6f6;
	display: flex;
	flex-direction: column;
	padding: 5px;
	margin: 5px auto;
}

.boxtitle .boxtitle_content i {
	font-size: 18px;
	color: #858585;
	margin-right: 5px;
}

.boxtitle .boxtitle_content span {
	font-size: 17px;
	font-family: cursive;
	color: #5a5959;
}

.tinder_card_new .img_secunds {
	width: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.tinder_card_new .img_secunds img {
	width: 98%;
	height: 96%;
	/*object-fit: contain;*/
	margin: 2px auto;
}

/*-----------*/
#alert_card_tinder {
	position: fixed;
	top: 110px;
	z-index: 500;
}

@media (max-width:725px) {
	.home_card_tinder .card_tinder {
		width: 100%;
		margin-bottom: 10px;
		height: 2100px;
	}

	.home_card_tinder .card_user_interes {
		display: none;
	}

	.tarjeta .tinder_card_new .img_principalCard {
		width: 75%;
		height: 60vh;
		margin-bottom: 30px;
	}

	.boxtitle .boxtitle_content span {
		font-size: 15px;
	}

	.tarjeta .tarBox {
		font-size: 15px;
	}

	.boxtitle .Sinteres {
		font-size: 16px;
	}

	.tinder_card_new .icon_profile_ .icon_ {
		width: 56px;
		height: 56px;
		margin: 6px;
	}

	.tinder_card_new .icon_profile_ {
		top: 54vh;
	}

	.boxtitle .boxtitle_inicio .boxtitle_inicio_title {
		width: 55%;
	}

	.boxtitle .boxtitle_inicio .boxtitle_inicio_title .span_title {
		font-size: 20px;
	}

	.boxtitle .boxtitle_inicio .boxtitle_inicio_title .span_edad {
		font-size: 16px;
	}

	.boxtitle_inicio .boxtitle_inicio_precio span {
		font-size: 16px;
	}

	.home_card_tinder .card_tinder_filter {
		display: none;
	}

	.home_card_tinder .card_tinder .Card_perfil {
		width: 100%;
	}

	.app-card_up {
		width: 96%;
		padding: 0px 6px;
	}

	.tinder_card_new .img_secunds {
		width: 100%;
		height: 850px;
		overflow: hidden;
	}

	.tinder_card_new .img_secunds img {
		max-width: 80%;
		max-height: 310px;
		margin: 2px auto;
	}
}
/*@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600&display=swap");*/
/*
:root {
    --body-font: "Manrope", sans-serif;
    --body-color: #b3b3b3;
    --body-bg-color: #f2f2f2;
    --theme-bg-color: #fafafa;
    --border-color: #efefef;
    --logo-color: #f13a2f;
    --main-color: #808080;
    --header-bg-color: #fff;
}*/
/*
.card-app {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

/*---- header ----*/
/*
.headerUx {
    position: sticky;
    height: 75px;
    width: 100%;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    background: rgb(250 250 250);
    align-items: center;
    padding: 0 20px;
    font-size: 15px;
    top: 0px;
    z-index: 100;
    box-shadow: rgba(90, 90, 90, 0.09) 0px 2px 1px, rgba(102, 101, 101, 0.09) 0px 4px 2px, rgba(141, 141, 141, 0.09) 0px 8px 4px, rgba(167, 167, 167, 0.09) 0px 16px 8px, rgba(211, 209, 209, 0.09) 0px 32px 16px;
}

.headerUx .header-left {
    display: flex;
    align-items: center;
    height: 65px;
    width: 30%;
}

.headerUx .header-left svg {
    width: 22px;
    margin-right: 25px;
    flex-shrink: 0;
}

.headerUx .header-left .logo {
    width: 75px;
    height: 34px;
    border: 3px solid var(--logo-color);
}

.headerUx .header-menu {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100;
    width: 20%;
}

.headerUx .user-settings {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.headerUx .user-settings>*+* {
    margin-left: 18px;
}

.headerUx .user-settings svg {
    width: 25px;
    flex-shrink: 0;
}

.headerUx .user-settings .button {
    display: flex;
    background-color: transparent;
    align-items: center;
    border: 2px solid var(--border-color);
    border-radius: 25px;
    color: var(--body-color);
    padding: 8px 16px;
    font-family: var(--body-font);
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
}

.headerUx .user-settings .button svg {
    margin-right: 8px;
    width: 15px;
    fill: var(--body-color);
}*/

/*---------------*/

/*-----Search---------*/
/*
.headerUx .search-bar {
    height: 35px;
    width: 50%;
    position: relative;
}

.headerUx .search-bar input {
    height: 100%;
    width: 100%;
    display: block;
    background-color: transparent;
    padding: 0 10px 0 54px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='18' height='18' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cdefs%3e%3cpath d='M18.5 17h-.79l-.28-.27a6.5 6.5 0 10-.7.7l.27.28v.79l5 4.99L23.49 22l-4.99-5zm-6 0a4.5 4.5 0 11-.01-8.99A4.5 4.5 0 0112.5 17z' id='a'/%3e%3c/defs%3e%3cg transform='translate(-6 -6)' fill='none' fill-rule='evenodd'%3e%3cmask id='b' fill='%23fff'%3e%3cuse xlink:href='%23a'/%3e%3c/mask%3e%3cg mask='url(%23b)' fill='%23D8D8D8'%3e%3cpath d='M3 3h24v24H3z'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 25px 50%;
    color: #7c7c7c;
    border-radius: 8px;
}

.headerUx .search-bar input::placeholder {
    color: var(--body-color);
}*/

/*--------------------*/

/*----user------*/
/*
.user-settings .user-profile {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.user-settings .user-profile+svg {
    width: 14px;
}

.header-menu .notify {
    position: relative;
}

.header-menu .notify:before {
    content: "";
    position: absolute;
    background-color: var(--logo-color);
    width: 5px;
    height: 5px;
    border-radius: 50%;
    right: -6px;
    bottom: 15px;
}

.header-menu .menu-item .active {
    color: var(--logo-color);
}*/

/*------*/
/*
.wrapperUx {
    width: 100%;
    display: flex;
    flex-grow: 1;
    overflow: auto;
    background-color: var(--theme-bg-color);
}

.wrapperUx .left-side {
    width: 13%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex-shrink: 0;
}

.wrapperUx .left-side .side-wrapper svg {
    width: 15px;
    fill: var(--body-color);
    margin-right: 8px;
    flex-shrink: 0;
}*/
/*
.side-wrapper .side-menu .username {
    color: var(--main-color);
    font-size: 15px;
}

.left-side .side-wrapper .side-menu a {
    text-decoration: none;
    font-weight: 300;
    color: var(--main-color);
    display: flex;
    align-items: center;
    font-size: 15px;
    white-space: nowrap;
}

.left-side .side-wrapper .side-menu a:not(:last-child) {
    margin-bottom: 16px;
    margin-top: 8px;
}

.side-wrapper .side-menu .side-title {
    font-size: 16px;
    color: rgb(245, 113, 24);
    margin-top: 15px;
    margin-bottom: 10px;
}

.left-side .side-wrapper .side-menu {
    padding: 13px;
}

.side-wrapper .side-menu .user {
    display: flex;
    align-items: center;
}

.side-wrapper .side-menu .user+.user {
    margin-top: 16px;
}

.side-menu .user .user-img {
    border-radius: 50%;
    width: 26px;
    height: 26px;
    margin-right: 8px;
    object-fit: cover;
    object-position: center;
}

.wrapperUx .left-side::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
}

.wrapperUx .left-side::-webkit-scrollbar-thumb:hover {
    background: #f0b8b8;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.2);
}

.wrapperUx .main-container {
    flex-grow: 1;
    overflow: auto;
    width: 68%;
    padding: 0px 26px;
}

.wrapperUx .main-container::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
}

.wrapperUx .main-container::-webkit-scrollbar-thumb:hover {
    background: #f0b8b8;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.2);
}*/

/*----se usa---*/
.main-container .profile {
    height: 45vh;
    max-height: 350px;
    min-height: 270px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    border-radius: 50px;
}

.main-container .profile img {
    max-width: 100%;
}

.main-container .profile .profile-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
}

/*
.main-container .profile .profile-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--header-bg-color);
    width: 100%;
    box-shadow: -1px 3px 8px -1px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 10px 10px;
    padding: 0 25px;
    z-index: 2;
}*/
/*
.main-container .profile .profile-menu .profile-img {
    border-radius: 50%;
    width: 42px;
    height: 42px;
    object-fit: cover;
    margin-right: 15px;
}*/
/*
.main-container .profile .profile-menu .profile-avatar {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}*/
/*
.profile-info {
    justify-content: center;
    display: flex;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    z-index: 2;
}

.profile-item {
    display: flex;
    align-items: center;
    color: #fff;
    padding: 26px 16px;
    white-space: nowrap;
}

.profile-item svg {
    width: 22px;
    margin-right: 10px;
}*/
/*
.profile-contact-info {
    position: absolute;
    display: flex;
    right: 10px;
    top: 20px;
    color: #fff;
}

.profile-contact-info svg {
    width: 18px;
}*/
/*
.profile-contact {
    border: 1px solid rgba(239, 239, 239, 0.3);
    padding: 16px;
    margin-left: 10px;
    border-radius: 50%;
    cursor: pointer;
}*/
/*
.follow-buttons {
    display: flex;
}*/

.main-container .profile .follow {
    border: 2px solid var(--border-color);
    border-radius: 25px 0 0 25px;
    color: var(--body-color);
    padding: 8px 16px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    background-color: transparent;
}

.main-container .profile .follow:hover {
    background-color: var(--header-bg-color);
}

.main-container .profile .follow-option {
    border-radius: 0 25px 25px 0;
    margin-left: -2px;
}


/*******header inicio*********/
#navMenu {
    color: #ff5c40;
    background: rgb(235, 235, 235);
    cursor: pointer;
    padding: 8px;
    font-size: 20px;
    border-radius: 6px;
    transition: all 1s ease;
}

#navMenu:hover {
    background: #ff5c40;
    color: rgb(235, 235, 235);
}

#publicar_auncios_btn {
    background-color: crimson;
    color: white;
    padding: 2px 4px;
    border-radius: 4px;
    transition: all 1s ease;
}

#publicar_auncios_btn i{
    color: white;
}

#publicar_auncios_btn:hover {
    background-color: rgb(255, 155, 88);
    color: #595959;
}

.menu_opcion {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #e4e4e4;
    width: 20%;
    margin: 8px;
    padding: 8px;
    border-radius: 8px;
}

.menu_opcion a {
    text-decoration: none;
    color: #fd6208;
    margin: 6px;
    font-size: 16px;
    font-weight: 600;
}

.menu_opcion a:hover {
    color: rgb(204, 0, 51);
    text-decoration: none;
}

.menu_opcion a i {
    margin-right: 2px;
    font-size: 12px;
    color: #ff4208;
}

@media (max-width: 750px) {
    #navMenu {
        display: none;
    }
}

/*
.trends {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 45px;
}

.trends a {
    text-decoration: none;
    color: var(--body-color);
    display: flex;
    align-items: center;
    font-size: 15px;
    white-space: nowrap;
}

.trends svg {
    width: 20px;
    margin-right: 16px;
}*/
/*
.follow-option .active {
    background-color: var(--header-bg-color);
    margin-left: -3px;
}

.play-all {
    background-color: var(--logo-color);
    color: #fff;
    padding: 10px 20px;
    border-radius: 30px;
    display: flex;
    align-items: center;
}

.play-all svg {
    width: 12px;
    flex-shrink: 0;
    margin-right: 8px;
}*/

/*--------------*/
.main-container .card-principal {
    margin: 30px 60px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 25px;
    grid-auto-rows: minmax(380px, auto);
}

.load-more {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    cursor: pointer;
    color: rgb(255, 81, 0);
    font-size: 16px;
    font-family: sans-serif;
}

.load-more button {
    width: 9em;
    height: 3em;
    border-radius: 30em;
    font-size: 15px;
    font-family: inherit;
    border: none;
    position: relative;
    overflow: hidden;
    z-index: 1;
    box-shadow: 6px 6px 12px #c5c5c5,
                -6px -6px 12px #ffffff;
}

.load-more button::before {
    content: '';
    width: 0;
    height: 3em;
    border-radius: 30em;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(to right, #d8980f 0%, #f97047 100%);
    transition: .5s ease;
    display: block;
    z-index: -1;
   }

   .load-more button:hover::before {
    width: 9em;
   }
/*
@keyframes load {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}*/

.footer-row .language {
    margin-bottom: 8px;
    background: rgb(241, 236, 236);
    color: #f76538;
    width: 110px;
    height: 40px;
    border-radius: 8px;
    text-decoration: none;
    border: none;
    font-size: 13px;
    transition: all 0.2s;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.footer-row .language:hover {
    background: rgb(236, 208, 200);
}

.footer-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-links a {
    text-decoration: none;
    color: rgb(129, 129, 129);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.footer-links a+a {
    margin-left: 8px;
}

.footer-links {
    display: flex;
}

.link-footer a {
    color: rgb(124, 122, 122);
    font-size: 14px;
}

.footer {
    padding: 30px 0;
}

.footer-last {
    border-top: 1px solid var(--border-color);
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #595959;
}

.social-media {
    display: flex;
    align-items: center;
}

.social-media svg {
    width: 100%;
}

.social-media a {
    border-radius: 50%;
    color: var(--body-color);
    padding: 8px;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 12px;
}

.policy a {
    text-decoration: none;
    color: var(--body-color);
}

/***revisar*-**/
@media (max-width: 1120px) {
    .footer-row {
        flex-direction: column;
    }

    .footer-row+.footer-row {
        margin-top: 10px;
    }

    .footer-row .button {
        display: none;
    }
}
/**************/

@media (max-width: 900px) {
    .play-all {
        color: transparent;
        white-space: nowrap;
        width: 30px;
        padding: 0;
        fill: #fff;
        height: 30px;
        position: relative;
    }

    /*.profile-item {
        padding: 20px 10px;
    }*/

    .play-all svg {
        position: absolute;
        left: 58%;
        top: 50%;
        transform: translate(-50%, -50%);
        fill: #fff;
    }
}

@media (max-width: 840px) {
    /*.profile-contact {
        padding: 6px;
    }*/

    .profile-item,
    .profile-avatar {
        flex-direction: column;
    }

  /*  .profile-item svg {
        margin-right: 0;
    }*/

    /*.profile-item {
        text-align: center;
    }*/

    /*.profile-img {
        margin-right: 0;
        margin-top: 10px;
    }*/

    /*.profile-menu {
        flex-direction: column;
    }*/

    .menu-items {
        order: 1;
    }
}

/******este es la falla por profile***/
@media (max-width: 980px) {
    .main-container .card-principal {
        margin: 5px 20px;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 15px;
        grid-row-gap: 15px;
        grid-auto-rows: minmax(280px, auto);
    }
/*
    .profile {
        min-height: 380px;
        max-height: 380px;
    }*/
}

@media (max-width: 800px) {
    .main-container .card-principal {
        grid-auto-rows: minmax(250px, auto);
    }
/*
    .trends .follow-buttons {
        display: none;
    }*/
}

@media (max-width: 750px) {

    /*.main-container .card-principal {
        margin: 5px 20px;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 15px;
        grid-row-gap: 15px;
        grid-auto-rows: minmax(280px, auto);
    }*/
    .main-container .card-principal {
        margin: 12px 0px;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 12px;
        grid-row-gap: 12px;
        grid-auto-rows: minmax(290px, auto);
    }

    .left-side {
        display: none;
    }

    .header-menu {
        display: none;
    }

    .search-bar input {
        max-width: 140px;
    }

    .user-settings button {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        padding: 0;
        position: relative;
        color: transparent;
    }

    .user-settings button svg {
        margin-right: 0;
        position: absolute;
        display: block;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
/*
@media (max-width: 440px) {

    .user-settings svg {
        display: none;
    }

    .main-container .card-principal {
        grid-template-columns: 1fr 1fr;
    }
}*/
/*
.dark-light {
    position: fixed;
    bottom: 30px;
    right: 30px;
    background-color: var(--header-bg-color);
    box-shadow: -1px 3px 8px -1px rgba(0, 0, 0, 0.2);
    padding: 8px;
    border-radius: 50%;
    z-index: 3;
}

.dark-light svg {
    width: 28px;
}

.dark-mode .dark-light svg {
    fill: #ffce45;
    stroke: #ffce45;
}

.dark-light svg {
    fill: transparent;
    transition: 0.5s;
}*/
/*
@media (max-width: 475px) {
    .footer-links {
        flex-direction: column;
    }

    .footer-links a+a {
        margin-left: 0;
    }

    .footer-row:last-child {
        align-items: flex-end;
    }

    .footer-row {
        align-items: flex-start;
    }

    .footer {
        display: flex;
        justify-content: space-between;
    }

    .footer-links a {
        margin-bottom: 5px;
    }

    .policy {
        display: none;
    }

    .dark-light {
        bottom: 60px;
    }
}*/

/*----- right-----*/
/*
.wrapperUx .right-side {
    width: 20%;
    padding: 1%;
    overflow: auto;
    flex-direction: column;
}

.wrapperUx .right-side img {
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 0 20px rgb(145, 144, 143);
    margin-top: 8px;
}

.right-side .right-coments .comment-main-level {
    display: flex;
    margin-top: 12px;
}*/

/**
* Avatar
---------------------------*/
/*
.comment-main-level .comment-avatar {
    width: 15%;
    height: 40px;
    position: relative;
    z-index: 99;
    float: left;
    border: 3px solid #FFF;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}

.comment-main-level .comment-box {
    width: 80%;
    float: right;
    position: relative;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
    left: 5%;
}

.comment-main-level .comment-box:before,
.comment-main-level .comment-box:after {
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    display: block;
    border-width: 10px 12px 10px 0;
    border-style: solid;
    border-color: transparent #FCFCFC;
    top: 8px;
    left: -11px;
}

.comment-main-level .comment-box:before {
    border-width: 11px 13px 11px 0;
    border-color: transparent rgba(0, 0, 0, 0.05);
    left: -12px;
}*/

/*
.comment-box .comment-head {
    background: #FCFCFC;
    padding: 6px 8px;
    border-bottom: 1px solid #E5E5E5;
    overflow: hidden;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
}

.comment-box .comment-head i {
    float: right;
    margin-left: 14px;
    position: relative;
    top: 2px;
    color: #A6A6A6;
    cursor: pointer;
    -webkit-transition: color 0.3s ease;
    -o-transition: color 0.3s ease;
    transition: color 0.3s ease;
}

.comment-box .comment-head i:hover {
    color: #03658c;
}

.comment-box .comment-name {
    color: #283035;
    font-size: 12px;
    font-weight: 700;
    float: left;
    margin-right: 5px;
}

.comment-box .comment-name a {
    color: #283035;
}

.comment-box .comment-head span {
    float: left;
    color: #999;
    font-size: 10px;
    position: relative;
    top: 1px;
}

.comment-box .comment-content {
    background: #FFF;
    padding: 8px;
    font-size: 10px;
    color: #595959;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
}*/


/*----------*/
/*


.wrapperUx .right-side::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
}

.wrapperUx .right-side::-webkit-scrollbar-thumb:hover {
    background: #f0b8b8;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.2);
}*/

/*---------*/

.card-principal .card-secun {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    box-shadow: 0px 0px 4px 1px rgba(163, 161, 161, 0.5);
    cursor: pointer;
}

.card-principal .card-secun .img-card {
    width: 96%;
    height: 98%;
    overflow: hidden;
    position: relative;
    margin: 2% auto;
    object-fit: cover;
    border-radius: 10px;
}

.card-principal .card-secun .img-card img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transition: all .7s ease;
}

.card-secun .img-card img:hover {
    transform: scale(1.08);
}

.card-secun .img-card span {
    font-size: 30px;
    position: absolute;
    left: 78%;
    top: 5%;
    color: rgb(161, 160, 160);
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 1px 1px 2px 2px rgba(190, 189, 189, 0.521);
}

.card-secun .img-card span:hover {
    color: red;
}

/*------------*/
.card-secun .card-footer1 {
    position: absolute;
    width: 94%;
    height: 28%;
    bottom: 8px;
    border-radius: 0px 0px 8px 8px;
    padding: 4%;
    margin: 1px 3%;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    background-color: #2222228f;
}

.card-secun .card-footer1 .card-nombre {
    display: flex;
    justify-content: space-between;
}

.card-secun .card-footer1 .card-nombre i {
    color: #D4AC0D;
}

.card-secun .card-footer1 .card-nombre h1 {
    text-shadow: 1px 1px 2px black;
}

.card-secun .card-footer1 .card-footer-nacio {
    display: flex;
}

.card-secun .card-footer1 .card-footer-nacio span {
    font-size: 13px;
    margin-left: 5px;
    color: #6affff;
    font-weight: 500;
    text-shadow: 1px 1px 2px rgba(32, 32, 32, 0.726);
    font-family: 'Permanent Marker', cursive;
}

.card-secun .card-footer1 .card-footer-nacio img {
    width: 16px;
    height: 16px;
}

.card-secun .card-footer1 .card-nombre span {
    color: rgb(255, 255, 255);
    font-weight: 600;
    text-shadow: 1px 1px 2px rgb(7, 7, 7);
    font-size: 13px;
    background: rgba(172, 2, 2, 0.466);
    padding: 0 4px;
    border-radius: 6px;
}

.card-secun .card-footer1 .card-nombre .card-name-nombre {
    display: flex;
}

.card-secun .card-footer1 .card-nombre img {
    width: 20px;
    height: 20px;
    margin-right: 8px
}

.card-secun .card-footer1 .card-nombre h1 {
    font-size: 80%;
    color: #efefef;
    padding-right: 5px;
    font-family: Verdana, Arial, fantasy sans-serif;
}

.card-secun .card-footer1 .card-lugar span {
    font-size: 12.5px;
    font-weight: 600;
    color: #ffd64f;
    text-shadow: 1px 1px 2px rgba(34, 34, 34, 0.774);
}

.card-secun .card-footer1 .card-lugar {
    justify-content: space-between;
    display: flex;
}

.card-secun .card-footer1 .card-lugar img {
    width: 20px;
    height: 20px;
    margin-right: 4px;
}

.card-secun .card-footer1 .card-lugar small {
    background: rgba(255, 255, 255, 0.692);
    padding: 0 4px;
    text-align: center;
    font-weight: 600;
    border-radius: 5px;
    color: #00014b;
}

.card-secun .card-footer1 .card-lugar h2 {
    font-size: 76%;
    color: #fafafa;
    font-family: "Century Gothic", Verdana, sans-serif;
}

@media (max-width: 750px) {
    .card-secun .card-footer1 {
        position: absolute;
        width: 94%;
        height: 30%;
        overflow: hidden;
        bottom: 4px;
        border-radius: 0px 0px 8px 8px;
        padding: 4%;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    }

    .card-secun .card-footer1 .card-footer-nacio span {
        font-size: 11px;
        margin-left: 4px;
        color: #50ffe8;
    }

    .card-secun .card-footer1 .card-footer-nacio img {
        width: 14px;
        height: 14px;
    }

    .card-secun .card-footer1 .card-nombre i {
        font-size: 10px;
    }

    .card-secun .card-footer1 .card-nombre span {
        font-size: 11px;
        padding: 0 3px;
        border-radius: 6px;
    }

    .card-secun .card-footer1 .card-nombre h1 {
        font-size: 70%;
        color: #efefef;
        padding-right: 5px;
        font-family: Verdana, Arial, fantasy sans-serif;
    }

    .card-secun .card-footer1 .card-lugar span {
        font-size: 9px;
        color: #ffdd19;
        text-shadow: 1px 1px 2px rgb(61, 61, 61);
    }
    .card-secun .card-footer1 .card-lugar small {
        font-size: 11px;
    }
}
/******/
.rj-container-sections .rj-izquierda {
    width: 15%;
    position: -webkit-sticky;
    position: sticky;
    top: 80px;
    height: 88vh;
    padding: 8px;
    z-index: 1;
}

.rj-central {
    width: 70%;
    padding: 10px;
}

.rj-derecha {
    width: 15%;
    height: 80vh;
    position: -webkit-sticky;
    position: sticky;
    top: 86px;
    padding: 8px;
}

.rj-container-sections {
    display: flex;
    transition: 1.5s;
}

.rj-navbar {
    height: 84vh;
    border-radius: 15px;
    transition: all .2s ease-in-out;
    padding: 4px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.rj-navbar .Logo-apps {
    height: 9%;
    text-align: center;
}

.cariñosas_title_red {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 5px 0px;
}

.cariñosas_title_red h4 {
    font-size: 16px;
}

#MenuHorizontal {
    background: #ffffff;
    width: 100%;
    height: 82px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    margin: 0 0 10px 0;
    z-index: 5000;
    /*box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
*/
}

#MenuHorizontal .Logo-header-rj {
    position: absolute;
    left: 15%;
    top: 20px;
}

#logo_css_header {
    font-size: 24px;
    font-weight: 700;
}

#MenuHorizontal .container-header-p {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

#MenuHorizontal .container-header-p .logo {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;

}

#MenuHorizontal .container-header-p .logo img {
    width: 100%;
    margin-top: 12px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

/****menu***********/
#MenuHorizontal .container-header-p .icons-headerP_inicio {
    width: 50%;
    display: flex;
    justify-content: center;
}

#MenuHorizontal .container-header-p .menu {
    width: 42%;
    display: flex;
    justify-content: center;
}

#MenuHorizontal .container-header-p .menu a {
    color: rgb(247, 57, 57);
    text-decoration: none;
    display: inline-block;
    padding: 20px 15px;
    line-height: normal;
    font-size: 14px;
    transition: all 1s ease;
    border-bottom: 4x solid transparent;
}

#MenuHorizontal .container-header-p .menu a:hover {
    color: rgb(248, 33, 4);
    border-bottom: 2px solid #f52c08;
}

#MenuHorizontal .container-header-p #btn-menu {
    display: none;
}

#MenuHorizontal .container-header-p .boton {
    float: right;
    display: none;
}

/****numep****/
.show-element {
    animation-duration: 0.7s;
    animation-fill-mode: both;
    animation-name: fadeIn;
    background-color: rgb(255, 185, 128);
    position: absolute;
    width: 100%;
    height: 50vh;
    top: 78px;
    display: flex;
    padding: 5px 20px;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.show-element .nav_paginas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 600px;
}

.show-element .nav_paginas .div_nav_pafinas {
    margin: 15px 4px;
}

.show-element .nav_paginas .div_nav_pafinas a {
    color: rgb(255, 81, 0);
}

.hide-element {
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-name: fadeOut;
}

#btn_mostrar_menu_link {
    display: none;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes otherAnimation {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes animationTranslateIn {
    from {
        opacity: 0;
        transform: translateX(-200px);
    }

    to {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes animationTranslateOut {
    from {
        opacity: 1;
        transform: translateX(0px);
    }

    to {
        opacity: 1;
        transform: translateX(-200px);
    }
}

/***********************/

#stylos_box_shadow {
    position: fixed;
    margin-left: 200px;
    z-index: 10000;
    top: 100px;
    width: 100px;
    height: 100px;
    background: red;
}

/*-----botones***/
#MenuHorizontal .botones-principal {
    display: flex;
    justify-content: center;
    align-items: center;
}

/******botones login ***/

.btn_header_logre {
    border: none;
    color: #fff;
    background-image: linear-gradient(30deg, #ed25ff, #fc9cfc);
    border-radius: 20px;
    background-size: 100% auto;
    font-family: inherit;
    font-size: 14px;
    padding: 0.2em 1.5em;
    font-weight: 600;
    margin: 0 8px;
    width: 140px;
}

.btn_header_logre i {
    font-size: 13px;
    margin-right: 4px;
}

.btn_header_logre.register {
    background-image: linear-gradient(30deg, #ff0000, #ffcc5d);

}

.btn_header_logre:hover {
    background-position: right center;
    background-size: 200% auto;
    animation: pulse512 1.5s infinite;
}

@keyframes pulse512 {
    0% {
        box-shadow: 0 0 0 0 #da1e0566;
    }

    70% {
        box-shadow: 0 0 0 10px rgb(218 103 68 / 0%);
    }

    100% {
        box-shadow: 0 0 0 0 rgb(218 103 68 / 0%);
    }
}


#MenuHorizontal .container-header-p .icons-headerP {
    display: flex;
    justify-content: space-between;
    margin: 0 5px;
    width: 35%;
}

#MenuHorizontal .container-header-p .icons-headerP a {
    color: rgb(255, 115, 0);
}

#MenuHorizontal .container-header-p .icons-headerP a:hover {
    color: #626567;
}

.container-header-p .icons-headerP .icons-header-p {
    width: 46px;
    height: 46px;
    padding: 6px;
    margin: 8px 10px 5px 8px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    color: #b4b6b4;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
    transition: all 1s;
}

#MenuHorizontal .container-header-p .icons-header-p:hover {
    background: #f8c8a8;
    color: #313131;
}

#MenuHorizontal .container-header-p .filter {
    display: none;
}

.dropdown,
.dropleft,
.dropright,
.dropup {
    display: none;
}

/*titulo header incio */
.icons-headerP_inicio .titulo_haader_inicio {
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icons-headerP_inicio .titulo_haader_inicio h3 {
    display: inline-block;
    color: #000;
    background: #fff;
    mix-blend-mode: multiply;
    position: relative;
    font-weight: 700;
    font-family: cursive;
    text-shadow: 1px 1px 2px rgb(112, 112, 112);
}

.icons-headerP_inicio .titulo_haader_inicio h3:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgb(255, 51, 0), rgb(248, 182, 38), rgb(255, 136, 0));
    mix-blend-mode: screen;
    position: absolute;
    top: 0;
    left: 0;
}

.container-header-p .icons-headerP .icons-headerP_btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;
}

.container-header-p .icons-headerP .icons-headerP_btn small {
    color: rgb(141, 138, 138);
    font-size: 12px;
}


@media only screen and (min-width:320px) and (max-width:750px) {
    #MenuHorizontal {
        width: 100%;
        height: 80px;
    }

    #MenuHorizontal .Logo-header-rj {
        position: absolute;
        left: 30%;
        top: 23px;
    }

    #logo_css_header {
        font-size: 34px;
        font-weight: 700;
    }

    #MenuHorizontal .container-header-p {
        padding: 5px;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        left: 0;
        width: 100vw;
        height: 80px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 35px 35px;
    }

    .rj-container-sections .rj-izquierda {
        display: none;
    }

    .rj-central {
        width: 98%;
        margin: 2px auto;
        padding: 4px;
    }

    .rj-derecha {
        display: none;
    }

    #MenuHorizontal .container-header-p .logo {
        grid-column: 1/3;
        width: 100%;
        padding: 5px;
    }

    #MenuHorizontal .container-header-p .filter {
        display: block;
        width: 100%;
        padding: 6px;
    }

    #MenuHorizontal .container-header-p .filter i {
        position: relative;
        float: right;
        margin-right: 12px;
    }

    #MenuHorizontal .container-header-p .logo h1 {
        font-size: 25px;
        margin: 2px;
        position: relative;
    }

    #MenuHorizontal .container-header-p .boton {
        margin-left: 15px;
    }

    #MenuHorizontal .container-header-p .icons-headerP {
        position: absolute;
        top: calc(100vh - 75px);
        height: 75px;
        left: 0;
        background: white;
        padding: 5px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    #MenuHorizontal .container-header-p .botones-principal {
        display: none;
    }

    /******menu***********/
    #MenuHorizontal .container-header-p .menu {
        position: absolute;
        background: rgb(236, 236, 236);
        width: 100%;
        height: calc(100vh - 80px);
        top: 69px;
        left: 0;
        transition: all 500ms ease;
        transform: translate(-100%);
        display: flex;
        flex-direction: column;
    }

    #btn-menu:checked~.menu {
        transform: translate(0);
    }

    #MenuHorizontal .container-header-p .menu a {
        display: block;
        margin-top: 10px;
    }

    #MenuHorizontal .container-header-p .boton {
        display: block;
        width: 50px;
        height: 50px;
    }

    /*********************/

    .dropdown,
    .dropleft,
    .dropright,
    .dropup {
        display: block;
    }

    .icons-headerP .btn:not(:disabled):not(.disabled) {
        width: 45px;
        height: 45px;
        margin: 2px 10px 2px 0px;
        margin-top: 5px;
        background: white;
        color: #626567;
        border-radius: 50%;
        border: none;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    .container-header-p .icons-headerP .icons-headerP_btn small {
        color: rgb(97, 95, 95);
        font-size: 9px;
    }

    .icons-headerP_inicio .titulo_haader_inicio h3 {
        display: none;
    }

}

/*-----*/

.rj-navbar h1 {
    font-size: 15px;
    margin-bottom: 6px;
    margin-top: 4px;
}

.rj-navbar svg {
    width: 11px;
    height: 11px;
    margin-right: 5px;
}


.rj-header a {
    text-decoration: none;
}

.rj-navbar .App-lugares {
    background: #ffffff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 6%;
    min-height: 32%;
    transition: all 1s ease;
}

.rj-navbar .App-lugares:hover {
    box-shadow: rgba(255, 66, 9, 0.329) 0px 3px 8px;
}

.rj-navbar .App-lugares::-webkit-scrollbar {
    display: none;
}

.rj-navbar .App-lugares a {
    text-decoration: none;
}


/***************/

.App-lugares .Lugar-app .popover-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    padding: 18px;
    left: 85%;
}

.Lugar-app .popover-content a {
    margin: 4px;
    font-weight: 400;
    color: rgb(58, 58, 58);
}

.Lugar-app:hover .popover-content a:hover {
    color: rgb(255, 60, 0);
    font-weight: 600;
}

.Lugar-app .popover-content .popover-content-one {
    display: flex;
}

.popover-content .popover-content-one .popover-content-prov {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.Lugar-app:hover .popover-content {
    display: flex;
    flex-direction: column;
}


/**************/

.rj-navbar .App-lugares .Lugar-app {
    display: flex;
    justify-content: space-between;
    border: #f7a196 solid 1px;
    color: rgb(119, 114, 114);
    padding: 4px;
    border-radius: 5px;
    width: 100%;
    height: 14%;
    min-width: 50%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 5px 0px 5px 0px;
    cursor: pointer;
    font-size: 1em;
    transition: all 0.6s ease;
}

.rj-navbar .App-lugares .Lugar-app span {
    font-size: 80%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: rgb(248, 60, 2);
}

.rj-navbar .App-lugares h1 {
    color: rgb(119, 114, 114);
    letter-spacing: .5ch;
}

.rj-navbar .App-lugares svg {
    color: rgb(119, 114, 114);
}

.rj-navbar .App-lugares .Lugar-app:hover,
.rj-navbar .App-lugares .Lugar-app span:hover {
    background: #f3592b;
    color: white;
}

.rj-navbar .App-servicios {
    width: 100%;
    min-height: 28%;
    background-color: #fff;
    border-radius: 10%;
    padding: 5%;
    font-size: 1em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    box-shadow: rgba(151, 150, 150, 0.35) 0px 5px 15px;
    transition: all 1s ease;
}

.App-servicios .Servicios-app {
    width: 100%;
    /* Ancho deseado para el div */
    white-space: nowrap;
    /* Evitar que el texto se divida en varias líneas */
    overflow: hidden;
    /* Ocultar el contenido que no cabe en el div */
    text-overflow: ellipsis;
}

.rj-navbar .App-servicios .Servicios-app span {
    font-size: 90%;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    color: #747272;
    display: inline-block;
    /* Hacer que el span sea un bloque en línea */
    vertical-align: middle;
    /* Alinear verticalmente el texto si es necesario */

}

.rj-navbar .App-servicios h1 {
    color: rgb(119, 114, 114);
    letter-spacing: .5ch;
}

.rj-navbar .App-servicios:hover {
    box-shadow: rgba(255, 117, 62, 0.493) 0px 5px 15px;
}

.rj-navbar .App-servicios span:hover {
    color: #f33f12;
}

.rj-navbar .App-mapa {
    border: #fc4d36 solid 0.5px;
    background: #fc4d36;
    border-radius: 8px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 5px;
    box-shadow: rgba(12, 9, 9, 0.35) 0px 5px 15px;
}

.rj-navbar .App-mapa i {
    color: #f5f4f4;
    font-size: 20px;
}

.rj-navbar .App-mapa span {
    font-size: 20px;
    color: #F4F6F7;
    font-family: fantasy;
    font-weight: 700;
    letter-spacing: .5ch;
}

.rj-navbar .App-mapa:hover {
    background: #f13730;
}

.rj-navbar .App-mapa span {
    margin-left: 10px;
    font-family: 'Courier New', Courier, monospace;
    font-size: 17px;
}

.header-registrar .header-registrar-perfil img {
    width: 25px;
    height: 25px;
}

/*-----lateral-----*/
.item-lugares .dropdown-menu {
    background: rgb(136, 133, 133);
    transform: translate3d(0px, 0px, 0px) !important;
}

/*---- imagens laterales----*/


/************************/


/*----------comments----------*/


/*----------portada_reds--------*/
.container-pruebaPP .portada_red_social {
    display: grid;
    grid-template-columns: 2.1fr 1fr;
    width: 100%;
    height: 300px;
    margin-bottom: 15px;
}

.container-pruebaPP .portada_red_social .uno_portada {
    width: 100%;
    height: 100%;
    overflow: hidden;
}


.uno_portada .img_carousel_portal .portal_content_ {
    position: absolute;
    margin: 20px;
    width: 10%;
    border-radius: 8px;
    background: rgba(66, 66, 66, 0.377);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    /*border: solid 2px rgb(223, 223, 223);*/
    padding: 5px;
    z-index: 3;
}

.uno_portada .img_carousel_portal .portal_content_ h1 {
    position: relative;
    text-align: center;
    font-family: 'Bungee Spice', cursive;
    text-shadow: rgb(0, 255, 234) 1px 0 10px;
}

.uno_portada .img_carousel_portal .portal_content_ p {
    color: white;
    font-family: 'Shadows Into Light', cursive;
    font-size: 22px;
    text-shadow: 1px 1px 2px rgb(0, 52, 255), 0 0 1em rgb(48, 48, 48), 0 0 0.2em rgb(0, 0, 0);
}

.img_carousel_portal .img_segun_portal {
    position: relative;
}

.uno_portada .img_carousel_portal .img_segun_portal img {
    width: 98%;
    height: 230px;
    margin: 5px auto;
    border-radius: 10px;
    object-fit: cover;
    -webkit-filter: blur(3px);
    filter: blur(3px);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.container-pruebaPP .portada_red_social .dos_portada {
    height: 100%;
    padding: 5px;
}

/*----------------*/

.portada_red_social .dos_portada .container_profile_content {
    text-align: center;
}

.dos_portada .container_profile_content a {
    text-decoration: none;
}

.portada_red_social .dos_portada .cariñosas_title_red h3 {
    font-size: 25px;
    text-shadow: 1px 1px 2px rgb(252, 175, 111);
    margin-top: 10px;
    font-weight: 600;
}

.portada_red_social .dos_portada .container_profile_content p {
    font-size: 16px;
    font-family: 'Gloock', serif;
    margin: 15px 0px;
    color: #626567;
}

.portada_red_social .dos_portada .container_profile_content span {
    background: rgb(255, 94, 0);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    letter-spacing: 0.2em;
    padding: 4px 16px;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 600;
    color: rgb(241, 241, 241);
    transition: all .5s;
}

.portada_red_social .dos_portada .container_profile_content span:hover {
    background: rgb(255, 176, 176);
    color: #fc4007;
}

.portada_red_social .dos_portada .container_profile_content i {
    margin-right: 4px;
}

/*-----------collage---------*/

.container_profile_ {
    display: flex;
    justify-content: center;
    width: 96%;
    max-width: 1440px;
    margin: 0 auto;
}

.profile_avatar_ {
    position: relative;
    transition: all 0.3s;
}

.profile_avatar_:hover {
    transform: translateY(20px);
}

.profile_avatar_ img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 3px solid #f7f7f7;
    -webkit-filter: drop-shadow(-20px 0 10px rgba(0, 0, 0, 0.1));
            filter: drop-shadow(-20px 0 10px rgba(0, 0, 0, 0.1));
    cursor: pointer;
    box-shadow: rgba(223, 223, 223, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.profile_avatar_:not(:first-child) img {
    margin-left: -15px;
}

.profile_avatar_ .name {
    position: absolute;
    background-color: #ffb03a;
    color: #fff;
    font-family: "Bebas Neue", cursive;
    padding: 15px 30px;
    border-radius: 100px;
    bottom: -80px;
    left: 50%;
    white-space: nowrap;
    transform: translate(-50%, -50px);
    letter-spacing: 1px;
    font-size: 20px;
    opacity: 0;
    transition: all 0.3s;
}

.profile_avatar_ .name::before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #ffb03a;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
}

.profile_avatar_ img:hover+.name {
    opacity: 1;
    transform: translateX(-50%);
    box-shadow: 0 10px 20px rgba(86, 86, 198, 0.3);
}

/*----------tags depart-----*/

.container-pruebaPP .Provincias_tags {
    display: flex;
    flex-direction: column;
    width: 94%;
    margin: 0px auto;
}

.container-pruebaPP .Provincias_tags button {
    margin: 4px;
}

.container-pruebaPP h3 {
    font-size: 20px;
    margin: 0;
    color: #423531;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.container-pruebaPP h2 {
    width: 100%;
    font-size: 20px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: #7c7c7c;
    text-align: center;
    padding: 8px 0px;
    margin: 30px 0px;
}

.Provincias_tags .provincia_carosusel {
    background: rgb(152, 152, 153);
    display: flex;
}

/*-------------------------------------------------*/
.contenedor_tags {
    width: 96%;
    margin: 2px auto;
}

.contenedor_tags ul {
    margin: 0;
}

ul.boxs-container {
    list-style: none;
    padding: 5px;
}

ul.boxs-container2 {
    padding: 0;
}

ul.boxs-container li {
    display: inline;
    margin: 0px 5px;
}

ul.boxs-container li label {
    display: inline-block;
    background-color: #eeeded;
    border: 1px solid #f5e0e0;
    color: #807777;
    border-radius: 25px;
    white-space: nowrap;
    margin: 3px 0px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    transition: all .2s;
    font-weight: 500;
}

ul.boxs-container li label {
    padding: 8px 12px;
    cursor: pointer;
}

ul.boxs-container li label:hover {
    border: 1px solid #fc7c49;
}

ul.boxs-container li input[type="checkbox"]:checked+label {
    border: 1px solid #fdbf85;
    background-color: #ff9650;
    color: #fff;
    transition: all .2s;
}

ul.boxs-container li input[type="checkbox"] {
    display: absolute;
}

ul.boxs-container li input[type="checkbox"] {
    position: absolute;
    opacity: 0;
}

/*---------------*/

ul.boxs-container2 li {
    display: inline;
    margin: 0px 5px;
}

ul.boxs-container2 li label {
    display: inline-block;
    background-color: #fceeee;
    border: 1px solid #f8dede;
    color: #817373;
    border-radius: 25px;
    white-space: nowrap;
    margin: 3px 0px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    transition: all .2s;
    font-weight: 500;
}

ul.boxs-container2 li label {
    padding: 8px 12px;
    cursor: pointer;
}

ul.boxs-container2 li label:hover {
    border: 1px solid #fd6224;
}

ul.boxs-container2 li input[type="checkbox"]:checked+label {
    border: 1px solid #ff7f7a;
    background-color: #ff2609;
    color: #fff;
    transition: all .2s;
}

ul.boxs-container2 li input[type="checkbox"] {
    display: absolute;
}

ul.boxs-container2 li input[type="checkbox"] {
    position: absolute;
    opacity: 0;
}

/*--------------*/
.cart_anuncios {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 5px 0px;
}

.cart_anuncios .lists_card {
    width: 300px;
    height: 550px;
    overflow: hidden;
}

.lists_card .img_Portal {
    width: 100%;
    height: 80%;
    border-radius: 10px;
}

.img_carousel_ {
    height: 380px;
}

/*
.img_carousel_ .img_segun span {
    position: absolute;
    margin: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 4px;
    background: white;
    cursor: pointer;
    font-size: 24px;
}*/

/*----------footer-img----------*/

.img_segun_footer .title_footer {
    display: flex;
    justify-content: space-between;
    margin: 6px 4px;
    font-size: 14px;
    padding: 0 3px;
}

.img_segun_footer .title_footer h4 {
    font-size: 16.5px;
    margin: 0;
    letter-spacing: 1.5px;
    color: #626567;
    text-shadow: 1px 1px 2px rgb(172, 172, 172);
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
}

.img_segun_footer .title_footer span {
    color: #E74C3C;
    font-weight: 700;
}

.img_segun_footer .content_footer {
    display: flex;
    justify-content: flex-start;
    margin: 0px 4px;
}

.img_segun_footer .content_footer span {
    font-family: cursive;
    color: #616A6B;
}

.img_segun_footer .content_footer img {
    margin: 0 4px;
    width: 22px;
    height: 22px;
}

.img_segun_footer .lugar_footer {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    margin: 0px 4px;
    padding: 0 3px;
}

.img_segun_footer .lugar_footer .lugar_footer_img {
    width: 70%;
    display: flex;
    align-items: center;
    color: red;
}

.img_segun_footer .lugar_footer img {
    margin: 0 4px;
    width: 22px;
    height: 22px;
}

#img_footer_img {
    box-shadow: none;
}

/*------------*/


.img_carousel_ .img_segun {
    width: 96%;
    height: 98%;
    margin: 2px auto;
    border-radius: 15px;
    padding: 2px;
    border: solid 1.5px #9a7d0ae0;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.img_carousel_ .img_segun .img_segun_favorite {
    position: absolute;
    background: rgba(253, 251, 238, 0.582);
    border-radius: 50%;
    border: solid 0.5px #9A7D0A;
    display: flex;
    color: #9A7D0A;
    font-size: 20px;
    padding: 4px;
    margin: 8px;
    cursor: pointer;
}

.img_carousel_ img {
    width: 98%;
    height: 74%;
    margin: 1px auto;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.img_carousel_ .img_segun_vip {
    width: 96%;
    height: 98%;
    margin: 2px auto;
    border: solid 1.5px #7a7a7ad3;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.img_carousel_ .img_segun_vip img {
    width: 98%;
    border-radius: 0;
}

.img_carousel_ .img_segun_vip .img_segun_footer img {
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

.img_carousel_ .img_segun_vip .img_segun_favorite {
    position: absolute;
    background: rgba(253, 251, 238, 0.582);
    border-radius: 50%;
    border: solid 0.5px #A93226;
    display: flex;
    color: #A93226;
    font-size: 20px;
    padding: 4px;
    margin: 8px;
    cursor: pointer;
}

.container-pruebaPP .flesh_directorio {
    font-size: 28px;
    margin-left: 35px;
}

.container-pruebaPP .flesh_directorio i {
    color: red;
}

.settings2 {
    width: 90%;
    margin: 20px auto;
}

.settings2 .slick-slider.slick-initialized .slick-prev::before {
    color: rgb(255, 102, 0);
}

.settings2 .slick-slider.slick-initialized .slick-next::before {
    color: rgb(255, 102, 0);
}

.load_more {
    text-align: center;
}

.load_more button {
    padding: 6px 12px;
    margin: 12px auto;
    border: 1.5px solid rgb(207, 67, 3);
    border-radius: 8px;
    background: rgba(255, 254, 254, 0.932);
    color: rgb(207, 67, 3);
    text-transform: capitalize;
    transition: all .5s;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.load_more button:hover {
    background: rgb(253, 83, 4);
    color: white;
}

/*---- card get perfil---*/

.postcarget_style {
    width: 100%;
    max-width: 1025px;
    margin: 2px auto;
    display: grid;
    grid-template-columns: 1fr;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.card_header_getuser {
    display: flex;
    justify-content: space-between;
    padding: 8px;
}

.card_header_getuser .fa-solid.fa-ellipsis {
    color: rgb(63, 63, 63);
    font-size: 25px;
    margin: 6px;
    cursor: pointer;
}

.card_header_getuser #dropdown-item-headerget {
    position: absolute;
    left: 10px
}

.card_icon_menu_get {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.card_icon_menu_get i {
    font-size: 24px;
    margin: 0 10px;
    cursor: pointer;
}

.card_icon_menu_get img {
    cursor: pointer;
}

.card_body-content_get {
    padding: 5px 12px;
}

/*****contenedor de interes de usuario*****/

.contenedor_interes_btn_ {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 2px;
    margin: 8px auto;
    align-items: center;
}

.contenedor_interes_btn_ button {
    margin: 0 10px;
    padding: 5px 8px;
    border-radius: 8px;
    border: none;
    font-size: 13px;
    color: #616A6B;
    box-shadow: rgba(247, 247, 247, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.contenedor_interes_btn_ button:hover {
    background: rgb(245, 227, 227);
}

.contenedor_card_interes {
    width: 100%;
    margin: 20px auto;
    display: flex;
    padding: 5px;
    flex-direction: column;
}

.card_interes_usuario {
    width: 100%;
    margin: 15px auto;
    overflow: hidden;
    height: 460px;
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    background: #FBFCFC;
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.card_interes_usuario img {
    width: 98%;
    height: 65%;
    margin-top: 1%;
    position: absolute;
    border-radius: 20px;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.card_interes_usuario .interes_usuario_header {
    width: 92%;
    height: 64%;
    padding: 2%;
    position: absolute;
    top: 3%;
}

#usuario_header_interes {
    background: rgba(124, 124, 124, 0.678);
    color: white;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 17px;
    text-shadow: 0.1em 0.1em 0.2em rgb(68, 68, 68);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

#usuario_header_edad {
    background: #f75858c4;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    position: absolute;
    right: 1%;
    top: 2%;
    padding: 2px 8px;
    font-weight: 700;
    font-size: 17px;
    color: rgb(254 254 254);
    border-radius: 4px;
    text-shadow: rgb(73, 73, 73) 2px 2px 8px;
    box-shadow: rgba(51, 51, 51, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.card_interes_usuario .interes_usuario_footer {
    position: absolute;
    bottom: 2px;
    width: 96%;
    height: 28%;
    margin: 2%;
    border-radius: 0 0 20px 20px;
    padding: 8px 18px;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.card_interes_usuario .interes_usuario_footer h4 {
    position: absolute;
    top: 10%;
    right: 4%;
    font-size: 18px;
    background: #fa4a04;
    color: #f5f5f5;
    border-radius: 5px;
    padding: 6px 10px;
    cursor: pointer;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.card_interes_usuario .interes_usuario_footer h4:hover {
    background: rgb(255, 207, 179);
    color: #db5a04;
    border: solid 1px #db5a04;
}

.card_interes_usuario .interes_usuario_footer span {
    color: #808B96;
    font-weight: 600;
    /*text-shadow: 1px 1px 2px rgb(17, 1, 59);*/
    z-index: 3;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif, cursiva;
}

#user_card_footer {
    color: rgb(31, 30, 30);
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 20px;
    font-weight: 600;
    color: whitesmoke;
    position: absolute;
    bottom: 8%;
    text-shadow: black 0.1em 0.1em 0.2em;
}

#user_card_footer_n {
    color: #fc2104;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 17px;
    text-shadow: 0.1em 0.1em 0.2em rgb(182, 181, 181)
}

#user_card_footer_p {
    color: #ff0831;
    font-family: cursive;
    font-size: 17px;
    text-shadow: 0.1em 0.1em 0.2em rgb(182, 181, 181)
}

.card_interes_usuario .interes_usuario_footer i {
    z-index: 2;
    color: #969696;
    text-shadow: 1px 1px 2px rgba(102, 102, 102, 0.356);
    font-size: 15px;
}

#user_card_footer_story {
    margin: 5px 0;
    padding: 2px 6px;
}

#user_card_footer_story p {
    font-size: 15.5px;
    color: #3b3b3b;
    font-weight: 500;
}

/*****btn inicio red soacial****/

.content_inicio_btn_3 {
    width: 90%;
    margin: 8px auto;
    padding: 8px;
}

.content_inicio_btn_3 a {
    text-decoration: none;
}

.custom-btn-inicio {
    position: relative;
    width: 80%;
    margin: 5px auto;
    border-radius: 10px;
    border: none;
    background-color: rgb(151, 95, 255);
    color: white;
    box-shadow: 0px 10px 10px rgb(210, 187, 253) inset,
    0px 5px 10px rgba(5, 5, 5, 0.212),
    0px -10px 10px rgb(124, 54, 255) inset;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 14px;
    font-weight: 600;
  }
  
  .custom-btn-inicio::before {
    width: 70%;
    height: 2px;
    position: absolute;
    background-color: rgba(250, 250, 250, 0.678);
    content: "";
    -webkit-filter: blur(1px);
            filter: blur(1px);
    top: 7px;
    border-radius: 50%;
  }
  
  .custom-btn-inicio::after {
    width: 70%;
    height: 2px;
    position: absolute;
    background-color: rgba(250, 250, 250, 0.137);
    content: "";
    -webkit-filter: blur(1px);
            filter: blur(1px);
    bottom: 7px;
    border-radius: 50%;
  }
  
  .custom-btn-inicio:hover {
    animation: jello-horizontal 0.9s both;
  }
  
  @keyframes jello-horizontal {
    0% {
      transform: scale3d(1, 1, 1);
    }
  
    30% {
      transform: scale3d(1.25, 0.75, 1);
    }
  
    40% {
      transform: scale3d(0.75, 1.25, 1);
    }
  
    50% {
      transform: scale3d(1.15, 0.85, 1);
    }
  
    65% {
      transform: scale3d(0.95, 1.05, 1);
    }
  
    75% {
      transform: scale3d(1.05, 0.95, 1);
    }
  
    100% {
      transform: scale3d(1, 1, 1);
    }
  }
  


@media (max-width: 750px) {

    #user_card_footer_story p {
        font-size: 11.5px;
    }

    .card_interes_usuario {
        height: 390px;
    }

    .card_interes_usuario img {
        width: 94%;
        margin: 2% 3%;
    }

    #usuario_header_interes {
        font-size: 12.5px;
    }

    #usuario_header_edad {
        font-weight: 600;
        font-size: 13px;
        margin: 5px;
    }

    .card_interes_usuario .interes_usuario_header {
        padding: 2% 6%;
        top: 2%;
    }

    .card_interes_usuario .interes_usuario_footer {
        bottom: 2px;
        width: 98%;
        margin: 1%;
        padding: 5px 8px;
    }

    .card_interes_usuario .interes_usuario_footer h4 {
        margin-top: 5px;
        font-size: 10.5px;
    }

    .card_interes_usuario .interes_usuario_footer i {
        font-size: 12px;
    }

    #user_card_footer {
        font-size: 17px;
    }

    #user_card_footer_n {
        font-size: 14px;
    }

    #user_card_footer_p {
        font-size: 14px;
    }

    .contenedor_interes_btn_ button {
        font-size: 10.2px;
    }

}

/*---------------*/

@media (max-width: 920px) {

    .rj-container-sections .rj-section.rj-left {
        display: none;
    }

    .rj-container-sections .rj-section.rj-lateral {
        display: none;
    }

    .rj-header {
        margin-left: 0;
    }

    .container-header-p .botones-principal i {
        display: none;
    }

    .container-header-p .icons-headerP {
        padding: 6px;
    }

    .container-header-p .icons-headerP .icons-header-p {
        width: 40px;
        height: 40px;
        font-size: 20px;
    }

    .container-pruebaPP h2 {
        margin: 30px 0;
        font-size: 17px;
        color: #7c7c7c;
        position: relative;
    }

}

/*------------------------------------------*/

@media (max-width: 920px) {
    .variable-width button {
        font-size: 11px;
    }

    ul.boxs-container li label {
        font-size: 11px;
        padding: 4px 6px;
    }

    ul.boxs-container2 li label {
        font-size: 11px;
        padding: 4px 6px;
    }

    .container-pruebaPP h3 {
        font-size: 15px;
    }
}

@media (max-width: 750px) {
    .img_carousel_ {
        height: 270px;
    }

    .img_carousel_ .img_segun img {
        height: 70%;
    }

    .img_carousel_ .img_segun span {
        font-size: 16px;
    }

    /*-----*/
    .img_segun_footer .title_footer h4 {
        font-size: 12px;
        letter-spacing: 1.5px;
    }

    .img_segun_footer .title_footer span {
        color: #E74C3C;
        font-size: 12px;
        font-weight: 500;
    }

    .img_segun_footer .content_footer {
        margin: 0px 4px;
    }

    .img_segun_footer .content_footer span {
        font-size: 12px;
    }

    .img_segun_footer .content_footer img {
        margin: 0 4px;
        width: 15px;
        height: 15px;
    }

    .img_segun_footer .lugar_footer {
        font-size: 10px;
        margin: 0px 2px;
        padding: 0 1px;
    }

    .img_segun_footer .lugar_footer img {
        margin: 0 4px;
        width: 15px;
        height: 15px;
    }

    .img_segun_footer .lugar_footer .lugar_footer_img span {
        font-size: 10px;
    }

    .img_carousel_ .img_segun_vip img {
        width: 98%;
        height: 70%;
    }

    .img_carousel_ .img_segun_vip .lugar_footer .lugar_footer_span span {
        font-size: 13px;
    }

    .img_carousel_ .img_segun_vip .img_segun_footer img {
        width: 15px;
        height: 15px;
    }

    .img_carousel_ .img_segun .img_segun_favorite {
        font-size: 14px;
    }

    .container-pruebaPP .flesh_directorio {
        font-size: 20px;
        margin-left: 10px;
    }

    /*---------------*/
    .container-pruebaPP .portada_red_social {
        display: grid;
        height: 380px;
        grid-template-columns: 1fr;
        grid-template-rows: 1.2fr 0fr;
    }

    .container-pruebaPP .portada_red_social .uno_portada {
        width: 100%;
        height: 100%;
    }

    .uno_portada .img_carousel_portal .img_segun_portal img {
        height: 145px;
    }

    .portada_red_social .dos_portada .container_profile_content {
        text-align: center;
    }

    .portada_red_social .dos_portada .cariñosas_title_red h3 {
        font-size: 24px;
        font-weight: 600;
    }

    .portada_red_social .dos_portada .container_profile_content p {
        font-size: 14px;
        margin: 8px 0;
        color: #4c4c4d;
    }

    .portada_red_social .dos_portada .container_profile_content span {
        background: rgb(255, 94, 0);
        padding: 6px 20px;
        border-radius: 4px;
        font-size: 12px;
        color: rgb(236, 236, 236);
    }

    .portada_red_social .dos_portada .container_profile_content i {
        margin-right: 4px;
    }

    /*-----------------*/
    .profile_avatar_ .name {
        display: none;
    }

    .container_profile_ {
        margin: 10px auto;
    }

    .portada_red_collage {
        margin: 8px auto;
    }

    /*------------------------*/


    .uno_portada .img_carousel_portal .portal_content_ {
        padding: 5px;
        text-align: center;
    }

    .uno_portada .img_carousel_portal .portal_content_ h1 {
        font-size: 30px;
        position: relative;
        left: 0%;
        margin: 4px;
    }

    .uno_portada .img_carousel_portal .portal_content_ p {
        font-size: 16px;
    }

    #btn_mostrar_menu_link {
        width: 32px;
        height: 32px;
        margin-left: 20px;
        border-radius: 5px;
        position: absolute;
        top: 30px;
        color: #4d4d4d;
        font-size: 22px;
        border: none;
        background: #ffc4a1;
        display: block;
    }

}
/*---------*/

.Ubicate-map {
  display: flex;
  width: 100%;
  max-width: 1025px;
  margin: 5px auto;
  height: 100vh;
}

.Ubicate-map .leaflet-container {
  margin: 0px auto;
  width: 98%;
  height: 100vh;
  border-radius: 8px;
}

.Ubicate-map .btn-hide-siderbar {
  position: absolute;
  border: none;
  background: rgba(245, 26, 26, 0.726);
  width: 30px;
  height: 37px;
  border-radius: 4px;
  left: 65%;
  top: 11%;
  font-size: 25px;
  z-index: 1000;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

/*---------*/

.Ubicate-map .sidebar {
  width: 485px;
  height: 100vh;
  padding: 10px;
  transition: all linear 0.75s;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.Ubicate-map .sidebar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #aaa;
  /* or add it to the track */
}

/*
  .Ubicate-map {
    display: flex;
  }*/

.Ubicate-map .sidebar.sbclosed {
  width: 0;
  transition: all linear 0.75s;
}

.sbcontent {
  width: 98%;
  height: 197px;
  padding: 6px;
  margin: 7px;
  border-radius: 15px;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.sbcontent-info-uno {
  display: flex;
  padding: 2px 6px;
  justify-content: space-between;
  font-size: 13px;
}

.sbcontent .sbcontent-info-uno .info-titulo {
  color: rgb(20, 20, 20);
  font-family: "Goudy Bookletter 1911", sans-serif;
  font-weight: 800;
}

.sbcontent .sbcontent-info-uno .info-precio {
  background: red;
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
}

.sbcontent-info-dos {
  display: flex;
  margin: 2px 3px;
  justify-content: center;
  font-size: 12px;
}

.sbcontent-info-dos .info-edad {
  color: rgb(46, 43, 43);
  font-size: 12px;
  background: rgba(78, 78, 78, 0.541);
  padding: 1px 3px;
  border-radius: 2px;
}

.sbcontent-info-dos .info-nacionalidad {
  color: rgb(253, 19, 19);
  font-size: 13px;
  margin-right: 8px;
}

.sbcontent .sbcontent-info-parra p {
  font-size: 13px;
  font-family: Comic Sans MS;
  line-height: 12px;
  margin: 5px 3px;
  color: rgb(66, 66, 66);
}

.sbcontent .info-distrito {
  font-size: 12px;
  margin: 0px 12px;
  font-weight: 800;
  color: rgb(88, 88, 88);
  font-family: "Lucida Sans Typewriter";
}

.sbcontent .info-btn {
  margin: 9px 0px;
  display: flex;
  justify-content: space-between;
  margin: 4px 7px;
}

.sbcontent .info-btn a {
  text-decoration: none;
  color: white;
}

.info-btn button {
  border: none;
  font-size: 14.5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.info-btn .info-btn-perfil {
  background: rgb(255, 65, 65);
  height: 100%;
  width: 65px;
  border: none;
  color: white;
  border-radius: 2px;
  cursor: pointer;
}

.info-btn .info-btn-whatsapp {
  background: #25d366;
  width: 25px;
  height: 25px;
  margin-left: 5px;
  color: white;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-btn .info-btn-location {
  background: #ff9742;
  width: 25px;
  height: 25px;
  color: white;
  border-radius: 2px;
  cursor: pointer;
}

.sbcontent .sbcontent-img-cont img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

@media (max-width: 725px) {
  .Ubicate-map .btn-hide-siderbar {
    width: 25px;
    height: 30px;
    font-size: 18px;
    position: absolute;
    top: 55%;
    left: 4%;
    z-index: 1000;
  }

  .Ubicate-map .leaflet-container {
    margin: 0px auto;
    width: 98%;
    height: 100vh;
    border-radius: 8px;
    z-index: 2;
  }

  .Ubicate-map .sidebar {
    width: 100%;
    height: 184px;
    top: calc(100vh - 250px);
    margin: 0 auto;
    display: grid;
    padding: 0px;
    grid-auto-flow: column;
    /*grid-auto-columns: 40%;*/
    grid-column-gap: 0.6rem;
    -webkit-column-gap: 0.6rem;
            column-gap: 0.6rem;
    position: absolute;
    z-index: 100;
    overflow-x: auto;
    overscroll-behavior-inline: contain;
  }

  .sidebar::-webkit-scrollbar {
    display: none;
  }

  .Ubicate-map .sbcontent {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    background: rgba(255, 255, 255, 0.616);
    width: 255px;
    height: 175px;
    padding: 2px 4px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
      rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  }

  .sbcontent .sbcontent-img-cont {
    display: flex;
    justify-content: center;
    padding: 5px auto;
  }

  .sbcontent .sbcontent-info-uno {
    display: flex;
    justify-content: space-between;
    padding: 6px 8px;
  }

  .sbcontent .sbcontent-info-uno .info-titulo {
    color: rgb(20, 20, 20);
    font-family: "Goudy Bookletter 1911", sans-serif;
    font-weight: 800;
  }

  .sbcontent .sbcontent-info-uno .info-precio {
    background: red;
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 12px;
  }

  .sbcontent .sbcontent-info-dos {
    display: flex;
    margin: 0px 1px;
    justify-content: center;
    padding: 0;
  }

  .sbcontent-info-dos .info-nacionalidad {
    color: rgb(253, 5, 5);
    margin-right: 10px;
    font-size: 10px;
    font-weight: 600;
  }

  .sbcontent-info-dos .info-edad {
    color: rgb(46, 43, 43);
    font-size: 12px;
    background: rgba(78, 78, 78, 0.541);
    padding: 1px 3px;
    border-radius: 2px;
  }

  .sbcontent .sbcontent-info-parra p {
    font-size: 10px;
    font-family: Comic Sans MS;
    line-height: 11px;
    margin: 2px 5px;
    color: rgb(66, 66, 66);
  }

  .sbcontent .info-distrito {
    font-size: 10px;
    color: rgb(153, 4, 153);
    font-family: "Lucida Sans Typewriter";
  }

  .sbcontent .info-btn {
    margin: 0px;
    padding: 4px 12px;
    display: flex;
    justify-content: space-between;
  }

  .info-btn .info-btn-perfil {
    background: rgb(255, 65, 65);
    border: none;
    color: white;
    border-radius: 2px;
    cursor: pointer;
  }

  .sbcontent-img-cont img {
    width: 125%;
    height: 105%;
    position: relative;
    top: -3px;
    left: -5px;
    border-radius: 5px;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
}

/*****geocoder----*/
/*
  .leaflet-container .geocoder-control-input {
    background-image: url("https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/search-512.png");
  }
  
  .leaflet-container .geocoder-control-input-disabled {
    background-image: url("/images/search-disabled.png");
  }
  
  .leaflet-container .geocoder-control-input.geocoder-control-loading {
    background-image: url("https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif");
  }
  
  @media only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2 / 1),
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min-device-pixel-ratio: 2) {
    .leaflet-container .geocoder-control-input {
      background-image: url("/images/search@2x.png");
    }
  
    .leaflet-container .geocoder-control-input-disabled {
      background-image: url("/images/search@2x-disabled.png");
    }
  
    .leaflet-container .geocoder-control-input.geocoder-control-loading {
      background-image: url("/images/loading@2x.gif");
    }
  }*/


/*-------*/
.leaflet-touch .leaflet-bar a:last-child {
  color: #4e4e4e;
}

/*-----------*/

.marker-pin {
  width: 44px;
  height: 44px;
  border-radius: 50% 50% 50% 0;
  background: #a2ff7e;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.marker-pin img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  position: relative;
  transform: rotate(45deg);
  left: 2px;
  top: 2px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  animation: alex 65s infinite linear;
}

@keyframes alex {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*
  .marker-pin .marker-state {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: linear-gradient(45deg, #66f, #f80, #ffc);
    position: relative;
    left: 1px;
    top: 1px;
    animation: alex 60s infinite linear;
  }*/

/*
  .marker-pin::after {
    content: "";
    width: 24px;
    height: 24px;
    margin: 3px 0 0 3px;
    background: #fff;
    position: absolute;
    border-radius: 50%;
  }*/

.content-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-popup h1 {
  margin: 3px auto;
  font-size: 22px;
  letter-spacing: 4px;
  text-shadow: black 0.1em 0.1em 0.2em
}

.content-popup .content-ppopu-img {
  display: flex;
  width: 100%;
  margin-bottom: 5px;
}

.content-popup .content-ppopu-img img {
  border-radius: 5px;
  height: 150px;
  margin: 2px auto;
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.content-popup span {
  color: rgb(100, 100, 100);
  font-size: 13px;
  font-weight: 500;
}

.cart-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.cart-btns .add-cart {
  background: rgb(192, 25, 25);
  color: #fff;
  width: 90px;
  height: 30px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 2px;
}

.cart-btns .add-cart i {
  font-size: 17px;
  margin-right: 5px;
}

.cart-btns .buy-now {
  background: #25d366;
  margin-left: 10px;
}

/*----------*/

.Ubicate-map .float {
  position: fixed;
  width: 52px;
  height: 52px;
  left: 3%;
  top: 40%;
  background-color: #25d366;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: btnrot 10s infinite linear;
}

@keyframes btnrot {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.my-float {
  margin-top: 16px;
}
.avatar-suger-carousel {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .cards-carousel {
    position: relative;
    width: 80px;
    height: 80px;
    border: 4px solid #fdfdfd;
    border-radius: 50%;
    margin: 15px auto;
  }

  .cards-carousel::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 15px);
    height: calc(100% + 15px);
    background-image:
    linear-gradient(45deg,
    #f09433, #e6683c 25%, #dc2743 50%, #6902be 75%, #bc1888 100%);
    border-radius: inherit;
    z-index: -1;
}

.carrousel_usuarios_avisos {
  width: 94%;
  margin: 0 auto;
}

.carousel_contactos_inte {
  width: 94%;
  margin: 0 auto;
}

.product_img{
    max-width: 300px;
    overflow: hidden;
    height: 220px;
    padding: 5px;
    box-shadow: 0 0 15px #ddd;
    margin: 10px 0;
    position: relative;
}
.product_img img{
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    border-radius: 15px;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.product_img:hover {
	transform: translateY(0.1rem) scale(1);
	box-shadow: 0 0.5em 3rem -1rem rgba(0,0,0,0.5);
}

.product_img h2 {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    color: rgb(233, 233, 233);
    text-shadow: 1px 1px 2px black;
}

#view{
    color: #FCFCFC;
    position: relative;
    top: -18%;
    right: -12%;
    font-size: 14px;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.title-avisos-home {
    color: rgb(120, 121, 121);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 500;
    margin: 5px 2px 0px 4px;
}
.anuncio_modal {
  position: fixed;
  top: 0;
  left: 0;
  background: #0008;
  z-index: 10000;
  width: 100%;
  height: 100vh;
  overflow: auto;
}

.anuncio_modal form {
  max-width: 680px;
  width: 98%;
  background: white;
  margin: 30px auto;
  padding: 20px;
  border-radius: 5px;
}

.anuncio_body .parrafo_initial {
  font-size: 18px;
  font-family: 'Kanit', sans-serif;
  color: rgb(218, 86, 86);
}

.anuncio_body .ubication_map {
  width: 100%;
  height: 500px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.anuncio_modal .anuncio_body h6 {
  font-size: 16px;
  margin: 10px 12px;
  font-family: 'Lilita One', cursive;
  font-weight: 500;
  color: rgb(108, 109, 109);
}

.anuncio_modal .anuncio_body span {
  font-size: 16px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: rgb(100, 100, 100);
}

.anuncio_modal .anuncio_body .content_datos_user {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4px;
  gap: 4px;
  margin: 8px 0px;
}

.anuncio_modal .anuncio_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  transform: translateY(-13px);
}

.anuncio_header span {
  font-size: 2rem;
  font-weight: 900;
  cursor: pointer;
  transform: translateY(-5px);
}

.anuncio_body textarea {
  width: 100%;
  min-height: 100px;
  border: none;
  outline: none;
  resize: none;
}

.anuncio_body .input_images {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.anuncio_body i {
  font-size: 2rem;
  cursor: pointer;
}

.anuncio_body .input_images .file_upload {
  overflow: hidden;
  margin: 0 10px;
  position: relative;
}

.anuncio_body .input_images .file_upload #file {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.anuncio_body .Content_Check {
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px;
  margin: 6px 0px;
  background: rgb(243, 241, 241);
}

.anuncio_body .Descripcion_anuncion {
  width: 100%;
  margin: 8px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.anuncio_body .Descripcion_anuncion .titulo_anuncio_pr {
  grid-column: 1/4;
  margin: 5px 0;
}

.anuncio_body .Descripcion_anuncion .titulo_anuncio_pr input {
  width: 98%;
  margin: 0px auto;
}

.anuncio_body .Descripcion_anuncion .textearea_content {
  grid-column: 1/4;
}

.anuncio_body .Descripcion_anuncion .textearea_content textarea {
  border: solid 1px rgb(184, 182, 182);
}

.anuncio_body .Descripcion_anuncion .textearea_content #dcorta {
  height: 50px;
}

.anuncio_body .Descripcion_anuncion .textearea_content #descripcion {
  height: 220px;
}

.anuncio_body .Descripcion_anuncion input {
  margin: 2px;
}

.anuncio_body .Descripcion_anuncion .input_mobile_anuncio {
  grid-column: 1/4;
  margin: 6px 0px;
}

.anuncio_body .content_ubicacion {
  width: 100%;
  margin: 8px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2px;
  gap: 2px;
}

.anuncio_body .content_ubicacion .input_coordenadas {
  display: flex;
  justify-content: center;
  margin: 12px 0px;
  grid-column: 1/3;
  position: relative;
}

.content_ubicacion .input_coordenadas .coordenas_inputs {
  display: flex;
  flex-direction: column;
  position: relative;
}

.content_ubicacion .input_coordenadas .coordenas_inputs .coordenas_div {
  display: flex;
  flex-direction: column;
  margin: 8px 0px;
  padding: 4px 30px;
  position: absolute;
  z-index: 2000;
  background-color: #acacaccb;
  top: 60px;
  right: 0;
  border-radius: 10px;
}

.anuncio_body .content_ubicacion .input_coordenadas .coordenadas_latlong {
  width: 50%;
  margin: 2px;
}

.anuncio_body .content_detail_ad textarea {
  border: solid 1px rgba(180, 180, 180, 0.712);
  padding: 8px;
  border-radius: 10px;
  height: 50px;

}

.anuncio_body .content_servicios {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 6px 0px;
  padding: 10px;
  background: rgb(236, 236, 236);
}

.anuncio_body .show_images {
  max-height: 270px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  place-items: center;
  padding: 10px 0;
  grid-gap: 10px;
}

.anuncio_body .show_images #file_img {
  position: relative;
  width: 100%;
  height: 100%;
}

.anuncio_body .show_images #file_img img,
.anuncio_body .show_images #file_img video {
  display: block;
  object-fit: contain;
  width: 100%;
  height: 100%;
  max-height: 100px;
}

.anuncio_body .show_images #file_img span {
  position: absolute;
  top: -1px;
  right: -2px;
  z-index: 4;
  background: white;
  color: crimson;
  padding: 3px 7px;
  border: 1px solid crimson;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bolder;
  cursor: pointer;
}

.anuncio_body .stream span {
  position: absolute;
  top: -10px;
  right: 5px;
  color: crimson;
  font-size: 2rem;
  font-weight: 900;
  cursor: pointer;
}

.anuncio_body .dropdown-menu {
  transform: translate3d(-206px, -237px, 0px) !important;
}

.style-picker-status {
  position: absolute;
  z-index: 100;
}

/************/
.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #89849b;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}

.pin:after {
  content: '';
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #2f2f2f;
  position: absolute;
  border-radius: 50%;
}

.pulse {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}

.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  box-shadow: 0 0 1px 2px #89849b;
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }

  50% {
    opacity: 1;
    -ms-filter: none;
    -webkit-filter: none;
            filter: none;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    -ms-filter: none;
    -webkit-filter: none;
            filter: none;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}

.anuncio_statusP_veranuncio {
  max-width: 50vw;
  margin: 5px auto;
}

/****/
.input_contet_form label {
  font-size: 11px;
  margin-left: 5px;
  color: #ff7777;
}

.input_contet_CO {
  width: 40%;
}

#simbolprecio {
  border: none;
  height: 40px;
  color: #5c5c5c;
}

.content_textarea_co {
  margin: 14px auto;
  padding: 3px;
}

#estyle_back_ {
  margin: 8px auto;
}

#estyle_back_err {
  background: #ffd3d396;
}

#input-group_mb-3 {
  display: flex;
  flex-direction: column;
}

#input-group_mb-3 label {
  font-size: 12px;
  color: #89849b;
}

/**************/

.btn_btn-secondar-sumit {
  position: relative;
  display: inline-block;
  border: none;
  background-color: #2196F3;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  margin: 10px auto;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.320, 1);
}

.btn_btn-secondar-sumit:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120%;
  height: 120%;
  background-color: rgb(206, 206, 206);
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.320, 1);
}

.btn_btn-secondar-sumit span {
  position: relative;
  z-index: 1;
}

.btn_btn-secondar-sumit:hover {
  color: #2196F3;
  background-color: rgb(224, 223, 223);
  box-shadow: 0 0 20px hsla(263, 77%, 31%, 0);
}

.btn_btn-secondar-sumit:active {
  scale: 0.9;
}

.btn_btn-secondar-sumit:hover:before {
  transform: translate(-50%, -50%) rotate(45deg);
  opacity: 1;
}






/**************/


@media (max-width:725px) {

  .anuncio_modal form {
    max-width: 680px;
    width: 96%;
  }

  .anuncio_body .content_servicios {
    font-size: 13.5px;
  }

  .content_ubicacion .input_coordenadas .coordenas_inputs .coordenas_div {
    font-size: 15px;
    display: flex;
    flex-direction: column;
    width: 50%;
    overflow: hidden;
    margin: 8px;
    padding: 4px 10px;
  }

  .anuncio_body .content_ubicacion .input_coordenadas .coordenadas_latlong {
    margin: 8px 25px;
    width: 90%;
  }

  .anuncio_modal .anuncio_body .content_datos_user {
    display: grid;
    grid-template-columns: 1fr;
    margin: 10px 0px;
  }

  .anuncio_body .Content_Check {
    display: flex;
    width: 100%;
  }

  .anuncio_body .Descripcion_anuncion {
    display: flex;
    flex-direction: column;
  }

  .anuncio_statusP_veranuncio {
    max-width: 98%;
    width: 98%;
  }

  #statusBtnPubli {
    font-size: 12px;
  }

  .anuncio_modal .anuncio_body span {
    font-size: 17.5px;
  }
}
.container_publicar_anuncio {
    width: 100%;
    max-width: 1025px;
    margin: 0 auto;
}

.container_publicar_anuncio .pricing-table-month a {
    text-decoration: none;
}

.container_publicar_anuncio .pricing-table-month a:hover {
    color: #ABB8C0;
}

.container_publicar_anuncio .pricing-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 5px;
}

.container_publicar_anuncio .pricing-wrap .pricing-table {
    width: 60%;
    margin: 10px auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/*****/

/*--blog----*/

.sec-title {
    position: relative;
    margin-bottom: 70px;
}

.sec-title .title {
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 1em;
    color: #ff8a01;
    font-weight: 500;
    background: rgb(247, 0, 104);
    background: linear-gradient(to left,
            rgba(247, 0, 104) 0%,
            rgba(68, 16, 102, 1) 25%,
            rgba(247, 0, 104, 1) 75%,
            rgba(68, 16, 102, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F70068', endColorstr='#441066', GradientType=1);
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 15px;
}

.sec-title h2 {
    position: relative;
    display: inline-block;
    font-size: 30px;
    line-height: 1.2em;
    color: #414141;
    font-weight: 700;
}

.sec-title .text {
    position: relative;
    font-size: 16px;
    line-height: 28px;
    color: #888888;
    margin-top: 30px;
}

.sec-title.light h2,
.sec-title.light .title {
    color: #ffffff;
    -webkit-text-fill-color: inherit;
}

.pricing-section {
    position: relative;
    padding: 100px 0 80px;
    overflow: hidden;
}

.pricing-section .outer-box {
    max-width: 1100px;
    margin: 0 auto;
}

.pricing-section .row {
    margin: 0 -30px;
    display: flex;
    justify-content: space-around;
}

.pricing-block {
    position: relative;
    padding: 0 30px;
    margin-bottom: 40px;
}

.pricing-block .inner-box {
    position: relative;
    background-color: #ffffff;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
    padding: 0 0 30px;
    max-width: 370px;
    margin: 0 auto;
    border-bottom: 20px solid #40cbb4;
}

.pricing-block .icon-box {
    position: relative;
    padding: 50px 30px 0;
    background-color: #40cbb4;
    text-align: center;
}

.pricing-block .icon-box:before {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 75px;
    width: 100%;
    border-radius: 50% 50% 0 0;
    background-color: #ffffff;
    content: "";
}

.pricing-block .icon-box .icon-outer {
    position: relative;
    height: 150px;
    width: 150px;
    background-color: #ffffff;
    border-radius: 50%;
    margin: 0 auto;
    padding: 10px;
}

.pricing-block .icon-box i {
    position: relative;
    display: block;
    height: 130px;
    width: 130px;
    line-height: 120px;
    border: 5px solid #40cbb4;
    border-radius: 50%;
    font-size: 50px;
    color: #40cbb4;
    transition: all 600ms ease;
}

.pricing-block .inner-box:hover .icon-box i {
    transform: rotate(360deg);
}

.pricing-block .price-box {
    position: relative;
    text-align: center;
    padding: 10px 20px;
}

.pricing-block .title {
    position: relative;
    display: block;
    font-size: 24px;
    line-height: 1.2em;
    color: #222222;
    font-weight: 600;
}

.pricing-block .price {
    display: block;
    font-size: 30px;
    color: #222222;
    font-weight: 700;
    color: #40cbb4;
}

.pricing-block .features {
    position: relative;
    max-width: 230px;
    margin: 0 auto 20px;
}

.pricing-block .features li {
    position: relative;
    display: block;
    font-size: 14px;
    line-height: 30px;
    color: #848484;
    font-weight: 500;
    padding: 5px 0;
    padding-left: 30px;
    border-bottom: 1px dashed #dddddd;
}

.pricing-block .features li:before {
    position: absolute;
    left: 0;
    top: 50%;
    font-size: 16px;
    color: #2bd40f;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    content: "\f058";
    font-family: "Font Awesome 5 Free";
    margin-top: -8px;
}

.pricing-block .features li.false:before {
    color: #e1137b;
    content: "\f057";
}

.pricing-block .features li a {
    color: #848484;
}

.pricing-block .features li:last-child {
    border-bottom: 0;
}

.pricing-block .btn-box {
    position: relative;
    text-align: center;
}

.pricing-block .btn-box a {
    position: relative;
    display: inline-block;
    font-size: 14px;
    line-height: 25px;
    color: #ffffff;
    font-weight: 500;
    padding: 8px 30px;
    background-color: #40cbb4;
    border-radius: 10px;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    transition: all 300ms ease;
    cursor: pointer;
}

.pricing-block .btn-box a:hover {
    color: #ffffff;
}

.pricing-block .inner-box:hover .btn-box a {
    color: #40cbb4;
    background: none;
    border-radius: 0px;
    border-color: #40cbb4;
}

.pricing-block:nth-child(2) .icon-box i,
.pricing-block:nth-child(2) .inner-box {
    border-color: #1d95d2;
}

.pricing-block:nth-child(2) .btn-box a,
.pricing-block:nth-child(2) .icon-box {
    background-color: #1d95d2;
}

.pricing-block:nth-child(2) .inner-box:hover .btn-box a {
    color: #1d95d2;
    background: none;
    border-radius: 0px;
    border-color: #1d95d2;
}

.pricing-block:nth-child(2) .icon-box i,
.pricing-block:nth-child(2) .price {
    color: #1d95d2;
}

.pricing-block:nth-child(3) .icon-box i,
.pricing-block:nth-child(3) .inner-box {
    border-color: #ffc20b;
}

.pricing-block:nth-child(3) .btn-box a,
.pricing-block:nth-child(3) .icon-box {
    background-color: #ffc20b;
}

.pricing-block:nth-child(3) .icon-box i,
.pricing-block:nth-child(3) .price {
    color: #ffc20b;
}

.pricing-block:nth-child(3) .inner-box:hover .btn-box a {
    color: #ffc20b;
    background: none;
    border-radius: 0px;
    border-color: #ffc20b;
}

/****/

.pricing-table .pricing-table-month {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #fff;
    text-align: center;
    font-size: 14px;
    display: flex;
}

.pricing-table-month-one {
    width: 40%;
}

.pricing-table-month-one.basico {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pricing-table-month-two {
    width: 60%;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.pricing-table .pricing-table-month ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.pricing-table-month .pricing-table-head {
    color: #173D50;
    padding: 25px 0px;
}

.pricing-table-month .pricing-table-head h2 {
    font-size: 16px;
    letter-spacing: 2px;
    font-weight: bold;
}

.pricing-table-month .pricing-table-head h3 {
    font-size: 50px;
    font-weight: 700;
    display: inline;
    text-shadow: 2px 1px 6px rgba(62, 101, 99, 0.69)
}

.pricing-table-month .pricing-table-head h3 sup,
.pricing-table-head h3 sub {
    font-size: 20px;
    color: #ABB8C0;
    font-weight: 600;
}

.pricing-table-month .pricing-table-head h3 sub {
    font-size: 13px;
}

.pricing-table-month .pricing-table-head.estandar-title h2,
.pricing-table-month .pricing-table-head.estandar-title h3,
.pricing-table-month .pricing-table-head.estandar-title h3 sup,
.pricing-table-month .pricing-table-head.estandar-title h3 sub {
    color: #E97D68;
}


.pricing-table-list li {
    background: #F1F3F5;
    padding: 10px 0;
}

.pricing-table-list li:nth-child(2n) {
    background: #fff;
}

.pricing-table-button {
    display: block;
    width: 100%;
    padding: 20px 0;
    background: #0C1F28;
    color: #fff;
    margin-top: 23px;
    cursor: pointer;
    transition: all 1s ease;
}

.pricing-table-button:hover {
    background: #ffb444;
    color: rgb(255, 1, 1);
    box-shadow: rgba(238, 107, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.pricing-table-button.estandar {
    background: #E97D68;
    color: white;
    font-weight: 600;
}

.pricing-table-button.estandar:hover {
    background-color: #18fd04;
    box-shadow: rgba(95, 238, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.container_publicar_anuncio .header_precios {
    margin: 20px 2px 25px;
    color: #f7f7f7;
    padding: 5px 10px;
    background: hsla(141, 81%, 87%, 1);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background: linear-gradient(90deg, hsla(141, 81%, 87%, 1) 0%, hsla(41, 88%, 75%, 1) 50%, hsla(358, 82%, 71%, 1) 100%);

    background: -webkit-linear-gradient(90deg, hsla(141, 81%, 87%, 1) 0%, hsla(41, 88%, 75%, 1) 50%, hsla(358, 82%, 71%, 1) 100%);

    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#C5F9D7", endColorstr="#F7D486", GradientType=1);
}

.header_precios li {
    margin: 10px 0;
    font-weight: 600;
    color: #2c2c2c;
    text-shadow: 0.1em 0.1em 0.2em rgba(15, 15, 15, 0.575);
    list-style: none;

}

.container_publicar_anuncio .preci_suscripción {
    max-width: 94%;
    margin: 8px auto;
    background: white;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/**************/
.container_publicar_anuncio .title_publicación {
    margin: 15px auto;
    font-size: 30px;
    line-height: 1.2em;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: rgb(95, 95, 95);
    text-align: center;
}

/************/

.preci_suscripción .price-table.dos {
    display: none;
}

.preci_suscripción .price-table {
    width: 100%;
    border-collapse: collapse;
    border: 0 none;
}

.preci_suscripción .price-table a {
    color: red;
}

.preci_suscripción .price-table tr:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}

.preci_suscripción .price-table tr td {
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    padding: 8px 24px;
    font-size: 14px;
}

.preci_suscripción .price-table tr td:first-child {
    border-left: 0 none;
}

.preci_suscripción .price-table tr td:not(:first-child) {
    text-align: center;
}

.preci_suscripción .price-table tr:nth-child(even) {
    background-color: #FFFFFF;
}

.preci_suscripción .price-table tr:hover {
    background-color: #EEEEEE;
}

.preci_suscripción .price-table .fa-check {
    color: #f7351b;
}

.preci_suscripción .price-table .fa-times {
    color: #D8D6E3;
}

/* Highlighted column */
.preci_suscripción .price-table tr:nth-child(2n) td:nth-child(3) {
    background-color: rgba(216, 214, 227, 0.25);
}

.preci_suscripción .price-table tr td:nth-child(3) {
    background-color: rgba(216, 214, 227, 0.15);
    padding: 8px 48px;
}

.preci_suscripción .price-table tr.price-table-head td {
    font-size: 16px;
    font-weight: 600;
    font-family: "Montserrat";
    text-transform: uppercase;
}

.preci_suscripción .price-table tr.price-table-head {
    background-color: #5336ca;
    color: #FFFFFF;
}

.preci_suscripción .price-table td.price {
    color: #f43f54;
    padding: 16px 24px;
    font-size: 20px;
    font-weight: 600;
    font-family: "Montserrat";
}

.preci_suscripción .price-table td.price a {
    background-color: #e97d68;
    color: #FFFFFF;
    padding: 12px 32px;
    margin-top: 16px;
    font-size: 12px;
    font-weight: 600;
    font-family: "Montserrat";
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    border-radius: 64px;
    transition: all 0.2s;
}

.preci_suscripción .price-table td.price a:hover {
    background-color: #ebe1df;
    color: #e97d68;
    border: solid 1px #e97d68;
}

.preci_suscripción .price-table td.price-table-popular {
    font-family: "Montserrat";
    border-top: 3px solid #e97d68;
    color: #e97d68;
    text-transform: uppercase;
    font-size: 12px;
    padding: 12px 48px;
    font-weight: 700;
}

.preci_suscripción .price-table .price-blank {
    background-color: #fafafa;
    border: 0 none;
}

.preci_suscripción .price-table svg {
    width: 90px;
    fill: #5336ca;
}

/*************************/

.pricing-wrap .miswitch_model {
    height: 100vh;
    width: 100%;
    background: rgba(17, 17, 17, 0.514);
    position: fixed;
    max-width: 1025px;
    top: 0;
    z-index: 12000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.miswitch_model .miswitch_model_btn {
    height: 10%;
    width: 25%;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.miswitch_model_btn i {
    font-size: 60px;
    cursor: pointer;
}

#close_btn_anuncios {
    margin-left: 30%;
    font-size: 25px;
    background: crimson;
    border-radius: 50%;
    color: #ffefef;
    cursor: pointer;
}

#infor_public_anuncio {
    margin: 10px;
    color: #ffffff;
    text-shadow: 2px 2px 4px rgb(32, 32, 32);
    font-size: 20px;
    font-weight: 700;
}

.btn_red_precio1,
.btn_red_precio2 {
    background: #fff;
    padding: 8px;
    margin: 0px 4px;
    border-radius: 8px;
    transition: all 0.7s ease;
}

.btn_red_precio1:hover {
    background: rgb(255, 192, 168);
    color: #686868;
    box-shadow: 4px 4px 8px rgba(255, 213, 158, 0.438);
}

.btn_red_precio2:hover {
    background: rgb(255, 192, 168);
    color: #686868;
    box-shadow: 4px 4px 8px rgba(255, 213, 158, 0.281);
}

.btn_red_precio1 {
    color: rgb(62, 139, 255);
}

.btn_red_precio2 {
    color: rgb(3, 175, 46);
}

@media screen and (max-width: 750px) {
    .pricing-section .container {
        width: 85%;
    }

    .sec-title {
        margin-bottom: 10px;
    }

    .pricing-section {
        padding: 30px 0;
    }

    .sec-title h2 {
        font-size: 20px;
    }

    .miswitch_model .miswitch_model_btn {
        height: 12%;
        width: 40%;
    }

    #infor_public_anuncio {
        margin: 10px;
        font-size: 12px;
    }

    #close_btn_anuncios {
        margin-left: 54%;
    }

    .pricing-wrap .miswitch_model {
        left: 0;
    }
}


@media screen and (max-width: 750px) {

    .container_publicar_anuncio {
        width: 98%;
    }

    .container_publicar_anuncio .pricing-wrap {
        flex-direction: column;
        width: 98%;
        margin: 5px auto;

    }

    .pricing-table-month-one {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }

    .container_publicar_anuncio .pricing-wrap .pricing-table {
        margin: 20px auto;
        width: 100%;
    }

    .pricing-table-month .pricing-table-head h2 {
        font-size: 8px;
    }

    .pricing-table .pricing-table-month {
        font-size: 11px;
    }

    .pricing-table-month .pricing-table-head {
        padding: 5px 0px;
    }

    .pricing-table-month .pricing-table-head h3 {
        font-size: 22px;
    }

    .pricing-table-month .pricing-table-head h3 sup,
    .pricing-table-head h3 sub {
        font-size: 13px;
    }

    .pricing-table-list li {
        padding: 5px 0;
    }

    .pricing-table-button {
        padding: 10px 0;
        background: #0C1F28;
        color: #fff;
        margin-top: 5px;
    }

    .container_publicar_anuncio .header_precios {
        margin: 0px 2px;
        margin-bottom: 100px;
    }

    .container_publicar_anuncio .header_precios li {
        margin: 5px 0;
        font-size: 12px;
    }

    .preci_suscripción .price-table td.price a {
        font-size: 10px;
        margin-top: 0px;
        padding: 10px 30px;
    }

    .preci_suscripción .price-table tr td {
        font-size: 10.2px;
    }

    .preci_suscripción .price-table td.price-table-popular {
        font-size: 10px;
    }

    .container_publicar_anuncio .title_publicación {
        margin: 15px 10px;
        font-size: 17px;
    }

    .preci_suscripción .price-table.dos {
        display: block;
    }

    .preci_suscripción .price-table.uno {
        display: none;
    }
}
/*
========================
========================
          MENU
========================
========================
*/

.styles_contactanos {
    max-width: 1025px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    margin: 50px auto;
}

.styles_contactanos .container {
    padding: 8px auto;
}

.styles_contactanos .sideMenu {
    /*big menu*/
    position: fixed;
    z-index: 1;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: #272125;
    width: 200px;
    height: 70%;
    top: 90px;
    left: 5px;
    box-shadow: 1px 5px 10px 5px #888888;
    transition: 4s;
}

.styles_contactanos .sideMenuMinimized {
    /*small menu*/
    position: fixed;
    background-color: #272125;
    width: 90px;
    height: 100%;
    top: 0;
    left: 0;
    box-shadow: 1px 5px 10px 5px #888888;
    transition: 4s;
}

.styles_contactanos .iconsTopMenu {
    /*button resize menu*/
    color: white;
    cursor: pointer;
    font-size: 30px;
    padding-right: 7px;
    margin-right: -10px;
}

.containerContent .info_contactos_p span {
    font-size: 15px;
    color: red;
    font-weight: 500;
    letter-spacing: 2px;
}

.styles_contactanos .animatedbounce {
    font-size: 23px;
    margin-bottom: 12px;
}

.styles_contactanos .imgMenu {
    /*perfil image*/
    margin-top: 5px;
    border-radius: 50%;
    animation-name: swing;
}

.styles_contactanos .imgMenu:hover {
    /*perfil image on mouse over*/
    animation-name: jello;
}

.styles_contactanos #imgPerfil {
    /*best position top image*/
    padding-top: 30px;
}

.styles_contactanos .myNameMenu {
    /*Eduardo Mondragon*/
    padding-top: 5px;
    text-align: center;
    color: white;
}

.styles_contactanos .especified {
    /*web developer*/
    padding-top: 5px;
    color: #bdc3c7;
}

.styles_contactanos .hrMenu {
    /*divisor between web developer and menu controls*/
    margin-top: 10px;
    background-color: #95a5a6;
    width: 70%;
}

.styles_contactanos .menuModules {
    /*=BIG MENU=About Me - My Works - My Tools - Contact Me*/
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #424949;
    line-height: 45px;
    font-size: 35px;
    font-weight: 600;
}

.styles_contactanos .menuModules i {
    color: #bbbbbb;
    cursor: pointer;
}

.styles_contactanos .menuModulesMinimized {
    /*=SMALL MENU=About Me - My Works - My Tools - Contact Me*/
    display: block;
    line-height: 65px;
    font-size: 30px;
}

.styles_contactanos ul li a {
    /*=TEXT=About Me - My Works - My Tools - Contact Me*/
    color: #c0392b;
    text-align: center;
    display: block;
}

.styles_contactanos ul li a:hover {
    /*=TEXT ON MOUSE OVER=About Me - My Works - My Tools - Contact Me*/
    text-decoration: none;
    color: #bdc3c7;
}

.styles_contactanos ul li a:focus {
    /*=TEXT ON CLICK= About Me - My Works - My Tools - Contact Me*/
    text-decoration: none;
    color: #bdc3c7;
}

.styles_contactanos li {
    /*BOTH SIDES TRANSPARENTS LINES = About Me - My Works - My Tools - Contact Me*/
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    margin-left: -40px;
}

.styles_contactanos li:hover {
    /*BOTH SIDES RED LINES ON MOUSE OVER = About Me - My Works - My Tools - Contact Me*/
    border-left: 3px solid #c0392b;
    border-right: 3px solid #c0392b;
}

.containerContent .row {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*
  ========================
  ========================
          GENERAL
  ========================
  ========================
  */

.styles_contactanos .transition {
    transition: 0.2s;
}

.styles_contactanos .hide {
    visibility: hidden;
}

.styles_contactanos .visible {
    visibility: visible;
}

.styles_contactanos .containerContent {
    margin: 15px auto;
    display: flex;
}

.styles_contactanos .smallMenuC {
    max-width: 1033px;
    margin-left: -70px;
}

.styles_contactanos .bigMenuC {
    max-width: 1095px;
    margin-left: -80px;
}

.styles_contactanos .myWorks_divs {
    display: flex;
    justify-content: space-between;
    margin: 12px auto;
    padding: 5px;
}

.styles_contactanos .myWorks_divs .myWorks_divs_info {
    display: flex;
    align-items: center;
    margin: 0 3px;
}

.myWorks_divs .myWorks_divs_info h3 {
    font-size: 17px;
    color: #424949;
    margin-right: 5px;
}

.myWorks_divs .myWorks_divs_info span {
    color: #641E16;
}

#contactMe{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 5px;
}

#contactMe .pull-right {
    color: #424949;
    font-size: 27px;
}

#contactMe label {
    color: #943126;
}

#contactMe .followSpaceTop {
    display: flex;
    justify-content: center;
    margin: 25px auto;
}

.followSpaceTop .followSpaceTop_div {
    width: 50px;
    height: 50px;
    margin: 5px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    cursor: pointer;
    color: #909497;
}

.followSpaceTop .followSpaceTop_div a {
    color: #909497;
}

/*
  ========================
  ========================
           BODY
  ========================
  ========================
  */

.styles_contactanos .myContainerOpen {
    margin-left: 300px;
    margin-top: 30px;
    transition: 0.2s;
}

.styles_contactanos .myContainerClose {
    margin-left: 200px;
    margin-top: 30px;
    transition: 0.2s;
}

.styles_contactanos .moduleTopSpace {
    margin-top: 100px;
}


/*
  ========================
  ========================
          ABOUT ME
  ========================
  ========================
  */

.styles_contactanos .aboutTitle {
    opacity: 0.9;
    border-left: 4px solid #c0392b;
    padding-left: 15px;
}

.styles_contactanos .someText {
    text-align: justify;
    padding-top: 20px;
    font-size: 22px;
    opacity: 0.9;
}

.styles_contactanos .aboutImg {
    top: 70px;
    width: 70%;
    left: 31%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.aboutRedDiv .title_fnas {
    width: 50%;
    margin: 40px auto;
}
.aboutRedDiv .title_fnas h3 {
    display: inline-block;
    color: #000;
    background: #fff;
    mix-blend-mode: multiply;
    position: relative;
    font-weight: 700;
    font-family: cursive;
    font-size: 45px;
    text-shadow: 1px 1px 2px rgb(112 112 112);
}

.aboutRedDiv .title_fnas h3::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgb(255, 51, 0), rgb(248, 182, 38), rgb(255, 136, 0));
    mix-blend-mode: screen;
    position: absolute;
    top: 0;
    left: 0;
}

.styles_contactanos .redButton {
    margin-left: -15px !important;
    border: 1px solid #c0392b;
    background-color: #c0392b;
    border-radius: 0px;
    width: 113.5%;
    cursor: pointer;
}

.styles_contactanos .redButton:hover {
    background-color: #c0392b;
    border: 1px solid #c0392b;
}

.styles_contactanos .redButton:focus {
    outLine: 0;
    box-shadow: none;
}

.styles_contactanos .greenButton {
    margin-left: -15px !important;
    border: 1px solid #272125;
    background-color: #272125;
    border-radius: 0px;
    width: 113.5%;
    cursor: pointer;
}

.styles_contactanos .greenButton:hover {
    border: 1px solid #272125;
    background-color: #272125;
}

.styles_contactanos .greenButton:focus {
    outLine: 0;
    box-shadow: none;
}


/*
  ========================
  ========================
          MY WORKS
  ========================
  ========================
  */

.styles_contactanos .firstImgMyWorks {
    width: 100%;
    border: 4px solid white;
    box-shadow: 0 7px 65px 0 rgba(0, 0, 0, 0.12);
    margin-bottom: 15px;
}

.styles_contactanos .myWorksAnimation:hover {
    animation-name: swing;
}

/*
  ========================
  ========================
          MY TOOLS
  ========================
  ========================
  */

.styles_contactanos .img-circle {
    border-radius: 50%;
    border: 6px solid #272125;
}

.styles_contactanos .imgAnimated:visited {
    transition: 5s;
    animation-name: flipInX;
}

@media (max-width:750px) {
    .styles_contactanos .sideMenu {
        display: none;
    }
    .styles_contactanos .container {
        display: flex;
        flex-direction: column;
    }

    #cold-md-ou {
        display: none;
    }

    .styles_contactanos .myWorks_divs {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        margin: 5px auto;
    }

    .styles_contactanos .myWorks_divs h3 {
        font-size: 13.5px;
    }

    .styles_contactanos .animatedbounce {
        font-size: 16px;
    }

    .info_contactos_p {
        font-size: 14px;
    }

    #contactMe .pull-right {
        font-size: 15px;
    }
}
.terminos_condiciones {
    max-width: 1025px;
    width: 100%;
    margin: 10px auto;
    background: white;
    border-radius: 5px;
    padding: 15px;
}

.terminos_condiciones h2 {
    color: rgb(170, 41, 2);
    font-size: 22px;
    margin: 6px 8px;
}

.terminos_condiciones p {
    margin: 5px auto;
    max-width: 80%;
    text-align: justify;
    font-size: 18px;
    color: #424949;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.terminos_condiciones ol {
    max-width: 90%;
    text-align: justify;
    padding: 10px;
    margin: 5px auto;
    font-size: 18px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.terminos_condiciones ol li {
    margin: 10px 0;
    color: #283747;
    font-family: Arial, Helvetica, sans-serif;
}

@media (max-width:725px) {

    .terminos_condiciones h2 {
        color: rgb(170, 41, 2);
        font-size: 16px;
        margin: 6px 8px;
    }

    .terminos_condiciones p {
        max-width: 90%;
        font-size: 14px;
    }

    .terminos_condiciones ol {
        font-size: 14px;
    }

    .terminos_condiciones ol li {
        margin: 10px 0;
        font-size: 13px;
    }
}

.terminos_condiciones .div_title_poli h3 {
    font-size: 24px;
    color: #626567;
}

.terminos_politicas {
    max-width: 1025px;
    margin: 5px auto;
    background: white;
    text-align: justify;
}

.terminos_condiciones .div_title_poli span {
    color: #8b8b8b;
    font-size: 16px;
    margin-left: 10px;
}

.terminos_condiciones .div_title_poli i {
    margin-right: 5px;
}

.terminos_politicas .accordion-item h3 {
    color: #707B7C;
    font-size: 18px;
}
.terminos_politicas .accordion-item h3 i {
    margin-right: 5px;
}

.terminos_politicas .accordion-item .accordion-body {
    color: #424949;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-align: justify;
}

.terminos_politicas .accordion-item .accordion-body h4 {
    font-size: 16px;
    color: #CD6155;
}

@media (max-width: 750px) {
    .politicas_condiciones_page {
        margin-bottom: 94px;
    }
    .terminos_condiciones .div_title_poli h3 {
        font-size: 15.5px;
    }
    .terminos_condiciones .div_title_poli span {
        font-size: 12px;
    }
    .terminos_politicas {
        width: 92%;
    }
    .terminos_politicas .accordion-item h3 {
        font-size: 12px;
    }
    .terminos_politicas .accordion-item .accordion-body p {
        font-size: 12.5px;
    }

    .terminos_politicas .accordion-item .accordion-body li {
        font-size: 12px;
    }

    .terminos_politicas .accordion-item .accordion-body span {
        font-size: 12px;
    }

    .terminos_politicas .accordion-item p {
        font-size: 12.5px;
    }

    .terminos_politicas .accordion-item ul li p {
        font-size: 12.5px;
    }

    .terminos_politicas .accordion-item ul li ul li {
        font-size: 12.5px;
    }

    .accordion-flush .accordion-collapse {
        width: 90%;
        margin: 0 auto;
    }
} 
.tiktok-effect {
    max-width: 1025px;
    width: 98%;
    margin: 5px auto;
    padding: 8px;
  }

  .content_linktiktok {
    width: 90%;
    /*border: solid 1px #ff0080;*/
    margin: 8px auto;
    background-color: #ffffff;
  }
  
  .content_linktiktok .tiktok-embed {
    /*border: solid 1px blue;*/
    background-color: #ffffff;
    padding: 8px;
  }

#mai_error{
    display: table;
    width: 100%;
    height: 100vh;
    text-align: center;
}

.fof__{
	  display: table-cell;
	  vertical-align: middle;
}

.fof__ h1{
	  font-size: 50px;
	  display: inline-block;
	  padding-right: 12px;
	  animation: type .5s alternate infinite;
}

@keyframes type{
	  from{box-shadow: inset -3px 0px 0px #888;}
	  to{box-shadow: inset -3px 0px 0px transparent;}
}
.fullscreen-video {
    max-width: 1025px;
    margin: 2px auto;
    display: flex;
}

.app_home .Menu_header {
    display: none;
}

/********/

.fullscreen-video .app_home {
    position: relative;
    margin: 0px auto;
    height: 90vh;
    width: 50%;
    display: grid;
    place-items: center;
}

.app__videos_inicio {
    position: absolute;
    top: 5px;
    display: flex;
    justify-content: center;
    margin: 20px auto;
    z-index: 4000;
}

.app__videos_inicio h3 {
    font-size: 20px;
    margin: 0px 10px;
    cursor: pointer;
    color: aliceblue;
    font-weight: 500;
    text-shadow: rgb(59, 59, 59) 1px 0 10px;
}

.app__videos {
    position: relative;
    height: 96%;
    margin: 5px;
    border-radius: 20px;
    overflow: scroll;
    width: 80%;
    max-width: 500px;
    -ms-scroll-snap-type: y mandatory;
        scroll-snap-type: y mandatory;
}

.app__videos::-webkit-scrollbar {
    display: none;
}

.app__videos .video {
    position: relative;
    background-color: white;
    width: 100%;
    height: 100%;
    margin: 5px 0px;
    scroll-snap-align: start;
}

.video__player {
    object-fit: fill;
    height: 100%;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.videoFooter {
    position: absolute;
    padding: 8px;
    bottom: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.videoFooter img {
    width: 35px;
    height: 35px;
    animation: rotate 4s infinite linear;
}

.videoSidebar__button a {
    color: aliceblue;
}

#text-no-video {
    position: absolute;
    font-size: 24px;
    top: 20%;
    width: 100%;
}

/*******page short hot*********/

#app_home_shorts {
    width: 72%;
    margin: 10px auto;
}


@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.videoFooter__text h3 {
    font-size: 18px;
    margin: 0;
}

.videoFooter__inicio {
    display: flex;
}

.videoFooter__inicio h2 {
    font-size: 18px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin-left: 5px;
    color: rgba(221, 221, 221, 0.925);
    text-shadow: rgb(32, 32, 32) 1px 0 10px;
}

#activebuttotik {
    font-weight: 600;
    font-size: 20px;
    color: aliceblue;
    text-decoration: underline;
}

.videoFooter__inicio img {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin: 0px 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/*------cambio-------*/
.videoFooter__inicio small {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #a3a3a3;
    text-shadow: rgb(32, 32, 32) 1px 0 10px;
}

/*-------------------*/

/*-----------modficacion--------*/
.videoFooter__ticker {
    margin: 10px;
    padding: 5px;
    max-width: 85%;
}

.videoFooter__ticker button {
    background: none;
    border: none;
    font-size: 13px;
    margin-left: 5px;
    color: #4b1500;
}

/*-------------------*/

.videoFooter__ticker span {
    font-size: 16.5px;
    color: rgb(219, 219, 219);
    margin-left: 10px;
    text-shadow: 1px 1px 2px rgba(54, 54, 54, 0.795);
}

.videoSidebar {
    position: absolute;
    top: 40%;
    left: 86%;
}

.videoSidebar .videoSidebar__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
    color: rgb(236, 236, 236);
}

.videoSidebar .videoSidebar__button i {
    font-size: 30px;
    text-shadow: rgb(114, 114, 114) 1px 0 10px;
    cursor: pointer;
}

.videoSidebar .videoSidebar__button small {
    font-size: 15px;
    text-shadow: rgb(59, 59, 59) 1px 0 10px;
}

.modal_compartir {
    background: aliceblue;
    position: absolute;
    top: 50%;
    width: 100%;
    padding: 10px 5px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.product_img .contenedor_rells {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 35px;
    border: solid 4px #f0b17aea;
    color: #BA4A00;
    border-radius: 5px;
    background: #f6ddcc65;
    cursor: pointer;
}

.product_img a:link,
.product_img a:visited,
.product_img a:hover,
.product_img a:active {
    color: #000000;
    text-decoration: none;
}

.product_img .contenedor_rells span {
    font-size: 14.5px;
    color: #A04000;
}

.product_img video {
    width: 96%;
    height: 96%;
    object-fit: cover;
    /* Ajusta el valor según tus necesidades */
    border-radius: 5px;
    position: absolute;
}

.title-avisos-home a:link,
.title-avisos-home a:visited,
.title-avisos-home a:hover,
.title-avisos-home a:active {
    color: #666666;
    text-decoration: none;
}

.carousel_contactos_inte .title-avisos-home {
    font-size: 21px;
}

.product_img .footer_short_videos {
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: 25px;
    display: flex;
    justify-content: center;
}

.product_img .footer_short_videos span {
    font-size: 19px;
    font-weight: 500;
    color: #5f5f5f;
    text-shadow: rgb(41, 41, 41) 1px 0 10px;
}

#slider_video {
    height: 500px;
}

@media (max-width:750px) {
    #sugerperfil_ {
        display: none;
    }

    .fullscreen-video .app_home {
        width: 100%;
        height: 100%;
        padding: 0;
    }

    .app__videos_inicio {
        margin: 10px auto;
    }

    .app__videos_inicio h3 {
        font-size: 18px;
        margin: 0px 10px;
    }


    .fullscreen-video {
        background: rgb(235, 146, 146);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 2000000000;
    }

    .app_home .Menu_header {
        display: block;
        background: rgba(0, 0, 0, 0.9);
        width: 100%;
        height: 60px;
        position: fixed;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
    }

    .app_home .Menu_header li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        color: rgb(224, 224, 224);
        cursor: pointer;
    }

    .app_home .Menu_header li a {
        color: rgb(224, 224, 224);
    }

    .app_home .Menu_header li i:hover {
        color: red;
    }

    .app_home .Menu_header li small {
        font-size: 12px;
    }

    .videoFooter {
        bottom: 60px;
    }

    .app__videos {
        height: 100%;
        width: 100%;
        margin: 5px;
        border-radius: 0px;
    }

    /*--------probar btn cargar más------*/
    .btn_videos_load {
        display: none;
    }

    .product_img .contenedor_rells {
        height: 80%;
    }

    .product_img .contenedor_rells span {
        font-size: 9px;
    }

    .carousel_contactos_inte .title-avisos-home {
        font-size: 18px;
    }

    .product_img video {
        height: 96%;
        width: 96%;
    }

    .product_img .footer_short_videos {
        bottom: 15px;
        width: 90%;
    }

    .videoFooter__ticker span {
        font-size: 13.5px;
    }

    .videoFooter__ticker button {
        font-size: 11.5px;
    } 

    .videoFooter__ticker {
        margin: 5px;
        padding: 3px;
    }

    #slider_video {
        height: 420px;
    }
}


/*.fullscreen-video {
    background: rgb(235, 146, 146);
    position: absolute;
    top: 0;
    left: 0;
    height: 92%;
    width: 100%;
    z-index: 2000000000;
}*/
.video-home-page {
    display: flex;
    max-width: 1025px;
    margin: 4px auto;
    background: rgb(92 92 92);
}

.app-home-page {
    width: 100%;
}

#content_auncios_page {
    max-width: 670px;
    padding: 5px;
    margin: 5px auto;
}

.app-home-page .btn-page-videohome {
    display: flex;
    justify-content: space-around;
    margin: 10px auto 0px;
}

.app-home-page .btn-page-videohome h3 {
    font-size: 20px;
    color: rgb(179 179 179);
    cursor: pointer;
}

#activebutton {
    color: rgb(245 178 178);
    font-weight: 600;
    text-decoration: underline;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.app__videos_flowers {
    width: 100%;
}

.card_headerflowers {
    background: #2c2b2b;
    margin: 4px auto 0px;
    width: 98%;
    display: flex;
    justify-content: space-between;
    padding: 10px 6px 1px;
    color: #fff;
}

.card_headerflowers .card_name h6 a {
    color: white;
    font-size: 20px;
    letter-spacing: 2px;
    margin-left: 5px;
}

.card_headerflowers .card_name small {
    color: #b8b8b8;
}

.card-body-page {
    margin: 0px auto;
    width: 98%;
}
.content-body-page {
    padding: 4px;
    background: #2c2b2b;
}

.content-body-page .readMore {
    color: rgb(90, 6, 3);
}
.content-body-page span {
    font-size: 17px;
    color: rgb(221 221 221);
}

.card-footer-page {
    background: #2c2b2b;
    margin: 0px auto;
    width: 98%;
}

.card-footer-page .page-content-between {
    display: flex;
    justify-content: space-between;
    color: rgb(187 181 181);
    padding: 5px;
}

.card-footer-page .page-content-between h6 {
    font-size: 18px;
}

.card-icon-page {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px 2px;
    font-size: 25px;
    color: rgb(197, 197, 197);
}

.card-page-footer {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 8px;
}

.card-content-info {
    margin: 10px 0px;
    background: #645f5f;
}

/******Video comments page******/

#inputspage {
    background: #262626;
    padding: 5px 2px;
    height: 60px;
}

#inputspage input {
    background: #858484;
    height: 100%;
}

#inputspage button {
    background: #fd4f28;
    width: 10%;
    height: 100%;
    color: #fff;
}

/*cmabios*/

#headervip_home {
    margin: 25px auto;
}

#headervip_home a {
    text-decoration: none;
}

#headervip_home a h3 {
    transition: all 0.6s ease;
}

#headervip_home a h3:hover {
    color: rgb(255, 91, 41);
    text-shadow: 0.2em 0.2em 0.4em rgba(24, 24, 24, 0.856)
}

@media (max-width:750px) {

    #headervip_home {
        margin: 15px auto;
    }

    #headervip_home a h3 {
        font-size: 14px;
    }



    .video-home-page {
        flex-direction: column;
    }

    .app-home-page .btn-page-videohome h3 {
        font-size: 15px;
        margin: 2px auto;
    }

    .card_headerflowers {
        width: 100%;
    }

    .card-body-page {
        width: 100%;
    }

    .content-body-page span {
        font-size: 14px;
    }

    .card-footer-page {
        width: 100%;
    }

    .card-footer-page .page-content-between {
        padding: 4px 5px 0px;
    }
    
    .card-footer-page .page-content-between h6 {
        font-size: 14px;
    }

    .card-icon-page {
        
        font-size: 18px;
    }

    .card-content-info {
        margin: 15px 0px;
    }

    #content_auncios_page {
        width: 330px;
        padding: 5px;
    }

    #righSidebar {
        display: none;
    }
   
}
.page-vent-contenido {
    max-width: 1025px;
    margin: 8px auto;
    height: 70vh;
    position: relative;
    border-radius: 10px;
    background-color: aliceblue;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.page-vent-contenido h2 {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 10%;
    color: #4b4b4b;
    font-family: 600;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif
}

.bounce {
    background-image: linear-gradient(to right, #fac384 0%, #f4dc8f 51%, #fa8484 100%);
    position: relative;
    padding-bottom: 10px;
    left: 50%;
    width: 120px;
    height: 120px;
    margin-left: -30px;
    border: 6px solid lightgray;
    border-radius: 50%;
    animation: bounce 5s infinite;
    -webkit-animation: bounce 5s infinite;
    -moz-animation: bounce 5s infinite;
    -o-animation: bounce 5s infinite;
}

#icons-construct {
    font-size: 90px;
    color: #1d0700;
}

@media ( max-width: 766px) {
    .page-vent-contenido h2 {
        font-size: 20px;
    }
    
}

/* Standard syntax */
@keyframes bounce {
    0% {
        top: 0px;
    }

    25% {
        top: 200px;
    }

    75% {
        top: 50px
    }

    100% {
        top: 100px;
    }

}
.content_info_red {
    max-width: 1025px;
    margin: 5px auto;
    background: #fff;
}

.content_info_red img {
    width: 100%;
    margin: 5px auto;
}

.content_img_ {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 15px auto;
    text-align: center;
}

.content_img_ img {
    width: 70%;
}

.content_img_ h3 {
    display: inline-block;
    color: #000;
    background: #fff;
    mix-blend-mode: multiply;
    position: relative;
    font-weight: 700;
    font-family: cursive;
    text-shadow: 1px 1px 2px rgb(112, 112, 112);
    margin: 25px;
    font-size: 50px;
}

.content_img_ h3::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgb(255, 51, 0), rgb(248, 182, 38), rgb(255, 136, 0));
    mix-blend-mode: screen;
    position: absolute;
    top: 0;
    left: 0;
}

.content_img_ p {
    font-size: 16.5px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: rgb(104, 103, 103);
    margin: 8px;
}

.content_redsocial .content_info_img {
    width: 100%;
    height: 400px;
    display: flex;
    position: relative;
}

.content_info_img .content_uno_img {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content_info_img .content_uno_img h1 {
    position: absolute;
    color: rgb(252, 53, 4);
    font-weight: 700;
    font-family: cursive;
    text-shadow: 5px 5px 10px rgba(248, 212, 212, 0.589);
    font-size: 65px;
    left: 5%;
    top: 25%;
}

#info_redsocial_span1 {
    font-size: 32px;
    font-family: cursive;
    color: #fff;
    text-shadow: 1px 1px 2px rgb(112, 112, 112);
    margin: 10px auto;
}

#info_redsocial_span2 {
    font-size: 18px;
    color: rgb(255, 119, 78);
    padding: 5px;
}

#info_redsocial_span3 {
    color: red;
}

.content_info_img .content_uno_img img {
    width: 100%;
    height: 100%;
    margin: 0;
}

.content_info_img .content_uno_info {
    width: 50%;
    height: 100%;
    background: #040404;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content_btn_log {
    background: red;
    padding: 8px 10px;
    border-radius: 10px;
    cursor: pointer;
    margin: 20px;
}

.content_uno_info span {
    color: #fff;
}

#content_h3_img {
    position: absolute;
    width: 50%;
    display: flex;
    flex-direction: column;
    left: 35%;
    top: 20%;
    padding: 8px;
}

#content_h3_img h3 {
    font-size: 32px;
    color: rgb(255, 94, 0);
    text-align: center;
    font-weight: 600;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

#content_span_img {
    font-size: 18px;
    color: rgb(214, 214, 214);
    font-weight: 500;
    text-align: center;
    margin: 8px 0px;
}

#content_title_img {
    text-align: center;
    font-size: 50px;
    font-family: cursive;
    font-weight: 600;
    color: rgb(255, 6, 6);
    text-shadow: 1px 2px 6px rgba(94, 94, 94, 0.699);
}


.icons_redsocial_img .wrapper {
    display: flex;
    margin: 8px 0px;
    list-style: none;
    width: 100%;
    padding: 0;
    justify-content: space-around;
}

.icons_redsocial_img .wrapper .icon {
    position: relative;
    background: #ffffff;
    border-radius: 50%;
    padding: 15px;
    margin: 10px;
    width: 50px;
    height: 50px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.icons_redsocial_img .wrapper .tooltip {
    position: absolute;
    top: 0;
    font-size: 14px;
    background: #ffffff;
    color: #ffffff;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.icons_redsocial_img .wrapper .tooltip::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background: #ffffff;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.icons_redsocial_img .wrapper .icon:hover .tooltip {
    top: -45px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.icons_redsocial_img .wrapper .icon:hover span,
.icons_redsocial_img .wrapper .icon:hover .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.icons_redsocial_img .wrapper .facebook:hover,
.icons_redsocial_img .wrapper .facebook:hover .tooltip,
.icons_redsocial_img .wrapper .facebook:hover .tooltip::before {
    background: #1877F2;
    color: #ffffff;
}

.icons_redsocial_img .wrapper .twitter:hover,
.icons_redsocial_img .wrapper .twitter:hover .tooltip,
.icons_redsocial_img .wrapper .twitter:hover .tooltip::before {
    background: #1DA1F2;
    color: #ffffff;
}

.icons_redsocial_img .wrapper .instagram:hover,
.icons_redsocial_img .wrapper .instagram:hover .tooltip,
.icons_redsocial_img .wrapper .instagram:hover .tooltip::before {
    background: #E4405F;
    color: #ffffff;
}

.icons_redsocial_img .wrapper .github:hover,
.icons_redsocial_img .wrapper .github:hover .tooltip,
.icons_redsocial_img .wrapper .github:hover .tooltip::before {
    background: #333333;
    color: #ffffff;
}

.icons_redsocial_img .wrapper .youtube:hover,
.icons_redsocial_img .wrapper .youtube:hover .tooltip,
.icons_redsocial_img .wrapper .youtube:hover .tooltip::before {
    background: #CD201F;
    color: #ffffff;
}

.icons_redsocial_img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
}

.icons_redsocial_img h3 {
    font-size: 25px;
    margin: 5px auto;
    text-align: center;
    color: rgb(78, 78, 78);
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.icons_redsocial_img h4 {
    font-size: 17px;
    color: rgb(114, 114, 114);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.wrapper a {
    color: #333333;
}

.footer_redsocial_infos {
    max-width: 1025px;
    padding: 10px;
    margin: 4px auto;
}


@media (max-width: 750px) {
    .content_img_ {
        grid-template-columns: 1fr;
    }

    .content_img_ p {
        font-size: 13px;
    }

    .content_img_ h3 {
        font-size: 30px;
        margin: 5px;
    }

    .content_info_img .content_uno_img h1 {
        font-size: 30px;
    }

    #info_redsocial_span1 {
        font-size: 17px;
        margin: 5px auto;
    }

    #info_redsocial_span2 {
        font-size: 12px;
        margin: 2px auto;
    }

    #info_redsocial_span3 {
        font-size: 11.5px;
    }

    .content_info_img .content_uno_img {
        width: 40%;
    }

    .content_info_img .content_uno_info {
        width: 60%;
    }

    #content_h3_img {
        width: 62%;
        left: 20%;
    }

    #content_h3_img h3 {
        font-size: 17px;
    }

    #content_span_img {
        font-size: 15px;
    }

    .icons_redsocial_img h3 {
        font-size: 15px;
    }

    .icons_redsocial_img h4 {
        font-size: 12px;
        text-align: center;
        margin-bottom: 50px;
    }

    .content_info_img.contenet {
        flex-direction: column;
        width: 100%;
        height: 500px;
    }

    .content_info_img.contenet .content_uno_img {
        width: 100%;
        height: 40%;
    }

    .content_info_img.contenet .content_uno_info {
        width: 100%;
        height: 60%;
    }
}
:root {
    --surface-color: #fff;
    --curve: 40;
}

.home_public {
    max-width: 1000px;
    margin: 8px auto;
}

.home_public .profile_tab_public {
    display: flex;
    justify-content: center;
    padding: 8px;
    width: 80%;
    margin: 2px auto;
    background: hsla(0, 0%, 98%, 1);
    border-radius: 10px;
}

.home_public .profile_tab_public button {
    margin: 0 10px;
    width: 25%;
    border: none;
    background-color: #999999;
    border-radius: 4px;
    color: whitesmoke;
    transition: all 0.7s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile_tab_public button span {
    font-size: 14px;
}

.home_public .profile_tab_public button:hover {
    background-color: #ffddb6;
    color: #696969;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(255, 221, 189, 0.25) 0px 54px 55px, rgba(255, 189, 145, 0.12) 0px -12px 30px, rgba(255, 211, 170, 0.12) 0px 4px 6px, rgba(255, 205, 147, 0.17) 0px 12px 13px, rgba(255, 190, 130, 0.09) 0px -3px 5px;
}

#activebuttoPublic {
    background-color: #ff351b;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fdfdfd;
}

.home_public .cardsHome {
    width: 100%;
    max-width: 1025px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 2rem;
    gap: 2rem;
    margin: 4rem 5vw;
    padding: 20px 10px;
    list-style-type: none;
    margin: 10px auto 80px;
}

.title_publici_cardsHome {
    margin: 16px 0;
    font-size: 26px;
    font-family: 'Pacifico', cursive;
    padding: 0px 6px;
    color: #252525;
}

.home_public .card {
    position: relative;
    display: block;
    height: 100%;
    border-radius: calc(40 * 1px);
    border-radius: calc(var(--curve) * 1px);
    overflow: hidden;
    text-decoration: none;
}

.home_public .card__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.home_public .card__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    border-radius: calc(40 * 1px);
    border-radius: calc(var(--curve) * 1px);
    background-color: #fff;
    background-color: var(--surface-color);
    transform: translateY(100%);
    transition: .2s ease-in-out;
}

.card:hover .card__overlay {
    transform: translateY(0);
}

.home_public .card__header {
    position: relative;
    display: flex;
    align-items: center;
    grid-gap: 2em;
    gap: 2em;
    padding: 2em;
    border-radius: calc(40 * 1px) 0 0 0;
    border-radius: calc(var(--curve) * 1px) 0 0 0;
    background-color: #fff;
    background-color: var(--surface-color);
    transform: translateY(-100%);
    transition: .2s ease-in-out;
}

.home_public .card__arc {
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: 100%;
    right: 0;
    z-index: 1;
}

.home_public .card__arc path {
    fill: #fff;
    fill: var(--surface-color);
}

.home_public .card:hover .card__header {
    transform: translateY(0);
}

.home_public .card__thumb {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.home_public .card__title {
    font-size: 1em;
    margin: 0 0 .3em;
    color: #6A515E;
}

.home_public .card__tagline {
    display: block;
    margin: 1em 0;
    font-family: "MockFlowFont";
    font-size: .8em;
    color: #D7BDCA;
}

.home_public .card__status {
    font-size: .8em;
    color: #D7BDCA;
}

.home_public .card__description {
    padding: 0 2em 2em;
    margin: 0;
    color: #D7BDCA;
    font-family: "MockFlowFont";
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.main_content_public {
    width: 70%;
    margin: 10px auto;
}

.avisoshome {
    display: flex;
    max-width: 1025px;
    margin: 5px auto;
}

.link_content_btn {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 15px 0px;
    margin: 15px 0px;
}

.link_content_btn a:hover {
    text-decoration: none;
}

.btn_public_acce {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    font-family: inherit;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    color: #708fb8;
    background-color: #e0e8ef;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-color: rgba(255, 255, 255, 0.333);
    border-radius: 40px 40px 40px 40px;
    padding: 20px 30px;
    transform: translate(0px, 0px) rotate(0deg);
    transition: 0.2s;
    box-shadow: -4px -2px 16px 0px #ffffff, 4px 2px 16px 0px rgb(95 157 231 / 48%);
}

.btn_public_acce:hover {
    color: white;
    background-color: #ffab8a;
    box-shadow: -2px -1px 8px 0px #ffd8b4, 2px 1px 8px 0px rgba(231, 161, 95, 0.48);
}

.btn_public_acce:active {
    box-shadow: none;
}


.btn_profilenew__div {
    display: flex;
    justify-content: center;
}

.btn_profilenew_ {
    background-color: rgb(255, 165, 91);
    width: 25%;
    min-width: 100px;
    border: none;
    border-radius: 5px;
    position: relative;
    margin: 8px auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: #ffffff;
    transition: all ease 1s;
}

.btn_profilenew_:hover {
    background-color: rgb(238, 238, 238);
    color: #808080;
    box-shadow: rgba(255, 143, 14, 0.35) 0px 5px 15px;
}

@media (max-width : 750px) {
    .home_public {
        margin-bottom: 85px;
    }

    .home_public .cardsHome {
        padding: 20px 25px;
        margin: 10px auto 80px;
    }

    .home_public .profile_tab_public {
        width: 96%;
    }

    .home_public .profile_tab_public button {
        width: 44%;
        font-size: 16px;
        margin: 0 5px;
    }

    .profile_tab_public button span {
        font-size: 10.5px;
    }

    .title_publici_cardsHome {
        font-size: 18px;
    }

    .main_content_public {
        width: 96%;
    }

    .link_content_btn {
        padding: 5px 0px;
        margin: 5px 0px;
    }

    .btn_public_acce {
        font-size: 12px;
        font-weight: 500;
        padding: 20px 30px;
    }

    .avisoshome {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}
.card_barraderech {
  --width: 90%;
  --height: 180px;
  --primary: #00b9ae;
  --text: #f9f9f9b2;
  --secondary: #f9f9f933;
  --radius: 20px;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  width: var(--width);
  height: var(--height);
  background: linear-gradient(180deg,
      rgba(22, 24, 30, 0) 0%,
      rgba(22, 24, 30, 0.7) 65.28%),
    linear-gradient(0deg, rgba(22, 24, 30, 0.4), rgba(22, 24, 30, 0.4));
  background-size: cover;
  border-radius: var(--radius);
  display: flex;
  flex-direction: column;
  grid-gap: 0.7rem;
  gap: 0.7rem;
  padding: 1em;
  padding-top: 1.4em;
  margin: 10px auto;
  position: relative;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.subtitle_barderch_ {
  font-size: 24px;
  color: rgb(99, 99, 99);
  width: 100%;
  font-weight: 600;
  text-align: center;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#card_barraderech_img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  z-index: 1;
}

/*  */

.card_barraderech .card-top {
  z-index: 3;
}

.card_barraderech .card__title {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 0.2rem;
  z-index: 3;
}

.card__title .rating {
  margin-top: 0.5em;
  width: 85px;
  height: 16px;
  background-image: url("data:image/svg+xml,%20%3Csvg%0A%20%20%20%20%20%20%20%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20%20%20%20%20%20%20%20width%3D%2217%22%0A%20%20%20%20%20%20%20%20%20%20height%3D%2217%22%0A%20%20%20%20%20%20%20%20%20%20viewBox%3D%220%200%2017%2017%22%0A%20%20%20%20%20%20%20%20%20%20fill%3D%22none%22%0A%20%20%20%20%20%20%20%20%3E%0A%20%20%20%20%20%20%20%20%20%20%3Cpath%0A%20%20%20%20%20%20%20%20%20%20%20%20d%3D%22M8.51948%201.625C9.1214%201.625%2010.0427%204.16625%2010.4636%205.43013C10.6014%205.8437%2010.9837%206.13054%2011.4192%206.14904C12.7373%206.20505%2015.375%206.39722%2015.375%207.0384C15.375%207.66696%2013.5161%209.17543%2012.5322%209.92976C12.1816%2010.1986%2012.0365%2010.6604%2012.1687%2011.082C12.5631%2012.34%2013.2755%2014.8755%2012.7573%2015.3009C12.2506%2015.717%2010.2147%2014.2326%209.15246%2013.4009C8.77021%2013.1016%208.22949%2013.1012%207.84719%2013.4004C6.78473%2014.2321%204.75246%2015.717%204.28166%2015.3009C3.79912%2014.8745%204.47615%2012.3275%204.84741%2011.0727C4.97086%2010.6555%204.82425%2010.2029%204.47885%209.93826C3.49798%209.18681%201.625%207.66933%201.625%207.0384C1.625%206.3962%204.2711%206.20444%205.5871%206.14878C6.0197%206.13048%206.3998%205.84769%206.53973%205.43793C6.97041%204.17673%207.91633%201.625%208.51948%201.625Z%22%0A%20%20%20%20%20%20%20%20%20%20%20%20fill%3D%22%2300B9AE%22%0A%20%20%20%20%20%20%20%20%20%20%20%20stroke%3D%22%2300B9AE%22%0A%20%20%20%20%20%20%20%20%20%20%20%20stroke-width%3D%222%22%0A%20%20%20%20%20%20%20%20%20%20%20%20stroke-linecap%3D%22round%22%0A%20%20%20%20%20%20%20%20%20%20%20%20stroke-linejoin%3D%22round%22%0A%20%20%20%20%20%20%20%20%20%20%2F%3E%0A%20%20%20%20%20%20%20%20%3C%2Fsvg%3E");
  background-repeat: space;
  z-index: 2;
}

.card_barraderech .card__info {
  display: flex;
  margin-top: auto;
  font-size: 0.8rem;
  color: var(--text);
  font-weight: 400;
  justify-content: space-between;
  z-index: 2;
}

.card_barraderech .card__btns {
  display: flex;
  grid-gap: 0.6rem;
  gap: 0.6rem;
  z-index: 2;
}

.card_barraderech button {
  border: none;
  background: none;
  cursor: pointer;
  background: var(--primary);
  color: #fff;
  border-radius: var(--radius);
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  transition: 0.2s;
  z-index: 2;
}

.card_barraderech button:hover {
  opacity: 0.8;
  transform: scale(0.9);
}

.card_barraderech .add-btn {
  color: #fff;
  padding: 0.5em 0.8em;
  background: rgba(252, 73, 2, 0.548);
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.card_barraderech .watch-btn {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.Perfil_COMER {
  max-width: 1025px;
  margin: 5px auto;
  height: 110vh;
  overflow: hidden;
  position: relative;
}

.Perfil_COMER_user {
  max-width: 1025px;
  margin: 5px auto;
  height: 110vh;
  overflow: hidden;
  position: relative;
}

.btn_id_perfil_comer {
  position: absolute;
  z-index: 20;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 10px;
}

.btn_id_perfil_comer span {
  font-size: 50px;
  margin-left: -45px;
  position: absolute;
  top: -28px;
  font-family: 'Fuggles', cursive;
  color: #d4ad00;
  text-shadow: rgba(250, 0, 0, 0.685) 1px 0 10px;
}

.btn_id_perfil_comer .heart_comerk {
  fill: rgba(255, 51, 0, 0.836);
  position: relative;
  width: 30px;
  cursor: pointer;
  animation: pulse 1s ease infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}


.Perfil_comercio_img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: inline-block;
}

.Perfil_comercio_img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(7, 7, 7, 0.3);
  /* Ajusta el color y la opacidad del oscurecimiento */
  pointer-events: none;
  /* Permite que los elementos debajo del ::before sean clicables */
}

.Perfil_comercio_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.perfil_comercio_content {
  position: relative;
  z-index: 2;
  width: 50%;
  overflow: hidden;
  margin: 5% auto;
}

.perfil_comercio_content .perfil_img_avatar {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  overflow: hidden;
  margin: 10px auto;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.perfil_comercio_content .perfil_img_avatar img {
  width: 100%;
  height: 100%;
}

.perfil_present_content {
  width: 98%;
  margin: 4px auto;
  padding: 6px;
  text-align: justify;
}

.perfil_present_content p {
  font-size: 16px;
  color: white;
  text-shadow: 1px 1px 2px rgb(73, 73, 73);
}

.Perfil_comercio_button {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

/***** btn ******/

.button_btn_comerc {
  display: flex;
  color: #ffffff;
  padding: 0.5rem 1.4rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  border-radius: 0.5rem;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  border: none;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  cursor: pointer;
  transition: .6s ease;
  height: 45px;
  margin: 8px 4px;
  animation: welcome 0.5s ease 0s 1 normal forwards;
}

.button_btn_comerc.instagram {
  background-color: #17e204;
  background-image: linear-gradient(to top right, #fd502a, #fc9c2f, #ffb380);
}

.button_btn_comerc.telegram {
  background-color: #4ab1e4;
  background-image: linear-gradient(to top right, #146ee4, #18ACFE);
}

.button_btn_comerc.tiktok {
  background-color: #353535;
  background-image: linear-gradient(to top right, #494949, #808080);
}

.button_btn_comerc.only {
  background-color: #4ab1e4;
  background-image: linear-gradient(to top right, #68bee9, #95ceeb);
}

.button_btn_comerc.link {
  background-color: #c062ff;
  background-image: linear-gradient(to top right, #cd82ff, #d8a0fd);
}

.button_btn_comerc .svg_logo {
  height: 30px;
  width: 30px;
  padding: 2px;
  font-size: 25px;
  text-align: center;
}

.svg_logo img {
  width: 30px;
  height: 30px;
}

.button_btn_comerc:hover {
  box-shadow: none;
}

.menu_comers_logo {
  position: absolute;
  width: 32px;
  height: 32px;
  right: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
}

.button_btn_comerc:hover {
  box-shadow: 0px 0px 15px 0px rgba(212, 133, 109, 0.9);
  animation: animate 8s ease infinite;
  transition: box-shadow, 0.4s;
  border: none;
  font-size: 19px;
  transform: scale(1.05, 1.05);
  background: linear-gradient(90deg, #fdec53, #cc5e4a, #dfad41, #fdec53);
  background-size: 400%;
  animation: animate 7s cubic-bezier(0.75, 0.75, 0.75, 0.75) infinite,
}

@keyframes animate {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 400%;
  }
}

@keyframes spin {
  0% {
    --rotate: 0deg;
  }

  100% {
    --rotate: 360deg;
  }
}

.button_btn_comerc:hover:before {
  -webkit-filter: blur(20px);
          filter: blur(20px);
  opacity: 1;
  animation: animate 8s linear infinite;
}

.Perfil_comer_precio {
  width: 100%;
  text-align: center;
  font-size: 16px;
  padding: 5px 0px;
  color: rgb(218, 218, 218);
  text-shadow: 1px 1px 1px #000,
    3px 3px 5px #7d0225;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.Perfil_comer_precio span {
  color: crimson;
}

.cssbuttons-io-button {
  background: #ff3c33;
  color: white;
  font-family: inherit;
  padding: 0.35em;
  padding-left: 1.2em;
  font-size: 14px;
  border-radius: 0.9em;
  border: none;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  height: 2.4em;
  width: 30%;
  margin: 24px auto;
  padding-right: 3.3em;
  box-shadow: rgba(240, 148, 148, 0.705) 0px 1px 1px 0px inset,
    rgba(145, 145, 240, 0.25) 0px 50px 100px -20px,
    rgba(94, 58, 58, 0.3) 0px 30px 60px -30px;
}

.cssbuttons-io-button .icon {
  background: white;
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2em;
  width: 2.2em;
  border-radius: 0.7em;
  right: 0.3em;
  transition: all 0.3s;
}

.cssbuttons-io-button:hover .icon {
  width: calc(100% - 0.6em);
}

.cssbuttons-io-button .icon svg {
  width: 1.1em;
  transition: transform 0.3s;
  color: #7b52b9;
}

.cssbuttons-io-button:hover .icon svg {
  transform: translateX(0.1em);
}

.cssbuttons-io-button:active .icon {
  transform: scale(0.95);
}

.modal_compartir_ {
  background-color: white;
  margin: 8px auto;
  padding: 10px 14px;
  border-radius: 6px;
  position: relative;
}

.modal_compartir_ i {
  position: absolute;
  color: crimson;
  right: 6px;
  top: 6px;
  cursor: pointer;
}

.dropdows_menu_ {
  position: fixed;
  background: rgba(0, 0, 0, 0.418);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
}

.dropdows_menu_compart {
  background-color: white;
  width: 40%;
  padding: 15px;
  position: absolute;
  border-radius: 5px;
}

.fa-regular.fa-rectangle-xmark {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 20px;
  color: crimson;
  cursor: pointer;
}

.dropdows_menu_compart label {
  color: rgb(128, 128, 128);
  margin: 6px;
  font-size: 15px;
  font-weight: 600;
}

.icon_compart_content {
  display: flex;
}

.icon_compart_i {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 6px;
  border-radius: 8px;
}

.icon_compart_i i:hover {
  color: red;
}

.icon_compart_i i {
  font-size: 30px;
  margin: 4px;
  cursor: pointer;
}

.icon_compart_i small {
  font-size: 9px;
  color: #555555;
}

.fa-brands.fa-square-instagram {
  color: #ff5724;
}

.fa-solid.fa-link {
  color: #146ee4;
}

.fa-brands.fa-telegram {
  color: #59abfd;
}

.button_btn_co {
  display: none;
}

.readMore {
  color: crimson;
}


.content_option_perfil {
  max-width: 1025px;
  margin: 5px auto;
}

.content_option_content {
  margin: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  background-image: radial-gradient(circle at 50% -20.71%, #eaecd0 0, #e6edd2 6.25%, #e2eed4 12.5%, #deefd6 18.75%, #daf0d9 25%, #d6f1dc 31.25%, #d3f1df 37.5%, #d0f2e2 43.75%, #cdf2e6 50%, #cbf2ea 56.25%, #c9f2ed 62.5%, #c8f2f1 68.75%, #c7f2f4 75%, #c7f2f7 81.25%, #c8f1fa 87.5%, #c9f1fd 93.75%, #cbf0ff 100%);
}

.content_option_content h2 {
  font-size: 20px;
}

.content_option_content h3 {
  padding: 3px;
  font-size: 17px;
  margin: 8px auto;
  color: #4e4e4e;
  width: 70%;
  text-align: justify;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.social-account-container {
  margin: 25px auto;
}

.social-account-container .title {
  display: block;
  text-align: center;
  font-size: 10px;
  color: rgb(170, 170, 170);
}

.social-account-container .social-accounts {
  width: 100%;
  display: flex;
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 5px;
}

.social-account-container .social-accounts .social-button {
  background: linear-gradient(45deg, rgb(0, 0, 0) 0%, rgb(112, 112, 112) 100%);
  border: 5px solid white;
  padding: 5px;
  border-radius: 50%;
  width: 40px;
  aspect-ratio: 1;
  display: grid;
  place-content: center;
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 12px 10px -8px;
  transition: all 0.2s ease-in-out;
}

.social-account-container .social-accounts .social-button:hover {
  transform: scale(1.2);
}

.social-account-container .social-accounts .social-button:active {
  transform: scale(0.9);
}

.content_option_title {
  text-align: center;
  margin: 10px auto;
  padding: 6px 2px;
  font-family: 'Indie Flower', cursive;
  color: #000;
  background: #fff;
  mix-blend-mode: multiply;
  position: relative;
  overflow: hidden;
  font-weight: 600;
  text-shadow: 3px 3px 5px rgba(97, 97, 97, 0.658);
}

.content_option_title:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgb(255, 145, 0), red);
  mix-blend-mode: screen;
  position: absolute;
}

.btn_crear_profileC {
  width: 60%;
  height: 40px;
  margin: 8px auto;
  background: #e3edf7;
  border-radius: 10px;
  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
  transition: transform 0.5s;
}

.btn_crear_profileC:hover {
  box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
    inset -4px -4px 6px -1px rgba(255, 255, 255, 0.7),
    -0.5px -0.5px 0px rgba(255, 255, 255, 1),
    0.5px 0.5px 0px rgba(0, 0, 0, 0.15),
    0px 12px 10px -10px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  transform: translateY(0.5em);
}

.btn_crear_profileC span {
  transition: transform 0.5s;
}

.btn_crear_profileC:hover span {
  transform: scale(0.9);
  fill: #333333;
  color: #fd3636;
}

/**********/
.uiverse_view {
  position: absolute;
  margin: 10px;
  width: 100px;
  height: 40px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  font-weight: bold;
  text-decoration: none;
  color: white;
  background-color: #4158D0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  transition: 0.2s ease-in-out;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  z-index: 200;
  top: 45px;
  right: 50px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.uiverse_view:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.uiverse_view:active {
  background-color: #00DBDE;
  background-image: linear-gradient(90deg, #00DBDE 0%, #FC00FF 100%);
}

.uiverse_view .tooltip {
  position: absolute;
  top: 0;
  font-size: 12px;
  background: #ffffff;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.uiverse_view .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #ffffff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.uiverse_view:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.uiverse_view:hover .tooltip,
.uiverse_view:hover .tooltip::before {
  background: linear-gradient(320deg, rgb(241, 241, 241), rgb(190, 190, 190));
  color: #494949;
}

.btn_content_elimupdate {
  position: absolute;
  z-index: 200;
  top: 15px;
  left: 15px;
  margin: 6px;
  display: flex;
}

.btn_content_elimupdate button {
  margin: 4px 8px;
  padding: 5px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: whitesmoke;
  background-color: crimson;
  box-shadow: rgba(138, 53, 53, 0.308) 0px 3px 8px;
  transition: all .8s ease;
  border: 2px solid crimson;
}

.btn_content_elimupdate button:hover {
  background-color: #fff;
  color: crimson;
  border: 2px solid crimson;
  box-shadow: rgba(255, 37, 37, 0.384) 0px 5px 15px;
}

/****/


@media (max-width : 750px) {

  .Perfil_COMER {
    position: fixed;
    top: 0;
    z-index: 1000000;
    width: 100vw;
    height: 100vh;
  }

  #comercio_content {
    width: 86%;
  }

  .perfil_present_content p {
    font-size: 14px;
  }

  .Perfil_comer_precio {
    font-size: 15px;
  }

  .cssbuttons-io-button {
    width: 40%;
  }

  .dropdows_menu_compart {
    width: 90%;
  }

  .menu_comers_logo {
    font-size: 18px;
  }

  .perfil_comercio_content .perfil_img_avatar {
    width: 100px;
    height: 100px;
  }

  .content_option_content h3 {
    font-size: 13px;
  }

  .uiverse_view {
    font-size: 10px;
    width: 70px;
    height: 30px;
    top: 2px;
    right: 2px;
  }

  .btn_content_elimupdate {
    top: 0;
    left: 0;
    margin: 8px;
  }

  .btn_content_elimupdate button {
    margin: 4px;
    font-size: 10px;
    padding: 2px;
    height: 30px;
  }
}
.errMsg{
    background: rgb(214, 10, 10);
    color: #fff9;
    text-align: center;
    padding: 10px 0;
    letter-spacing: 1.3px;
}

.successMsg{
    background: rgb(9, 158, 54);
    color: #fff9;
    text-align: center;
    padding: 10px 0;
    letter-spacing: 1.3px;
}

.successMsg_a {
    text-decoration: none;
    color: aliceblue;
    font-weight: 600;
    font-family: 20px;
}
/* Estilos personalizados para el acordeón 
.accordion {}

.accordion-item {
    
}

.accordion-header {}
 Estilos para cada elemento del acordeón */

.accordion-button {
    color: rgb(78, 78, 78) !important;
    font-weight: 500;
    height: 50px;
    background-color: solid rgb(214, 214, 214) 1px !important;
    text-shadow: 0.2px 0.25px 0.25px rgba(68, 68, 68, 0.555);
}

.accordion-button i {
    margin-right: 10px;
    color: rgba(146, 146, 146, 0.459);
    font-weight: 600;
}

.accordion-button:focus {
    box-shadow: 0 0 0 0.25rem rgba(253, 223, 185, 0.25);
    border-color: rgba(255, 81, 0, 0.692);
}

.accordion-button:not(.collapsed) {
    margin-bottom: 5px;
    background-color: rgba(248, 248, 248, 0.637);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.Btn_enlace_forum {
    outline: 0;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background: #ff4949;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
    box-sizing: border-box;
    padding: 20px 24px;
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    overflow: hidden;
    cursor: pointer;
    margin: 15px auto;
    width: 60%;
    text-shadow: 1px 1px 2px black;
    position: relative;
    left: 50%;
    transform: translate(-50%);
}

.Btn_enlace_forum:hover {
    opacity: .95;
}

.Btn_enlace_forum .animation {
    border-radius: 100%;
    animation: ripple 0.6s linear infinite;
}

@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1);
    }

    100% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1), 0 0 0 80px rgba(255, 255, 255, 0);
    }
}

/*
.accordion-collapse {}

 Estilos específicos para cuando el contenido del acordeón está mostrándose 
.accordion-collapse.show {
     Estilos para el contenido del acordeón cuando está expandido 
}*/

/*********/
.row-forum-item {
    display: flex;
    width: 100%;
}

.ibox-content {
    clear: both;
}

.ibox-content {
    background-color: #ffffff;
    color: inherit;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 1px 0;
}

.forum-container,
.forum-post-container {
    padding: 0 15px !important;
}

.forum-title {
    margin: 10px 0 10px 0;
}

.forum-item {
    margin: 10px 0;
    padding: 8px 0;
    border-bottom: 1px solid #f1f1f1;
    font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.forum-icon {
    float: left;
    width: 20px;
    margin-right: 10px;
    text-align: center;
}

.forum-item.active a.forum-item-title {
    color: #3a322e;
    transition: all 0.6s ease;
}

a.forum-item-title {
    color: inherit;
    display: block;
    font-size: 16.5px;
    font-weight: 600;
}

a.forum-item-title:hover {
    text-decoration: none;
}

.forum-item .forum-sub-title {
    color: #999;
    margin-left: 50px;
}


.views-number {
    font-size: 12px;
    font-weight: 600;
}

.ibox-content h1,
.ibox-content h2,
.ibox-content h3,
.ibox-content h4,
.ibox-content h5,
.ibox-title h1,
.ibox-title h2,
.ibox-title h3,
.ibox-title h4,
.ibox-title h5 {
    margin-top: 5px;
}

.row-forum-item h3,
.row-forum-item h4,
.row-forum-item h5 {
    margin-top: 5px;
    font-weight: 600;
}

.row-forum-item h3 {
    font-size: 16px;
}

.forum-item.active .fa {
    color: #f9ba8e;
}

.forum-icon .fa {
    font-size: 20px;
    margin-top: 4px;
    color: #9b9b9b;
}

.forum-desc {
    color: #999;
}

.forum-item small {
    color: #999;
    font-size: 85%;
}

.forum-item.active a.forum-item-title:hover {
    color: rgb(136, 43, 0);
}

.col-forum-item {
    width: 70%;
}

.col-md-forum-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-left: solid 1px rgba(124, 124, 124, 0.671);
}

.col-md-forum-info .forum_icons_ img {
    width: 26px;
    height: 26px;
    border-radius: 50%;
}

.col-md-forum-info small {
    font-size: 10.5px;
}

.col-forum-info {
    width: 50%;
    border-right: solid 1px rgba(124, 124, 124, 0.671);
    border-left: solid 1px rgba(124, 124, 124, 0.671);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#title_forum_a {
    height: 30px;
    width: 90%;
    /* Ancho fijo para el div */
    overflow: hidden;
    /* Oculta el texto que se desborda del div */
    text-overflow: ellipsis;
    /* Agrega puntos suspensivos (...) al final del texto que se corta */
    display: inline-block;
    /* Para que el div solo tenga el ancho del contenido */
}

.content_footer_body_forum {
    display: flex;
    width: 30%;
}

.forum-item .forum-sub-title a {
    color: #ac0b00;
    font-weight: 600;
}

.card_header.forum {
    margin: 5px auto;
    padding: 4px 6px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
}

.material-icons.forum {
    font-size: 28px;
    display: block;
}

.nav-item.dropdown.forum {
    display: block;
    cursor: pointer;
}

.info_list_detail {
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px 6px;
    border-radius: 4px;
    background-color: white;
}

.footer_forum_cards {
    margin: 8px auto;
}

.contet_card_forum_ {
    padding: 6px;
    margin: 8px auto;
}

.contet_card_forum_ h2 {
    color: #cb2702;
    font-size: 18px;
    margin: 10px 0;
}

.contet_card_forum_ h2:hover {
    color: #f37703;
}

.footer_forum_cards .footer_icons_ {
    display: flex;
    justify-content: end;
    padding: 5px 8px;
    align-items: center;
    font-size: 22px;
}

.footer_icons_ i,
.footer_icons_ a {
    margin: 0 15px;
}

.footer_icons_ i:hover {
    color: #ff5601;
    cursor: pointer;
}

.postBtn.forum {
    padding: 3px 8px;
    border-radius: 4px;
    background-color: #ff520e;
    color: white;
    transition: all 1s ease;
    position: relative;
    margin: 6px;
    width: 80px;
}

.postBtn.forum:hover {
    background-color: #ffe5d7e3;
    color: rgb(61, 61, 61);
    box-shadow: rgba(255, 133, 85, 0.35) 0px 5px 15px;
}


.cardfooter.comment_input {
    margin: 6px auto;
}

.comment_input textarea {
    background: #f7f7f7;
    border: none;
    outline: none;
    flex: 1 1;
    overflow: auto;
    min-height: 100px;
    border-radius: 2px;
}

.content_footer_replay {
    width: 86%;
    margin: 4px;
    position: relative;
}

.content_btn_form_ {
    padding: 4px;
    margin: 0px auto 8px;
}

.content_btn_form_ button {
    border: none;
    height: 25px;
    width: auto;
    margin: 0 6px;
    border-radius: 4px;
    background-color: rgb(255, 107, 107);
    padding: 1px 5px;
    font-size: 14px;
    color: rgb(247, 247, 247);
    transition: all 0.8s ease;
}

.content_btn_form_ button:Hover {
    background-color: whitesmoke;
    color: rgb(255, 107, 107);
}



.comment_content.forums {
    background-color: rgba(243, 243, 243, 0.945);
}

.content_commet_replay {
    padding: 10px 0;
}


/********************/

.title_MODEL_body input {
    margin: 5px auto 10px;
    width: 100%;
}

#status_form_MODAL textarea {
    margin: 8px auto;
}

/*******************/

.accordion-body.forum {
    background-color: #ffffff;
}

.d-flex.forumsta {
    background-color: #dfdfdfbd;
    border: none;
}

.d-flex.forumsta button {
    background-color: #ff9f9f;
    font-weight: 600;
}

.activity-feed {
    padding: 0px;
    list-style: none;
    display: flex;
    justify-content: space-between;
}

.activity-feed .feed-item {
    position: relative;
    padding-bottom: 8px;
    padding-left: 20px;
    border-left: 2px solid #e4e8eb;
}

.activity-feed .feed-item:last-child {
    border-color: transparent;
}

.activity-feed .feed-item::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -6px;
    width: 10px;
    height: 10px;
    border-radius: 6px;
    background: #fff;
    border: 1px solid #f37167;
}

.title_forums_ {
    margin: 20px 0 14px;
    font-size: 22px;
    padding: 8px 4px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
    color: #383838;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.activity-feed .feed-item .date {
    display: block;
    position: relative;
    top: -5px;
    color: #8c96a3;
    text-transform: uppercase;
    font-size: 13px;
}

.activity-feed .feed-item .text {
    position: relative;
    top: -3px;
    font-size: 17.5px;
}

.activity-feed .feed-item .text a {
    text-decoration: none;
    color: #a83102;
    font-weight: 500;
    transition: all 1 ease;
}

.activity-feed .feed-item .text a:hover {
    color: rgb(255, 192, 55);
}

.form_category_formulary {
    background-color: #29292991;
    position: fixed;
    top: 80px;
    width: 100%;
    height: 100vh;
    left: 0;
    z-index: 2000000000000000;
}

#modal_form_category {
    width: 50%;
    margin: 10px auto;
    background-color: rgb(255, 255, 255);
    position: relative;
    border-radius: 10px;
    height: 80%;
    padding: 6px;
    overflow: scroll;
}

.outline-danger_form {
    position: absolute;
    top: 0;
    margin: 10px 20px;
    right: 0;
    border: none;
    background-color: crimson;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: whitesmoke;
}

.row_category_ {
    border: solid rgba(134, 134, 134, 0.555) 0.5px;
    display: flex;
    justify-content: space-between;
    padding: 2px 4px;
    margin: 0px 8px;
}

.row_category_ p {
    font-size: 16px;
}

.row_category_ button {
    border: none;
    margin: 0 5px;
    width: 50px;
    border-radius: 4px;
    color: rgb(51, 51, 51);
}

.form_category_inicio {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    width: 90%;
    margin: 8px auto;
    justify-content: center;
    align-items: center;
}

.categori_input_n {
    display: flex;
    flex-direction: column;
    flex: 1 1;
}

.categori_input_n input {
    margin: 2px 4px;
}

.form_category_inicio button {
    margin: auto 6px;
    border: none;
    border-radius: 4px;
    background-color: #8c96a3;
    color: white;
    width: 60px;
}

.btn_form_create_cat {
    background-color: #ff4c06;
    border: none;
    margin: 6px;
    padding: 2px 6px;
    border-radius: 4px;
    color: white;
}

.activity-feed .feed-item i {
    margin: 2px 4px;
    color: #837f7a;
}

.activity-feed .feed-item small {
    color: rgb(54, 54, 54);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#status_form_MODAL {
    display: flex;
    flex-direction: column;
}

.title_MODEL_body {
    display: flex;
    flex-direction: column;
}

@media (max-width:750px) {

    .Btn_enlace_forum {
        width: 90%;
        font-size: 13px;
        padding: 5px 8px;
    }
    .row-forum-item {
        flex-direction: column;
    }

    .col-forum-item {
        width: 100%;
    }

    .content_footer_body_forum {
        width: 100%;
    }

    .col-md-forum-info,
    .col-forum-info {
        flex-direction: row;
        justify-content: space-around;
    }

    .forum-item .forum-sub-title {
        font-size: 13.5px;
        margin-bottom: 3px;
    }

    a.forum-item-title {
        font-size: 15.5px;
    }

    .forum-icon .fa {
        font-size: 15px;
    }

    .accordion-button {
        font-size: 13.5px;
        height: 42px;
    }

    .activity-feed .feed-item .text {
        font-size: 14.5px;
        top: -8px;
    }

    .activity-feed .feed-item .date {
        font-size: 12px;
    }

    #modal_form_category {
        width: 98%;
    }

    .row_category_ p {
        font-size: 12.5px;
    }

    .row_category_ button {
        width: 40px;
        font-size: 11px;
    }

    .title_forums_ {
        font-size: 15.5px;
        letter-spacing: 0;
    }

    .d-flex.forumsta button {
        font-size: 13px;
        font-weight: 700;
    }

    .cardfooter.comment_input {
        display: flex;
        flex-direction: column;
    }

    .postBtn.forum {
        width: 90%;
    }

}
.content_hub_home {
  display: flex;
  width: 96%;
  margin: 8px auto;
}

.content_hub_derach {
  width: 70%;
}

.content_hub_medid {
  width: 100%;
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  /* Permite que las tarjetas se ajusten en múltiples filas */
  grid-gap: 12px;
  gap: 12px;
  /* Espacio entre las tarjetas */
  justify-content: center;
  /* Centra las tarjetas horizontalmente */
  padding: 16px;
}

.content_creadoras_content {
  width: 100%;
  padding: 10px;
}

.content_hub_izder {
  width: 30%;
  margin: 6px;
}

/************************/

.cart_contethub_public_creadoras {
  width: 96%;
  background-color: #ffffff;
  /* Fondo blanco para la tarjeta */
  border-radius: 12px;
  /* Bordes redondeados */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  /* Sombra suave */
  overflow: hidden;
  /* Para que los bordes redondeados funcionen bien */
  margin: 10px auto;
  /* Espaciado entre tarjetas */
  max-height: 250px;
  position: relative;
}

.header_cart_contenthub_creadoras {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  /*border-bottom: 1px solid #ddd;*/
  position: absolute;
  width: 100%;
  /* Líneas sutiles para separar secciones */
}

.header_cart_contenthub_creadoras img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  /* Imagen redondeada */
  object-fit: cover;
  /* Asegura que la imagen se recorte correctamente */
  margin-right: 20px;
  background-color: #fff;
}

.header_cart_contenthub_creadoras h1 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  color: #ffffff;
  text-shadow: -1px 3px 5px rgba(0, 0, 0, 0.747);
  cursor: pointer;
}

.content_hub_derach_h1 {
  width: 100%;
  text-align: center;
  margin: 20px 0;
  font-size: 25px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #4b4b4b;
}

.header_cart_contenthub_creadoras small {
  font-size: 12px;
  color: #888;
}

.content_img_contenhub_creadoras {
  width: 100%;
  height: auto;
  /* Ajusta dinámicamente el alto */
  overflow: hidden;
}

.content_img_contenhub_creadoras img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  /* Deforma la imagen para llenar el contenedor */
}

/* From Uiverse.io by alexmaracinaru */
.cardhub {
  background: white;
  padding: .2em;
  border-radius: 6px;
  max-width: 300px;

  min-height: 350px;
  max-height: 480px;
}

.card-imagehub {
  background-color: rgb(236, 236, 236);
  width: 100%;
  height: 80%;
  border-radius: 6px 6px 0 0;
}

.card-imagehub img {
  width: 100%;
  height: 100%;
}

.card-imagehub:hover {
  transform: scale(0.98);
}

.categoryhub {
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 600;
  color: rgb(63, 121, 230);
  padding: 8px auto;
}

.categoryhub:hover {
  cursor: pointer;
}

.headinghub {
  font-weight: 600;
  color: rgb(88, 87, 87);
  padding: 7px;
  max-height: 50px;
}

#headinghub_h3 {
  font-size: 12px;
  text-align: justify;
  overflow: hidden;
  /* Oculta el contenido que se desborde */
  text-overflow: ellipsis;
  /* Agrega puntos suspensivos al final */
  display: -webkit-box;
  /* Muestra el contenido en un contenedor flexible */
  -webkit-line-clamp: 2;
  /* Limita a 2 líneas de texto */
  -webkit-box-orient: vertical;
  /* Define la orientación del contenedor flexible como vertical */
  max-height: 3em;
  /* Ajusta la altura máxima para 2 líneas de texto (depende del tamaño de fuente) */
  line-height: 1.5em;
  /* Define el espacio entre líneas */
  margin: 0;
  height: auto;
}

.headinghub h3 a {
  color: rgb(87 86 86);
  text-decoration: none;
}


.headinghub:hover {
  cursor: pointer;
}

.authorhub {
  color: gray;
  font-weight: 400;
  font-size: 11px;
  padding-top: 0px;
}

.namehub {
  font-weight: 600;
}

.namehub:hover {
  cursor: pointer;
}

/*/**Videos private***/
/* Galería */
/* Contenedor general */
.gallery-container {
  display: flex;
  flex-direction: column;
  /* Organiza imágenes y videos en columnas */
  grid-gap: 25px;
  gap: 25px;
  /* Espacio entre imágenes y videos */
  padding: 10px;
  background-color: #f9f9f9;
  /* Fondo claro para contraste */
}

/* Galería de imágenes */
.image-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 4px;
  gap: 4px;
  /* Espacio entre imágenes */
}

/* Galería de videos */
.video-gallery {
  display: flex;
  flex-wrap: wrap;
  /* Permite que los videos se ajusten al ancho disponible */
  grid-gap: 16px;
  gap: 16px;
  /* Espacio entre videos */
  justify-content: center;
  /* Centra los videos horizontalmente */
}

/* Estilo común para las imágenes y videos */
.gallery-item {
  position: relative;
  overflow: hidden;
  min-height: 350px;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery-item:hover {
  /*transform: scale(1.02);*/
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

/* Estilo para las imágenes */
.image-gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease, -webkit-filter 0.3s ease;
  transition: transform 0.3s ease, filter 0.3s ease;
  transition: transform 0.3s ease, filter 0.3s ease, -webkit-filter 0.3s ease;
  border-radius: 5px;
}

.image-gallery img:hover {
  transform: scale(1.1);
  -webkit-filter: brightness(0.8);
          filter: brightness(0.8);
}

/* Estilo para los videos */
/* Galería de videos */
/* Galería de videos */
.video-gallery {
  display: flex;
  flex-wrap: wrap;
  /* Permite que los videos se ajusten al ancho disponible */
  grid-gap: 16px;
  gap: 16px;
  /* Espacio entre videos */
  justify-content: center;
  /* Centra los videos horizontalmente */
  align-items: flex-start;
  /* Alinea los videos en la parte superior */
}

/* Contenedor de videos */
.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  border-radius: 6px;
  overflow: hidden;
  flex: 0 1 auto;
  /* Adapta el ancho del contenedor según el video */
  max-width: 100%;
  /* Evita que se desborde */
  aspect-ratio: auto;
  /* Adapta dinámicamente al video */
}

/* Video ajustado al contenedor */
.video-container video {
  width: auto;
  /* Ajusta el ancho para videos altos */
  height: auto;
  /* Ajusta el alto para videos anchos */
  max-width: 100%;
  /* Nunca excede el contenedor */
  max-height: 100%;
  /* Mantén el video dentro del contenedor */
  object-fit: contain;
  /* Respeta proporciones originales */
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

/* Responsividad */
@media (max-width: 768px) {
  .content_hub_derach {
    width: 100%;
  }

  .video-gallery {
    flex-direction: column;
    /* Videos en una sola columna en pantallas pequeñas */
    align-items: center;
  }

  .image-gallery {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .content_hub_izder {
    width: 100%;
    margin: 10px 0;
  }

  .cart_contethub_public_creadoras {
    max-height: 200px;
  }

  .content_hub_derach_h1 {
    font-size: 18px;
  }
}

/* Visor de imágenes */
.custom-image-viewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000;
  cursor: zoom-out;
}

.custom-image-viewer-content {
  position: relative;
  cursor: auto;
}

.custom-image-viewer img {
  max-width: 90vw;
  max-height: 90vh;
  transition: transform 0.3s ease;
}

.custom-image-controls {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}

.custom-image-controls button {
  padding: 8px 12px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.custom-image-controls button:hover {
  background: rgba(255, 255, 255, 0.6);
  color: black;
}

/******style modal********/
.btn_content_hub_utls {
  position: relative;
}

.btn_content_hub_utls .content-private-mediahub {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #1f1f1f, #2d2d2e, #2e2e31ec, #29292ee3, rgba(126, 126, 150, 0.8));
  z-index: 2000;
  text-align: center;
  padding: 50px;
  display: flex;
  flex-direction: column;
  margin: 10px auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
  /* Mejora la apariencia */
}

.footer_false_mediahub {
  width: 100%;
  height: 300px;
  background: linear-gradient(0deg, #161616, #262626, #404040);
}

.open-modal-button {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 700;
  color: #4d4d4d;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  width: 40%;
  margin: 8px auto;
  transition: all ease 0.8s;
}

.open-modal-button:hover {
  background-color: #23c483;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  scale: 1.05;
}

.open-modal-button:active {
  transform: translateY(-1px);
}

.content-private-mediahub i {
  font-size: 8em;
  margin: 10px;
  margin-bottom: 20px;
  color: #949494;
  text-shadow: rgba(75, 75, 75, 0.781) 1px 0 10px;
}

.btn_content_hub_utls .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000000000000000000000000000;
}

.btn_content_hub_utls .modal-content_hub {
  background: white;
  border-radius: 10px;
  max-width: 900px;
  /* Limita el ancho máximo */
  max-height: 88vh;
  /* Altura máxima */
  overflow-y: auto;
  /* Añade scroll si el contenido es muy alto */
  position: relative;
  display: flex;
  flex-wrap: wrap;
  /* Permite que los elementos se ajusten en filas */
  grid-gap: 20px;
  gap: 20px;
  /* Espaciado entre elementos */
  padding: 20px;
  /* Espaciado interno */
  justify-content: center;
  align-items: flex-start;
  position: relative;
  transition: width 0.3s ease, height 0.3s ease;
}

.modal-content_hub.expanded {
  width: 420px;
  height: auto;
  /* Ajusta la altura automáticamente según el contenido */
}

.extra-info {
  margin-top: 15px;
  background: #f9f9f9;
  padding: 10px;
  border-top: 1px solid #ddd;
  border-radius: 8px;
  color: #333;
  width: 100%;
}

.modal-content_hub .close-button {
  position: absolute;
  top: -10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: crimson;
}

/****/

.content_money_hub {
  display: flex;
  flex-direction: column;
  padding: 4px;
}

.content_money_hub label {
  display: flex;
  align-items: center;
  width: 80%;
  align-items: center;
}

.content_money_hub label input {
  margin: 2px 6px;
}

/*****/
.btn_content_hub_utls b {
  padding: 0px 4px;
  display: inline-block;
}

.btn_content_hub_utls .con-items {
  display: flex;
  flex-wrap: wrap;
  /* Permite que las tarjetas se apilen en pantallas pequeñas */
  grid-gap: 20px;
  gap: 20px;
  justify-content: space-between;
  align-items: stretch;
  /* Ajusta la altura de las tarjetas */
  width: 100%;
  /* Asegura que se ajusten al contenedor */
  padding: 10px;
}

.btn_content_hub_utls .item {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(33.333% - 40px);
  /* Cada tarjeta ocupa un tercio del espacio disponible menos el espacio del gap */
  max-width: 340px;
  min-width: 200px;
  /* Define un ancho mínimo */
  background: #fff;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: transform 0.25s ease;
}

.btn_content_hub_utls .item ul {
  padding: 2px;
}

.btn_content_hub_utls .item.color {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(100% - 40px);
  /* Ocupará toda la fila en pantallas pequeñas */
}

.btn_content_hub_utls .item:not(.color):hover {
  transform: scale(1.02);
}

.btn_content_hub_utls .item:hover .con-img {
  transform: scale(1.02);
}

.btn_content_hub_utls .item.color:hover {
  transform: scale(1.02);
}

.btn_content_hub_utls .con-img {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .25s ease;
}

.btn_content_hub_utls .con-img img {
  width: 130px;
}

.btn_content_hub_utls .item.color {
  background: #3dcbab;
  color: #fff;
  transform: scale(1.05);
}

.btn_content_hub_utls .item.color li {
  color: rgba(255, 255, 255, .75);
}

.btn_content_hub_utls .item.color li b {
  color: rgba(255, 255, 255, 1);
}

.btn_content_hub_utls .item.color li i {
  color: rgba(255, 255, 255, 1);
}

.btn_content_hub_utls .item header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.btn_content_hub_utls .item header h3 {
  font-size: 1.2rem;
  margin: 0;
}

.btn_content_hub_utls .item header p {
  font-size: 1.5rem;
  margin: 0;
}

.btn_content_hub_utls .badge {
  position: absolute;
  top: 12px;
  right: 12px;
  background: #fff;
  padding: 5px 10px;
  border-radius: 12px;
  color: rgb(61, 203, 171);
  font-weight: bold;
  font-size: .6rem;
}

.btn_content_hub_utls item ul {
  padding: 10px 0px;
  flex: 1 1;
  width: 100%;
}

.btn_content_hub_utls .item ul li {
  width: 100%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  color: rgb(var(--color), .5);
  font-size: 13px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
}

.btn_content_hub_utls .item ul li b {
  color: rgb(var(--color), 1);
  margin-left: 8px;
}

.btn_content_hub_utls .item ul li i {
  font-size: 1.6rem;
  margin-right: 15px;
  color: rgb(var(--color), 1)
}

.btn_content_hub_utls .item .btn_plans_medianOpen {
  width: 100%;
  background: rgb(184, 255, 238);
  border: 1px solid rgb(119, 223, 174);
  border-radius: 20px;
  color: #888787;
  font-weight: bold;
  font-size: 1.1rem;
  box-shadow: 0px 10px 25px 0px rgba(61, 203, 171, .35);
  cursor: pointer;
  transition: all .25s ease;
}

.item .btn_plans_medianClose {
  width: 100%;
  background: rgb(255, 71, 111);
  border: 3px solid transparent;
  border-radius: 20px;
  color: #fff;
  font-weight: bold;
  font-size: 1.1rem;
  box-shadow: 0px 10px 25px 0px rgba(61, 203, 171, .35);
  cursor: pointer;
  transition: all .25s ease;
}

.btn_plans_medianOpen.standar {
  background: #000;
}

.btn_plans_medianOpen:not(.border):hover {
  transform: translate(0, 5px);
  box-shadow: 0px 0px 0px 0px rgba(61, 203, 171, .35);
}

.item .btn_plans_medianOpen.border {
  border: 3px solid #fff;
}

.item .btn_plans_medianOpen.border:hover {
  background: #fff;
  color: rgb(61, 203, 171);
}

.modal-content_hub {
  scrollbar-width: none;
  /* Oculta el scroll en Firefox */
}

.modal-content_hub::-webkit-scrollbar {
  display: none;
  /* Oculta el scroll en Webkit */
}

@media (max-width: 768px) {
  .btn_content_hub_utls .item {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(50% - 20px);
    /* Reduce a dos columnas en pantallas medianas */
  }

  .btn_content_hub_utls .item.color {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(100% - 20px);
    /* Mantén en una fila para destacar */
  }

  .btn_content_hub_utls .item header h3 {
    font-size: 1.1rem;
  }

  .btn_content_hub_utls .item header p {
    font-size: 1.5rem;
  }

  .open-modal-button {
    color: #ffffff;
    background-color: #00e667;
    font-size: 10px;
    transition: all 0.3s ease 0s;
    width: 70%;
  }

  .btn_content_hub_utls .modal-content_hub {
    width: 98%;
    margin: 0x auto;
  }

}

@media (max-width: 480px) {
  .btn_content_hub_utls .item {
    flex: 1 1 100%;
    /* En pantallas pequeñas, todas las tarjetas son de ancho completo */
  }
}

/********Detailles de anuncio ***********/

.content_detail_hubmovie {
  width: 90%;
  margin: 6px auto;
}

.member-details {
  padding-top: 25px;
  padding-bottom: 25px
}

@media (min-width:992px) {
  .member-details {
    padding-top: 20px;
    padding-bottom: 20px
  }
}

.img-container {
  margin: 6px auto;
}

.member-details .member_designation {
  margin-bottom: 30px
}

.member-details .member_designation h2 {
  margin: 15px;
  font-size: 22px;
  color: #525252;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.member-details .member_designation h2 a {
  color: #8a8a8b;
}

@media (min-width:768px) {

  .member-details .member_designation h2 {
    margin-top: 0
  }
}

.member-details .member_designation span {
  font-style: italic
}

.member-details .member_desc li {
  display: block;
  float: unset;
  width: 100%
}

.member-details .member_desc li i {
  color: #0cc652;
  font-size: 14px
}

.member-details .member_desc h4 {
  margin-top: 40px
}

.member-details .member_desc p {
  margin-top: 10px
}

.member-details .member_desc .progress-holder {
  margin-top: 30px
}

.member-details .media-box {
  margin-bottom: 20px
}

@media (min-width:992px) {
  .member-details .media-box {
    margin-bottom: 0
  }
}

.member-details .member_contact {
  padding: 25px;
  position: relative;
  margin-top: 8px 20px;
  background-color: #6161615b;
}

.member-details .member_contact .media-icon {
  font-size: 32px;
  color: #dae0e6;
  position: relative;
  width: 30px;
  text-align: center;
  float: left;
  margin-right: 15px
}

.member-details .member_contact .media-content {
  padding-left: 0;
  float: left
}

.member-details .member_contact .media-content h5 {
  font-size: 15px
}

.member-details .member_contact .media-content h5,
.member-details .member_contact .media-content a {
  color: #dae0e6
}

@media (min-width:992px) {
  .member-details .member_contact .social-icons {
    text-align: right
  }
}

.member-details .member_contact .social-icons .btn-social {
  width: 40px;
  height: 40px;
}

.member-details .member_contact .social-icons .btn {
  background-color: transparent;
  border: 1px solid;
  border-color: #999;
  color: #dae0e6
}

.member-details .member_contact .social-icons .btn:hover {
  background-color: #0cc652;
  border-color: #0cc652;
  opacity: 1
}

.bg-image-holder,
.bg-image {
  background-size: cover !important;
  background-position: 50% 0 !important;
  transition: all .3s linear;
  background: #8a8a8b;
  position: relative;
}

.bg-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bg-image:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .7)
}

.bg-fixed {
  background-attachment: fixed
}

.bg-image .overlay-content {
  position: relative;
  z-index: 5
}


.progress-holder {
  margin-top: 50px
}

.progress-holder .barWrapper .progressText {
  font-size: 15px;
  color: #222
}

.progress-holder .progress {
  margin-bottom: 25px;
  margin-top: 10px;
  overflow: visible;
  background-color: #f5f5f6
}

.progress-holder .progress .progress-bar {
  position: relative
}

.progress-holder .progress .progress-bar:after {
  position: absolute;
  content: '';
  width: 1px;
  height: 33px;
  background-color: #0cc652;
  top: -8px;
  right: 0;
  z-index: 55
}

.img-full {
  width: 100%;
}

.member-details p {
  color: #8b8e93;
  font-weight: 300;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 26px;
}


.styled_list {
  margin-top: 15px;
  position: relative;
  display: inline-block
}

@media (min-width:768px) {
  .styled_list {
    margin-top: 15px
  }
}

.styled_list li {
  font-size: 14px;
  line-height: 30px
}

@media (min-width:768px) {
  .styled_list li {
    font-size: 14px;
    float: left;
    width: 50%
  }
}

.styled_list li i {
  margin-right: 10px;
  font-size: 12px
}

.styled_list li a {
  color: #8b8e93
}

@media (min-width:768px) {
  .styled_list li a {
    font-size: 12px
  }
}

@media (min-width:992px) {
  .styled_list li a {
    font-size: 14px
  }
}

ol.styled_list {
  margin-left: 15px
}

ol.styled_list li {
  padding-left: 10px
}

@media (max-width:750px) {
  .content_detail_hubmovie {
    width: 100%;
  }
}

.content_detail_hubmovie h3 {
  font-size: 22px;
  margin: 40px auto;
  color: #424242;
  padding: 8px;
}

/************/
.content_movie_expo,
.content_movie_retringi {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* Hace que el contenedor ocupe el 100% del ancho */
  height: 100%;
  /* Ajusta esta altura según lo que necesites */
  max-width: 100%;
  /* Asegura que el contenedor no sobrepase el ancho */
  overflow: hidden;
  /* Oculta cualquier desbordamiento */
}

.main-container {
  display: flex;
  padding: 2px;
  margin: 20px auto;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  /* Espacio entre los reproductores de video */
}

.content_movie_expo {
  width: 100%;
  position: relative;
  padding-top: 56.25%;
  /* Relación de aspecto 16:9 */
  max-height: 90vh;
  /* Limita la altura al 80% de la pantalla en dispositivos grandes */
  overflow: hidden;
  min-height: 50vh;
}

.content_movie_expo iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.conten_relacion_detailhub {
  width: 100%;
  margin: 40px 2px;
  text-align: center;
  padding: 4px;
  font-size: 24px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: #707070;
}

.conten_btn_hubpaypal {
  padding: 8px;
  display: flex;
  width: 30%;
  align-items: center;
  border-radius: 5px;
  background-color: #ffff;
  margin: 10px 0;
  box-shadow: rgba(223, 139, 139, 0.24) 0px 3px 8px;
}

#nombre_multimet_cantidad {
  font-size: 15px;
  font-weight: 700;
  color: crimson;
}

.conten__hubpaypal_he {
  margin: 4px 12px;
  height: 100%;
  width: 150px;
  padding: 5px;
  text-align: justify;
}

.conten__hubpaypal_he span {
  font-size: 10px;
  color: #5a5a5a;
  font-weight: 600;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.preci_content_hub {
  display: flex;
  /* Usa flexbox para facilitar el centrado */
  flex-direction: column;
  /* Alinea los elementos verticalmente */
  align-items: center;
  /* Centra horizontalmente */
  justify-content: center;
  /* Centra verticalmente */
  text-align: center;
  /* Centra el texto */
  padding: 12px 16px;
  /* Añade algo de espacio alrededor */
  background-color: #f7f7f7;
  /* Color de fondo para dar un toque visual */
  border-radius: 8px;
  /* Bordes redondeados */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Sombra ligera */
}

.preci_content_hub h2 {
  margin: 0;
  /* Elimina el margen para ajustar mejor el espacio */
  font-size: 18px;
  /* Ajusta el tamaño de la fuente */
  color: #333;
  /* Color del texto del precio */
}

.preci_content_hub small {
  font-size: 14px;
  /* Tamaño de fuente más pequeño para "mes" */
  color: #666;
  /* Color más claro para "mes" */
}

.paypal-button-container {
  width: 100%;
}

@media (max-width:750px) {
  .extra-info {
    width: 75%;
  }

  .preci_content_hub {
    width: 30%;
  }

  .preci_content_hub h2 {
    font-size: 16px;
  }

  .conten_btn_hubpaypal {
    width: 96%;
    justify-content: space-between;
    font-size: 8px;
    margin: 6px auto;
  }

  .content_detail_hubmovie h3 {
    font-size: 12px;
    margin: 16px auto 0;
  }
}

/************/

.vid_tiktok_title {
  margin: 5px;
  font-size: 18px;
  color: #333;
  text-align: center;
}

.content_tiktok_vid {
  display: flex;
  /* Coloca los elementos en línea */
  overflow-x: auto;
  /* Permite el desplazamiento horizontal */
  padding: 5px 10px;
  grid-gap: 20px;
  gap: 20px;
  /* Espacio entre los videos */
}

.content_tiktok_vid::-webkit-scrollbar {
  height: 8px;
  /* Altura de la barra de desplazamiento */
}

.content_tiktok_vid::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color de la barra */
  border-radius: 4px;
  /* Bordes redondeados */
}

.content_tiktok_vid h2 {
  flex-basis: 100%;
  /* Título ocupa el 100% del ancho antes del carrusel */
}

.content_tiktok_vid>div {
  min-width: 300px;
  /* Ancho mínimo para los videos */
  flex-shrink: 0;
  /* Evita que se redimensionen los elementos */
  display: flex;
  justify-content: center;
  /* Centra el video */
}





@media (max-width: 750px) {

  .content_hub_home {
    flex-direction: column;
    width: 98%;
  }

  .content_hub_medid {
    justify-content: space-around;
    /* Ajusta la alineación en pantallas pequeñas */
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .cardhub {
    width: 90%;
    min-width: 350px;
    /* Reducir el ancho mínimo en pantallas más pequeñas */
  }
}
.Contenthub_inicio_destacat {
	width: 90vw;
	margin: 8px auto;
	padding: 10px;
}

.Contenthub_inicio_destacat .container {
	background-color: #fff;
	position: relative;
	overflow: hidden;
	width: 100%;
	min-height: 500px;
	padding: 0;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
}

.overlay-container {
	width: 50%;
	background: linear-gradient(-45deg, #ee7752, #f56da1, #8cf2ff, #ff5fb7);
	background-size: 400% 400%;
	animation: gradient 12s ease infinite;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}

.overlay-panel {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}


@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}

	50% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0% 50%;
	}
}


/**/

.overlay-panel h2 {
	font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
	font-size: 20px;
	margin-bottom: 20px;
	color: #5c5c5c;
}

.overlay-panel h1 {
	display: flex;
	align-items: center;
	font-family: "Protest Revolution", sans-serif;
	font-size: 42px;
	color: #414141;
	margin: 8px auto;
	text-shadow: 1px 4px 3px rgba(0, 0, 0, 0.6);
}

.overlay-panel p {
	font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
	font-size: 10px;
	color: #666666;
}

.overlay-panel .heart {
	font-size: 50px;
	margin-right: 15px;
	color: rgb(255, 80, 80);
	animation: beat2 .4s infinite alternate;
	transform-origin: center;
}

.overlay-panel .heart.left {
	margin-left: 15px;
}

/* Heart beat animation */
@keyframes beat2 {
	to {
		transform: scale(1.2);
	}
}

/*/*/

/* From Uiverse.io by Wendell47 */
.button_registerhub {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 15px 30px;
	border: 0;
	position: relative;
	overflow: hidden;
	border-radius: 10rem;
	transition: all 0.02s;
	font-weight: bold;
	cursor: pointer;
	color: rgb(37, 37, 37);
	z-index: 0;
	box-shadow: 0 0px 7px -5px rgba(0, 0, 0, 0.5);
}

.button_registerhub:hover {
	background: rgb(193, 228, 248);
	color: rgb(33, 0, 85);
}

.button_registerhub:active {
	transform: scale(0.97);
}

.hoverEffect {
	position: absolute;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
}

.hoverEffect div {
	background: rgb(222, 0, 75);
	background: linear-gradient(90deg,
			rgba(222, 0, 75, 1) 0%,
			rgba(191, 70, 255, 1) 49%,
			rgba(0, 212, 255, 1) 100%);
	border-radius: 40rem;
	width: 10rem;
	height: 10rem;
	transition: 0.4s;
	-webkit-filter: blur(20px);
	        filter: blur(20px);
	animation: effect infinite 3s linear;
	opacity: 0.5;
}

.button_registerhub:hover .hoverEffect div {
	width: 8rem;
	height: 8rem;
}

@keyframes effect {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}



/***/

.form-container {
	width: 50%;
}


.Contenthub_inicio_destacat .auth_page-login {
	width: 100%;
	height: 100%;
	border: 0;
	margin: 0;
}


.Contenthub_inicio_destacat h1 {
	font-weight: bold;
	margin: 0;
}

.Contenthub_inicio_destacat h2 {
	text-align: center;
}

.Contenthub_inicio_destacat p {
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 5px 0;
}

.Contenthub_inicio_destacat span {
	font-size: 12px;
}

.Contenthub_inicio_destacat a {
	color: #333;
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
}

/*
.Contenthub_inicio_destacat button {
	border-radius: 20px;
	border: 1px solid #FF4B2B;
	background-color: #FF4B2B;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
}*/

.Contenthub_inicio_destacat button:active {
	transform: scale(0.95);
}

.Contenthub_inicio_destacat button:focus {
	outline: none;
}

.Contenthub_inicio_destacat button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}

.Contenthub_inicio_destacat form {
	display: flex;
	flex-direction: column;
	height: 100%;
	text-align: center;
}

.Contenthub_inicio_destacat input {
	background-color: #eee;
	border: none;
	padding: 12px 15px;
	margin: 10px 0;
	width: 100%;
}

/****/
.auth_page-login form .social-container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 10px auto;
}

.auth_page-login form .social-container a {
	border: 1px solid #DDDDDD;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 40px;
	text-decoration: none;
	-webkit-text-emphasis: none;
	        text-emphasis: none;
}

#google-signin-btn {
	border: solid rgba(255, 120, 79, 0.527) 2px;
	margin: 15px auto;
	width: 100%;
}

/** tarjed content **/
.cart_contethub_public {
    width: 84%;
    background-color: #ffffff; /* Fondo blanco para la tarjeta */
    border-radius: 12px; /* Bordes redondeados */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Sombra suave */
    overflow: hidden; /* Para que los bordes redondeados funcionen bien */
    margin: 20px auto; /* Espaciado entre tarjetas */
height: auto;
}

.cart_contethub_public .header_cart_contenthub {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #ddd; /* Líneas sutiles para separar secciones */
}

.cart_contethub_public .header_cart_contenthub img {
    width: 40px;
    height: 40px;
    border-radius: 50%; /* Imagen redondeada */
    object-fit: cover; /* Asegura que la imagen se recorte correctamente */
    margin-right: 20px;
}

.cart_contethub_public .header_cart_contenthub h1 {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    color: #333;
}

.cart_contethub_public .header_cart_contenthub small {
    font-size: 12px;
    color: #888;
}

.cart_contethub_public .content_cart_description {
    padding: 8px 20px;
    font-size: 14px;
    color: #555;
    line-height: 1.5;
    height: auto; /* Permite que el contenido se ajuste */
    overflow: hidden;
    text-overflow: ellipsis; /* Agrega puntos suspensivos si el texto es largo */
}

.content_img_contenhub {
    width: 100%; /* Se adapta al ancho disponible */
    overflow: hidden; /* Evita que contenido adicional sobresalga */
}
.content_img_contenhub video {
    width: 100%; /* Ajusta el video al contenedor */
    height: auto; /* Mantiene la proporción */
    object-fit: contain;
}

.cart_contethub_public:hover {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); /* Sombra más fuerte al pasar el ratón */
}

.cart_contethub_public .header_cart_contenthub:hover {
    background-color: #f0f0f0; /* Color de fondo más oscuro al pasar el ratón */
}

.h2_containerhub {
	width: 80%;
	margin: 60px auto;
	padding: 8px;
	font-size: 24px;
	font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
	color: #5c5c5c;
}




@media (max-width: 768px) {
    .cart_contethub_public {
		width: 100%;
        margin: 10px auto; /* Menos margen en pantallas pequeñas */
    }

    .cart_contethub_public .header_cart_contenthub h1 {
        font-size: 14px;
    }

    .cart_contethub_public .content_cart_description {
        font-size: 12px;
    }
}


@media (max-width: 750px) {
	.Contenthub_inicio_destacat {
		width: 100%;
		margin: 0;
	}

	.Contenthub_inicio_destacat .container {
		flex-direction: column;
	}

	.overlay-container {
		width: 100%;
	}

	.form-container {
		width: 100%;
	}

	.overlay-panel {
		padding: 5px;
	}

	.overlay-panel h2 {
		font-size: 12px;
		margin: 14px;
	}

	.overlay-panel h1 {
		font-size: 36px;
		margin: 14px;
	}

	.overlay-panel .heart {
		font-size: 32px;
	}

	.Contenthub_inicio_destacat p {
		margin: 8px auto;
		font-size: 10.5px;
		width: 80%;
		text-align: center;
	}

	.content_cart_description p {
		margin: 2px auto;
		font-size: 10.5px;
		width: 100%;
		text-align: justify;
	}
}
* {
    padding: 0;
    box-sizing: border-box;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  #theme{
    display: none;
  }
  
  #theme:checked ~ * {
    -webkit-filter: invert(1);
            filter: invert(1);
  }
  
  .App{
    width: 100%;
    min-height: 100vh;
    background: white;
  }
  
  .main {
    width: 100%;
    min-height: 100vh;
    background: #F4F6F6;
  }
  
  img{
    object-fit: cover;
  }
  .avatar{
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

/*** warningmodal **/
.modal-overlay_waring {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500000000000000;
}

.modal-content_waring {
  background: rgb(255, 49, 49);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.modal-content_waring h2 {
  color: #fff;
}

.modal-content_waring p {
  color: #ccc;
  font-size: 20px;
}

.modal-content_waring span {
  color: #fff;
  font-weight: 600;
}

.modal-content_waring button {
  margin: 10px;
  font-size: 16px;
  border: none;
  padding: 5px 10px;
  border-radius: 2px;
  background-color: #424242;
  color: #fff
}

@media (max-width: 750px) {
  .modal-content_waring {
    width: 70%;
  }

  .modal-content_waring h2 {
    font-size: 18px;
  }

  .modal-content_waring p {
    font-size: 16px;
  }

  .modal-content_waring button {
    font-size: 14px;
    padding: 2px 10px;
    margin: 4px 20px
  }
}
  
  /*---auth----*/
  
  /*---loading----*/
  
  /*---header----*/
  
  /*---avatar----*/

  /*---Profile----*/

   /*---home----*/

   /*---status_modal----*/

   /* -------- Comments ---------- */

   /* -------- Post Thumb ---------- */

   /* -------- Message---------- */

  /* -------- Icons---------- */

  /* -------- Call Modal---------- */


  /* --------- Responsive ------ */
  @media (max-width: 766px){

  .header .menu .navbar-nav{
      display: flex;
      justify-content: space-around;
      align-items: center;
  }
  
  .header .search_form{
      width: 60%;
  }
  .header .logo{
      margin: 0px;
  }
  .header .logo h1{
      font-size: 1.3rem;
  }
  
  .message{
    height: calc(100vh - 100px);
  }
  .right_mess,
  .left_mess{
    display: none;
  }
  .status{
    padding: 20px 10px;
  }
  .chat_container{
    transform: translate(0px, -45px);
  }
  .chat_input {
    transform: translate(0px, -45px);
    position: relative;
    z-index: 2;
    top: 45px;
  }
  .message_header, .chat_container{
    position: relative;
  }
  .message_header{
    z-index: 2;
    top: -10px;
  }
  .chat_container{
    z-index: 1;
    top: 30px;
  }

}

.posts {
  max-width: 1025px;
  margin: 0px auto;
}

/*********Chat btn************/
.chat-float {
  position: fixed;
  bottom: 19vh;
  right: 22%;
  background-color: #D1F2EB;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
  z-index: 9999;
  border-radius: 8px;
}

.chatfloat-btn {
  background-color: #03eeb3;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: fixed;
  bottom: 10vh;
  right: 20%;
  z-index: 10000;
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 26px;
  transition: all 0.8s ease;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.chatfloat-btn:hover {
  background-color: #9fdfd1;
  color: #646464;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.btn_input_chat {
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: #e6fff9;
  border: solid 0.6px #9fdfd1;
}

.sumit_form_chat {
  border: none;
  width: 60px;
  height: 30px;
  border-radius: 6px;
  text-align: center;
  background-color: #73ffdc;
  color: rgb(1, 65, 5);
  font-weight: 600;
  transition: all 1s ease;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.sumit_form_chat:hover {
  background-color: #ccc;
}

@media (max-width: 750px) {
  .chatfloat-btn {
    bottom: 15vh;
  right: 5%;
  width: 45px;
  height: 45px;
  font-size: 19px
  }

  .chat-float {
  bottom: 23vh;
  right: 8%;
  }
}


/*--------------------HeaderPro--------------------*/

/*-----------Products-----*/

/*-----------Detail Product-------*/

/*----------cart-----------*/

/*----------cart-detalles-----------*/

/*-------categories-------*/

/*-----------CrearProduct------*/

/* ----------------- Responsive ------------------ */

/*------MenuDos-----*/
   .mode{
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

/*------buscador-----*/

/*------catalago-----*/

/*-----pagetiner----*/

/*-------Pricipal-----*/

/*----start-----*/

/*-------Mapaubicaciones---------*/

/*-------Carrosel-slick---------*/

/*-------anuncio---------*/

/*-------publicar anuncio---------*/

/*-------contactanos---------*/

/*-------terminos---------*/

/*-------politicas---------*/

/*-------tiktokefect---------*/

/*-------tiktokefect---------*/

/*-------videoefect---------*/

/*-------videosfowers--------*/

/*-------ventacontenido--------*/

/*----red social-----*/

/*----publicidad home-----*/

/*----barraderech-----*/

/*----barraderech-----*/

/*----barraderech-----*/

/*----barraderech-----*/

/*----Contenidos hub-----*/

/*----Contenidos hub-----*/




.load-page{
    background: #fff;
    overflow: hidden;
}
.loader{
    width: 300px;
    height: 300px;
    margin: auto;
}
.loader div{
    width: calc(100% - 15px);
    height: calc(100% - 15px);
    border: 2px solid #fff;
    border-top: 2px solid crimson;
    border-radius: 50%;
    animation: rotate 10s linear infinite alternate-reverse;
}
@keyframes rotate{
    50%{
        transform: rotate(80deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
