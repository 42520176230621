@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

* {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.catalogo_card {
    height: 420px;
    margin: 1rem;
    background: #FDFEFE;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border-radius: 12px;
    overflow: hidden;
    transition: all 0.2s linear;
    cursor: pointer;
    position: relative;
}

.catalogo_card:hover {
    box-shadow: 2px 8px 20px #0004;
    transform: translate3d(0, -3px, 0);
}

.catalogo_card h2 {
    text-align: center;
    padding: 1rem 0;
    background: linear-gradient(120deg, #fbc2eb 0%, #a6c1ee 100%);
    font-size: 1.1rem;
    color: white;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 0;
    font-family: 'Impact', 'fantasy';
    text-shadow: 1px 1px 2px black;
}

.catalogo_card .spanh1 {
    color: #F0F3F4;
    font-family: 'Jazz LET', 'fantasy';
    font-size: 12px;
}

/*---h2 degradado---*/

.catalogo_card:nth-child(2n) h2 {
    background: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
}

.catalogo_card:nth-child(4n) h2 {
    background: linear-gradient(120deg, #ff9a9e 0%, #fecfef 100%);
}

.catalogo_card:nth-child(5n) h2 {
    background: linear-gradient(120deg, #93b1f1 0%, #44e4f0 100%);
}

.catalogo_card:nth-child(6n) h2 {
    background: linear-gradient(120deg, #ffc3a0 0%, #ffafbd 100%);
}

.catalogo_card:nth-child(7n) h2 {
    background: linear-gradient(120deg, #f7bf9a 0%, #f37f7b);
}

.catalogo_card:nth-child(8n) h2 {
    background: linear-gradient(120deg, #f098dd 0%, #f8c27b 100%);
}

.catalogo_card:nth-child(9n) h2 {
    background: linear-gradient(120deg, 100% #d5db7c 0%, #f8bf23 100%);
}

.catalogo_card:nth-child(10n) h2 {
    background: linear-gradient(120deg, #9be8ff 0%, #7885ff 100%);
}

.catalogo_card:nth-child(11n) h2 {
    background: linear-gradient(120deg, #f098dd 0%, #f8c27b 100%);
}

/*-----------*/

.catalogo_card img {
    display: block;
    width: 100%;
    height: 90%;
    object-fit: cover;
    object-position: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

.catalogo_card .image {
    position: relative;
    bottom: 20%;
    left: 6%;
    display: grid;
    grid-template-columns: [first] 22px [line2] 200px;
    grid-template-rows: [row1-start] 22px [row1-end] 22px;
    grid-gap: 5px;
}

#img_cart_ubication {
width: 70%;
height: 90%;
margin: 2px auto;
}

.catalogo_card .image img {
    width: 100%;
    border-radius: 4px;
    height: 100%;
}

.catalogo_card .image .spanuno {
    font-family: 'Stencil Std', 'fantasy';
    color: #FBFCFC;
    font-size: 15px;
    text-shadow: 1px 1px 2px black;
    font-weight: 600;
}

.catalogo_card .image .spandos {
    font-family: 'Snell Roundhand', cursive;
    color: #D5D8DC;
    font-size: 13.5px;
    text-shadow: 1px 1px 2px black;
}

.catalogo_card .material-icons {
    position: relative;
    top: -98%;
    left: 82%;
    color: #dbdedf;
    font-size: 32px;
}

.catalogo_card .material-icons:hover {
    box-shadow: 2px 8px 20px rgba(230, 228, 228, 0.363);
    color: red;
}

/*----material-hover---*/
[data-md-tooltip] {
    position: relative;
}

[data-md-tooltip]:before {
    content: attr(data-md-tooltip);
    position: absolute;
    bottom: -35px;
    left: 50%;
    padding: 8px;
    transform: translateX(-50%) scale(0);
    transition: transform 0.3s ease-in-out;
    transform-origin: top;
    background: #616161e6;
    color: white;
    border-radius: 2px;
    font-size: 12px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
}

[data-md-tooltip]:hover:before {
    transform: translateX(-50%) scale(1);
}

/*-----btn guradar---------*/

.row_btnCatalogo {
    position: absolute;
    top: 15%;
    left: 74%;
}


/*----------*/

.catalogo_card .botones {
    position: relative;
    top: -98%;
    left: -4%;
}


/*--- Responsive----*/

@media (min-width: 600px) {
    .catalogo_card {
        flex: 1 1 calc(50% - 2rem)
    }
}

@media (min-width: 900px) {
    .catalogo_card {
        flex: 1 1 calc(33% - 2rem)
    }
}

@media (max-width: 750px) {

    .catalogo_card h2 {
        text-align: center;
        padding: 0.8rem 0;
        font-size: 13px;
        color: white;
        letter-spacing: 2px;
        margin-bottom: 0;
    }

    .catalogo_card {
        height: 340px;
        margin: 16px 0 16px 0;
    }

    .catalogo_card img {
        width: 100%;
        height: 90%;
        object-fit: cover;
    }

    .catalogo_card .image {
        grid-template-columns: [first] 18px [line2] 200px;
        grid-template-rows: [row1-start] 18px [row1-end] 18px;
        grid-gap: 5px;
    }

    .catalogo_card .image .spanuno {
        font-size: 13px;
    }

    .catalogo_card .image .spandos {
        font-size: 11px;
    }

    .catalogo_card .material-icons {
        top: -98%;
        left: 78%;
        font-size: 28px;
    }

    .catalogo_card .spanh1 {
        font-size: 10px;
    }

    .row_btnCatalogo {
        top: 15%;
        left: 63%;
    }
}