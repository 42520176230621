.profile {
    width: 100%;
    min-height: 100vh;
}

/* ------ Info ---------- */

.info {
    width: 100%;
    max-width: 1000px;
    margin: 0px auto;
    margin-bottom: 8px;
    padding: 3px 10px;
}

.informe {
    margin: 18px 0px;
    font-size: 18px;
}

.informe .informe_fullname span {
    color: #566573;
    font-size: 17px;
}

.informe .informe_fullname i {
    font-size: 17px;
    color: #616A6B;
    margin-Right: 4px;
}

.informe .informe_sobrenombre span {
    margin-left: 5px;
}

.informe .informe_sobrenombre small {
    font-size: 16px;
}

.informe .informe_sobrenombre i {
    font-size: 14px;
}

.informe .informacion_user span {
    font-size: 18px;
    margin-left: 5px;
}

.informe .informacion_user i {
    font-size: 14px;
}

.informe .informe_website {
    max-width: 96%;
    overflow: hidden;
    height: 28px;
    white-space: nowrap;
}

.informe .informe_website a {
    font-size: 18px;
}

._info_addres {
    max-width: 96%;
    overflow: hidden;
    height: 28px;
    white-space: nowrap;
}

.informe .informe_history p {
    font-size: 16px;
    margin: 8px auto;
    color: rgb(87, 87, 87);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.informe span {
    color: #566573;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif,
}

.info_container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    position: relative;
    margin: 3px auto;
}

.info_content {
    min-width: 280px;
    max-width: 550px;
    width: 100%;
    flex: 1;
    opacity: 0.9;
    margin: 5px 15px;
}

#icon_location_ {
    margin-right: 5px;
    position: relative;
    top: 5px;
}


.info_content .pais-origen {
    display: flex;
    width: 100%;
    color: rgb(112, 111, 111);
    justify-content: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.info_content_title {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    flex-wrap: wrap;
}

.info_content_title h2 {
    font-size: 2rem;
    font-weight: 400;
    transform: translateY(4px);
    flex: 3;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: #56585a
}

.info_content_title button {
    width: 116px;
    height: 35px;
    font-size: 12px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    padding: 0;
}

.info_container-img {
    width: 100%;
    margin-bottom: 80px;
}

#nacio_stile {
    display: block;
}

#nacio_stile_none {
    display: none;
}

/*---*/
.veri_orientacion {
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    top: -2px;
    margin-left: 5px;
}


/*--------Profile edit-----------*/
.edit_profile {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100vw;
    height: 100%;
    background: #0008;
    z-index: 90000;
    overflow: auto;
}

.edit_profile form {
    max-width: 450px;
    width: 98%;
    background: white;
    padding: 20px;
    border-radius: 5px;
    margin: 20px auto;
}

.edit_profile .btn_close {
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.edit_profile .info_avatar {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    margin: 15px auto;
    border: 1px solid #ddd;
    cursor: pointer;
}

.edit_profile .info_avatar img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}

.edit_profile .info_avatar span {
    position: absolute;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: 50%;
    text-align: center;
    color: orange;
    transition: 0.3 ease-in-out;
    background: #fff5;
}

.edit_profile .info_avatar:hover span {
    bottom: -15%;
}

.edit_profile .info_avatar #file_up {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}

::-webkit-file-upload-button {
    cursor: pointer;
}

/*-----Follow------*/

.follow {
    position: fixed;
    top: 0;
    left: 0;
    background: #0008;
    width: 100%;
    height: 100vh;
    z-index: 4;
}

.follow_box {
    width: 350px;
    height: 400px;
    border: 1px solid #222;
    border-radius: 5px;
    background: white;
    padding: 20px 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: auto;
}

.follow_box .close {
    position: absolute;
    top: 0;
    right: 3px;
    font-size: 3rem;
    cursor: pointer;
}

/* --------- Save Tab ----------- */
.profile_tab {
    width: 100%;
    display: flex;
    justify-content: center;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.profile_tab button {
    outline: none;
    border: none;
    background: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 5px 20px;
    margin: 0 25px;
    opacity: 0.5;
}

.profile_tab button.active {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    opacity: 0.8;
}

/* --------- botton chat ----------- */

.btn-info {
    margin: 7px;
}

/*-----------------*/

.info_container .follow_btn {
    margin: 5px auto;
    position: relative;
    color: #56585a;
    width: 100%;
    height: 75px;
    display: flex;
}

.follow_btn .follow_btn-segu {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    width: 50%;
    height: 94%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 5px;
    cursor: pointer;
    transition: all 0.8s ease;
    margin: 6px;
}

.follow_btn .follow_btn-segu .mr-seguidor {
    margin: 0px auto;
    font-size: 22px;
    font-weight: 700;
    color: #474747;
}

.follow_btn .follow_btn-segu small {
    color: #8d7777;
}

.follow_btn .follow_btn-segu:hover {
    background: rgba(255, 229, 219, 0.479);
    box-shadow: rgba(255, 161, 124, 0.699) 0px 2px 8px 0px;
}


#verify-user {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    font-size: 22px;
    top: 14%;
    left: 55.5%;
    background: #84effd;
    color: white;
    font-weight: 900;
}

.pruebas_stylos {
    display: none;
}

.pruebas_stylos_dos {
    display: block;
}

.btn_desordenar_datos_home {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    border-radius: 50%;
    border: none;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    font-size: 22px;
    margin-left: 5px;
    color: #7a7979;
}

.status_modal_alert_sus {
    position: fixed;
    top: 200px;
    left: 0;
    background: #0008;
    z-index: 10000;
    width: 100%;
    height: 100%;
    overflow: auto;
}

#alerta-titulo {
    background: rgb(255, 65, 65);
    width: 45%;
    height: 50vh;
    position: absolute;
    top: 25vh;
    left: 25%;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
}

#alerta-titulo h4 {
    font-size: 18px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: whitesmoke;
    font-weight: 500;
}

#alerta-titulo i {
    font-size: 50px;
    margin: 15px 8px;
    color: #ffffff;
}

#alerta-titulo .outline-secondary {
    background: rgb(112, 112, 112);
    margin: 10px;
    border: none;
    border-radius: 8px;
    color: rgb(236, 236, 236);
    padding: 2px 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    font-weight: 600;
}

a .outline-secondary {
    background: rgb(112, 112, 112);
    margin: 10px;
    border: none;
    border-radius: 8px;
    color: rgb(236, 236, 236);
    padding: 2px 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    font-weight: 600;
}

#alerta-titulo .outline-danger {
    background: rgb(66, 43, 43);
    margin: 10px;
    border: none;
    border-radius: 8px;
    color: whitesmoke;
    padding: 2px 10px;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/*
style={{ display: `${user.informacionuno.length == 0 ? 'none' : 'block'}` }}
*/

/*
style={{ display: `${user.mobile.length == 0 ? 'none' : 'block'}` }}
*/

.home-page-btn {
    display: flex;
    justify-content: center;
    padding: 2px 0px;
}

.home-page-btn a {
    width: 25%;
}

.home-page-btn a button {
    width: 100%;
    border: none;
    color: #464646;
    border-left: solid 1px #c7c6c6;
    border-right: solid 1px #c7c6c6;
}

.home-page-btn a button span {
    margin-left: 4px;
}

.home-page-btn a button img {
    width: 20px;
}

#activebuttoP {
    background: rgb(53, 52, 52);
    color: rgb(255, 255, 255);
}

.cantidad_content {
    max-width: 1025px;
    margin: 4px auto;
    display: flex;
    justify-content: space-around;
    transition: all 1s ease;
}

.cantidad_content h4 {
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: 20px;
    transition: all 1s ease;
    background-color: #FDFEFE;
}

.cantidad_content h4:hover {
    background: rgb(250, 240, 255);
    background: linear-gradient(306deg, rgba(250, 240, 255, 1) 18%, rgba(252, 246, 255, 1) 39%, rgba(255, 236, 236, 1) 61%, rgba(255, 234, 234, 1) 83%);
}

.cantidad_content h4 span {
    color: #464646;
    font-size: 20px;
}

.list_pots_container {
    max-width: 1025px;
    margin: 10px auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px 20px;
}

.card_list_potsu {
    background: white;
    overflow: hidden;
    text-align: center;
}

.card_list_potsu span {
    color: rgb(139, 3, 3);
    background: rgba(253, 67, 67, 0.541);
}

.card_list_potsu img,
.card_list_potsu video {
    width: 100%;
    height: 450px;
}


/******************/

#alerta-titulo-telegram {
    position: fixed;
    height: 50px;
    z-index: 1000;
    width: 100%;
    background: rgb(109, 154, 253);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 82px;
}

#alerta-titulo-telegram a {
    text-decoration: none;
}

#alerta-titulo-telegram a span {
    color: #ffffff;
    font-size: 20px;
}

#alerta-titulo-telegram button {
    position: absolute;
    border: none;
    width: 30px;
    height: 30px;
    margin: 5px;
    right: 5px;
    color: #f1f1f1;
    background: rgb(180 200 255 / 85%);
    border-radius: 4px;
}

#btn_LoadMoreBtn {
    max-width: 1025px;
    margin: 4px auto;
    padding: 8px;
    display: flex;
    justify-content: center;
}

#btn_LoadMoreBtn button {
    width: 50%;
    border: none;
    background-color: white;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    transition: all 1s ease;
    border: solid 1px white;
}

#btn_LoadMoreBtn button:hover {
    background-color: rgb(255, 169, 89);
    border: solid 1px rgb(252, 211, 178);
    box-shadow: rgba(255, 187, 147, 0.25) 0px 54px 55px, rgba(255, 141, 88, 0.12) 0px -12px 30px, rgba(250, 106, 49, 0.12) 0px 4px 6px, rgba(253, 79, 35, 0.17) 0px 12px 13px, rgba(255, 65, 8, 0.09) 0px -3px 5px;
}

/******edition date for Public*******/

.content_info_user {
    display: flex;
    flex-direction: column;
}

.content_info_user h2 {
    font-size: 19px;
    color: #474747;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.content_info_public {
    display: flex;
    flex-direction: column;
    padding: 8px 10px;
}

.content_info_public h4 {
    font-size: 15px;
    color: #222;
}

.content_info_public h4 span {
    color: crimson;
}

@media (max-width: 750px) {
    
    #alerta-titulo-telegram {
        height: 42px;
        justify-content: start;
        padding: 0 1px;
    }

    #alerta-titulo-telegram a {
        margin: 4px;
        padding: 4px;
    }

    #alerta-titulo-telegram a span {
        color: #ffffff;
        font-size: 13px;
    }

    #alerta-titulo-telegram button {
        position: relative;
        width: 24px;
        height: 24px;
    }
}

@media (max-width: 750px) {
    .profile_tabNew button {
        font-size: 16px;
        margin: 10px 0px 0px;
        height: 100%;
        width: 100%;
        padding: 2px 0;
    }

    .cantidad_content h4 {

        font-size: 16px;
    }

    .cantidad_content h4 span {
        font-size: 12px;
    }

}

@media (max-width: 750px) {
    .home-page-btn a button {
        font-size: 12px;
    }

    .home-page-btn a button img {
        width: 18px;
    }

}


@media (max-width: 750px) {
    .info_content_title {
        display: flex;
        flex-direction: column;
    }

    .info_content_title .title-perfil {
        position: relative;
        display: flex;
        justify-content: center;
    }

    .info_content_title .title-perfil h2 {
        margin: 2px auto;
        position: relative;
        font-size: 17px;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        color: #56585a;
    }

    .info_container-img {
        margin: 5px;
        height: 142px;
    }

    .edit_profile .btn_close {
        position: fixed;
        z-index: 100;
    }

    #verify-user {
        position: absolute;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        text-align: center;
        font-size: 18px;
        top: 14%;
        left: 61%;
        color: white;
    }

    .info_container .follow_btn {
        margin: 2.5px 0px;
        overflow: hidden;
        position: relative;
        top: 0px;
        color: #56585a;
        width: 100%;
        height: 50px;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .follow_btn .follow_btn-segu {
        display: inline-grid;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        width: 96%;
        height: 90%;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        padding: 5px;
        cursor: pointer;
        margin: 0px 2px;
    }

    .follow_btn .follow_btn-segu .mr-seguidor {
        margin: 0px auto;
        font-size: 16px;
        font-weight: 700;
        color: #474747;
    }

    .follow_btn .follow_btn-segu small {
        color: #8d7777;
        font-size: 10px;
    }

    .informe {
        position: relative;
        font-size: 14px;
    }

    .informe .informacion_user span {
        font-size: 12.5px;
    }

    .informe .informe_fullname span {
        font-size: 11.5px;
    }

    .informe .informe_sobrenombre small {
        font-size: 13px;
    }

    .informe .informacion_user i {
        font-size: 12px;
    }

    .informe .informe_sobrenombre i {
        font-size: 12px;
    }

    .informe .informe_website a {
        font-size: 11.8px;
    }

    .informe .informe_website {
        max-width: 96%;
        overflow: hidden;
        height: 20px;
        white-space: nowrap;
    }

    #icon_location_ {
        margin-right: 5px;
        position: relative;
        top: 4px;
        font-size: 14px;
    }

    ._info_addres {
        max-width: 96%;
        overflow: hidden;
        height: 20px;
        white-space: nowrap;
    }

    .informe .informe_fullname i {
        font-size: 12px;
    }

    .informe .informe_history p {
        margin: 5px auto;
        font-size: 12px;
    }

    .info_content_title button {
        width: 115px;
        height: 30px;
        font-size: 12px;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    }

    .profile_tab button {
        padding: 3px 15px;
        margin: 0 15px;
    }

    #alerta-titulo {
        bottom: 10px;
        left: 10%;
        width: 80%;
        height: 55vh;
    }

    #profiles_btn_perfil button {
        width: 75px;
        font-size: 12px;
        padding: 0;
    }

    .list_pots_container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px 20px;
    }

    .card_list_potsu h4 {
        font-size: 17px;
        color: crimson;
    }

    .card_list_potsu img,
    .card_list_potsu video {
        width: 100%;
        height: 350px;
    }

}